import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Braintree } from 'react-braintree-fields';

import clsx from 'clsx';

import { useForm } from 'utils/hooks';

import { Button, FieldRow, Input, InputBraintree, Loader } from 'components/common';

import Body from '../../../../components/Body';
import Footer from '../../../../components/Footer';
import { usePMCContext } from '../../../../context';
import { CreditCardProcessorProps } from '../../index';
import styles from './style.module.scss';

const DEVMODE_PAYMENT_INFO = {
  cardNumber: '5555555555554444',
  expiryDate: '12/2028',
  cvv: '123',
  label: 'Master card',
  zipCode: '10005',
};

enum ErrorCodes {
  FieldsEmpty = 'HOSTED_FIELDS_FIELDS_EMPTY',
  FieldsInvalid = 'HOSTED_FIELDS_FIELDS_INVALID',
  TokenizationDuplicate = 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE',
  TokenizationCVVVerification = 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED',
  Tokenization = 'HOSTED_FIELDS_FAILED_TOKENIZATION',
  TokenizationNetwork = 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR',
}

const BraintreeProcessor: FC<CreditCardProcessorProps> = ({ mutate, creditCardToEdit, buttonText }) => {
  const { clientToken, collectionType, devMode } = usePMCContext();

  // If the braintree sdk is ready to be rendered
  const [resetting, setResetting] = useState<boolean>(false);
  const [authorizedAndReady, setAuthorizedAndReady] = useState<boolean>(false);
  // Reference to get the nonce from braintree iframed fields
  const getNonceTokenRef = useRef<any>((_) => {}); // eslint-disable-line
  // Form state for custom (non-sdk) fields
  const form = useForm();

  useEffect(() => {
    if (creditCardToEdit) {
      form.setValue('label', creditCardToEdit.label);
      form.setValue('numberMasked', `**** **** **** ${creditCardToEdit.lastFourDigits || '****'}`);
    } else if (devMode) {
      form.setValue('label', DEVMODE_PAYMENT_INFO.label);
    }
  }, []);

  const validateBraintreeErrors = useCallback(
    (error: any) => {
      switch (error.SDK_ERROR_CODE) {
        case ErrorCodes.FieldsEmpty: {
          form.setError('number', 'Field can not be empty');
          form.setError('expirationDate', 'Field can not be empty');
          form.setError('cvv', 'Field can not be empty');
          break;
        }
        case ErrorCodes.FieldsInvalid: {
          if (error.details) {
            const { invalidFieldKeys } = error.details;
            invalidFieldKeys.forEach((invalidField) => {
              form.setError(invalidField, 'Field is not valid');
            });
          }
          break;
        }
        case ErrorCodes.TokenizationDuplicate: {
          form.setError('number', 'Card has been already used');
          break;
        }
        case ErrorCodes.TokenizationCVVVerification: {
          form.setError('cvv', 'The CVV is not valid');
          break;
        }
        case ErrorCodes.Tokenization:
        case ErrorCodes.TokenizationNetwork:
        default:
      }
    },
    [form]
  );

  const handleReset = useCallback((e) => {
    if (e?.code === 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME') {
      setResetting(true);
      setTimeout(() => setResetting(false), 1000);
    }
  }, []);

  const handleAuthorize = useCallback(() => {
    setAuthorizedAndReady(true);
  }, []);

  const handleSave = useCallback(async () => {
    const label = form.getValue('label');
    let nonceTokenDetails: any;
    try {
      nonceTokenDetails = await getNonceTokenRef.current({ cardholderName: label });
    } catch (nonceError: any) {
      return validateBraintreeErrors({
        SDK_ERROR_CODE: nonceError?.code,
        details: nonceError?.details,
      });
    }
    const mutationPayload = {
      collectionType,
      data: { label: label as string, nonce: nonceTokenDetails?.nonce as string },
    };
    const { errors } = await mutate(mutationPayload);
    if (errors) {
      if (errors.SDK_ERROR_CODE) validateBraintreeErrors(errors);
      if (errors.label) form.setError('label', errors.label);
      if (errors.nonce) form.setError('number', errors.nonce);
      if (errors.cardNumber) form.setError('number', errors.cardNumber);
      if (errors.braintree) form.setError('number', errors.braintree);
    }
  }, [form, mutate, creditCardToEdit, validateBraintreeErrors]);

  return (
    <>
      <Loader loading={!authorizedAndReady} />
      {!resetting && (
        <Braintree
          className={clsx(styles.braintree, { [styles['braintree--authorizing']]: !authorizedAndReady })}
          authorization={clientToken}
          onAuthorizationSuccess={handleAuthorize}
          onError={(error) => handleReset(error)}
          getTokenRef={(ref) => (getNonceTokenRef.current = ref)}
          styles={{
            input: {
              'font-size': '16px',
              'font-weight': '500',
              'font-style': 'normal',
              'font-stretch': 'normal',
              'line-height': 'normal',
              padding: '13px 25px 13px 0px',
              'font-family': 'helvetica, tahoma, calibri, sans-serif',
              'letter-spacing': 'normal',
              width: '100%',
              color: '#333333',
            },
            ':focus': {
              color: '#333333',
            },
            'input::placeholder': {
              color: '#999999',
              'font-weight': '500',
              'font-size': '16px',
            },
          }}
        >
          <Body>
            <FieldRow>
              {creditCardToEdit ? (
                <Input
                  {...form.getProps('numberMasked')}
                  readOnly
                  label='Card Number'
                  testId='pmc--braintree--card-number-input'
                  hideValidState
                />
              ) : (
                <InputBraintree
                  {...form.getProps('number')}
                  {...(devMode ? { prefill: DEVMODE_PAYMENT_INFO.cardNumber } : {})}
                  label='Card Number'
                  type='number'
                  testId='pmc--braintree--card-number-braintree-input'
                  hideValidState
                />
              )}
            </FieldRow>

            <FieldRow>
              <InputBraintree
                {...form.getProps('expirationDate')}
                {...(creditCardToEdit || devMode
                  ? { prefill: creditCardToEdit?.expirationLabel || DEVMODE_PAYMENT_INFO.expiryDate }
                  : {})}
                label='Expiration'
                type='expirationDate'
                placeholder='MM/YYYY'
                testId='pmc--braintree--expiration-date-braintree-input'
                hideValidState
              />
              <InputBraintree
                {...form.getProps('cvv')}
                {...(devMode && !creditCardToEdit ? { prefill: DEVMODE_PAYMENT_INFO.cvv } : {})}
                label='CVV'
                type='cvv'
                testId='pmc--braintree--cvv-braintree-input'
                hideValidState
              />
            </FieldRow>

            <FieldRow>
              {!creditCardToEdit && (
                <InputBraintree
                  {...form.getProps('zip')}
                  {...(creditCardToEdit || devMode
                    ? { prefill: creditCardToEdit?.zipCode || DEVMODE_PAYMENT_INFO.zipCode }
                    : {})}
                  label='Billing Zip Code'
                  type='postalCode'
                  testId='pmc--braintree--zip-braintree-input'
                  hideValidState
                />
              )}
              <Input
                {...form.getProps('label')}
                required={true}
                label='Card Label'
                placeholder='e.g. Visa, Primary'
                testId='pmc--braintree--card-label-input'
                hideValidState
              />
            </FieldRow>
          </Body>
        </Braintree>
      )}
      <Footer>
        <Button onClick={handleSave} disabled={!authorizedAndReady} testId={'pmc-payment-button'}>
          {buttonText}
        </Button>
      </Footer>
    </>
  );
};

export default BraintreeProcessor;
