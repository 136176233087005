import BTapplePay from 'braintree-web/apple-pay';
import BT from 'braintree-web/client';

import { PaymentProcessor } from 'redux/apis/OG/payment';

import ApplePayConnector from '../connector';
import { ApplePayPaymentRequest } from '../index';

class BraintreeConnector extends ApplePayConnector {
  processor = PaymentProcessor.Braintree;

  createInstance = async (clientToken: string) => {
    return new Promise<any>((resolve) => {
      BT.create({ authorization: clientToken }, async (clientError, client) => {
        if (clientError) {
          throw new Error('Cannot initialize braintree for Apple Pay');
        }
        await BTapplePay.create({ client }, async (applePayError, instance) => {
          if (applePayError) {
            throw new Error('Error creating Apple Pay Instance: ' + applePayError);
          }
          return resolve(instance);
        });
      });
    });
  };

  createPaymentRequest = (request: ApplePayPaymentRequest): ApplePayPaymentRequest => {
    return this.applePaySdkInstance.createPaymentRequest(request);
  };

  sessionOnValidateMerchant = (event) => {
    return new Promise<void>((resolve, reject) => {
      this.applePaySdkInstance.performValidation(
        {
          validationURL: event.validationURL,
          displayName: this.MERCHANT_DISPLAY_NAME,
        },
        (err: any, merchantSession: any) => {
          if (err) return reject(err);
          this.applePayPaymentRequestSession.completeMerchantValidation(merchantSession);
          console.log('Apple Pay Session -- Merchant Validated Successfully');
          resolve();
        }
      );
    });
  };

  sessionOnPaymentAuthorized = async (event) => {
    return new Promise<{ nonce: string }>((resolve, reject) => {
      this.applePaySdkInstance.tokenize({ token: event.payment.token }, (err: any, payload: any) => {
        if (err) return reject(err);
        console.log('In Payment Authorized Callback, returning: ', payload);
        resolve(payload);
      });
    });
  };
}

export default BraintreeConnector;
