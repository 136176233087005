import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

export const PersonMapping = registerMapping<T.SourcePersonType, T.PersonType>('Person', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    [
      'copy',
      {
        sourceProperties: ['nickname', 'mobile', 'relationship', 'gender', 'email'],
      },
    ],
    ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
    ['concat', { sourceProperties: ['fname', 'lname'], targetProperty: 'fullName' }],
    ['copy', { sourceProperty: 'birthday', targetProperty: 'birthdate' }],
    ['copy', { sourceProperty: 'personal_notes', targetProperty: 'personalNotes' }],
    ['boolLiteralToBool', { sourceProperty: 'has_guardian', targetProperty: 'hasGuardian' }],
    ['copy', { sourceProperty: 'legal_guardian_member_person_id', targetProperty: 'legalGuardianMemberPersonId' }],
  ],
  decoders: {
    source: T.SourcePersonDecoder,
    target: T.PersonDecoder,
  },
});

export const UserMapping = registerMapping<T.SourceUserType, T.UserType>('User', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    [
      'copy',
      {
        sourceProperties: ['uid', 'email', 'phone', 'mobile'],
      },
    ],
    ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
    ['copy', { sourceProperty: 'birthday', targetProperty: 'birthdate' }],
    ['copy', { sourceProperty: 'sex', targetProperty: 'gender' }],
    ['toString', { sourceProperty: 'zip', targetProperty: 'zipCode' }],
    ['copy', { sourceProperty: 'personal_notes', targetProperty: 'personalNotes' }],
    ['copy', { sourceProperty: 'completed_blasting_appointment_count', targetProperty: 'completedAppointmentCount' }],
    ['copy', { sourceProperty: 'plan_name', targetProperty: 'membership' }],
    ['copy', { sourceProperty: 'allow_upgrade', targetProperty: 'membershipUpgradable' }],
    ['concat', { sourceProperties: ['fname', 'lname'], targetProperty: 'fullName' }],
    ['boolLiteralToBool', { sourceProperty: 'mobile_confirm', targetProperty: 'mobileConfirmed' }],
    ['boolLiteralToBool', { sourceProperty: 'current_terms_accepted', targetProperty: 'currentTermsAccepted' }],
    ['boolLiteralToBool', { sourceProperty: 'identity_verified', targetProperty: 'verified' }],
    ['compose', { sourceProperty: 'persons', targetProperty: 'people', mapper: 'Person' }],
  ],
  decoders: {
    source: T.SourceUserDecoder,
    target: T.UserDecoder,
  },
});

export const AddressClientMapping = registerMapping<T.SourceAddressClientType, T.AddressClientType>('AddressClient', {
  operations: [['toString', { sourceProperty: 'id' }]],
  decoders: {
    source: T.SourceAddressClientDecoder,
    target: T.AddressClientDecoder,
  },
});

export const AddressMapping = registerMapping<T.SourceAddressType, T.AddressType>('Address', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    [
      'copy',
      {
        sourceProperties: [
          'name',
          'description',
          'status',
          'address1',
          'address2',
          'city',
          'state',
          'country',
          'latitude',
          'longitude',
          'notes',
          'parking',
          'ein',
        ],
      },
    ],
    ['copy', { sourceProperty: 'address_category', targetProperty: 'addressCategory' }],
    ['intToString', { sourceProperty: 'address_category_id', targetProperty: 'addressCategoryId' }],
    ['toString', { sourceProperty: 'zip', targetProperty: 'zipCode' }],
    ['copy', { sourceProperty: 'pet_text', targetProperty: 'petText' }],
    ['copy', { sourceProperty: 'pets_text', targetProperty: 'petsText' }],
    ['copy', { sourceProperty: 'building_notes', targetProperty: 'buildingNotes' }],
    ['copy', { sourceProperty: 'client_id', targetProperty: 'clientId' }],
    ['boolLiteralToBool', { sourceProperty: 'shipping' }],
    ['boolLiteralToBool', { sourceProperty: 'main', targetProperty: 'default' }],
    ['boolLiteralToBool', { sourceProperty: 'approximate' }],
    ['boolLiteralToBool', { sourceProperty: 'walkup' }],
    ['stringToInt', { sourceProperty: 'floor' }],
    ['stringToInt', { sourceProperty: 'table_count', targetProperty: 'tableCount' }],
    ['copy', { sourceProperty: 'dress_code', targetProperty: 'dressCode' }],
    ['copy', { sourceProperty: 'check_in', targetProperty: 'checkIn' }],
    ['copy', { sourceProperty: 'check_out', targetProperty: 'checkOut' }],
    ['compose', { sourceProperty: 'client', mapper: 'AddressClient' }],
  ],
  decoders: {
    source: T.SourceAddressDecoder,
    target: T.AddressDecoder,
  },
});

export const ClientBenefitsEligibilityMapping = registerMapping<
  T.SourceClientBenefitsEligibilityType,
  T.ClientBenefitsEligibilityType
>('ClientBenefitsEligibility', {
  operations: [
    [
      'copy',
      {
        sourceProperties: ['logo', 'name', 'credits'],
      },
    ],
    ['copy', { sourceProperty: 'copay_amount', targetProperty: 'copayAmount' }],
  ],
  decoders: {
    source: T.SourceClientBenefitsEligibilityDecoder,
    target: T.ClientBenefitsEligibilityDecoder,
  },
});

export const AccountTypesMapping = registerMapping<T.SourceAccountTypesType, T.AccountTypesType>('AccountTypes', {
  operations: [
    ['copy', { sourceProperty: 'is_admin', targetProperty: 'isAdmin' }],
    ['copy', { sourceProperty: 'is_applied_concierge', targetProperty: 'isAppliedConcierge' }],
    ['copy', { sourceProperty: 'is_concierge', targetProperty: 'isConcierge' }],
    ['copy', { sourceProperty: 'is_full_member', targetProperty: 'isFullMember' }],
    ['copy', { sourceProperty: 'is_hipaa', targetProperty: 'isHipaa' }],
    ['copy', { sourceProperty: 'is_member', targetProperty: 'isMember' }],
    ['copy', { sourceProperty: 'is_or_was_zeelot', targetProperty: 'isOrWasZeelot' }],
    ['copy', { sourceProperty: 'is_provider', targetProperty: 'isProvider' }],
    ['copy', { sourceProperty: 'is_spa', targetProperty: 'isSpa' }],
    ['copy', { sourceProperty: 'is_spa_full_service', targetProperty: 'isSpaFullService' }],
    ['copy', { sourceProperty: 'is_spa_self_service', targetProperty: 'isSpaSelfService' }],
    ['copy', { sourceProperty: 'is_temp_member', targetProperty: 'isTempMember' }],
    ['copy', { sourceProperty: 'is_va', targetProperty: 'isVa' }],
  ],
  decoders: {
    source: T.SourceAccountTypesDecoder,
    target: T.AccountTypesDecoder,
  },
});

export const AdditionalAttributesMapping = registerMapping<
  T.SourceAdditionalAttributesType,
  T.AdditionalAttributesType
>('AdditionalAttributes', {
  operations: [
    ['copy', { sourceProperty: 'has_active_medical_referral', targetProperty: 'hasActiveMedicalReferral' }],
    ['copy', { sourceProperty: 'has_wellness_history', targetProperty: 'hasWellnessHistory' }],
    ['copy', { sourceProperty: 'has_medical_history', targetProperty: 'hasMedicalHistory' }],
    ['copy', { sourceProperty: 'has_store_history', targetProperty: 'hasStoreHistory' }],
    ['copy', { sourceProperty: 'is_assigned_to_kemtai', targetProperty: 'isAssignedToKemtai' }],
  ],
  decoders: {
    source: T.SourceAdditionalAttributesDecoder,
    target: T.AdditionalAttributesDecoder,
  },
});

export const PlanMapping = registerMapping<T.SourcePlanType, T.PlanType>('Plan', {
  operations: [
    ['copy', { sourceProperties: ['title', 'features', 'tip', 'tax', 'savings'] }],
    ['copy', { sourceProperty: 'item_price', targetProperty: 'price' }],
    ['copy', { sourceProperty: 'transaction_amount_without_credit', targetProperty: 'priceWithoutCredit' }],

    ['copy', { sourceProperty: 'tip_percentage', targetProperty: 'tipPercentage' }],
    ['copy', { sourceProperty: 'tax_percentage', targetProperty: 'taxPercentage' }],
    ['copy', { sourceProperty: 'features_short', targetProperty: 'featuresShort' }],
    [
      'mapLiteral',
      {
        sourceProperty: 'shipping_addresses_requirement',
        targetProperty: 'shippingAddressRequired',
        options: {
          properties: [
            ['required', true],
            ['optional', false],
          ],
        },
      },
    ],
  ],
  decoders: {
    source: T.SourcePlanDecoder,
    target: T.PlanDecoder,
  },
});

export const CreditHistoryMapping = registerMapping<T.SourceCreditHistoryType, T.CreditHistoryType>('CreditHistory', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperties: ['reward', 'details'] }],
    ['copy', { sourceProperty: 'dt_inserted', targetProperty: 'dateInserted' }],
    ['copy', { sourceProperty: 'dt_expires', targetProperty: 'dateExpires' }],
  ],
  decoders: {
    source: T.SourceCreditHistoryDecoder,
    target: T.CreditHistoryDecoder,
  },
});

export const SubscriptionChargeTransactionMapping = registerMapping<
  T.SourceSubscriptionChargeTransactionType,
  T.SubscriptionChargeTransactionType
>('SubscriptionChargeTransaction', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperty: 'member_payment_profiles_id', targetProperty: 'memberPaymentProfileId' }],
    ['copy', { sourceProperty: 'dt_tx', targetProperty: 'dateTransaction' }],
    ['copy', { sourceProperty: 'dt_refunded', targetProperty: 'dateRefunded' }],
    ['copy', { sourceProperty: 'dt_voided', targetProperty: 'dateVoided' }],
    ['copy', { sourceProperty: 'charged' }],
    ['copy', { sourceProperty: 'amount' }],
    ['copy', { sourceProperty: 'zeel_promo_credit', targetProperty: 'zeelPromoCredit' }],
    ['copy', { sourceProperty: 'standard_credit', targetProperty: 'standardCredit' }],
    ['copy', { sourceProperty: 'client_credit', targetProperty: 'clientCredit' }],
    ['copy', { sourceProperty: 'purchased_credit', targetProperty: 'purchasedCredit' }],
    ['copy', { sourceProperty: 'credit' }],
    ['copy', { sourceProperty: 'member_payment_profile', targetProperty: 'memberPaymentProfile' }],
  ],
  decoders: {
    source: T.SourceSubscriptionChargeTransactionDecoder,
    target: T.SubscriptionChargeTransactionDecoder,
  },
});

export const SubscriptionChargeMapping = registerMapping<T.SourceSubscriptionChargeType, T.SubscriptionChargeType>(
  'SubscriptionCharge',
  {
    operations: [
      ['toString', { sourceProperty: 'id' }],
      ['copy', { sourceProperty: 'tx_id', targetProperty: 'transactionId' }],
      ['copy', { sourceProperty: 'dt_planned', targetProperty: 'datePlanned' }],
      ['copy', { sourceProperty: 'dt_actual', targetProperty: 'dateActual' }],
      ['copy', { sourceProperty: 'dt_inactive', targetProperty: 'dateInactive' }],
      [
        'copy',
        {
          sourceProperty: 'member_contract_charge_cancellation_reason_id',
          targetProperty: 'cancellationReasonId',
        },
      ],
      ['copy', { sourceProperty: 'cancellation_reason', targetProperty: 'cancellationReason' }],
      ['copy', { sourceProperty: 'required' }],
      ['compose', { sourceProperty: 'tx', targetProperty: 'transaction', mapper: 'SubscriptionChargeTransaction' }],
    ],
    decoders: {
      source: T.SourceSubscriptionChargeDecoder,
      target: T.SubscriptionChargeDecoder,
    },
    derivedProperties: [
      ['activated'],
      ({ input }) => ({
        activated: !input?.dt_inactive,
      }),
    ],
  }
);

const CANCELLATION_REASON_ID_CEIL_FOR_NATURAL = 2;
export const MembershipMapping = registerMapping<T.SourceMembershipType, T.MembershipType>('Membership', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperties: ['type', 'role'] }],
    ['copy', { sourceProperty: 'dt_accepted', targetProperty: 'dateAccepted' }],
    ['copy', { sourceProperty: 'dt_expires', targetProperty: 'dateExpires' }],
    ['copy', { sourceProperty: 'dt_cancel', targetProperty: 'dateCancelled' }],
    ['copy', { sourceProperty: 'cancellation_reason_id', targetProperty: 'cancellationReasonId' }],
    ['copy', { sourceProperty: 'cancellation_reason_label', targetProperty: 'cancellationReasonLabel' }],
    ['copy', { sourceProperty: 'day_of_month_charge', targetProperty: 'dayOfMonthCharge' }],
    ['copy', { sourceProperty: 'price', targetProperty: 'priceMonthly' }],
    ['copy', { sourceProperty: 'num_upcomming_subscription_charges', targetProperty: 'nbUpcomingSubscriptionCharges' }],
    ['copy', { sourceProperty: 'num_past_subscription_charges', targetProperty: 'nbPastSubscriptionCharges' }],
    ['copy', { sourceProperty: 'num_required_charges', targetProperty: 'nbRequiredCharges' }],
    ['copy', { sourceProperty: 'completed_contract', targetProperty: 'completedContract' }],
    ['copy', { sourceProperty: 'is_self_redeemed_gift', targetProperty: 'isSelfRedeemedGift' }],
    ['copy', { sourceProperty: 'is_gift', targetProperty: 'isGift' }],
    ['copy', { sourceProperty: 'gift_from_fname', targetProperty: 'giftFromFirstName' }],
    ['copy', { sourceProperty: 'gift_from_lname', targetProperty: 'giftFromLastName' }],
    ['copy', { sourceProperty: 'payment_profile', targetProperty: 'paymentProfiles' }],
    [
      'compose',
      {
        sourceProperty: 'past_subscription_charges',
        targetProperty: 'pastSubscriptionCharges',
        mapper: 'SubscriptionCharge',
      },
    ],
    [
      'compose',
      {
        sourceProperty: 'upcomming_subscription_charges',
        targetProperty: 'upcomingSubscriptionCharges',
        mapper: 'SubscriptionCharge',
      },
    ],
    [
      'compose',
      {
        sourceProperty: 'cancellation_tx',
        targetProperty: 'terminationCharge',
        mapper: 'SubscriptionChargeTransaction',
      },
    ],
  ],
  decoders: {
    source: T.SourceMembershipDecoder,
    target: T.MembershipDecoder,
  },
  derivedProperties: [
    ['cancelled'],
    ({ input }) => ({
      cancelled: !!input?.cancellation_reason_id,
      cancelledNaturally:
        !!input?.cancellation_reason_id &&
        Number(input.cancellation_reason_id) <= CANCELLATION_REASON_ID_CEIL_FOR_NATURAL,
    }),
  ],
});

export const GiftedMembershipMapping = registerMapping<T.SourceGiftedMembershipType, T.GiftedMembershipType>(
  'GiftedMembership',
  {
    operations: [
      ['toString', { sourceProperty: 'id' }],
      ['copy', { sourceProperties: ['type', 'role'] }],
      ['copy', { sourceProperty: 'dt_accepted', targetProperty: 'dateAccepted' }],
      ['copy', { sourceProperty: 'dt_expires', targetProperty: 'dateExpires' }],
      ['copy', { sourceProperty: 'dt_cancel', targetProperty: 'dateCancelled' }],
      ['copy', { sourceProperty: 'cancellation_reason_id', targetProperty: 'cancellationReasonId' }],
      ['copy', { sourceProperty: 'cancellation_reason_label', targetProperty: 'cancellationReasonLabel' }],
      ['copy', { sourceProperty: 'day_of_month_charge', targetProperty: 'dayOfMonthCharge' }],
      [
        'copy',
        { sourceProperty: 'num_upcomming_subscription_charges', targetProperty: 'nbUpcomingSubscriptionCharges' },
      ],
      ['copy', { sourceProperty: 'num_past_subscription_charges', targetProperty: 'nbPastSubscriptionCharges' }],
      ['copy', { sourceProperty: 'num_required_charges', targetProperty: 'nbRequiredCharges' }],
      ['copy', { sourceProperty: 'price', targetProperty: 'priceMonthly' }],
      ['copy', { sourceProperty: 'billing_type', targetProperty: 'billingType' }],
      ['copy', { sourceProperty: 'completed_contract', targetProperty: 'completedContract' }],
      ['copy', { sourceProperty: 'is_redeemed', targetProperty: 'isRedeemed' }],
      ['copy', { sourceProperty: 'gift_to_fname', targetProperty: 'giftToFirstName' }],
      ['copy', { sourceProperty: 'gift_to_lname', targetProperty: 'giftToLastName' }],
      ['copy', { sourceProperty: 'payment_profile', targetProperty: 'paymentProfiles' }],
      [
        'compose',
        {
          sourceProperty: 'past_subscription_charges',
          targetProperty: 'pastSubscriptionCharges',
          mapper: 'SubscriptionCharge',
        },
      ],
      [
        'compose',
        {
          sourceProperty: 'upcomming_subscription_charges',
          targetProperty: 'upcomingSubscriptionCharges',
          mapper: 'SubscriptionCharge',
        },
      ],
      [
        'compose',
        {
          sourceProperty: 'cancellation_tx',
          targetProperty: 'terminationCharge',
          mapper: 'SubscriptionChargeTransaction',
        },
      ],
    ],
    decoders: {
      source: T.SourceGiftedMembershipDecoder,
      target: T.GiftedMembershipDecoder,
    },
    derivedProperties: [
      ['cancelled'],
      ({ input }) => ({
        cancelled: !!input?.cancellation_reason_id,
        cancelledNaturally:
          !!input?.cancellation_reason_id &&
          Number(input.cancellation_reason_id) <= CANCELLATION_REASON_ID_CEIL_FOR_NATURAL,
      }),
    ],
  }
);
