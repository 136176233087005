import { FC, useCallback, useEffect } from 'react';

import { useForm } from 'utils/hooks';

import { useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { FieldRow, Input } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

type ModalModifyCollectedLocationProps = ModalProps;

/**
 * Modal for modifying the collected location. Allows the user to modify the collected location.
 * The address fields are pre-filled if the collected information has an address.
 * THis modal is currently connected to the spa self serve redux slice, and
 * would benefit from being refactored to be stateless.
 */
const ModalModifyCollectedLocation: FC<ModalModifyCollectedLocationProps> = ({ onClose, ...rest }) => {
  const form = useForm();
  const {
    actions: { collectedInformationChanged },
    selectors: { useCollectedInformationSelector },
  } = useSpaSelfServeFlowSlice();

  /** Selectors **/
  const collectedInformation = useCollectedInformationSelector();

  /** Effects **/
  useEffect(() => {
    if (collectedInformation?.address) {
      if (collectedInformation.address.address1) form.setValue('address1', collectedInformation.address.address1);
      if (collectedInformation.address.address2) form.setValue('address2', collectedInformation.address.address2);
      if (collectedInformation.address.city) form.setValue('city', collectedInformation.address.city);
      if (collectedInformation.address.state) form.setValue('state', collectedInformation.address.state);
      if (collectedInformation.address.zip) form.setValue('zip', collectedInformation.address.zip);
    }
  }, []);

  /** Handlers **/
  const save = useCallback(async () => {
    const { address1, address2, city, state, zip } = form.getValues();
    collectedInformationChanged({ address: { address1, address2, city, state, zip, type: 'manual' } });
    onClose?.();
  }, [form, onClose]);

  return (
    <Modal
      {...rest}
      onClose={() => onClose(false)}
      mobileType='drawer'
      title='Spa Location'
      actions={[{ label: 'Save', onClick: save, disabled: !form.isValid() }]}
    >
      <FieldRow>
        <Input {...form.getProps('address1')} placeholder='Address 1' autoFocus required hideValidationSpaceWhenEmpty />
        <Input
          {...form.getProps('address2')}
          required={false}
          placeholder='Room/Suite/Floor # (Optional)'
          hideValidationSpaceWhenEmpty
        />
      </FieldRow>
      <FieldRow>
        <Input {...form.getProps('city')} placeholder='City' type='letters' required hideValidationSpaceWhenEmpty />
        <Input {...form.getProps('state')} placeholder='State' required hideValidationSpaceWhenEmpty />
      </FieldRow>
      <FieldRow>
        <Input {...form.getProps('zip')} placeholder='Zip Code' type='number' required />
      </FieldRow>
    </Modal>
  );
};

export default ModalModifyCollectedLocation;
