import { FC, useCallback, useEffect } from 'react';

import { routes } from 'utils';
import { useForm } from 'utils/hooks';

import { useSignInMutation } from 'redux/apis/OG/auth';

import { useModals } from '@zeel-dev/zeel-ui';
import { FieldRow, Input, Link } from 'components/common';
import ModalForgotPassword from 'components/modals/items/ForgotPassword';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  email?: string;
};

const Login: FC<Props> = ({ email, onClose, ...rest }) => {
  const form = useForm();
  const { openModal } = useModals();
  const [signIn] = useSignInMutation();

  useEffect(() => {
    if (email) {
      form.setValue('email', email);
    }
  }, [email, form]);

  const login = useCallback(async () => {
    const _email = form.getValue('email');
    const password = form.getValue('password');

    form.blur();
    try {
      const { id } = await signIn({ email: _email, password }).unwrap();
      onClose(id);
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setGlobalStatus({ error: 'Email or password is incorrect' });
      }
    }
  }, [form]);

  return (
    <Modal
      {...rest}
      onClose={onClose}
      title='Welcome back!'
      description={
        <>
          Don&apos;t have an account?{' '}
          <Link styled href={routes.SIGN_UP({ host: true })}>
            Sign up!
          </Link>
        </>
      }
      className={styles.base}
      mobileType={'drawer'}
      onSubmit={login}
      actions={[{ label: 'Sign In', action: 'submit', disabled: !form.isValid() }]}
      testId='login-modal'
    >
      <FieldRow>
        <Input
          {...form.getProps('email')}
          error={form.getError('email')}
          type='email'
          label='Email address'
          autoComplete='email username'
          testId='login-modal--email-input'
        />
      </FieldRow>
      <FieldRow>
        <Input
          {...form.getProps('password')}
          error={form.getError('password')}
          type='password'
          label='Password'
          autoComplete='current-password'
          testId='login-modal--password-input'
        />
      </FieldRow>
      <FieldRow alignY='center' alignX='center'>
        <Link
          styled
          onClick={() => openModal({ element: <ModalForgotPassword /> })}
          testId='login-modal--forgot-password-link'
        >
          Forgot/Create password?
        </Link>
      </FieldRow>
    </Modal>
  );
};

export default Login;
