import { FC } from 'react';

import { Image } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & { features: { title?: string; description?: string; type?: string }[]; title: string };

const MembershipInfo: FC<Props> = ({ features, ...rest }) => {
  return (
    <Modal {...rest} mobileType={'drawer'} testId='membership-info-modal'>
      {features?.map((feature, i) => {
        return (
          <div key={feature.title} className={styles.feature} data-testid={`membership-info-modal--feature-${i}`}>
            <Image src={`icons/membership-${feature.type}.svg`} className={styles.featureImage} />
            <div className={styles.featureInfo}>
              <div className={styles.featureTitle}>{feature.title}</div>
              <div className={styles.featureDescription}>{feature.description}</div>
            </div>
          </div>
        );
      })}
    </Modal>
  );
};

export default MembershipInfo;
