import { FC } from 'react';

import { themes } from 'utils/theme-context';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import { Link } from '../../../../components/common';
import { routes } from '../../../../utils';

type ModalServiceUnavailableProps = ModalProps & {
  zip: string;
};

/**
 * Modal for when Zeel is not yet available in the user's area. It is currently
 * used in the intro page and when creating a new spa location.
 */
const ModalServiceUnavailable: FC<ModalServiceUnavailableProps> = ({ zip, onClose, ...rest }) => {
  return (
    <Modal
      {...rest}
      icon='exclamation'
      onClose={() => onClose(false)}
      mobileType='drawer'
      title={`Not yet available in ${zip || ' your area'}`}
      centerContent
      theme={themes.atWork}
    >
      Self-serve spa staffing is not available in your requested location but we can potentially help you with staffing
      for a full-service account. Please fill out our{' '}
      <Link styled target='_blank' href={routes.SPA({ search: { form: 1 } })}>
        contact form
      </Link>{' '}
      to inquire about setting up a full-service account.
    </Modal>
  );
};

export default ModalServiceUnavailable;
