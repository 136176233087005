import { FC, ReactNode, useState } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

type FieldHelperTextProps = {
  children: ReactNode;
  start?: ReactNode;
  expandable?: boolean;
  accent?: boolean;
  onClick?: () => void | Promise<void>;
  gutterTop?: boolean;
};

/**
 * Helper text component for fields. Can be used to display additional information or errors.
 * Can be expanded to show more information. It is built to be generic, but is kept within the
 * flow for now, as it is only used there. Will be moved to zeel-ui if it is used in multiple places.
 */
const FieldHelperText: FC<FieldHelperTextProps> = ({ children, start, expandable, accent, onClick, gutterTop }) => {
  // State to manage the expanded state of the content
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <div
      className={clsx(styles.base, {
        [styles.accent]: accent,
        [styles.expanded]: expanded,
        [styles.gutterTop]: gutterTop,
      })}
      onClick={onClick}
      style={{ userSelect: 'none', ...(onClick ? { cursor: 'pointer' } : {}) }}
    >
      {start && <div className={styles.start}>{start}</div>}
      <p>{children}</p>
      {expandable && !expanded && (
        <div className={styles.expandLink} onClick={() => setExpanded(true)}>
          More
        </div>
      )}
    </div>
  );
};

export default FieldHelperText;
