import { FC, useCallback, useMemo } from 'react';

import ModalNew, { ModalProps } from 'components/modals/templates/ModalNew';

import { helpers } from '../../../../utils';
import PaymentMethodCollector, { PMCProps } from '../../../PaymentMethodCollector';
import { PMCRenderMode } from '../../../PaymentMethodCollector/types';
import styles from './style.module.scss';

export type ModalPaymentListProps = ModalProps & {
  pmc: Omit<PMCProps, 'initialMode'>;
  onClose?: PMCProps['onSuccess'];
};

const ModalPaymentMethods: FC<ModalPaymentListProps> = ({ pmc, onClose, ...rest }) => {
  const devMode = useMemo(() => helpers.onLowerEnvironment(), []);

  const handlePaymentMethodSelected = useCallback<PMCProps['onSuccess']>(async (payload) => {
    onClose?.(payload);
  }, []);

  return (
    <ModalNew
      {...rest}
      title={null}
      headerClassName={styles.modalHeader}
      mobileType={'drawer'}
      onClose={() => onClose?.()}
      testId='payment-list-modal'
    >
      <PaymentMethodCollector
        {...pmc}
        initialMode={PMCRenderMode.List}
        onSuccess={handlePaymentMethodSelected}
        devMode={devMode}
      />
    </ModalNew>
  );
};

export default ModalPaymentMethods;
