import { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

type Props = {
  id?: string;
  title: ReactNode;
  titleAlignToTop?: boolean;
  subtitle?: string;
  subtitleContent?: ReactNode;
  description: ReactNode;
  className?: string;
  testId?: string;
};

const TitleDescriptionBlock = (props: Props) => {
  const {
    id,
    title,
    titleAlignToTop,
    subtitle,
    subtitleContent,
    description,
    className,
    testId = 'title-description-block',
  } = props;

  return (
    <div id={id} className={clsx(styles.block, className)} data-testid={`${testId}`}>
      <div className={clsx(styles.content, styles.glContainer, { [styles['content--alignTop']]: titleAlignToTop })}>
        <div>
          <h3 data-testid={`${testId}--title`}>{title}</h3>
          {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
          {subtitleContent && <div className={styles.subtitleContent}>{subtitleContent}</div>}
        </div>
        <div className={styles.description} data-testid={`${testId}--description`}>
          {description}
        </div>
      </div>
    </div>
  );
};

export default TitleDescriptionBlock;
