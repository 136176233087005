import { GenericResponse, OGApi, TAGS } from '..';
import { BookingMapping } from '../booking/mapping';
import { BookingType } from '../booking/types';
import { VA_BUSINESS_LINE_ID, VA_CORPORATE_ENTITY_ID } from './constants';
import { PatientDocumentMapping, PatientMapping } from './mapping';
import { PatientDocumentType, PatientType, SourcePatientDocumentType, SourcePatientType } from './types';

export const patientApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get currently logged in user's patient information
     */
    getPatient: builder.query<PatientType, void>({
      query: () => {
        return {
          url: '/api/v1/member/patient/info',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { patient: SourcePatientType }) => {
        const { patient } = responseData;
        return PatientMapping.apply(patient);
      },
      providesTags: () => {
        return [TAGS.PATIENT];
      },
    }),

    /**
     * Get referral booking data
     */
    getReferralBookingDetails: builder.query<BookingType, string>({
      query: (referralId) => {
        return {
          url: '/api/v1/booking/new',
          method: 'GET',
          params: {
            _data: '',
            service_request_id: referralId,
            corporate_entity_id: VA_CORPORATE_ENTITY_ID,
            business_line_id: VA_BUSINESS_LINE_ID,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { booking: any }) => {
        return BookingMapping.apply(responseData?.booking);
      },
      providesTags: [TAGS.PATIENT],
    }),

    /**
     * Links a patient to a referral
     */
    linkPatient: builder.mutation<
      string,
      { icn?: string; firstName?: string; lastName?: string; birthdate?: string; zipCode?: string }
    >({
      query: ({ icn, firstName, lastName, birthdate, zipCode }) => {
        return {
          url: '/api/v1/member/patient/match',
          method: 'GET',
          params: {
            _data: '',
            icn,
            fname: firstName,
            lname: lastName,
            birthday: birthdate,
            zip: zipCode,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { patientId: string }) => {
        return responseData?.patientId;
      },
      invalidatesTags: [TAGS.PATIENT, TAGS.USER],
    }),

    /**
     * Fetch form template
     */
    getFormTemplate: builder.query<any, string>({
      query: (formId) => {
        return {
          url: '/api/v1/member/get_form_template',
          method: 'GET',
          params: {
            type: formId,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { html: any }) => {
        return responseData?.html;
      },
    }),

    /**
     * Get forms that need to be signed
     */
    getFormsToSign: builder.query<any, { serviceId?: string; serviceRequestId?: string; guestId?: string }>({
      query: ({ serviceId, serviceRequestId, guestId }) => {
        return {
          url: '/api/v1/member/get_unsigned_forms',
          method: 'GET',
          params: {
            ...(serviceId ? { service_id: serviceId } : {}),
            ...(serviceRequestId ? { service_request_id: serviceRequestId } : {}),
            ...(guestId ? { member_person_id: guestId } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { unsigned_agreements: string[] }) => {
        return responseData?.unsigned_agreements;
      },
      providesTags: [TAGS.PATIENT],
    }),

    /**
     * Sign consent form
     */
    signForm: builder.mutation<
      GenericResponse,
      { formId: string; serviceRequestId?: string; guestId?: string; clientId?: string; serviceId?: string }
    >({
      query: ({ formId, serviceRequestId, guestId, clientId, serviceId }) => {
        return {
          url: '/api/v1/member/sign_form',
          method: 'GET',
          params: {
            _data: '',
            type: formId,
            ...(serviceRequestId ? { service_request_id: serviceRequestId } : {}),
            ...(guestId ? { member_person_id: guestId } : {}),
            ...(clientId ? { client_id: clientId } : {}),
            ...(serviceId ? { service_id: serviceId } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.PATIENT],
    }),

    /**
     * Get patient documents
     */
    getPatientDocuments: builder.query<PatientDocumentType[], void>({
      query: () => {
        return {
          url: '/api/v1/member/patient/documents',
          method: 'GET',
          params: {
            _data: '',
            grouped: 1,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { documents: Record<string, SourcePatientDocumentType[]> }) => {
        const allDocuments: PatientDocumentType[] = [];
        Object.entries(responseData.documents).forEach(([key, documents]) => {
          // Hide the RFS document until 2FA is implemented
          if (key !== 'rfs') {
            allDocuments.push(...documents.map((doc) => PatientDocumentMapping.apply(doc)));
          }
        });

        allDocuments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        return allDocuments;
      },
      providesTags: [TAGS.PATIENT_DOCUMENTS],
    }),
  }),
});

export const {
  // Queries
  useGetPatientQuery,
  useGetReferralBookingDetailsQuery,
  useGetFormTemplateQuery,
  useGetFormsToSignQuery,
  useGetPatientDocumentsQuery,
  // Lazy
  useLazyGetPatientQuery,
  useLazyGetReferralBookingDetailsQuery,
  useLazyGetFormTemplateQuery,
  useLazyGetFormsToSignQuery,
  useLazyGetPatientDocumentsQuery,
  // Mutations
  useLinkPatientMutation,
  useSignFormMutation,
} = patientApi;
