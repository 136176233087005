import { FC, useState } from 'react';

import { SpaClientAddressType, SpaClientType } from 'redux/apis/OG/spa';

import { Card } from '@zeel-dev/zeel-ui';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  selectedClientId?: string;
  clients: SpaClientType[];
};

const SpaClientAndAddress: FC<Props> = ({ selectedClientId, clients, onClose, ...rest }) => {
  const [selectedClient, setSelectedClient] = useState<SpaClientType | null>(
    clients?.find((c) => c.id === selectedClientId)
  );
  const [selectedAddress, setSelectedAddress] = useState<SpaClientAddressType | null>();
  const [mode, setMode] = useState<'client' | 'address'>(selectedClient ? 'address' : 'client');

  return (
    <Modal
      {...rest}
      actions={[
        {
          label: 'Continue',
          onClick: () => {
            if (mode === 'client' && selectedClient) {
              setMode('address');
              if (selectedClient.addresses?.length === 1) {
                onClose({ client: selectedClient, address: selectedClient.addresses[0] });
              }
            }
            if (mode === 'address' && selectedClient && selectedAddress) {
              onClose({ client: selectedClient, address: selectedAddress });
            }
          },
          disabled: mode === 'client' ? !selectedClient : !selectedAddress,
        },
      ]}
      className={styles.base}
      bodyClassName={styles.body}
      closable={false}
      mobileType={'fullscreen'}
      title={mode === 'client' ? 'Choose a Client' : 'Choose a Location'}
      testId='spa-client-and-address-modal'
      onBack={
        mode === 'address'
          ? () => {
              setSelectedAddress(null);
              setMode('client');
            }
          : null
      }
    >
      {mode === 'client' && (
        <div className={styles.cardsContainer}>
          {clients?.map((client) => {
            return (
              <Card
                key={client.id}
                onClick={() => {
                  setSelectedClient(client);
                }}
                selected={client.id === selectedClient?.id}
                full
                className={styles.card}
              >
                <b>{client.name}</b>
                {client.logo && (
                  <div className={styles.clientLogo} style={{ backgroundImage: `url(${client.logo})` }} />
                )}
              </Card>
            );
          })}
        </div>
      )}
      {mode === 'address' && (
        <div className={styles.cardsContainer}>
          {selectedClient?.addresses?.map((address) => {
            return (
              <Card
                key={address.id}
                onClick={() => setSelectedAddress(address)}
                selected={address.id === selectedAddress?.id}
                full
                className={styles.card}
              >
                <b>{address.name}</b>
                <p>
                  {address.address1} {address.address2} {address.city} {address.state} {address.zipCode}
                </p>
              </Card>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default SpaClientAndAddress;
