import { FC } from 'react';

import { themes } from 'utils/theme-context';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type ModalGratuityProps = ModalProps;

const ModalGratuity: FC<ModalGratuityProps> = ({ onClose, ...rest }) => {
  return (
    <Modal
      {...rest}
      onClose={() => onClose()}
      mobileType='drawer'
      title='How tips work for self-serve spa staffing appointments'
      theme={themes.atWork}
      className={styles.base}
    >
      <ul>
        <li>
          Zeel pays massage therapists an hourly rate + gratuity that they agree to as their total payment amount. They
          do not expect or require additional gratuity on top of the total pay from Zeel.
        </li>
        <li>Your spa should not collect gratuity on behalf of the massage therapists.</li>
        <li>
          If you do collect gratuity on behalf of a massage therapist, including any that is added by customers via
          credit card, room charge or cash, you must pass on that gratuity to the therapist.
        </li>
      </ul>
      <p>
        If you upgrade to a full-service enterprise spa account, the hourly rate paid to massage therapists is not
        inclusive of gratuity and therefore the rate charged by Zeel to your spa will be lower.
      </p>
      <p>
        For full-service accounts, spas are responsible for collecting, reporting, and passing on gratuities to massage
        therapists, including those that are added by customers via credit card, room charge or cash.{' '}
      </p>
    </Modal>
  );
};

export default ModalGratuity;
