import { FC, useEffect, useState } from 'react';

import { ProviderType, getGenderFallbackAvatar } from 'redux/apis/OG/booking';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  provider: ProviderType;
  providerId?: string;
};

const ProviderInfo: FC<Props> = ({ providerId, provider, ...rest }) => {
  const [retrievedProvider, setRetrievedProvider] = useState<ProviderType | null>(provider || null);

  useEffect(() => {
    if (!provider && providerId) {
      // TODO: fetch the provider by ID and set in state
    }
  }, []);
  useEffect(() => setRetrievedProvider(provider), [provider]);

  const { profilePicture, firstName, lastName, excerpt, licenseNumber, boards, specialtySchool } =
    retrievedProvider || {};
  return (
    <Modal
      {...rest}
      className={styles.base}
      centerContent
      bodyClassName={styles.body}
      mobileType={'fullscreen'}
      title={
        <>
          <div
            className={styles.therapistAvatar}
            style={{ backgroundImage: `url(${profilePicture || getGenderFallbackAvatar(provider.gender)})` }}
          />
          <div className={styles.title}>{`${firstName} ${lastName}`}</div>
        </>
      }
      testId='provider-info-modal'
    >
      <p>{excerpt}</p>

      {licenseNumber && (
        <>
          <h5>Professional Licence</h5>
          <p>{licenseNumber}</p>
        </>
      )}

      {boards && boards.length > 0 && (
        <>
          <h5>Certifications</h5>
          {boards.map((b) => {
            const { id: boardId, board, country } = b;
            return <p key={boardId}>{`${board} (${country})`}</p>;
          })}
        </>
      )}

      {specialtySchool && (
        <>
          <h5>Specialized Training</h5>
          <p>{specialtySchool}</p>
        </>
      )}
    </Modal>
  );
};

export default ProviderInfo;
