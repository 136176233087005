export enum ProductName {
  Hypervolt2 = 'HYPERVOLT_2',
  Hypervolt2Pro = 'HYPERVOLT_2_PRO',
  HypervoltGo2 = 'HYPERVOLT_GO_2',
  HypericeX = 'HYPERICE_X',
  Vyper3 = 'VYPER_3',
  HypersphereGo = 'HYPERSPHERE_GO',
  Hypersphere2 = 'HYPERSPHERE_2',
  Venom2Back = 'VENOM_2_BACK',
  Normatec3 = 'NORMATEC_3',
}

export enum DiscountType {
  Zeelot = 'zeelot',
  Provider = 'provider',
  Sale = 'sale',
}

export const originalPrices: { [key: string]: number } = {
  [ProductName.Hypervolt2]: 199,
  [ProductName.Hypervolt2Pro]: 329,
  [ProductName.HypervoltGo2]: 129,
  [ProductName.HypericeX]: 399,
  [ProductName.Vyper3]: 199,
  [ProductName.HypersphereGo]: 99,
  [ProductName.Hypersphere2]: 149,
  [ProductName.Venom2Back]: 249,
  [ProductName.Normatec3]: 799,
};
