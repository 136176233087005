import { HTMLAttributeAnchorTarget, KeyboardEvent, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover, useNavigate } from '@zeel-dev/zeel-ui';
import { Button, LinkButton } from 'components/common';

import styles from './style.module.scss';

type Props = {
  id?: string;
  title: ReactNode;
  description: ReactNode;
  action?: ActionData;
  className?: string;
  contentClassName?: string;
  testId?: string;
};

interface ActionData {
  actionType: 'button' | 'link';
  text: string;
  buttonType?: string;
  href?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
  target?: HTMLAttributeAnchorTarget;
}

const TitleWithContentBlock = (props: Props) => {
  const { id, title, description, action, className, contentClassName, testId = 'title-with-content-block' } = props;
  const { actionType, text, buttonType = 'primary', href = null, onClick = null, target = null } = action || {};
  const navigate = useNavigate();

  let element = null;
  if (action) {
    if (actionType === 'button')
      element = (
        <Button
          type={buttonType}
          className={styles.actionButton}
          onClick={href ? () => navigate(href) : onClick}
          testId={`${testId}--action-button`}
        >
          {text}
        </Button>
      );
    else if (actionType === 'link')
      element = (
        <LinkButton
          className={styles.actionLink}
          arrow
          href={href || null}
          onClick={onClick}
          target={target}
          testId={`${testId}--action-link`}
        >
          {text}
        </LinkButton>
      );
  }

  return (
    <div id={id} className={clsx(styles.block, className)} data-testid={`${testId}`}>
      <div className={clsx(styles.content, contentClassName, styles.glContainer)}>
        <SkeletonCover>
          <h3 data-testid={`${testId}--title`}>{title}</h3>
          <p data-testid={`${testId}--description`}>{description}</p>
          {element}
        </SkeletonCover>
      </div>
    </div>
  );
};

export default TitleWithContentBlock;
