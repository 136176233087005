import { FC, KeyboardEvent, MouseEvent, ReactNode, useCallback, useContext } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon, { IconProps } from '../IconSvg';
import Image from '../Image';
import styles from './style.module.scss';

type Props = {
  title: string;
  description?: string;
  subCardContent?: any;
  image?: string;
  icon?: string;
  iconProps?: IconProps;
  icons?: Array<{ icon: string; title: string }>;
  selected?: boolean;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  activeClassName?: string;
  imageClassName?: string;
  disabled?: boolean;
  theme?: string;
  selectedBackgroundClass?: string;
  underContent?: ReactNode;
  testId?: string;
};

const SelectionCard: FC<Props> = ({
  image = '',
  title,
  description,
  subCardContent,
  icons = [],
  icon: cardIcon,
  iconProps = {},
  selected = false,
  onClick,
  className,
  activeClassName,
  imageClassName,
  disabled,
  selectedBackgroundClass,
  underContent,
  theme: propTheme,
  testId,
}) => {
  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick?.();
    }
  }, []);

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <div className={clsx(styles.background, { [selectedBackgroundClass]: selected })}>
      <SkeletonCover>
        <div
          tabIndex={0}
          data-type='selection-card'
          onClick={onClick}
          onKeyPress={handleKeyPress}
          className={clsx(
            styles.base,
            { [styles.selected]: selected, [activeClassName]: selected, [styles.disabled]: disabled },
            styles[`theme-${theme}`],
            className
          )}
          data-testid={testId ?? `selection-card`}
        >
          {(image || cardIcon) && (
            <div className={styles.image}>
              {image && <Image src={image} className={imageClassName} />}
              {cardIcon && <Icon name={cardIcon} size={80} {...iconProps} />}
            </div>
          )}
          <div className={styles.title} data-testid={`${testId ?? `selection-card`}--title`}>
            {title}
          </div>
          {description && <div className={styles.description}>{description}</div>}

          {icons.length > 0 && (
            <div className={styles.icons}>
              {icons.map(({ icon, title: iconTitle }) => {
                return (
                  <div key={iconTitle} className={styles.iconBox}>
                    <Icon name={icon} className={styles.icon} color='theme' />
                    <div className={styles.iconTitle}>{iconTitle}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {underContent && selected && <div className={styles.underContent}>{underContent}</div>}
      </SkeletonCover>
      {subCardContent && <div className={styles.subCardContent}>{subCardContent}</div>}
    </div>
  );
};

export default SelectionCard;
