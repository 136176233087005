import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { ProductType, useGetProductsQuery } from 'redux/apis/OG/estore';
import { DiscountType } from 'redux/apis/OG/estore/constants';
import { useEstoreFlowSlice } from 'redux/features/estoreFlow';

import { Button, Carousel, ColorTilePicker, Icon, Image } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/Modal';

import styles from './style.module.scss';

type Props = ModalProps & {
  productId: string;
};

const ProductDetails: FC<Props> = ({ productId, ...rest }) => {
  const { data: products, isLoading: isProductsLoading } = useGetProductsQuery();
  const [pickedColor, setPickedColor] = useState<ProductType['_colors'][0]>();
  const [benefitsToggled, setBenefitsToggled] = useState<boolean>(false);
  const [featuresToggled, setFeaturesToggled] = useState<boolean>(false);
  const [includedToggled, setIncludedToggled] = useState<boolean>(false);
  const [specsToggled, setSpecsToggled] = useState<boolean>(false);

  const {
    actions: { cartOpened, itemAddedToCart },
  } = useEstoreFlowSlice();

  const slider = useRef<any>(null);
  const product = useMemo(
    () => products?.find((p) => p.id + '' === (pickedColor?._product_id || productId) + ''),
    [products, productId, pickedColor]
  );
  useEffect(() => {
    if (product) {
      const defaultColor = product._colors?.find((_c) => _c._color_id === product._color_id);
      setPickedColor(defaultColor);
    }
  }, [product]);

  const addToCart = useCallback(async () => {
    if (!product?.id) return;
    await helpers.waitFor(200);
    await cartOpened();
    setTimeout(() => {
      itemAddedToCart({ id: product.id + '', quantity: 1 });
    }, 500);
  }, [product]);
  const onColorChange = useCallback(
    (colorHex) => {
      const { _colors } = product || {};
      const _pickedColor = _colors.find((c) => c._hex === colorHex);
      if (!_pickedColor) return;
      setPickedColor(_pickedColor);
    },
    [product, products]
  );

  const getColorTiles = useCallback(
    (center = false) => {
      if (!product?._colors) return null;
      return (
        <div className={styles.colorTiles}>
          <ColorTilePicker
            name='color-tile-picker-store'
            center={center}
            value={pickedColor ? pickedColor._hex : null}
            onChange={onColorChange}
            label='Color selection'
            colors={(product?._colors || []).map((c) => ({ label: c._color_id, value: c._hex }))}
          />
        </div>
      );
    },
    [product, pickedColor]
  );

  const getBenefits = useCallback(() => {
    return (
      <>
        <h2>Benefits</h2>
        <p>{product?.description}</p>
        <ul>
          {(product?.extra?.benefits || []).map((benefit, i) => {
            const { title } = benefit;
            return <li key={i}>{title}</li>;
          })}
        </ul>
      </>
    );
  }, [product]);

  const getIncluded = useCallback(() => {
    return (
      <>
        <h2>What&apos;s Included</h2>
        <ul>
          {(product?.extra?.includes || []).map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </>
    );
  }, [product]);

  const getSpecifications = useCallback(() => {
    const specifications = product?.extra?.specifications || [];

    return (
      <>
        <h2>Specifications</h2>
        {specifications.map((spec) => (
          <div className={styles.specRow}>
            <p>{spec.label}</p>
            <p>{spec.description}</p>
          </div>
        ))}
      </>
    );
  }, [product]);

  const getFeatures = useCallback(() => {
    return (
      <>
        <h2>Features</h2>
        {(product?.extra?.features || []).map((feature) => {
          const { title, description } = feature;
          return (
            <>
              <h3>{title}</h3>
              <p>{description}</p>
            </>
          );
        })}
      </>
    );
  }, [product]);

  const { _label_without_color, price, extra } = product || {};
  const { zeel_take, photos, photos_wide, slogan } = extra || {};
  const isMobile = helpers.isMobileWidth();
  const slides = ((isMobile ? photos : photos_wide) || []).map((photo, i) => (
    <div key={i} className={styles['carousel-slide']} style={{ background: `url(${photo})` }} />
  ));

  return (
    <Modal
      {...rest}
      loading={isProductsLoading}
      className={styles.modal}
      mobileStyle='fullscreen'
      backdropClose={true}
      testId='product-details-modal'
    >
      <div className={styles.insideModal}>
        <Carousel
          carouselRef={(s) => (slider.current = s)}
          className={styles.carousel}
          slides={slides}
          settings={{
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            slidesToShow: 1,
            dots: true,
            swipeToSlide: true,
          }}
        />
        <div className={styles.topInfoContainer}>
          <div className={styles.topInfo}>
            <div className={styles.titleContainer}>
              <h1>{_label_without_color}</h1>
            </div>
            <p>{slogan}</p>
            <div className={styles.priceContainer}>
              {price?.discount_type === DiscountType.Sale && (
                <>
                  <div className={clsx(styles.priceBox, styles['priceBox-striked'])}>
                    <p className={styles.price}>${Number(price?.original)?.toFixed(2)}</p>
                    <p className={styles.label}>Regular Price</p>
                  </div>
                  <div className={styles.priceBox}>
                    <p className={styles.priceHighlight}>${Number(price?.current)?.toFixed(2)}</p>

                    <p className={styles.labelHighlight}>Sale Price</p>
                  </div>
                </>
              )}

              {price?.discount_type !== DiscountType.Sale && (
                <>
                  <div className={styles.priceBox}>
                    <p className={styles.price}>${Number(price?.current)?.toFixed(2)}</p>
                  </div>
                </>
              )}
            </div>
            {getColorTiles(true)}
          </div>
          <div className={styles.addCartContainer}>
            {getColorTiles()}
            <Button flex onClick={addToCart} testId='product-details-modal--add-to-cart-button'>
              Add to Cart
            </Button>
          </div>
        </div>

        <div className={styles.zeelTakeContainer}>
          <div>
            <h2>Zeel&apos;s Take</h2>
            <p>{zeel_take?.description}</p>
          </div>
          <div>
            <h3>{_label_without_color} is best for:</h3>
            <ul>
              {(zeel_take?.best_for || []).map((bestFor, i) => {
                const { label, icon } = bestFor;
                return (
                  <li key={i}>
                    <Image className={styles.bestForIcon} src={icon?.svg} /> {label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className={styles.detailsContainer}>
          <div>
            <div className={styles.box}>{getBenefits()}</div>
            <div className={styles.box}>{getIncluded()}</div>
            <div className={styles.box}>{getSpecifications()}</div>
          </div>
          <div>
            <div className={styles.box}>{getFeatures()}</div>
          </div>

          <div
            onClick={() => setBenefitsToggled(!benefitsToggled)}
            className={clsx(styles.togglableBox, { [styles['togglableBox--open']]: benefitsToggled })}
          >
            {getBenefits()}
            <Icon type='arrow-down' />
          </div>
          <div
            onClick={() => setFeaturesToggled(!featuresToggled)}
            className={clsx(styles.togglableBox, { [styles['togglableBox--open']]: featuresToggled })}
          >
            {getFeatures()}
            <Icon type='arrow-down' />
          </div>
          <div
            onClick={() => setIncludedToggled(!includedToggled)}
            className={clsx(styles.togglableBox, { [styles['togglableBox--open']]: includedToggled })}
          >
            {getIncluded()}
            <Icon type='arrow-down' />
          </div>
          <div
            onClick={() => setSpecsToggled(!specsToggled)}
            className={clsx(styles.togglableBox, { [styles['togglableBox--open']]: specsToggled })}
          >
            {getSpecifications()}
            <Icon type='arrow-down' />
          </div>
        </div>

        <div className={styles.bottomSpacer} />
      </div>
    </Modal>
  );
};

export default ProductDetails;
