import { FC, ReactNode, createContext, useContext, useEffect, useRef } from 'react';

import { kebabCase, merge } from 'lodash';
import { nanoid } from 'nanoid';

// Context
const LoggerContext = createContext<Logger | undefined>(undefined);
export const LoggerProvider: FC<{ settings?: ConfigureSettings; children?: ReactNode }> = ({ children, settings }) => {
  const logger = useRef<Logger>();
  useEffect(() => {
    if (!logger.current) logger.current = new Logger();
    if (settings) logger.current.configure(settings);
  }, [settings]);
  return <LoggerContext.Provider value={logger.current}>{children}</LoggerContext.Provider>;
};
export const useLoggerContext = (): Logger => {
  const logger = useContext(LoggerContext);
  if (!logger) throw new Error('useLogger must be used within a LoggerProvider');
  return logger;
};
export const useLogger = (settings?: ConfigureSettings): Logger => {
  const logger = useRef<Logger>(new Logger(settings));
  return logger.current;
};

// -------

export enum LogLevel {
  Success = 'success',
  Info = 'info',
  InfoContrast = 'info_contrast',
  Warn = 'warn',
  Error = 'error',
  Header = 'header',
}

type LevelLoggerExposedArguments = [content: any, options?: LevelLoggerOptions];
type LevelLoggerOptions = {
  namespace?: string;
  appendix?: string;
  indent?: number;
  settings?: LevelSettings;
};
type LogListenerArgs = { level: LogLevel | `${LogLevel}`; content: any; namespace: string; timestamp: string };
type LogListenerCallback = (args: LogListenerArgs) => Promise<void> | void;
type LogListener = { id: string; callback: LogListenerCallback };

type GenericSettings = {
  style?: {
    global?: StyleSettings;
    content?: StyleSettings;
    namespace?: StyleSettings;
    appendix?: StyleSettings;
    indent?: StyleSettings;
  };
};

type StyleSettings = Partial<CSSStyleDeclaration>;

type LevelSettings = GenericSettings & {};

type ConfigureSettings = GenericSettings & {
  timestamp?: boolean | 'elapsed';
  mute?: LogLevel[];
  disabled?: boolean;
  level?: Partial<Record<LogLevel, LevelSettings>>;
};

export class Logger {
  private START_TIME: number;
  private settings: ConfigureSettings;
  private listeners: Partial<Record<LogLevel | `${LogLevel}` | '*', LogListener[]>> = {};

  constructor(initialSettings: ConfigureSettings = {}) {
    // Initialize settings with defaults and any passed configuration
    this.settings = merge({}, this.defaultSettings(), initialSettings);
    // Start time
    this.START_TIME = new Date().getTime();
  }

  /** Default settings */
  private defaultSettings(): ConfigureSettings {
    return {
      timestamp: 'elapsed',
      mute: [],
      disabled: false,
      style: {
        global: {
          fontSize: '11px',
          fontFamily: 'Courier',
        },
        namespace: {
          fontFamily: 'Tahoma',
          fontWeight: '700',
          color: 'white',
          background: 'blue',
          padding: '1px 2px',
          borderRadius: '2px',
        },
        content: {
          fontFamily: 'Monaco',
          fontSize: '11px',
          fontWeight: '600',
          marginLeft: '8px',
        },
        appendix: {
          position: 'absolute',
          right: '0px',
          top: '0px',
          fontSize: '8px',
          fontWeight: '100',
          color: 'rgb(190,190,190)',
          marginLeft: '8px',
        },
        indent: {
          color: 'rgba(120,120,120)',
          fontSize: '12.5px',
          padding: '0',
          marginRight: '1px',
          borderRadius: '2px',
          backgroundColor: 'rgb(230,230,230)',
        },
      },
      level: {
        [LogLevel.Success]: {
          style: {
            content: {
              color: 'green',
            },
          },
        },
        [LogLevel.Warn]: {
          style: {
            content: {
              color: 'orange',
            },
          },
        },
        [LogLevel.Error]: {
          style: {
            namespace: {
              color: 'white',
              background: 'red',
            },
            content: {
              color: 'red',
            },
          },
        },
        [LogLevel.Header]: {
          style: {
            content: {
              fontFamily: 'Monaco',
              textAlign: 'center',
              background: 'navy',
              color: 'white',
              border: 'solid 2px blue',
              marginLeft: '0px',
              padding: '16px 18%',
              fontSize: '14px',
              fontWeight: '100',
            },
          },
        },
      },
    };
  }

  /** Configure the logger */
  public configure = (settings: ConfigureSettings) => {
    this.settings = merge({}, this.settings, settings);
  };

  /** Register a listener */
  public listen = (listener: LogListenerCallback, level: LogLevel | `${LogLevel}` | '*' = '*'): string => {
    const id = nanoid();
    if (!this.listeners[level]) this.listeners[level] = [];
    this.listeners[level]?.push({ id, callback: listener });
    return id;
  };

  /** Unregister a listener */
  public unlisten = (id: string): void => {
    for (const level in this.listeners) {
      const listeners = this.listeners[level];
      if (!listeners) continue;
      const index = listeners.findIndex((l) => l.id === id);
      if (index !== -1) listeners.splice(index, 1);
    }
  };

  /** Notify listeners */
  private notifyListeners = (args: LogListenerArgs): void => {
    const listeners = [...(this.listeners[args.level] || []), ...(this.listeners['*'] || [])];
    listeners.forEach((listener) => listener?.callback?.(args));
  };

  /** Get log level styles */
  private getLogLevelStyles = (level: LogLevel | `${LogLevel}`, levelSettingsOverride: LevelSettings = {}) => {
    const settings = merge({}, this.settings, { level: { [level]: levelSettingsOverride } });
    const globalStyles = settings.style.global || {};
    const namespaceStyles = settings.style.namespace || {};
    const contentStyles = settings.style.content || {};
    const appendixStyles = settings.style.appendix || {};
    const indentStyles = settings.style.indent || {};
    const levelStyles = settings.level?.[level]?.style || {};
    const levelGlobalStyles = levelStyles.global || {};
    const levelNamespaceStyles = levelStyles.namespace || {};
    const levelContentStyles = levelStyles.content || {};
    const levelAppendixStyles = levelStyles.appendix || {};
    const levelIndentStyles = levelStyles.indent || {};

    return {
      global: merge({}, globalStyles, levelGlobalStyles),
      namespace: merge({}, globalStyles, levelGlobalStyles, namespaceStyles, levelNamespaceStyles),
      content: merge({}, globalStyles, levelGlobalStyles, contentStyles, levelContentStyles),
      appendix: merge({}, globalStyles, levelGlobalStyles, appendixStyles, levelAppendixStyles),
      indent: merge({}, globalStyles, levelGlobalStyles, indentStyles, levelIndentStyles),
    };
  };

  /** Generate inline style */
  private inlineStyle = (styles: StyleSettings): string => {
    if (!styles) return '';
    return Object.entries(styles)
      .filter(([, value]) => value) // Ensure non-null values
      .map(([key, value]) => `${kebabCase(key)}: ${value}`)
      .join('; ');
  };

  /** Get timestamp */
  private getTimestamp = (): string => {
    if (this.settings.timestamp === 'elapsed') {
      const elapsedSinceStart = new Date().getTime() - this.START_TIME;
      const elapsed = `${Math.floor(elapsedSinceStart / 1000)}.${(elapsedSinceStart % 1000)
        .toString()
        .padStart(3, '0')}s`;
      return `${elapsed}`;
    } else {
      return new Date().toISOString().split('T')[1].split('Z')[0];
    }
  };

  /** Logger function */
  private log = (level: LogLevel | `${LogLevel}`, ...rest: LevelLoggerExposedArguments): void => {
    const [content = '', options = {}] = rest;
    const styles = this.getLogLevelStyles(level, options.settings);
    const timestamp = this.getTimestamp();
    const hasIndent = options.indent && options.indent > 0;
    const indentationText = hasIndent ? `   `.repeat(options.indent) : '';
    const isObjectOrFct = ['object', 'function'].includes(typeof content);
    const isNumber = ['number', 'bigint'].includes(typeof content);
    const namespace = level === LogLevel.Header ? '' : ` ${options.namespace || level} `;
    const text = `${isObjectOrFct ? '%O' : isNumber ? '%i' : content}`;
    const appendix = options.appendix || (level !== LogLevel.Header && this.settings.timestamp && timestamp) || '';
    const inlineStylesList = [
      ...(hasIndent ? [this.inlineStyle(styles.indent)] : []),
      this.inlineStyle({
        ...styles.namespace,
        ...(hasIndent
          ? {
              background: `color-mix(in srgb, ${styles.namespace.background} ${
                75 - options.indent * 15
              }%, rgb(200,200,200));`,
            }
          : {}),
      }),
      this.inlineStyle(styles.content),
      ...(isObjectOrFct ? [content] : []),
      ...(isNumber ? [content] : []),
      this.inlineStyle(styles.appendix),
    ];
    console.log(`${hasIndent ? `%c${indentationText}` : ''}%c${namespace}%c${text}%c${appendix}`, ...inlineStylesList);

    this.notifyListeners({ level, content, namespace, timestamp });
  };

  public success = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.Success, ...rest);
  public info = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.Info, ...rest);
  public infoContrast = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.InfoContrast, ...rest);
  public warn = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.Warn, ...rest);
  public error = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.Error, ...rest);
  public header = (...rest: LevelLoggerExposedArguments): void => this.log(LogLevel.Header, ...rest);
}

export default new Logger();
