import { FC, useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'utils/theme-context';

import styles from './style.module.scss';

type Props = {
  loading: boolean;
  value?: number;
  className?: string;
  theme?: string;
  testId?: string;
  bottom?: boolean;
};

const Loader: FC<Props> = ({ loading, value, className = '', theme: propTheme, testId, bottom }) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    const tm = setTimeout(() => {
      setShowLoading(true);
    }, 1);

    return () => {
      if (tm) clearTimeout(tm);
    };
  }, []);

  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <div
      className={`${styles.loader} ${
        loading ? (showLoading ? styles.loading : styles.prepareLoading) : styles.loaded
      } ${className} ${styles[`theme-${theme}`]} ${bottom ? styles.bottom : ''}`}
      data-testid={testId ?? `loader`}
    >
      <div
        className={styles.loaderProgress}
        style={
          value !== null && value !== undefined
            ? { width: `${value * 100}%`, maxWidth: `${value * 100}%`, transition: 'all 0.5s ease' }
            : {}
        }
      />
    </div>
  );
};

export default Loader;
