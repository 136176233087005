import clsx from 'clsx';

import styles from './style.module.scss';

type Props = {
  background?: boolean;
  hideOnMobile?: boolean;
  showOnMobile?: boolean;
  testId?: string;
};

const GapBlock = (props: Props) => {
  const { background, hideOnMobile, showOnMobile, testId = 'gap-block' } = props;
  return (
    <div
      className={clsx(
        styles.block,
        { [styles.background]: background },
        { [styles.hideOnMobile]: hideOnMobile },
        { [styles.showOnMobile]: showOnMobile }
      )}
      data-testid={testId}
    />
  );
};

export default GapBlock;
