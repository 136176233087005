import { PaymentProcessor, paymentApi } from 'redux/apis/OG/payment';

import { store } from '../../../../../redux/store';
import ApplePayConnector from '../connector';
import { ApplePayPaymentRequest } from '../index';

class FortisConnector extends ApplePayConnector {
  processor = PaymentProcessor.Fortis;

  createInstance = async (clientToken: string) => {
    return { ct: clientToken };
  };

  createPaymentRequest = (request: ApplePayPaymentRequest): ApplePayPaymentRequest => {
    return {
      ...request,
      currencyCode: 'USD',
      countryCode: 'US',
      supportedNetworks: ['masterCard', 'visa', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      requiredBillingContactFields: ['postalAddress'],
    };
  };

  sessionOnValidateMerchant = async (event: any) => {
    // Retrieve merchant details
    const merchantResult = await store.dispatch(paymentApi.endpoints.getFortisMerchantDetails.initiate());
    const merchantId = merchantResult?.data?.merchantId;
    // Validate merchant session
    const merchantSession = await store.dispatch(
      paymentApi.endpoints.validateApplePayMerchant.initiate({
        validationUrl: event.validationURL,
        merchantId,
      })
    );
    let parsedMerchantSession: any;
    try {
      parsedMerchantSession = JSON.parse(merchantSession?.data?.merchant_session);
    } catch (e) {
      console.error('Failed to parse merchant session', e);
    }

    this.applePayPaymentRequestSession.completeMerchantValidation(parsedMerchantSession);
  };

  sessionOnPaymentAuthorized = async (event: any) => {
    /*
      paymentData: {data: "hqVtie0snFawVwH8LYqOaMZ0VBzu3VU6dLLLUgVj6IUnPwQU1h…GL9bSbEr/tHjRt0y0MiXQ40kXmsm8WxTDa1Itq7beC6F8zSs=", signature: "MIAGCSqGSIb3DQEHAqCAMIACAQExDTALBglghkgBZQMEAgEwgA…PLZllj3fkFXd0YC+Vg8jM6c0BuCjQbyF0U7FRgQAAAAAAAA==", header: {publicKeyHash: "N+TA9m09PaSqYU/mDZ5nvyMKekqpVxHDtog5DKkUcfo=", ephemeralPublicKey: "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE6GOSF4nnJoqGwu…u7ZVa1c5P10cEZAv/vuwCcA2uZXT9Djwf73e9usPtpiTR4g==", transactionId: "7ac5786986bb14c16428f2a80a63f99528da039f81e3c77031f7458ba84c3c5e"}, version: "EC_v1"}
      paymentMethod: {displayName: "Visa 7154", network: "Visa", type: "debit"}
      transactionIdentifier: "7ac5786986bb14c16428f2a80a63f99528da039f81e3c77031f7458ba84c3c5e"
     */
    const billingZip = event?.payment?.billingContact?.postalCode;
    console.log('In Payment Authorized Callback, returning: ', event);
    return {
      nonce: JSON.stringify({
        ...event.payment.token,
        billingAddressPostalCode: billingZip,
      }),
    };
  };
}

export default FortisConnector;
