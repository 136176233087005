import { FC } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { IconSvg, LinkButton } from 'components/common';

import CoreBlock from '../CoreBlock';
import { BannerBlockProps } from './BannerBlock.types';
import styles from './style.module.scss';

const BannerBlock: FC<BannerBlockProps> = ({
  className,
  icon,
  iconSize,
  title,
  titleClassName,
  subtitle,
  ogHostLink,
  link,
  linkText,
  testId = 'banner-block',
}) => {
  return (
    <CoreBlock
      className={clsx(styles.bannerBlock, className)}
      contentClassName={styles['bannerBlock-content']}
      testId={testId}
    >
      <SkeletonCover>
        {icon && <IconSvg name={icon} color='white' size={iconSize} />}
        <div>
          {title && <p className={clsx(styles.title, titleClassName)}>{title}</p>}
          {(subtitle || link) && (
            <p className={styles.subtitle}>
              {subtitle}
              {subtitle && link ? ' ' : ''}
              {link && (
                <LinkButton
                  arrow
                  href={ogHostLink ? routes.VA_MICROSITE({ host: true, params: { link } }) : link}
                  testId={`${testId}--link`}
                >
                  {linkText}
                </LinkButton>
              )}
            </p>
          )}
        </div>
      </SkeletonCover>
    </CoreBlock>
  );
};

export default BannerBlock;
