import { FC, useCallback, useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';
import qs from 'qs';

import { helpers, routes } from 'utils';
import { useLocation } from 'utils/hooks';

import { areasOfPracticeOptions, statesOptions } from 'redux/apis/OG/user';

import { Segment } from '@zeel-dev/zeel-ui';
import { Button, Link } from 'components/common';
import { IconSvg } from 'components/common';
import { FormikSelect } from 'components/common/Select';
import { FormikInput } from 'components/common/inputs/Input';

import CoreBlock from '../CoreBlock';
import { ProviderLeadFormValues } from './ProviderLeadFormBlock.types';
import styles from './style.module.scss';

export type Props = {
  source?: string;
  testId?: string;
};

const LEAD_FORM_SUCCESS_CONTAINER_ID = 'lead-form-success-container';
const LEAD_FORM_TITLE_ID = 'lead-form-title';

const ProviderLeadFormBlock: FC<Props> = ({ testId = 'provider-lead-form-block', ...props }) => {
  const [submitData, setSubmitData] = useState<ProviderLeadFormValues>(null);
  const [formError, setFormError] = useState<string | null>();
  const [interestedInMedical, setInterestedInMedical] = useState<boolean>(false);
  const [hasOfficeSpace, setHasOfficeSpace] = useState<boolean>(false);

  const { search } = useLocation();

  const submitLead = useCallback(
    async (values: ProviderLeadFormValues, { setSubmitting }) => {
      const { firstName, lastName, email, mobile, serviceProviderType, state, zip } = values;
      const urlParams = qs.parse(search || '', { ignoreQueryPrefix: true });
      const selectedOption = areasOfPracticeOptions.find((o) => o.value === serviceProviderType);
      const selectedState = statesOptions.find((o) => o.value === state);
      if (!selectedOption || !selectedState) return;

      setSubmitting(true);
      setFormError(null);

      try {
        const fields: any = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          mobile: mobile,
          zip: zip,
          state: state,
          Service_Provider_Type__c: serviceProviderType,
          recordType: '0121K000000vgPs',
          status: 'New',
        };

        if (urlParams.utm_source) fields.utm_source__c = urlParams.utm_source;
        if (urlParams.utm_campaign) fields.utm_campaign__c = urlParams.utm_campaign;
        if (urlParams.utm_medium) fields.utm_medium__c = urlParams.utm_medium;
        if (urlParams.gclid) fields.GCLID__c = urlParams.gclid;
        if (urlParams.gclsrc) fields.GCLSRC__c = urlParams.gclsrc;

        if (props.source) {
          fields.lead_source = props.source;
        }

        if (serviceProviderType === 'Massage Therapist') {
          fields.Medical__c = interestedInMedical;
          if (interestedInMedical) fields.Facility__c = hasOfficeSpace;
        }

        helpers.submitSalesforceLead(fields);

        setSubmitData({
          firstName,
          lastName,
          zip,
          mobile,
          email,
          serviceProviderType,
          state,
          interestedInMedical,
          hasOfficeSpace,
        });

        document
          .querySelector(`#${LEAD_FORM_SUCCESS_CONTAINER_ID}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } catch {
        setFormError('Sorry, we had trouble processing this information. Please check each entry below and resubmit.');
        document.querySelector(`#${LEAD_FORM_TITLE_ID}`)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }

      setSubmitting(false);
    },
    [interestedInMedical, hasOfficeSpace, search, props.source]
  );

  return (
    <CoreBlock
      className={styles.providerLeadFormBlock}
      contentClassName={styles['providerLeadFormBlock-content']}
      size='large'
      testId={testId}>
      {submitData && (
        <div id={LEAD_FORM_SUCCESS_CONTAINER_ID} className={styles.successContainer}>
          <IconSvg name='checkmark-circle' size={56} />
          <h2>Thank you for your interest in becoming a provider at Zeel</h2>
          <p>We&apos;ll reach out to you within 1 business day to follow up.</p>
        </div>
      )}
      {!submitData && (
        <>
          <h2 id={LEAD_FORM_TITLE_ID} data-testid={`${testId}--title`}>
            Get more information about the benefits of providing on-location care – <i>on your terms</i> – with Zeel.
          </h2>
          <div className={styles.formContainer}>
            {formError && <div className={styles.errorBox}>{formError}</div>}
            <Formik
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                zip: '',
                mobile: '',
                state: '',
                serviceProviderType: '',
              }}
              validateOnMount={true}
              onSubmit={submitLead}>
              {({ values, isValid, handleSubmit, isSubmitting }: FormikProps<ProviderLeadFormValues>) => (
                <Form>
                  <div className={styles.fieldRow}>
                    <FormikInput
                      name='firstName'
                      type='text'
                      required
                      label='First Name'
                      autoComplete='given-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      testId={`${testId}--first-name-input`}
                    />
                    <FormikInput
                      name='lastName'
                      type='text'
                      required
                      label='Last Name'
                      autoComplete='family-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      testId={`${testId}--last-name-input`}
                    />
                  </div>
                  <div className={styles.fieldRow}>
                    <FormikInput
                      name='email'
                      type='email'
                      required
                      label='Email'
                      autoComplete='email username'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      testId={`${testId}--email-input`}
                    />
                    <FormikInput
                      name='mobile'
                      type='mobile'
                      required
                      label='Mobile Phone Number'
                      autoComplete='mobile'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      testId={`${testId}--mobile-phone-input`}
                    />
                  </div>
                  <div className={styles.fieldRow}>
                    <FormikSelect
                      name='serviceProviderType'
                      required
                      label='Area of Practice'
                      inputClassName={styles.select}
                      className={styles.input}
                      mountInBody
                      placeholder='Select...'
                      options={areasOfPracticeOptions}
                      testId={`${testId}--service-provider-type-select`}
                    />
                    <div className={styles.subfieldRow}>
                      <FormikSelect
                        name='state'
                        required
                        label='State'
                        inputClassName={styles.select}
                        className={styles.input}
                        mountInBody
                        searchable
                        placeholder='Select...'
                        options={statesOptions}
                        testId={`${testId}--state-select`}
                      />
                      <FormikInput
                        name='zip'
                        type='number'
                        label='Zip Code'
                        required
                        autoComplete='zip'
                        max={5}
                        containerClassName={styles.input}
                        wrapperClassName={styles.inputWrapper}
                        testId={`${testId}--zip-code-input`}
                      />
                    </div>
                  </div>
                  {values.serviceProviderType === 'Massage Therapist' && (
                    <div className={styles.segmentsRow}>
                      <p className={styles.fieldLabel}>
                        As part of Zeel’s medical massage program, local therapists may be able to deliver covered
                        in-network massage treatments as part of a patient’s treatment plan. <br />
                        <b>Would you be interested in this type of work?</b>
                      </p>
                      <div className={styles.segments}>
                        <Segment
                          items={[
                            { id: false, label: 'No' },
                            {
                              id: true,
                              label: 'Yes',
                            },
                          ]}
                          value={interestedInMedical}
                          onChange={(v) => setInterestedInMedical(v as boolean)}
                        />
                      </div>
                    </div>
                  )}
                  {values.serviceProviderType === 'Massage Therapist' && interestedInMedical && (
                    <div className={styles.segmentsRow}>
                      <p className={styles.fieldLabel}>
                        <b>
                          Do you have an office space where patients with a medical prescription for massage treatments
                          could come directly to you for care?
                        </b>{' '}
                        If you do, this is a great way for you to maximize your time and save money on travel expenses.
                      </p>
                      <div className={styles.segments}>
                        <Segment
                          items={[
                            { id: false, label: 'No' },
                            {
                              id: true,
                              label: 'Yes',
                            },
                          ]}
                          value={hasOfficeSpace}
                          onChange={(v) => setHasOfficeSpace(v as boolean)}
                        />
                      </div>
                    </div>
                  )}
                  <div className={styles.fieldRow}>
                    <p className={styles.terms}>
                      By selecting &apos;Find Out More&apos;, I confirm that I have read and agreed to our{' '}
                      <Link
                        href={routes.TERMS_OF_USE({ host: true })}
                        target='_blank'
                        className={styles.link}
                        testId={`${testId}--terms-of-use-link`}>
                        Terms of Use
                      </Link>{' '}
                      and{' '}
                      <Link
                        href={routes.PRIVACY_POLICY({ host: true })}
                        target='_blank'
                        className={styles.link}
                        testId={`${testId}--privacy-policy-link`}>
                        Privacy Policy
                      </Link>
                    </p>
                    <Button
                      className={styles.button}
                      disabled={!isValid}
                      loading={isSubmitting}
                      onClick={() => handleSubmit()}
                      testId={`${testId}--main-button`}>
                      Find Out More
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </CoreBlock>
  );
};

export default ProviderLeadFormBlock;
