import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Html, IconSvg } from 'components/common';

import { PTFAQData } from '../PTFAQBlock.types';
import styles from './style.module.scss';

const PTFAQItem: FC<{ item: PTFAQData; collapsed: boolean; onClick: () => void }> = ({ item, collapsed, onClick }) => {
  return (
    <SkeletonCover>
      <div className={clsx(styles.title, { [styles.collapsed]: collapsed })} onClick={onClick}>
        <p>{item.question}</p>
        <IconSvg name='caret-down' size={24} />
      </div>
      {collapsed && <Html className={styles.description} content={item.answer} />}
    </SkeletonCover>
  );
};

export default PTFAQItem;
