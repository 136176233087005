import { CSSProperties } from 'react';

import clsx from 'clsx';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { Image } from 'components/common';

import styles from './style.module.scss';

type Props = {
  data: Array<{ style: CSSProperties; src: string; link?: string }>;
  text?: string;
  title?: string;
  className?: string;
  background?: boolean;
  stackMobileTwo?: boolean;
  testId?: string;
};

const LogosBlock = (props: Props) => {
  const {
    data,
    className = '',
    title = '',
    text = '',
    background = false,
    stackMobileTwo = false,
    testId = 'logos-block',
  } = props;
  const navigate = useNavigate();
  return (
    <div className={`${styles.block} ${background ? styles.background : ''} ${className}`} data-testid={testId}>
      {title && <h3 data-testid={`${testId}--title`}>{title}</h3>}
      <div className={styles.glContainer}>
        {text && <div className={styles.text}>{text}</div>}
        <div
          id='logo-list'
          data-type='list'
          className={`${styles.list} ${text === '' ? styles.full : ''} ${stackMobileTwo ? styles.stackMobileTwo : ''}`}
        >
          {data.map((d, i) => (
            <div
              id={i == data.length - 1 ? 'image-block-last' : undefined}
              key={i}
              onClick={() => {
                if (d.link) navigate(d.link);
              }}
              className={clsx(styles.imageBlock, { [styles['imageBlock--clickable']]: d.link })}
              data-testid={`${testId}--logo-${i}`}
            >
              <Image style={d.style} src={d.src} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogosBlock;
