import { FC, useCallback, useState } from 'react';

import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { log } from 'utils';
import { useForm, usePage } from 'utils/hooks';

import {
  BookingAppointmentType,
  BookingCategoryEnum,
  useLazyGetBookingQuery,
  useReviewBookingMutation,
} from 'redux/apis/OG/booking';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Chips, Input, Rating, Segments } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  appointmentId?: string;
  appointmentIds?: Array<string>;
};

const ReviewAppointment: FC<Props> = ({ id, appointmentId, appointmentIds = [], onClose, ...rest }) => {
  const [bookingIds, setBookingIds] = useState<string[]>([]);
  const [bookingIndex, setBookingIndex] = useState<number>(0);
  const [appointments, setAppointments] = useState<BookingAppointmentType[]>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchBooking] = useLazyGetBookingQuery();
  const [reviewBooking] = useReviewBookingMutation();

  const form = useForm();

  const { isLoading } = usePage(async () => {
    // fetch appointment data
    resetForm();

    const _bookingIds = appointmentId ? [appointmentId] : appointmentIds;

    setBookingIds(_bookingIds);
    await fetchBookingAppointments(_bookingIds[0]);
  });

  const fetchBookingAppointments = useCallback(
    async (bookingId) => {
      // set fetching state to show spinner
      setFetching(true);
      try {
        const appointmentData = await fetchBooking({ id: bookingId }).unwrap();
        let _appointments = cloneDeep(appointmentData.appointments);
        if (appointmentData.category == BookingCategoryEnum.BackToBack) {
          _appointments = [_appointments[0]]; // if back to back, we just want to review the first one
        }

        setAppointments(_appointments);
      } catch (error) {
        // if booking fails to fetch, proceed to next one if any
        if (bookingIndex + 1 <= bookingIds.length - 1) {
          // if there are bookings left to review
          setBookingIndex(bookingIndex + 1);
          setCurrentIndex(0);

          resetForm();
          fetchBookingAppointments(bookingIds[bookingIndex + 1]);
        } else {
          onClose();
        }
      }
    },
    [bookingIds, bookingIndex]
  );

  const resetForm = useCallback(() => {
    form.setValue('rating', 0);
    form.setValue('pillFeedback', null);
    form.setValue('level', 'standard');
    form.setValue('comments', '');
  }, [form]);

  const review = useCallback(async () => {
    const { comments, level, rating, pillFeedback } = form.getValues();
    const currentAppointment = (appointments || [])[currentIndex];
    const { therapist } = currentAppointment;
    const providerId = therapist.id;

    try {
      await reviewBooking({
        bookingId: currentAppointment.id,
        comments,
        level,
        providerId,
        starRating: rating,
        ratingDetail: rating <= 3 ? pillFeedback : null,
      }).unwrap();
      let hasToScroll = false;
      if (appointments.length - 1 > currentIndex) {
        // check if there is another appointment to review in this booking
        hasToScroll = true;
        setCurrentIndex(currentIndex + 1);

        resetForm();
      } else if (bookingIds.length - 1 > bookingIndex) {
        // check if there are other bookings to review
        const nextBookingId = bookingIds[bookingIndex + 1];

        hasToScroll = true;
        setBookingIndex(bookingIndex + 1);
        setCurrentIndex(0);

        await fetchBookingAppointments(nextBookingId);
        resetForm();
      } else {
        // finished reviewing, can close the modal
        onClose();
      }

      if (hasToScroll) {
        const elem = document.getElementById(id);
        elem.scrollTop = 0;
      }
    } catch (errors) {
      log.error(errors);
      onClose(); // if, for some reason, the previous review call fails, the modal will force close to avoid blocking the user from proceeding in the booking flow
    }
  }, [id, currentIndex, appointments, bookingIds, bookingIndex, form]);

  const isComplete = useCallback(() => {
    const { level, rating, pillFeedback } = form.getValues();

    if (!rating || rating == 0 || !level) return false;
    if (rating <= 3) return !!pillFeedback;

    return true;
  }, [form]);

  const currentAppointment = (appointments || [])[currentIndex];

  const { therapist, scheduled, length, category, service } = currentAppointment || {};
  const { profilePicture, firstName, lastName } = therapist || {};
  const date = scheduled && moment(scheduled).format('ddd MMM D, YYYY @ h:mma');

  // defining massage type for details string template
  let details = '';
  if (category == BookingCategoryEnum.Couples) details = `${length}-min Couples`;
  if (category == BookingCategoryEnum.BackToBack) details = 'Back to Back Massage';
  if (category == BookingCategoryEnum.Single) details = `${length}-min ${service.name} Massage`;

  // adding couples index text
  if (category == BookingCategoryEnum.Couples && appointments.length > 1) {
    details += `, Massage ${currentIndex + 1}/${appointments.length}`;
  }

  const { rating } = form.getValues();
  const feedbacks = [
    {
      value: '5',
      label: 'Ineffective Massage',
    },
    {
      value: '7',
      label: 'Arrived Late',
    },
    {
      value: '6',
      label: 'Unprofessional Appearance',
    },
  ];

  return (
    <Modal
      {...rest}
      id={id}
      onClose={onClose}
      loading={isLoading}
      className={clsx({ [styles.fetching]: fetching })}
      closable={false}
      mobileType='fullscreen'
      testId='review-appointment-modal'
      title='Review Last Appointment'
      actions={[{ label: 'Submit', onClick: review, disabled: !isComplete(), main: true }]}
      centerContent
    >
      {bookingIds.length > 1 && (
        <div className={styles.topLabelContainer}>{`${bookingIndex + 1}/${bookingIds.length}`}</div>
      )}

      <SkeletonCover loading={isLoading}>
        <div className={styles.therapistAvatar} style={{ backgroundImage: `url(${profilePicture})` }} />
        <h3 className={styles.therapistName}>{`${firstName} ${lastName}`}</h3>
        <p className={styles.detailLine}>{date}</p>
        <p className={styles.detailLine}>{details}</p>
      </SkeletonCover>

      <div className={styles.separator} />

      <Rating
        className={styles.stars}
        iconClassName={styles.starIcon}
        {...form.getProps('rating')}
        testId='review-appointment-modal--rating'
      />
      {!!rating && rating <= 3 && (
        <Chips
          {...form.getProps('pillFeedback')}
          className={styles.chips}
          items={feedbacks}
          testId='review-appointment-modal--feedback-chips'
        />
      )}
      <Segments
        {...form.getProps('level')}
        className={styles.level}
        label={`Would you see ${firstName} again?`}
        items={[
          { value: 'blocked', label: 'Never' },
          { value: 'standard', label: 'Sure' },
          { value: 'priority', label: 'Prioritize' },
        ]}
        testId='review-appointment-modal--level-segments'
      />
      <Input
        {...form.getProps('comments')}
        multiline
        label='Comments'
        className={styles.comments}
        hideValidation
        required={false}
        placeholder='Your rating and comments will not be shared with the therapist.'
        testId='review-appointment-modal--comments-input'
      />
    </Modal>
  );
};

export default ReviewAppointment;
