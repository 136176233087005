import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers, log } from 'utils';
import { useForm } from 'utils/hooks';

import { useSendMobilePhoneVerificationCodeMutation, useVerifyMobilePhoneMutation } from 'redux/apis/OG/user';

import Modal, { ModalProps } from '../../templates/ModalNew';
import styles from './style.module.scss';

type Props = ModalProps & {
  onSuccess?: () => void;
  onCancel?: () => void;
  mobile?: string;
};

const MobileVerification: FC<Props> = ({ mobile, onSuccess, onCancel, onClose, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [localError, setLocalError] = useState<any>(null);
  const [sendMobilePhoneVerificationCode] = useSendMobilePhoneVerificationCodeMutation();
  const [verifyMobilePhone] = useVerifyMobilePhoneMutation();

  const n1 = useRef<HTMLInputElement>(null);
  const n2 = useRef<HTMLInputElement>(null);
  const n3 = useRef<HTMLInputElement>(null);
  const n4 = useRef<HTMLInputElement>(null);

  const form = useForm();

  useEffect(() => {
    n1.current?.focus();
    if (helpers.onLowerEnvironment()) {
      form.setValues({
        n1: 1,
        n2: 2,
        n3: 3,
        n4: 4,
      });
    }
  }, []);

  const confirm = useCallback(async () => {
    setLoading(true);
    const numbers = [1, 2, 3, 4];
    const code = numbers.map((nr) => form.getValue('n' + nr)).join('');

    try {
      await verifyMobilePhone(code).unwrap();
      onSuccess();
      onClose(true);
    } catch (errors: any) {
      console.log(errors);
      numbers.forEach((nr) => form.setValue('n' + nr, ''));
      n1.current?.focus();
      log.error(errors);

      if (errors?.mobile_confirm_code) {
        setLocalError(errors.mobile_confirm_code);
      } else {
        setLocalError('An error occurred, please try again later.');
      }
    }

    setLoading(false);
  }, [form]);

  const focusNext = useCallback(
    (id: number, event: ChangeEvent<HTMLInputElement>) => {
      form.setValue('n' + id, event.target.value);
      switch (id) {
        case 1:
          return n2.current?.focus();
        case 2:
          return n3.current?.focus();
        case 3:
          return n4.current?.focus();
        case 4:
          return n4.current?.blur();
      }
    },
    [form]
  );

  const resendCode = async () => {
    setLoading(true);
    await sendMobilePhoneVerificationCode().unwrap();
    setLoading(false);
  };

  const handlePaste = useCallback(
    (event) => {
      event.clipboardData.items[0].getAsString((text) => {
        const code = text
          .trim()
          .split('')
          .map((char) => {
            if (Number.isInteger(Number(char))) {
              return Number(char);
            }

            return '';
          });

        if (code.length === 4) {
          let wrongCharacter = false;
          code.forEach((val, index) => {
            if (val !== '') {
              form.setValue(`n${index + 1}`, val);
            } else wrongCharacter = true;
          });

          if (!wrongCharacter) {
            confirm();
          }
        }
      });
    },
    [form]
  );

  const mobileWithoutSpaces = mobile.replace(/\s/g, '');
  const displayPhone = /^[0-9 ]+$/.test(mobile)
    ? `${mobileWithoutSpaces.substring(0, 3)}-${mobileWithoutSpaces.substring(3, 6)}-${mobileWithoutSpaces.substring(
        6,
        10
      )}`
    : mobile;

  return (
    <Modal
      {...rest}
      title='Mobile Verification'
      description={
        <>
          A verification code was just sent to{' '}
          {mobile ? (
            <>
              <br />
              {displayPhone}
            </>
          ) : (
            <>
              your
              <br />
              mobile phone number
            </>
          )}
          .
        </>
      }
      loading={loading}
      mobileType={'drawer'}
      onClose={() => {
        onCancel?.();
        onClose();
      }}
      actions={[
        {
          label: 'Verify',
          onClick: () => confirm(),
          main: true,
          disabled: !form.isValid() || loading,
        },
      ]}
      testId='mobile-verification-modal'
    >
      <div className={clsx(styles.inputsWrapper, { [styles.inputsWrapperDisabled]: loading })} onPaste={handlePaste}>
        <input
          ref={(input) => (n1.current = input)}
          inputMode='numeric'
          pattern='[0-9]*'
          type='number'
          className={styles.input}
          name='n1'
          value={form.getValue('n1')}
          onChange={(event) => {
            focusNext(1, event);
          }}
          maxLength={1}
          autoComplete='one-time-code'
          data-testid='mobile-verification-modal--code-input-1'
        />
        <input
          ref={(input) => (n2.current = input)}
          inputMode='numeric'
          pattern='[0-9]*'
          type='number'
          className={styles.input}
          name='n2'
          value={form.getValue('n2')}
          onChange={(event) => {
            focusNext(2, event);
          }}
          maxLength={1}
          data-testid='mobile-verification-modal--code-input-2'
        />
        <input
          ref={(input) => (n3.current = input)}
          inputMode='numeric'
          pattern='[0-9]*'
          type='number'
          className={styles.input}
          name='n3'
          value={form.getValue('n3')}
          onChange={(event) => {
            focusNext(3, event);
          }}
          maxLength={1}
          data-testid='mobile-verification-modal--code-input-3'
        />
        <input
          ref={(input) => (n4.current = input)}
          inputMode='numeric'
          pattern='[0-9]*'
          type='number'
          className={styles.input}
          name='n4'
          value={form.getValue('n4')}
          onChange={(event) => {
            focusNext(4, event);
          }}
          maxLength={1}
          data-testid='mobile-verification-modal--code-input-4'
        />
      </div>
      <u onClick={resendCode} className={styles.glBoldLink} data-testid='mobile-verification-modal--resend-code-link'>
        Resend Code
      </u>
      {localError && <p className={styles.codeError}>{localError}</p>}
    </Modal>
  );
};

export default MobileVerification;
