import { Rating } from 'components/common';

import styles from './style.module.scss';

type Props = {
  title?: string;
  description?: string;
  testId?: string;
};

const RatingBlock = (props: Props) => {
  const {
    title = 'Customer Satisfaction Rating',
    description = 'Zeel is home to the largest, highest-quality network of health and wellness professionals nationwide (and the smartest and most discerning customers).',
    testId = 'rating-block',
  } = props;
  return (
    <div className={styles.block} data-testid={testId}>
      <h3>
        <span className={styles.rating}>4.8</span>
        <Rating
          className={styles.ratingBlock}
          iconClassName={styles.ratingBlockStar}
          value={4.65}
          readonly={true}
          fractions={100}
          theme='primary'
        />
      </h3>
      <h3 data-testid={`${testId}--title`}>{title}</h3>
      <h5 data-testid={`${testId}--description`}>{description}</h5>
    </div>
  );
};

export default RatingBlock;
