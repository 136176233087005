import { FC, useMemo } from 'react';

import BoxItem from 'components/common/BoxItem';

export type ChoiceListProps = {
  items: ChoiceListItem[];
  capitalizeLabels?: boolean;
  hideDisabledItems?: boolean;
  onClick?: (item: ChoiceListItem) => void | Promise<void>;
};

export type ChoiceListItem = {
  id: string;
  label: string;
  iconType: string;
  message?: string;
  disabled?: boolean;
  disabledMessage?: string;
  className?: string;
};

const ChoiceList: FC<ChoiceListProps> = ({
  items: _items = [],
  capitalizeLabels = true,
  hideDisabledItems,
  onClick,
}) => {
  const items = useMemo(
    () => (_items || []).filter((i) => !hideDisabledItems || !i.disabled),
    [_items, hideDisabledItems]
  );
  return (
    <>
      {items.map((item) => {
        const { id, label, iconType, message, disabled, disabledMessage, className } = item;
        return (
          <BoxItem
            key={id}
            title={capitalizeLabels ? `${label.charAt(0).toUpperCase()}${label.slice(1)}` : label}
            subtitle={(disabled ? disabledMessage : message) || ''}
            onClick={() => onClick?.(item)}
            disabled={disabled}
            iconType={iconType}
            state={disabled ? 'invalid' : undefined}
            className={className}
          />
        );
      })}
    </>
  );
};

export default ChoiceList;
