import { KeyboardEvent, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Image, LinkButton } from 'components/common';

import styles from './style.module.scss';

type Props = {
  title?: ReactNode; // main title
  description?: ReactNode; // description / quote (hidden on mobile)
  source?: ReactNode; // source of the quote (like the quote block)
  content?: ReactNode; // custom content
  image: string; // image of the slide
  imageMobile: string; // image of the slide to use on mobile device
  action?: {
    actionType: 'link' | 'button';
    text: ReactNode;
    buttonType?: string;
    href?: string;
    onClick?: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement> | KeyboardEvent<HTMLAnchorElement>) => void;
    className?: string;
  };
  align?: string; // align on the X axis 'left', 'center' or 'right'
  className?: string; // class to add to the slide container
  contentClassName?: string; // class of content container
  contentTheme?: string;
};

const Slide = (props: Props) => {
  const {
    title,
    description,
    source,
    content,
    image,
    imageMobile,
    action,
    align = '',
    className,
    contentClassName,
    contentTheme = 'light',
  } = props;
  const navigate = useNavigate();
  const imageUrl = helpers.getImagePath(image);

  let actionElement = null;
  if (action) {
    const {
      actionType = 'link',
      className: actionClassName,
      text = '',
      buttonType = 'inverse',
      href = null,
      onClick = null,
    } = action || {};
    if (actionType === 'link')
      actionElement = (
        <LinkButton className={clsx(styles.link, actionClassName)} href={href} onClick={onClick}>
          {text}
        </LinkButton>
      );
    if (actionType === 'button')
      actionElement = (
        <Button
          type={buttonType || 'inverse'}
          className={clsx(styles.button, actionClassName)}
          onClick={href ? () => navigate(href) : onClick}
        >
          {text}
        </Button>
      );
  }
  return (
    <div className={clsx(styles.base, className)} style={{ backgroundImage: `url(${imageUrl})` }}>
      <Image className={styles.mobileImage} alt='carousel-image' src={imageMobile} />
      <div
        className={clsx(
          styles.content,
          {
            [styles[`align-${align}`]]: align && styles[`align-${align}`],
            [styles[`contentTheme-${contentTheme}`]]: contentTheme && styles[`contentTheme-${contentTheme}`],
          },
          contentClassName
        )}
      >
        {content}
        {!content && (
          <>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
            {source && <p className={styles.source}>- {source}</p>}
            {action && actionElement}
          </>
        )}
      </div>
    </div>
  );
};

export default Slide;
