import { sortBy } from 'lodash';

import { OGApi, TAGS } from '..';
import { SourcePricingItemType, SourcePricingMapType } from '../booking';
import { SpaBookingMapping, SpaBookingRequestMapping, SpaClientMapping } from './mapping';
import {
  SourceSpaBookingType,
  SpaBookingPayloadType,
  SpaBookingPreferenceType,
  SpaBookingRequestType,
  SpaBookingType,
  SpaClientType,
} from './types';

export const bookingApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get Spa Booking Preferences
     */
    getClientList: builder.query<SpaClientType[], { bookingType?: SpaClientType['bookingType'] } | undefined>({
      query: () => {
        return {
          url: '/api/v1/member/spa/clients',
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { clients }, _meta, _args) => {
        if (_args?.bookingType) {
          return (responseData.clients || [])
            .filter((c) => c.booking_flow === _args.bookingType)
            .map((c) => SpaClientMapping.apply(c));
        }
        return (responseData.clients || []).map((c) => SpaClientMapping.apply(c));
      },
      providesTags: (result) => {
        return result
          ? [...result.map(({ id }) => ({ type: TAGS.SPA_CLIENT, id })), TAGS.SPA_CLIENT]
          : [TAGS.SPA_CLIENT];
      },
    }),

    /**
     * Get # uncheckedout appts
     */
    getNumUncheckoutAppts: builder.query<number, undefined>({
      query: () => {
        return {
          url: '/api/v1/member/spa/uncheckout_appointments',
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { number_of_unchecked_out_appointments }) => {
        const num = responseData.number_of_unchecked_out_appointments;
        return num ? Number(num) : null;
      },
    }),

    /**
     * Get Spa Booking Preferences
     */
    getBookingPreferences: builder.query<
      SpaBookingPreferenceType,
      {
        clientId?: string;
        isGig?: boolean;
      }
    >({
      query: ({ clientId: client_id, isGig: is_gig } = {}) => {
        return {
          url: '/api/v1/booking/preferences/spa',
          method: 'POST',
          params: {
            _data: '',
            client_id,
            is_gig,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { spa_preferences: SpaBookingPreferenceType }) => {
        return (responseData.spa_preferences || {}) as SpaBookingPreferenceType;
      },
    }),

    /**
     * Get all available providers for a specific booking configuration
     */
    getBookingTimes: builder.query<any[], SpaBookingRequestType>({
      query: (bookingConfiguration) => {
        return {
          url: '/api/v1/booking/times',
          method: 'POST',
          body: {
            _data: '',
            booking: SpaBookingRequestMapping.reverse(bookingConfiguration),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { dates: any[] }) => {
        return responseData.dates;
      },
      // providesTags: (result) => {
      //   return result ? [...result.map(({ id }) => ({ type: TAGS.PROVIDER, id })), TAGS.PROVIDER] : [TAGS.PROVIDER];
      // },
    }),

    /**
     * Get all available providers for a specific booking configuration
     */
    getBookingEntourage: builder.query<any[], { includeBlocked?: boolean } | undefined>({
      query: () => {
        return {
          url: '/api/v1/member/entourage',
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { entourage: any[] }, _meta, arg) => {
        const { includeBlocked } = arg || {};
        return sortBy(responseData?.entourage || [], (a) => {
          return `${a.provider?.fname} ${a.provider?.lname}`;
        }).filter((p) => {
          if (p.level === 'blocked' && !includeBlocked) return false;
          return true;
        });
      },
      // providesTags: (result) => {
      //   return result ? [...result.map(({ id }) => ({ type: TAGS.PROVIDER, id })), TAGS.PROVIDER] : [TAGS.PROVIDER];
      // },
    }),

    /**
     * Submit booking requests
     */
    submitRequests: builder.mutation<
      string[],
      { requests: SpaBookingRequestType[]; updateMemberName?: boolean; isGig?: boolean }
    >({
      query: ({ requests = [], updateMemberName, isGig }) => {
        const allRequests = [];

        requests.forEach((request) => {
          if (request._quantity) {
            for (let i = 0; i < request._quantity; i++) {
              allRequests.push(request);
            }
          } else {
            allRequests.push(request);
          }
        });

        return {
          url: '/api/v1/booking/create/multiple',
          method: 'POST',
          body: {
            bookings: allRequests.map((r) => SpaBookingRequestMapping.reverse(r)),
            only_first_to_blasting: isGig || false,
            ...(updateMemberName ? { update_target_member_name: true } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { ids: number[] }) => {
        return (responseData.ids || []).map((id) => id + '');
      },
    }),

    /**
     * Submit booking requests without transformations
     */
    createGigBooking: builder.mutation<string[], SpaBookingPayloadType>({
      query: (bookingPayload) => {
        return {
          url: '/api/v1/booking/create/multiple',
          method: 'POST',
          body: bookingPayload,
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { ids: number[] }) => {
        return (responseData.ids || []).map((id) => id + '');
      },
    }),

    getGigRequestPricing: builder.query<any, SpaBookingRequestType>({
      query: (request) => {
        return {
          url: '/api/v1/booking/pricing',
          method: 'POST',
          body: {
            _data: '',
            booking: request,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return responseData;
      },
    }),

    getGigMultiplePricing: builder.query<
      {
        pricings: {
          pricing: SourcePricingMapType;
        }[];
        pricing_list: SourcePricingItemType[];
      },
      SpaBookingPayloadType
    >({
      query: ({ bookings }) => {
        return {
          url: '/api/v1/booking/pricing/multiple',
          method: 'POST',
          body: {
            _data: '',
            bookings: bookings,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),
    createSpaClient: builder.mutation<string, { spaName: string; locationId: string }>({
      query: ({ spaName, locationId }) => {
        return {
          url: '/api/v1/member/spa/create',
          method: 'POST',
          body: {
            _data: '',
            spa_name: spaName,
            address_id: locationId,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return responseData?.client_id;
      },
      invalidatesTags: () => [TAGS.SPA_CLIENT, TAGS.USER],
    }),
    updateSpaClientName: builder.mutation<string, { id: string; name: string }>({
      query: ({ id, name }) => {
        return {
          url: '/api/v1/member/spa/update_name',
          method: 'POST',
          body: {
            _data: '',
            client_id: id,
            name,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: () => [TAGS.SPA_CLIENT],
    }),
    submitSelfServeFlowEvent: builder.mutation<string, Record<string, any>>({
      query: (data = {}) => {
        return {
          url: '/api/v1/events/submit',
          method: 'POST',
          body: {
            _data: '',
            event_name: 'spa_self_serve_booking_flow',
            custom_attributes: {
              ...data,
            },
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),
    getSpaBookings: builder.query<
      { bookings: SpaBookingType[]; hasMore: boolean },
      {
        clientId?: string;
        include?: ('past' | 'upcoming' | 'canceled')[];
        page?: number;
      }
    >({
      query: ({ clientId, include = ['upcoming'], page = 0 }) => {
        let upcomingVal = undefined;
        let canceledVal = false;
        if (include.includes('upcoming') && include.includes('past')) upcomingVal = undefined;
        else if (include.includes('upcoming')) upcomingVal = true;
        else if (include.includes('past')) upcomingVal = false;
        if (include.includes('canceled')) canceledVal = true;
        return {
          url: '/api/v1/member/spa/bookings',
          method: 'POST',
          body: {
            _data: '',
            ...(clientId ? { client_id: clientId } : {}),
            ...(upcomingVal !== undefined ? { upcoming: upcomingVal } : {}),
            return_therapist_canceled: canceledVal,
            page,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return {
          bookings: (responseData?.bookings || []).map((booking: SourceSpaBookingType) =>
            SpaBookingMapping.apply({
              ...booking,
              child_appts: (booking.child_appts || []).map((appt) => SpaBookingMapping.apply(appt)),
            })
          ),
          hasMore: responseData?.has_more || false,
        };
      },
    }),
  }),
});

export const {
  // Queries
  useGetClientListQuery,
  useGetNumUncheckoutApptsQuery,
  useGetBookingPreferencesQuery,
  useGetBookingTimesQuery,
  useGetBookingEntourageQuery,
  useGetGigMultiplePricingQuery,
  useGetGigRequestPricingQuery,
  useGetSpaBookingsQuery,
  // Lazy
  useLazyGetClientListQuery,
  useLazyGetNumUncheckoutApptsQuery,
  useLazyGetBookingPreferencesQuery,
  useLazyGetBookingTimesQuery,
  useLazyGetBookingEntourageQuery,
  useLazyGetGigMultiplePricingQuery,
  useLazyGetGigRequestPricingQuery,
  useLazyGetSpaBookingsQuery,
  // Mutations
  useSubmitRequestsMutation,
  useCreateGigBookingMutation,
  useCreateSpaClientMutation,
  useUpdateSpaClientNameMutation,
  useSubmitSelfServeFlowEventMutation,
} = bookingApi;

// /api/v1/booking/new/gig
