import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Carousel, Image } from 'components/common';

import CoreBlock from '../CoreBlock';
import { LogosCarouselBlockProps } from './LogosCarouselBlock.types';
import styles from './style.module.scss';

const LogosCarouselBlock: FC<LogosCarouselBlockProps> = ({
  items = [],
  className = '',
  title = '',
  autoPlay = false,
  nbItemsPerView = 4,
  natural = true,
  topper,
  dots = true,
  arrows = false,
  testId = 'logos-carousel-block',
}: LogosCarouselBlockProps) => {
  const logos = items.map(({ image, topper: logoTopper, content, style = {}, url, className: _className }, i) => {
    const slideContent = (
      <SkeletonCover key={`${i}-image`}>
        <div
          className={clsx(styles.logoContainer, { [styles['logoContainer--clickable']]: url })}
          data-testid={`${testId}--logo-${i}`}
        >
          <Image className={clsx(styles.logo, _className)} src={image} style={style} />
          {logoTopper && <p>{logoTopper}</p>}
          {content && <h4>{content}</h4>}
        </div>
      </SkeletonCover>
    );
    if (url) {
      return (
        <a href={url} target='_blank' rel='noreferrer'>
          {slideContent}
        </a>
      );
    }
    return slideContent;
  });

  return (
    <CoreBlock
      className={clsx(styles.block, className)}
      titleClassName={styles.blockTitle}
      title={title}
      topper={topper}
      natural={natural}
      size='large'
      testId={testId}
    >
      <div className={styles.content}>
        <Carousel
          className={styles.carousel}
          slides={logos}
          settings={{
            autoplay: autoPlay,
            infinite: autoPlay && items?.length >= nbItemsPerView,
            slidesToShow: nbItemsPerView,
            autoplaySpeed: 3000,
            arrows,
            dots,
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: 1040,
                settings: {
                  slidesToShow: 3,
                  infinite: autoPlay && items?.length >= 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  infinite: autoPlay && items?.length >= 2,
                },
              },
              {
                breakpoint: 565,
                settings: {
                  slidesToShow: 1,
                  infinite: autoPlay && items?.length >= 1,
                },
              },
            ],
          }}
          testId={`${testId}--carousel`}
        />
      </div>
    </CoreBlock>
  );
};

export default LogosCarouselBlock;
