import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Html, LinkButton } from 'components/common';

import CoreBlock from '../CoreBlock';
import { MediaVideoBlockProps } from './MediaVideoBlock.types';
import styles from './style.module.scss';

const MediaVideoBlock: FC<MediaVideoBlockProps> = ({
  className,
  topper,
  title,
  linkUrl,
  embedVideoHtml,
  testId = 'media-video-block',
}) => {
  return (
    <CoreBlock
      size='normal'
      natural
      className={clsx(styles.section, className)}
      contentClassName={styles.content}
      testId={testId}
    >
      <SkeletonCover>
        <div className={styles.videoWrapper}>
          <Html purify={false} className={styles.iframeWrapper} content={embedVideoHtml} />
        </div>
      </SkeletonCover>
      <div className={styles.infoContent}>
        <SkeletonCover>
          <p>{topper}</p>
          <h3>{title}</h3>
          {linkUrl && (
            <LinkButton
              icon='play-video'
              iconProps={{ size: 25 }}
              arrow={false}
              className={styles.link}
              href={linkUrl}
              target='_blank'
              testId={`${testId}--link`}
            >
              Watch Now
            </LinkButton>
          )}
        </SkeletonCover>
      </div>
    </CoreBlock>
  );
};

export default MediaVideoBlock;
