import { FC, useMemo } from 'react';

import clsx from 'clsx';
import { merge } from 'lodash';

import { CheckmarkBoldIcon } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type ProgressBarProps = {
  steps: string[];
  currentIndex: number | null;
  style?: Record<any, any>;
};

/**
 * Progress bar component for the SPA Self Serve flow.
 * It is built to be generic, but is kept within the flow for now, as it is only used there.
 * Will be moved to zeel-ui if it is used in multiple places.
 */
const ProgressBar: FC<ProgressBarProps> = ({ steps, currentIndex, style }) => {
  // Merge the inline styles with the passed-in styles
  const inlineStyles = useMemo(
    () =>
      merge(
        {
          maxWidth: '100%',
          margin: '0 auto',
        },
        style || {}
      ) as any,
    [style]
  );

  return (
    <div className={styles.base} style={inlineStyles}>
      {steps.map((step, index) => {
        const isActive = index === currentIndex;
        const isCompleted = index < currentIndex;
        return (
          <>
            <div
              key={`node--${step}`}
              className={clsx(styles.node, {
                [styles['node--active']]: isActive,
                [styles['node--completed']]: isCompleted,
              })}
            >
              {isActive && <div className={styles['inner-dot']} />}
              {isCompleted && <CheckmarkBoldIcon className={styles['inner-checkmark']} size={12.5} />}
              <div className={styles.label}>{step}</div>
            </div>
            {index < steps.length - 1 && (
              <div
                className={clsx(styles.connector, {
                  [styles['connector--completed']]: isCompleted,
                })}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

export default ProgressBar;
