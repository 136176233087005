import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonProvider } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

export const SCROLL_ANCHOR_ID = 'payment-methods-scroll-anchor';

const Root: FC<{ loading?: boolean; children: ReactNode }> = ({ loading, children }) => {
  return (
    <div className={clsx(styles.base, { [styles.loading]: loading })}>
      <div id={SCROLL_ANCHOR_ID} />
      <SkeletonProvider loading={loading}>{children}</SkeletonProvider>
    </div>
  );
};

export default Root;
