import { FC } from 'react';

import { themes } from 'utils/theme-context';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type ModalBookingTipsProps = ModalProps;

/**
 * Modal for displaying booking tips. This modal is displayed when the user clicks on
 * the booking tips link. It is currently used on the intro and requests pages.
 */
const ModalBookingTips: FC<ModalBookingTipsProps> = ({ onClose, ...rest }) => {
  return (
    <Modal
      {...rest}
      onClose={() => onClose()}
      mobileType='drawer'
      title='Booking Tips'
      theme={themes.atWork}
      className={styles.base}
      actions={[{ label: 'Ok', onClick: () => onClose(), variant: 'accent' }]}
    >
      <b>How can I ensure that the therapists know what treatment modalities are expected?</b>
      <ul>
        <li>You should add that information in the Notes field on this screen.</li>
      </ul>
      <b>
        My spa has a strict no visible tattoos policy, how can I make sure therapists are aware of this and other
        details?
      </b>
      <ul>
        <li>
          When you add your address details, you will be required to fill out a field about your spa’s dress code, which
          therapists will see.
        </li>
      </ul>
      <b>How will I pay for the therapists?</b>
      <ul>
        <li>
          You will be prompted to add a credit card as part of the checkout process. Your credit card will be charged
          once for each therapist after the therapist completes their time at your spa. You will receive an emailed
          receipt for each therapist.
        </li>
      </ul>
      <b>Can I be invoiced instead of paying by credit card?</b>
      <ul>
        <li>
          If you upgrade to a full-service spa account, we offer multiple payment options in our AR portal, including
          invoicing options. To use our self-serve spa staffing feature, we do require a credit card.
        </li>
      </ul>
      <b>How are tips for therapists handled?</b>
      <ul>
        <li>
          Zeel pays massage therapists an hourly rate + gratuity that they agree to as their total payment amount. They
          do not expect or require additional gratuity on top of the total pay from Zeel.
          <br />
          Your spa should not collect gratuity on behalf of the massage therapists.
          <br />
          If you do collect gratuity on behalf of a massage therapist, including any that is added by customers via
          credit card, room charge or cash, you must pass on that gratuity to the therapist.
        </li>
      </ul>
    </Modal>
  );
};

export default ModalBookingTips;
