import { CSSProperties, FC, ReactNode, useCallback, useMemo } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';
import { useUser } from 'utils/hooks';

import { Steps, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { Button, LinkButtonFlow } from 'components/common';
import { ButtonProps } from 'components/common/Button';
import { LinkButtonFlowProps } from 'components/common/LinkButtonFlow';
import FlowLayout, { FlowLayoutProps } from 'components/layouts/FlowLayout';

import HowItWorks from '../HowItWorks';
import ProgressBar from '../ProgressBar';
import SpaCart from '../SpaCart';
import styles from './style.module.scss';

// Enum for the timeline steps
enum timelineSteps {
  DETAILS = 'Details',
  SPA = 'Spa',
  REVIEW = 'Review',
}

type Props = FlowLayoutProps & {
  title?: string;
  description?: ReactNode;
  showTimeline?: boolean;
  showHowItWorks?: boolean;
};

/**
 * Layout for the SPA Self Serve flow.
 * Includes a timeline, cart, and how it works section.
 */
const Layout: FC<Props> = ({
  loading,
  children,
  navProps,
  step,
  title,
  description,
  showTimeline,
  showHowItWorks,
  ...rest
}) => {
  const { isAuthenticated, isLoading: isUserLoading, isTempMember } = useUser();
  const {
    actions: { cartToggledStateChanged },
    selectors: { useRequestsSelector, useCartToggledSelector },
  } = useSpaSelfServeFlowSlice();

  /** States **/
  const cartToggled = useCartToggledSelector();

  /** Selectors **/
  const requests = useRequestsSelector();

  /** Handlers **/
  const handleCartClicked = useCallback(() => {
    cartToggledStateChanged(!cartToggled);
  }, [cartToggled]);

  /** Memoized Values **/
  const steps = useMemo(() => {
    return isAuthenticated && !isTempMember
      ? [timelineSteps.SPA, timelineSteps.DETAILS, timelineSteps.REVIEW]
      : [timelineSteps.DETAILS, timelineSteps.SPA, timelineSteps.REVIEW];
  }, [isAuthenticated, isTempMember]);

  const currentStepIndex = useMemo(() => {
    const matches = {
      [timelineSteps.DETAILS]: [Steps.Intro, Steps.Requests],
      [timelineSteps.SPA]: [Steps.LocationInfo, Steps.Locations],
      [timelineSteps.REVIEW]: [Steps.Checkout],
    };
    let matchKey: string;
    Object.entries(matches).find(([key, value]) => {
      if (value.includes(step as Steps)) matchKey = key;
    });
    return Math.max(
      steps.findIndex((s) => s === matchKey),
      0
    );
  }, [steps, step]);

  return (
    <FlowLayout
      {...rest}
      loading={loading || isUserLoading}
      flowClassName={clsx(styles.base, { [styles['base--withTimeline']]: showTimeline })}
      full
      navProps={{
        logoLink: routes.SETTINGS.SPA_BOOKINGS.UPCOMING(),
        showCart: true,
        cartQuantity: (requests || []).length,
        onCartClick: handleCartClicked,
        cartBadgeColor: '#EB5757',
        ...(navProps || {}),
      }}
    >
      {showTimeline && (
        <ProgressBar
          steps={steps}
          currentIndex={currentStepIndex}
          style={{ width: 'max(25%, 300px)', marginBottom: '24px' }}
        />
      )}
      {(title || description) && (
        <div className={clsx('flow-header', styles.flowHeader)}>
          {title && <h1>{title}</h1>}
          {description && <h3>{description}</h3>}
        </div>
      )}
      {children}
      {showHowItWorks && <HowItWorks />}
      <SpaCart />
    </FlowLayout>
  );
};

export default Layout;

/**
 * Body component to use within the layout children.
 * Includes a size prop to adjust the width of the body.
 */
export const Body: FC<{ children: ReactNode; size?: 'narrow' | 'default' | 'large' | 'full' }> = ({
  children,
  size = 'default',
}) => {
  return <div className={clsx('flow-body', styles.flowBody, styles[`flowBody--${size}`])}>{children}</div>;
};

type FooterAction = {
  label: string;
  onClick: () => void;
} & (
  | { type: 'button'; componentProps?: Partial<Omit<ButtonProps, 'onClick'>> }
  | {
      type: 'link';
      componentProps?: Partial<Omit<LinkButtonFlowProps, 'onClick'>>;
    }
);

/**
 * Footer component to use within the layout children.
 * Includes a list of actions to render as buttons or links.
 */
export const Footer: FC<{ children?: ReactNode; actions?: FooterAction[] }> = ({ children, actions }) => {
  return (
    <div className={clsx('flow-footer', styles.flowFooter)}>
      {children}
      {(actions || []).map((action, i) => {
        const key = `footer-action-${i}`;
        if (action.type === 'button') {
          return (
            <Button type='accent' key={key} onClick={action.onClick} {...(action.componentProps || {})}>
              {action.label}
            </Button>
          );
        } else {
          return (
            <LinkButtonFlow key={key} onClick={action.onClick} {...(action.componentProps || {})}>
              {action.label}
            </LinkButtonFlow>
          );
        }
      })}
    </div>
  );
};

/**
 * Row and Cell components to use within the layout children.
 */
export const Row: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className={clsx(styles.row)}>{children}</div>;
};

/**
 * Cell component to use within the layout children.
 */
export const Cell: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
  return (
    <div className={clsx(styles.cell)} style={style}>
      {children}
    </div>
  );
};
