import { ReactNode } from 'react';

import clsx from 'clsx';

import { Carousel } from 'components/common';

import Slide from './components/Slide';
import styles from './style.module.scss';

type Props = {
  data: Array<SlideInfo>;
  autoplay?: boolean;
  carouselRef?: any;
  className?: string; // class to add to the block container
  testId?: string;
};

interface SlideInfo {
  title?: ReactNode; // main title
  titleClassName?: string;
  description?: ReactNode; // description / quote (hidden on mobile)
  source?: ReactNode; // source of the quote (like the quote block)
  content?: ReactNode; // custom content
  image: string; // image of the slide
  imageMobile: string; // image of the slide to use on mobile device
  actionHref?: string; // href of cta link
  actionText?: string; // text of the cta link
  action?: ActionData;
  align?: string; // align on the X axis 'left', 'center' or 'right'
  className?: string; // class to add to the slide container
  contentClassName?: string; // class of content container
  contentTheme?: string;
}

interface ActionData {
  actionType: 'link' | 'button';
  text: string;
  href?: string;
  buttonType?: string;
  onClick?: () => void;
  className?: string;
}

const ImageCarouselBlock = (props: Props) => {
  const { data = [], className = '', autoplay = true, carouselRef = null, testId = 'image-carousel-block' } = props;
  const slides = data.map((item) => {
    const {
      title,
      description,
      source,
      content,
      image,
      imageMobile,
      action,
      align,
      className: itemClassName,
      contentClassName,
      contentTheme,
    } = item;
    const slideProps = {
      title,
      description,
      source,
      content,
      image,
      imageMobile,
      action,
      align,
      className: itemClassName,
      contentClassName,
      contentTheme,
    };
    return {
      content: <Slide {...slideProps} />,
    };
  });

  return (
    <div className={clsx(styles.block, className)} data-testid={testId}>
      <Carousel
        className={styles.carousel}
        carouselRef={carouselRef}
        slides={slides}
        settings={{
          autoplay,
          autoplaySpeed: 10000,
          infinite: true,
          slidesToShow: 1,
          dots: true,
          swipeToSlide: true,
          adaptiveHeight: true,
        }}
        testId={`${testId}--carousel`}
      />
    </div>
  );
};

export default ImageCarouselBlock;
