import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { ProductType, useGetProductsQuery } from 'redux/apis/OG/estore';
import { DiscountType } from 'redux/apis/OG/estore/constants';
import { useEstoreFlowSlice } from 'redux/features/estoreFlow';

import { useModals } from '@zeel-dev/zeel-ui';
import { Button, ColorTilePicker, Image } from 'components/common';
import ModalProductDetails from 'components/modals/items/ProductDetails';

import styles from './style.module.scss';

type Props = {
  productId: string;
  className?: string;
  right?: boolean;
  greenery?: boolean;
  testId?: string;
};

const ProductInfoBlock: FC<Props> = ({
  productId,
  className = '',
  right,
  greenery = true,
  testId = 'product-info-block',
}) => {
  const [activeImage, setActiveImage] = useState<any>(0);
  const [pickedColor, setPickedColor] = useState<ProductType['_colors'][0]>();
  const { data: products } = useGetProductsQuery();
  const { openModal } = useModals();

  const {
    actions: { cartOpened, itemAddedToCart },
  } = useEstoreFlowSlice();

  const product = useMemo(
    () => products?.find((p) => p.id + '' === (pickedColor?._product_id || productId) + ''),
    [products, productId, pickedColor]
  );

  useEffect(() => {
    if (product) {
      const defaultColor = product._colors?.find((_c) => _c._color_id === product._color_id);
      setPickedColor(defaultColor);
    }
  }, [product]);

  const learnMore = useCallback(() => {
    openModal({
      element: <ModalProductDetails productId={product?.id + ''} />,
    });
  }, [product]);

  const addToCart = useCallback(async () => {
    await helpers.waitFor(200);
    await cartOpened();
    setTimeout(() => {
      itemAddedToCart?.({ id: product?.id + '', quantity: 1 });
    }, 500);
  }, [product]);

  const getPhotoTiles = useCallback(
    (isForMobile = false) => {
      const photos = product?.extra?.photos || [];
      return (
        (photos || []).length > 0 && (
          <div className={clsx(styles.imageTileContainer, { [styles['imageTileContainer-mobile']]: isForMobile })}>
            {(photos || []).map((photo, i) => {
              return (
                <div
                  key={i}
                  className={clsx(styles.imageTile, { [styles['imageTile--active']]: activeImage === i })}
                  style={{ backgroundImage: `url(${photo})` }}
                  onClick={() => setActiveImage(i)}
                />
              );
            })}
          </div>
        )
      );
    },
    [product, activeImage]
  );

  const onColorChange = useCallback(
    (colorHex) => {
      const { _colors } = product || {};
      const _pickedColor = _colors.find((c) => c._hex === colorHex);
      if (!_pickedColor) return;
      setPickedColor(_pickedColor);
    },
    [product, products]
  );

  if (!product) return null; // if product isn't in store or disabled, will hide this block

  const { name, _label_without_color, description, price } = product || {};

  const { slogan, benefits, photos } = product?.extra || {};

  return (
    <div id={`product-${name}`} className={clsx(styles.block, className)} data-testid={testId}>
      {greenery && (
        <img
          id={`product-${name}-parallax`}
          className={clsx(styles.parallaxPhoto, { [styles['parallaxPhoto-right']]: !right })}
          src={helpers.getImagePath('greenery/greenery-square.png')}
          alt='greenery'
        />
      )}
      <div
        id={`hash-${name}`}
        className={clsx(styles.largePhoto, { [styles['largePhoto-right']]: !right })}
        style={{ background: `url(${(photos || [])[activeImage]})` }}
      />
      <div className={styles.photoMobileSpacer} />
      {getPhotoTiles(true)}
      <div
        className={clsx(styles.productInfoContainer, styles.glContainer, {
          [styles['productInfoContainer-right']]: right,
          [styles[className]]: className,
        })}
      >
        <div className={styles.infoBox}>
          <div className={styles.titleContainer}>
            <h3 onClick={learnMore}>{_label_without_color}</h3>
          </div>
          <p className={styles.slogan}>{slogan}</p>
          <div className={styles.description}>{description}</div>
          <ul>
            {(benefits || []).map((benefit, i) => {
              const { title, icon } = benefit;
              return (
                <li key={i}>
                  <Image className={styles.benefitIcon} src={icon?.svg} /> {title}
                </li>
              );
            })}
          </ul>

          <div className={styles.priceContainer}>
            {price?.discount_type === DiscountType.Sale && (
              <>
                <div className={clsx(styles.priceBox, styles['priceBox-striked'])}>
                  <p className={styles.price}>${Number(price?.original)?.toFixed(2)}</p>
                  <p className={styles.label}>Regular Price</p>
                </div>
                <div className={styles.priceBox}>
                  <p className={styles.priceHighlight}>${Number(price?.current)?.toFixed(2)}</p>
                  <p className={styles.labelHighlight}>Sale Price</p>
                </div>
              </>
            )}

            {price?.discount_type !== DiscountType.Sale && (
              <>
                <div className={styles.priceBox}>
                  <p className={styles.price}>${Number(price?.current)?.toFixed(2)}</p>
                </div>
              </>
            )}
          </div>

          {product?._colors && (
            <div className={styles.colorContainer}>
              <ColorTilePicker
                name='color-tile-picker-store'
                value={pickedColor ? pickedColor._hex : null}
                onChange={onColorChange}
                label='Color selection'
                colors={(product?._colors || []).map((c) => ({ label: c._color_id, value: c._hex }))}
              />
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              className={styles.actionButton}
              type='secondary'
              onClick={learnMore}
              testId={`${testId}--learn-more-button`}
            >
              Learn More
            </Button>
            <Button className={styles.actionButton} onClick={addToCart} testId={`${testId}--add-to-cart-button`}>
              Add to Cart
            </Button>
          </div>

          {getPhotoTiles()}
        </div>
      </div>
    </div>
  );
};

export default ProductInfoBlock;
