import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';
import { usePage } from 'utils/hooks';
import { getRouteTab } from 'utils/routes';
import { ThemeContext } from 'utils/theme-context';

import { useLogoutMutation } from 'redux/apis/OG/auth';
import { openChat } from 'redux/apis/OG/help';
import { useEstoreFlowSlice } from 'redux/features/estoreFlow';

import { SkeletonCover, useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Dropdown, Icon, IconSvg, Link, Loader } from 'components/common';

import SuperMenu from '../SuperMenu';
import { NavigationProps } from './Navigation.types';
import styles from './style.module.scss';

const Navigation: FC<NavigationProps> = ({
  onHeaderRef,
  hideMainButton,
  mainButtonText,
  mainButtonAction,
  mainButtonProps = {},
  progressBar,
  gated,
  simple,
  showCart = false,
  cartQuantity: customCartQuantity,
  cartBadgeColor,
  onCartClick,
  backAction,
  completionBar,
  logoClassName,
  dualLogo,
  logoLink,
  helpIconOnClick,
  helpIconOpensChat,
  hideUserIcon,
  hideBackArrow,
  topRightContent,
  theme: propsTheme,
  testId = 'navigation',
}) => {
  const [isSuperMenuOpened, setIsSuperMenuOpened] = useState(false);
  const [activeTab, setActiveTab] = useState('personal');
  const [showMainButton, setShowMainButton] = useState(false);
  const [logout] = useLogoutMutation();
  const themeContext = useContext(ThemeContext);
  const theme = propsTheme || themeContext;
  const navigate = useNavigate();

  const {
    actions: { cartToggled },
    selectors: { useCartTotalQuantitySelector },
  } = useEstoreFlowSlice();
  const cartQuantity = useCartTotalQuantitySelector();

  // Queries
  const {
    isAuthenticated,
    user,
    isAdmin,
    isConcierge,
    isAppliedConcierge,
    isSpa,
    isSpaFullService,
    isProvider,
    isUserFetching,
  } = usePage();

  const isSpaFullServiceAccount = useMemo(() => isSpa && isSpaFullService, [isSpa, isSpaFullService]);

  const fakeNavRef = useRef(null);
  const navRef = useRef(null);

  const updateFakeSize = () => {
    if (navRef?.current && fakeNavRef?.current) {
      const height = navRef.current.clientHeight ?? 0;
      fakeNavRef.current.style.height = `${height}px`;
    }
  };

  const onResize = () => {
    updateFakeSize();
  };

  const handleScroll = () => {
    if (window.scrollY > 500) {
      if (!showMainButton) {
        setShowMainButton(true);
      }
    } else {
      setShowMainButton(false);
    }
  };

  const handleLogout = async () => {
    await logout().unwrap();
  };

  useEffect(() => {
    // setting the active tab
    const tab = getRouteTab({ noFallback: true });
    setActiveTab(tab);

    updateFakeSize();
    setTimeout(updateFakeSize);

    if (onHeaderRef) {
      onHeaderRef(navRef.current);
    }

    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    });
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const toggleSuperMenu = () => {
    const opened = !isSuperMenuOpened;
    setIsSuperMenuOpened(opened);
  };

  const toggleCart = () => {
    cartToggled();
  };

  const getNavItems = (tab: string) => {
    switch (tab || 'personal') {
      case 'personal': {
        if (!tab && isSpaFullServiceAccount) {
          return (
            <div className={styles.spaNavLinkContainer}>
              <Link
                href={routes.SPA_BLOCK_PROJECT_REQUEST.index()}
                className={styles.linkSpa}
                activeClassName={styles['linkSpa--active']}
                activeIfIncludes={routes.SPA_BLOCK_PROJECT_REQUEST.index()}
              >
                Request a Block Project
              </Link>
              <Link
                href={routes.SPA_INDIVIDUAL_REQUEST.index()}
                className={styles.linkSpa}
                activeClassName={styles['linkSpa--active']}
                activeIfIncludes={routes.SPA_INDIVIDUAL_REQUEST.index()}
              >
                Request Single Treatment
              </Link>
            </div>
          );
        }
        const items = [
          {
            label: <>Massage</>,
            icon: 'massage-hands',
            link: routes.IN_HOME_MASSAGE(),
          },
          {
            label: <>Massage for Veterans</>,
            icon: 'military',
            link: routes.VA_MICROSITE({ host: true }),
          },
          {
            label: <>Massage Membership</>,
            icon: 'orientation-card',
            link: routes.MEMBERSHIP.index(),
          },
          {
            label: <>Zeel Gifts</>,
            icon: 'gift',
            link: routes.GIFT_CERTIFICATE.index(),
          },
        ];

        return (
          <>
            <Dropdown
              id='services-dropdown-button'
              buttonClass={clsx(styles.link)}
              buttonActiveClass={styles['link--active']}
              fixed
              label={
                <>
                  In-Home Services
                  <Icon type='angle-down' />
                </>
              }
            >
              <div id='services-dropdown' className={styles.dropdownServices}>
                <div className={clsx(styles.section, styles['section-services'])}>
                  <ul className={styles.content} role='list'>
                    {items.map(({ label, icon, link }) => {
                      return (
                        <li key={link}>
                          <Link href={link} className={styles.item}>
                            <IconSvg name={icon} size={24} />
                            <p className={styles.label}>{label}</p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Dropdown>
            <Link
              href={routes.ESTORE.index()}
              className={styles.link}
              activeClassName={styles['link--active']}
              activeIfIncludes={routes.ESTORE.index()}
            >
              Store
            </Link>
            <Link href={routes.BLOG({ host: true })} className={styles.link} activeClassName={styles['link--active']}>
              Blog
            </Link>
            <Link
              href={routes.TRUST_AND_SAFETY.index()}
              className={styles.link}
              activeClassName={styles['link--active']}
              activeIfIncludes={routes.TRUST_AND_SAFETY.CONSUMER()}
            >
              Trust & Safety
            </Link>

            {!hideMainButton && (
              <div
                className={clsx(styles.navButtonContainer, styles.bookMassageBtn, {
                  [styles['navButtonContainer--hidden']]: !showMainButton,
                })}
              >
                <Button
                  onClick={mainButtonAction ? mainButtonAction : () => navigate(routes.PERSONAL_WELLNESS.index())}
                  {...(mainButtonProps || {})}
                >
                  {mainButtonText || 'Book a Massage'}
                </Button>
              </div>
            )}
          </>
        );
      }
      case 'business': {
        return (
          <>
            <Link
              href={routes.CORPORATE_WELLNESS.index()}
              className={styles.linkBusiness}
              activeClassName={styles['linkBusiness--active']}
            >
              Workplace Wellness
            </Link>
            <Link
              href={routes.SPA({ host: true })}
              className={styles.linkBusiness}
              activeClassName={styles['linkBusiness--active']}
            >
              Spa Staffing
            </Link>
            <Link
              href={routes.TRUST_AND_SAFETY.BUSINESS()}
              className={styles.linkBusiness}
              activeClassName={styles['linkBusiness--active']}
              activeIfIncludes={routes.TRUST_AND_SAFETY.index()}
            >
              Trust & Safety
            </Link>

            {!hideMainButton && (
              <div
                className={clsx(styles.navButtonContainer, styles.bookMassageBtn, {
                  [styles['navButtonContainer--hidden']]: !showMainButton,
                })}
              >
                <Button
                  onClick={mainButtonAction ? mainButtonAction : () => navigate(routes.CHAIR_MASSAGE.index())}
                  {...(mainButtonProps || {})}
                >
                  {mainButtonText || 'Book a Chair Massage Event'}
                </Button>
              </div>
            )}
          </>
        );
      }
      case 'health': {
        return (
          <>
            <Link href={routes.HEALTHCARE()} className={styles.link} activeClassName={styles['link--active']}>
              Solutions for Healthcare Partners
            </Link>
          </>
        );
      }
      case 'provider': {
        return (
          <>
            <Link
              href={routes.APPLY({ host: true })}
              className={styles.link}
              activeClassName={styles['link--active']}
              activeIfIncludes={routes.PROVIDER.index()}
            >
              Work With Zeel
            </Link>
            <Link href={routes.BLOG({ host: true })} className={styles.link} activeClassName={styles['link--active']}>
              Blog
            </Link>
            <Link
              href={routes.TRUST_AND_SAFETY.PROVIDER()}
              className={styles.link}
              activeClassName={styles['link--active']}
              activeIfIncludes={routes.TRUST_AND_SAFETY.index()}
            >
              Trust & Safety
            </Link>

            {!hideMainButton && (
              <div
                className={clsx(styles.navButtonContainer, styles.bookMassageBtn, {
                  [styles['navButtonContainer--hidden']]: !showMainButton,
                })}
              >
                <Button
                  onClick={mainButtonAction ? mainButtonAction : () => navigate(routes.APPLY({ host: true }))}
                  role='link'
                  {...(mainButtonProps || {})}
                >
                  {mainButtonText || 'Apply Now'}
                </Button>
              </div>
            )}
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  // The items of the nav to be conditionally rendered in the nav, or underneath it (for mobile devices)
  const navItems = getNavItems(activeTab);

  // Icons for reusability
  const backArrowIcon = !hideBackArrow && (
    <Icon
      id='back-arrow'
      type='arrow-left'
      title='go back button'
      className={clsx(styles.navIcon, styles.backArrowIcon)}
      onClick={backAction ? backAction : () => navigate(routes.index({ host: true }))}
      testId={`${testId}--back-icon`}
    />
  );
  const superMenuIcon = (id) => (
    <Icon
      id={`superMenu-open-${id}`}
      type='hamburger'
      title='open supermenu button'
      hotspot={false}
      className={clsx(styles.navIcon, styles.superMenuIcon)}
      onClick={() => toggleSuperMenu()}
      testId={`${testId}--supermenu-icon`}
    />
  );
  const helpIcon = (
    <Icon
      id='help-open'
      type='question'
      title='open help button'
      hotspot={false}
      className={clsx(styles.navIcon, styles.helpIcon)}
      onClick={helpIconOpensChat ? openChat : helpIconOnClick || (() => navigate(routes.HELP.index()))}
      testId={`${testId}--help-icon`}
    />
  );
  const cartIcon = (
    <div className={styles.cartIconWrapper}>
      <Icon
        id='cart-open'
        title='open cart button'
        type='cart'
        hotspot={false}
        className={clsx(styles.navIcon, styles.cartIcon)}
        onClick={onCartClick || (() => toggleCart())}
        testId={`${testId}--cart-icon`}
      />
      {(customCartQuantity || cartQuantity) > 0 && (
        <div className={styles.badge} style={cartBadgeColor ? { backgroundColor: cartBadgeColor } : {}}>
          {customCartQuantity || cartQuantity}
        </div>
      )}
    </div>
  );
  const userIcon =
    !hideUserIcon &&
    (isAuthenticated ? (
      <Dropdown
        buttonClass={styles.headerIcon}
        buttonActiveClass={styles['link-active']}
        right={true}
        label={
          <Icon type='user' className={clsx(styles.navIcon, styles.userIcon)} testId={`${testId}--my-account-icon`} />
        }
        testId={`${testId}--my-account-topper-dropdown`}
      >
        <div className={styles.dropdownAccount}>
          {(isConcierge || isAppliedConcierge) && (
            <Link
              href={routes.ACCOUNT_CONCIERGE({ host: true })}
              testId={`${testId}--my-account-topper-dropdown--manage-account-link`}
            >
              Manage Your Account
            </Link>
          )}
          {!isConcierge && !isAppliedConcierge && isProvider && (
            <>
              <Link
                href={routes.SETTINGS.PERSONAL_ACCOUNT.index()}
                testId={`${testId}--my-account-topper-dropdown--manage-personal-account-link`}
              >
                Manage Personal Account
              </Link>
              <Link
                href={routes.ACCOUNT_PROVIDER({ host: true })}
                testId={`${testId}--my-account-topper-dropdown--manage-provider-account-link`}
              >
                Manage Provider Account
              </Link>
            </>
          )}
          {!isConcierge && !isAppliedConcierge && !isProvider && (
            <Link
              href={routes.SETTINGS.PERSONAL_ACCOUNT.index()}
              testId={`${testId}--my-account-topper-dropdown--manage-account-link`}
            >
              Manage Your Account
            </Link>
          )}
          <Link
            href={routes.SETTINGS.PERSONAL_ACCOUNT.CREDIT()}
            testId={`${testId}--my-account-topper-dropdown--add-promo-link`}
          >
            Add Promo Code
          </Link>
          <Link href={routes.INVITE_PROMO()} testId={`${testId}--my-account-topper-dropdown--invite-friends-link`}>
            Invite Friends
          </Link>
        </div>
      </Dropdown>
    ) : (
      <Link
        href={`${routes.SIGN_IN({ host: true })}?referrer=${window.location.href}`}
        testId={`${testId}--my-account-topper-dropdown--log-in-link`}
      >
        <Icon type='user' className={clsx(styles.navIcon, styles.userIcon)} />
      </Link>
    ));
  // -----
  return (
    <div id='header' role='navigation' data-testid={testId}>
      <div ref={fakeNavRef} className={styles.headerFake} />
      <div
        ref={navRef}
        className={clsx(
          styles.header,
          {
            [styles.simple]: simple,
            [styles.gated]: gated,
            [styles.withCart]: showCart,
          },
          styles[`theme-${theme}`]
        )}
      >
        {/* Top header container */}
        <div className={styles.headerTop}>
          <ul className={styles['headerTop-left']} role='list'>
            <li>
              <Link
                href={routes.IN_HOME_WELLNESS()}
                className={clsx(styles.tab, {
                  [styles['tab--active']]: activeTab === 'personal',
                })}
                testId={`${testId}--individuals-topper-link`}
              >
                Individuals
              </Link>
            </li>
            <li>
              <Link
                href={routes.CORPORATE_WELLNESS.index()}
                className={clsx(styles.tab, {
                  [styles['tab--active']]: activeTab === 'business',
                })}
                testId={`${testId}--businesses-topper-link`}
              >
                Businesses
              </Link>
            </li>
            <li>
              <Link
                href={routes.HEALTHCARE()}
                className={clsx(styles.tab, {
                  [styles['tab--active']]: activeTab === 'health',
                })}
                testId={`${testId}--healthcare-partners-topper-link`}
              >
                Healthcare Partners
              </Link>
            </li>
            <li>
              <Link
                href={routes.APPLY({ host: true })}
                className={clsx(styles.tab, {
                  [styles['tab--active']]: activeTab === 'provider',
                })}
                testId={`${testId}--providers-topper-link`}
              >
                Providers
              </Link>
            </li>
          </ul>
          <ul className={styles['headerTop-right']}>
            {isAuthenticated && (
              <>
                {isAdmin && (
                  <li>
                    <Link
                      href={routes.CMS({ host: true })}
                      className={styles.link}
                      testId={`${testId}--cms-topper-link`}
                    >
                      CMS
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    href={routes.INVITE_PROMO()}
                    className={styles.link}
                    testId={`${testId}--invite-friends-topper-link`}
                  >
                    Invite Friends
                  </Link>
                </li>
                <li>
                  <Link href={routes.HELP.index()} className={styles.link} testId={`${testId}--help-topper-link`}>
                    Help & Contact
                  </Link>
                </li>
                <li>
                  <Dropdown
                    id='account-dropdown-button'
                    buttonClass={styles.link}
                    buttonActiveClass={styles['link--active']}
                    right={true}
                    label={
                      <>
                        <SkeletonCover loading={isUserFetching}>
                          <span>
                            {isUserFetching ? (
                              <>...........</>
                            ) : (
                              <>{user?.firstName ? `Hi ${user.firstName}!` : `Hi!`}</>
                            )}
                            <Icon type='angle-down' />
                          </span>
                        </SkeletonCover>
                      </>
                    }
                    testId={`${testId}--my-account-topper-dropdown`}
                  >
                    <div id='account-dropdown' className={styles.dropdownAccount}>
                      {(isConcierge || isAppliedConcierge) && (
                        <Link
                          href={routes.ACCOUNT_CONCIERGE({ host: true })}
                          testId={`${testId}--my-account-topper-dropdown--manage-account-link`}
                        >
                          Manage Your Account
                        </Link>
                      )}
                      {!isConcierge && !isAppliedConcierge && isProvider && (
                        <>
                          <Link
                            href={routes.SETTINGS.PERSONAL_ACCOUNT.index()}
                            testId={`${testId}--my-account-topper-dropdown--manage-personal-account-link`}
                          >
                            Manage Personal Account
                          </Link>
                          <Link
                            href={routes.ACCOUNT_PROVIDER({ host: true })}
                            testId={`${testId}--my-account-topper-dropdown--manage-provider-account-link`}
                          >
                            Manage Provider Account
                          </Link>
                        </>
                      )}
                      {!isConcierge && !isAppliedConcierge && !isProvider && (
                        <Link
                          href={routes.SETTINGS.PERSONAL_ACCOUNT.index()}
                          testId={`${testId}--my-account-topper-dropdown--manage-account-link`}
                        >
                          Manage Your Account
                        </Link>
                      )}
                      <Link
                        href={routes.SETTINGS.PERSONAL_ACCOUNT.CREDIT()}
                        testId={`${testId}--my-account-topper-dropdown--add-promo-link`}
                      >
                        Add Promo Code
                      </Link>
                      <Link
                        onClick={() => handleLogout()}
                        testId={`${testId}--my-account-topper-dropdown--log-out-link`}
                      >
                        Sign Out
                      </Link>
                    </div>
                  </Dropdown>
                </li>
              </>
            )}
            {!isAuthenticated && (
              <>
                <li>
                  <Link
                    href={`${routes.SIGN_IN({ host: true })}?referrer=${window.location.href}`}
                    className={styles.link}
                    testId={`${testId}--log-in-topper-link`}
                  >
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link
                    href={routes.SIGN_UP({ host: true })}
                    className={styles.link}
                    testId={`${testId}--sign-up-topper-link`}
                  >
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link href={routes.HELP.index()} className={styles.link} testId={`${testId}--help-topper-link`}>
                    Help & Contact
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* The core navbar with page links */}
        <div className={styles.headerNav}>
          {!dualLogo && (
            <Link
              id='logo'
              title='homepage'
              href={logoLink || routes.index({ host: true })}
              className={clsx(styles.logo, logoClassName)}
              testId={`${testId}--main-company-logo`}
            />
          )}
          {dualLogo && (
            <>
              <div
                className={clsx(styles.customLogo, logoClassName)}
                onClick={dualLogo.link ? () => navigate(dualLogo.link) : null}
                style={{ cursor: dualLogo.link ? 'pointer' : 'default' }}
                data-testid={`${testId}--dual-logo`}
              >
                <div className={styles['customLogo--zeel']} />
                <div className={styles['customLogo--separator']} />
                <div className={styles['customLogo--companyLogo']}>
                  {dualLogo.logoUrl ? (
                    <img src={dualLogo.logoUrl} />
                  ) : (
                    <p className={clsx({ [styles.longName]: dualLogo.fallbackName.length > 16 })}>
                      {dualLogo.fallbackName}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}

          {/* Nav content for desktop */}
          <div className={styles['navItems']}>{navItems}</div>

          {/* Nav left icons  */}
          <div className={clsx(styles['navIconsContainer-left'], styles['navIconsContainer-left-desktop'])}>
            {gated && backArrowIcon}
            {simple && superMenuIcon('left-desktop')}
          </div>
          <div className={clsx(styles['navIconsContainer-left'], styles['navIconsContainer-left-mobile'])}>
            {gated && backArrowIcon}
            {!gated && superMenuIcon('left-mobile')}
            {!gated && showCart && helpIcon}
          </div>

          {/* Nav right icons */}
          <div className={clsx(styles['navIconsContainer-right'], styles['navIconsContainer-right-desktop'])}>
            {!gated && !simple && superMenuIcon('right-desktop')}
            {showCart && cartIcon}
            {topRightContent}
            {(simple || helpIconOpensChat || helpIconOnClick) && helpIcon}
            {simple && userIcon}
          </div>
          <div className={clsx(styles['navIconsContainer-right'], styles['navIconsContainer-right-mobile'])}>
            {!gated && !showCart && helpIcon}
            {!gated && userIcon}
            {showCart && cartIcon}
            {topRightContent}
          </div>
        </div>

        {/* Content displayed underneath nav on mobile (if no progress bar has been passed) */}
        {!progressBar && !simple && !gated && (
          <div className={styles['navItems-underneath']}>
            <div className={styles['navItems-underneath-inner']}>{navItems}</div>
          </div>
        )}
        {progressBar}

        {completionBar !== null && completionBar !== undefined && (
          <Loader className={styles.completionBar} loading={true} value={completionBar} testId={`${testId}--loader`} />
        )}
      </div>

      <SuperMenu isOpen={isSuperMenuOpened} toggleSuperMenu={() => toggleSuperMenu()} />
    </div>
  );
};

export default Navigation;
