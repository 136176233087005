import { FC } from 'react';

import clsx from 'clsx';

import { Button, Icon } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/Modal';

import styles from './style.module.scss';

type Props = ModalProps;

const PromosSuccess: FC<Props> = ({ onClose, ...rest }) => {
  return (
    <Modal {...rest} onClose={onClose} testId='promos-success-modal'>
      <div className={styles.insideModal}>
        <div className={clsx(styles.modalHeader, styles.customHeader)}>
          <Icon type='checkmark-circle' className={clsx(styles.icon, styles.customIcon)} />
          <div className={styles.title}>Credit Added</div>
          <div className={clsx(styles.subtitle, styles.customText)}>
            Your credit was successfully applied to your Zeel balance.
          </div>
        </div>

        <div className={styles.modalFooter}>
          <Button className={styles.button} type='primary' onClick={onClose} testId='promos-success-modal--main-button'>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PromosSuccess;
