import clsx from 'clsx';

import { Carousel, Image } from 'components/common';

import styles from './style.module.scss';

type Props = {
  data: Array<Record<string, any>>;
  quoteImageUrl?: string;
  template?: 'quote' | 'quoteNew';
  settings?: Record<string, any>;
  background?: boolean;
  className?: string;
  testId?: string;
  fixedQuote?: boolean;
};

const QuotesBlock = (props: Props) => {
  const {
    data,
    quoteImageUrl,
    template = 'quote',
    settings,
    background = false,
    className,
    fixedQuote,
    testId = 'quotes-block',
  } = props;

  const constructedData = [];
  data.map((item) => {
    return constructedData.push({
      template: {
        name: template,
        props: {
          ...item,
          hideQuote: fixedQuote,
        },
      },
    });
  });

  return (
    <div
      className={clsx(
        styles.block,
        { [styles.withDots]: background },
        { [styles.single]: constructedData.length === 1 },
        className
      )}
      data-testid={testId}
    >
      {background && <Image className={styles.dots} alt='dots' src={'other/dots.svg'} />}
      {fixedQuote && (
        <div className={styles.quoteContainer}>
          <Image className={styles.quote} alt='quote' src={quoteImageUrl || 'icons/quote.svg'} />
        </div>
      )}
      <Carousel
        slides={constructedData}
        settings={{ autoplaySpeed: 3000, ...settings }}
        testId={`${testId}--carousel`}
      />
    </div>
  );
};

export default QuotesBlock;
