import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import { usePage } from 'utils/hooks';

import { BookingConfigurationType } from 'redux/apis/OG/booking';
import {
  PaymentOption,
  PaymentProcessor,
  PaymentUsedFor,
  useGetPaymentOptionsQuery,
  useLazyGetClientProcessorTokenQuery,
} from 'redux/apis/OG/payment';

import { useModals } from '@zeel-dev/zeel-ui';

import { getCookie } from '../../../../utils/helpers';
import { WEBVIEW_SOURCE_COOKIE_NAME, WebviewSourceEnum } from '../../../../utils/hooks/useUi';
import { Button, Icon } from '../../../common';
import ModalInfo from '../../../modals/items/Info';
import { useLogger } from '../../logger';
import { PMCSupportedProcessors } from '../../types';
import ApplePayConnector from './connector';
import { Connectors } from './processors';
import styles from './style.module.scss';

export type ApplePayProps = {
  usedFor: PaymentUsedFor;
  request: ApplePayPaymentRequestClientConfig;
  bookingData?: BookingConfigurationType;
  override?: ApplePayOverride;
  onSuccess?: (payload: { nonce: string; type: string; processor: PaymentProcessor }) => Promise<void> | void;
  onFailure?: (error: ApplePayStatus) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
  devMode?: boolean;
  logs?: boolean;
};

type ApplePayLineItemTypes = 'final' | 'pending';
type ApplePayRecurringIntervalUnit = 'year' | 'month' | 'day' | 'hour' | 'minute';

export type ApplePayPaymentRequestLineItem = {
  type?: ApplePayLineItemTypes;
  label: string;
  amount: number;
};

// Apple Pay Payment Request Config passed as a prop to the ApplePay component
export type ApplePayPaymentRequestClientConfig = {
  total: {
    label: string;
    amount: number;
  };
  lineItems?: ApplePayPaymentRequestLineItem[];
  recurring?: {
    description: string;
    interval: {
      unit: ApplePayRecurringIntervalUnit;
      value: number;
    };
  };
};

// Apple Pay Payment Request object that is used to create the payment request.
export type ApplePayPaymentRequestConfig = {
  total: {
    label: string;
    amount: string;
  };
  lineItems?: {
    type: ApplePayLineItemTypes;
    label: string;
    amount: string;
  }[];
  recurringPaymentRequest?: {
    paymentDescription: string;
    regularBilling: {
      amount: string;
      paymentTiming: 'recurring';
      recurringPaymentIntervalUnit: ApplePayRecurringIntervalUnit;
      recurringPaymentIntervalCount: number;
    };
  };
};

export type ApplePayPaymentRequest = any;

export type ApplePaySuccessResponse = {
  nonce: string;
  type: string;
};

export type ApplePayOverride = {
  processor?: PaymentProcessor;
  ignoreAvailability?: boolean;
};

export type ApplePayStatus = {
  code: ApplePayStatusCode;
  errorCode?: ApplePayErrorCode | null;
  customMessage?: string | null;
};

export enum ApplePayStatusCode {
  Initializing = 'initializing',
  Ready = 'ready',
  Success = 'success',
  Failure = 'failure',
  NotAvailable = 'not_available',
}

export enum ApplePayErrorCode {
  InvalidParameterUsedFor = 'invalid_parameter__used_for',
  InvalidParameterBooking = 'invalid_parameter__booking',
  AvailableProcessorsRetrievalFailure = 'processor__retrieval_failure',
  ForcedProcessorNotAvailable = 'processor__not_available',
  ForcedProcessorNotSupported = 'processor__not_supported',
  NoAvailableProcessors = 'processor__none_available',
  ClientTokenRetrievalFailure = 'client_token__retrieval_failure',
  ClientTokenMissingAfterRetrieval = 'client_token__missing_after_retrieval',
  SdkFailure = 'sdk__failure',
  UnknownError = 'unknown_error',
  ApplePayNotSupported = 'apple_pay__not_supported',
  ApplePayVersionNotSupported = 'apple_pay__version_not_supported',
  ApplePayCannotMakePayments = 'apple_pay__cannot_make_payments',
  ApplePayCannotMakePaymentsWithCard = 'apple_pay__cannot_make_payments_with_card',
  ApplePayMissingMerchantId = 'apple_pay__merchant_id_missing',
  ApplePayMissingConnector = 'apple_pay__missing_connector',
  ApplePayPaymentRequestFailure = 'apple_pay__payment_request_failure',
}

const ApplePayErrorMessage: Record<ApplePayErrorCode, string> = {
  [ApplePayErrorCode.InvalidParameterUsedFor]: 'The used_for parameter is invalid',
  [ApplePayErrorCode.InvalidParameterBooking]: 'The booking parameter is invalid',
  [ApplePayErrorCode.AvailableProcessorsRetrievalFailure]:
    'Could not retrieve the available payment processors. Please try again later',
  [ApplePayErrorCode.ForcedProcessorNotAvailable]:
    'The specified processor is not available at the moment. Please try again later',
  [ApplePayErrorCode.ForcedProcessorNotSupported]:
    'The specified processor is unsupported. Please specify another processor',
  [ApplePayErrorCode.NoAvailableProcessors]:
    'Payment method creation is not available at the moment. Please try again later',
  [ApplePayErrorCode.ClientTokenRetrievalFailure]:
    'Could not retrieve the necessary information to connect to the selected payment processor. Please try again later',
  [ApplePayErrorCode.ClientTokenMissingAfterRetrieval]:
    'Could not retrieve the necessary information to connect to the selected payment processor. Please try again later',
  [ApplePayErrorCode.SdkFailure]: 'An issue occurred when processing your payment information. Please try again later',
  [ApplePayErrorCode.UnknownError]: 'An unknown error occurred. Please try again later',
  [ApplePayErrorCode.ApplePayNotSupported]: 'Apple Pay is not supported on this device',
  [ApplePayErrorCode.ApplePayVersionNotSupported]: 'Apple Pay version is not supported',
  [ApplePayErrorCode.ApplePayCannotMakePayments]: 'Apple Pay is unavailable due to the inability to make payments',
  [ApplePayErrorCode.ApplePayCannotMakePaymentsWithCard]: 'Apple Pay is unavailable with the selected card',
  [ApplePayErrorCode.ApplePayMissingMerchantId]: 'Apple Pay Merchant ID is missing',
  [ApplePayErrorCode.ApplePayMissingConnector]: 'Apple Pay is not supported for the selected processor',
  [ApplePayErrorCode.ApplePayPaymentRequestFailure]:
    'An issue occurred when processing your payment information. Please try again later',
};

/**
 * Apple Pay button component that handles the initialization and payment request for Apple Pay payments.
 * The component will automatically determine the best processor to use based on the available processors and the forced processor (if specified).
 * The component will also handle the client token retrieval and the initialization of the selected processor.
 * The component will handle the payment request and notify the parent component of the success or failure of the payment.
 * The component will also handle the status of the Apple Pay button and display the appropriate message if the button is not available.
 * The component will also log the status of the Apple Pay button to the console and notify the parent component of the failure.
 * The component will also display a modal with the status of the Apple Pay button if the button is not available and the dev mode is enabled.
 * @param usedFor
 * @param bookingData
 * @param request
 * @param forceProcessor
 * @param ignoreAvailability
 * @param onSuccess
 * @param onFailure
 * @param label
 * @param className
 * @param disabled
 * @param testId
 * @param devMode
 * @param logs
 */
const ApplePay: FC<ApplePayProps> = ({
  usedFor,
  bookingData,
  request,
  override: { processor: forceProcessor, ignoreAvailability } = {},
  onSuccess,
  onFailure,
  label = 'Pay',
  className,
  disabled,
  testId,
  devMode,
  logs = true,
  loading: forceLoading,
}) => {
  const logger = useLogger({ disabled: !logs });

  const { openModal } = useModals();
  const connectorInstance = useRef<ApplePayConnector>();

  const [loading, setLoading] = useState(false);
  const [failureNotified, setFailureNotified] = useState(false);
  const [status, setStatus] = useState<ApplePayStatus>({
    code: ApplePayStatusCode.Initializing,
  });

  /*** Queries ***/
  const {
    data: paymentOptions,
    isLoading: isPaymentOptionsLoading,
    error: paymentOptionsError,
  } = useGetPaymentOptionsQuery({
    usedFor,
    bookingData,
    amount: Number(request.total.amount),
  });
  const [processorClientTokenQuery] = useLazyGetClientProcessorTokenQuery();

  /*** BOOT ***/
  usePage(
    async () => {
      try {
        const _option = paymentOptions?.[PaymentOption.ApplePay];
        const _processors = _option?.processors || [];
        let _selectedProcessor: PaymentProcessor | null = null;

        // Verify provided parameter requirements
        if (!_option || !_option.available) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.NoAvailableProcessors,
          });
        }

        if (!usedFor || !Object.values(PaymentUsedFor).find((u) => u === usedFor)) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.InvalidParameterUsedFor,
          });
        }
        if (usedFor === PaymentUsedFor.InHomeBooking && !bookingData) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.InvalidParameterBooking,
          });
        }

        // Handling if the processor api failed
        if (paymentOptionsError) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.AvailableProcessorsRetrievalFailure,
            customMessage: JSON.stringify(paymentOptionsError),
          });
        }

        // Handling the forced processor (if specified)
        if (forceProcessor) {
          if (!_processors.includes(forceProcessor) && !ignoreAvailability) {
            // unavailable
            return setStatus({
              code: ApplePayStatusCode.Failure,
              errorCode: ApplePayErrorCode.ForcedProcessorNotAvailable,
            });
          }
          if (!PMCSupportedProcessors[forceProcessor]) {
            // unsupported
            return setStatus({
              code: ApplePayStatusCode.Failure,
              errorCode: ApplePayErrorCode.ForcedProcessorNotSupported,
            });
          }
          _selectedProcessor = forceProcessor;
        } else {
          // Picking the first supported processor
          for (const processor of _processors) {
            if (PMCSupportedProcessors[processor]) {
              _selectedProcessor = processor;
              break;
            }
          }
          if (!_selectedProcessor) {
            // none supported
            return setStatus({
              code: ApplePayStatusCode.Failure,
              errorCode: ApplePayErrorCode.NoAvailableProcessors,
            });
          }
        }

        // Retrieving client token for selected processor
        let clientToken: string | null = null;
        try {
          clientToken = await processorClientTokenQuery({ processor: _selectedProcessor, usedFor }).unwrap();
        } catch (clientTokenError: any) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.ClientTokenRetrievalFailure,
            customMessage: JSON.stringify(clientTokenError),
          });
        }
        if (!clientToken) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.ClientTokenMissingAfterRetrieval,
          });
        }

        // Getting the right connector for the selected processor
        const connector = Connectors[_selectedProcessor] as any;
        if (!connector) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: ApplePayErrorCode.ApplePayMissingConnector,
          });
        }

        // Initializing the connector
        connectorInstance.current = new connector({ clientToken });
        const result = await connectorInstance.current.initialize();
        if (!result?.success) {
          return setStatus({
            code: ApplePayStatusCode.Failure,
            errorCode: result?.errorCode || ApplePayErrorCode.SdkFailure,
            customMessage: result?.error,
          });
        }

        // Apple Pay is ready
        setStatus({ code: ApplePayStatusCode.Ready });
      } catch (initError: any) {
        console.error('Apple Pay initialization error: ', initError);
        setStatus({
          code: ApplePayStatusCode.Failure,
          errorCode: ApplePayErrorCode.UnknownError,
          customMessage: JSON.stringify(initError),
        });
      }
    },
    { hold: isPaymentOptionsLoading }
  );

  const mappedRequest = useMemo<ApplePayPaymentRequestConfig>(() => {
    const fix = (n: number) => n.toFixed(2);
    const _mappedRequest: any = {
      total: {
        label: request.total.label,
        amount: fix(request.total.amount),
      },
      lineItems: request.lineItems?.map((item) => ({
        type: item.type || 'final',
        label: item.label,
        amount: fix(item.amount),
      })),
    };
    if (request.recurring) {
      _mappedRequest.recurringPaymentRequest = {
        paymentDescription: request.recurring.description,
        regularBilling: {
          amount: fix(request.total.amount),
          paymentTiming: 'recurring',
          recurringPaymentIntervalUnit: request.recurring.interval.unit,
          recurringPaymentIntervalCount: request.recurring.interval.value,
        },
      };
    }
    return _mappedRequest;
  }, [request]);

  // Determines if the status is unsuccessful
  const isUnsuccessful = useMemo(
    () => [ApplePayStatusCode.Failure, ApplePayStatusCode.NotAvailable].includes(status.code),
    [status]
  );

  // Determines if the button should be visible
  const isVisible = useMemo(() => {
    return (
      (status.code === ApplePayStatusCode.Ready ||
        status.code === ApplePayStatusCode.Success ||
        (devMode && isUnsuccessful)) &&
      getCookie(WEBVIEW_SOURCE_COOKIE_NAME) !== WebviewSourceEnum.Android
    );
  }, [status, devMode, isUnsuccessful]);

  // Determines if the button is enabled
  const isEnabled = useMemo(() => {
    return status.code === ApplePayStatusCode.Ready;
  }, [status]);

  // Handles the Apple Pay request
  const handleOnClick = useCallback(async () => {
    if (loading || !isEnabled) return;
    setLoading(true);
    try {
      const result = await connectorInstance.current?.requestPayment(mappedRequest);
      if (!result?.nonce) throw new Error('Apple Pay request failed');
      if (onSuccess)
        await onSuccess({
          nonce: result.nonce,
          type: 'ApplePay',
          processor: connectorInstance.current.processor,
        });
      setStatus({ code: ApplePayStatusCode.Success });
    } catch (error: any) {
      console.error('Error occurred during payment request:', error, error?.message);
    }
    setLoading(false);
  }, [request, mappedRequest, isEnabled, devMode]);

  // Opens a modal with the status
  const handleOpenStatusModal = useCallback(() => {
    openModal({
      id: 'apple-pay-status',
      element: (
        <ModalInfo
          title={`Status: ${status.code}`}
          description={
            <>
              <p>{status.errorCode ? `${status.errorCode}: ${ApplePayErrorMessage[status.errorCode]}` : ''}</p>
              <p>{status.customMessage}</p>
            </>
          }
        />
      ),
    });
  }, [status]);

  // Notifies the parent of a failure
  useEffect(() => {
    if (isUnsuccessful && !failureNotified) {
      setFailureNotified(true);
      onFailure?.(status);
    }
  }, [isUnsuccessful, status]);

  // Logs the activity on status change
  useEffect(() => {
    if (status.code === ApplePayStatusCode.Failure)
      logger.error(
        `<${status.errorCode}>\n${ApplePayErrorMessage[status.errorCode]}${
          status.customMessage ? `\n${status.customMessage}` : ''
        }`,
        { namespace: 'ApplePay' }
      );
    else {
      logger.info(`${status.code}`, { namespace: 'ApplePay' });
    }
  }, [logger, status, status?.code, status?.errorCode, status?.customMessage]);

  if (isVisible) {
    return (
      <Button
        loading={loading || forceLoading}
        className={clsx(
          styles.base,
          {
            [styles.errored]: isUnsuccessful,
            [styles.disabled]: !isEnabled || disabled,
            [styles.loading]: loading || forceLoading,
          },
          className
        )}
        type='secondary'
        disabled={!isEnabled || disabled}
        onClick={isUnsuccessful && devMode ? () => handleOpenStatusModal() : () => handleOnClick()}
        data-testid={testId ?? `pmc-apple-pay-button`}
      >
        <Icon className={styles.appleIcon} type='fa-apple' />
        <span>{label}</span>
      </Button>
    );
  }
};

export default ApplePay;
