import { FC, useState } from 'react';

import { PaymentOption } from 'redux/apis/OG/payment';

import View from '../../components/View';
import { usePMCContext } from '../../context';
import { CreditCard } from '../../options';
import { PMCRenderMode, PMCRendererConfig, PMCViewError } from '../../types';

export type CollectRendererProps = PMCRendererConfig;
export type CollectRendererOptionProps = Omit<
  PMCRendererConfig,
  'title' | 'description' | 'initialError' | 'className'
>;

const CollectRenderer: FC<CollectRendererProps> = ({ title, description, initialError, className, ...rest }) => {
  const { mode, canBack, changeMode, paymentOption } = usePMCContext();
  const [error] = useState<PMCViewError | null>(initialError || null);
  const commonProps: CollectRendererOptionProps = { ...rest };
  return (
    <View
      title={title || (mode === PMCRenderMode.Create ? 'Add Payment Method' : 'Edit Payment Method')}
      description={description}
      onBack={canBack ? () => changeMode(PMCRenderMode.List) : undefined}
      error={error}
      className={className}
    >
      {
        {
          [PaymentOption.CreditCard]: <CreditCard {...commonProps} />,
        }[paymentOption || '']
      }
    </View>
  );
};

export default CollectRenderer;
