import { FC, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { routes } from 'utils';
import { useAmplitude, useForm, usePage } from 'utils/hooks';

import { useSignInMutation } from 'redux/apis/OG/auth';
import { useSendEmailFunnelMutation } from 'redux/apis/OG/booking';
import { usePersonalWellnessFlowSlice } from 'redux/features/personalWellnessFlow';

import { useModals, useNavigate } from '@zeel-dev/zeel-ui';
import { FieldRow, Input, Link } from 'components/common';
import ModalForgotPassword from 'components/modals/items/ForgotPassword';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  zip?: string;
  category?: string;
  email?: string;
  signInForm?: boolean;
  allowVa?: boolean;
};

const ContinueCustomizing: FC<Props> = ({ signInForm, category, zip, allowVa, onClose, ...rest }) => {
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [sendEmailFunnel] = useSendEmailFunnelMutation();
  const amplitude = useAmplitude();

  const { isLoading, user, isVa } = usePage();

  const {
    actions: { userEnteredEmail, userInitiatedFlow },
    selectors: { useEmailSelector },
  } = usePersonalWellnessFlowSlice();

  const [signIn] = useSignInMutation();
  const form = useForm();
  const navigate = useNavigate();
  const { openModal } = useModals();
  const emailFromStore = useEmailSelector();

  useEffect(() => {
    amplitude.track('booking_email_collector_visited');
    if (signInForm) {
      form.setValue('email', emailFromStore);
      setIsSignIn(true);
    }
  }, []);

  const _continue = useCallback(async () => {
    const email = form.getValue('email');

    try {
      await sendEmailFunnel({ zipCode: zip, category, email }).unwrap();
      await userEnteredEmail(email);
      onClose();
      navigate(routes.PERSONAL_WELLNESS.LOCATION(), {
        state: {
          emailCaptured: true,
        },
      });
    } catch (errors: any) {
      if (errors?.user_exists) {
        setIsSignIn(true);
      }
      if (errors?.email) {
        form.setError('email', 'Please enter a valid email address.');
      }
    }
  }, [form, user]);

  const handleSignIn = useCallback(async () => {
    const email = form.getValue('email');
    const password = form.getValue('password');

    try {
      await signIn({ email, password }).unwrap();
      await userInitiatedFlow(user?.id);

      // Test if user is a veteran and the flow isn't in the allowVa mode (to kick to va.zeel.com)
      if (isVa && !allowVa) {
        navigate(`${process.env.REACT_APP_VA_HOST}/${routes.VA_FLOW.DETAILS()}`);
        return;
      }

      onClose();
      navigate(routes.PERSONAL_WELLNESS.LOCATION());
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setError('email', errors.authentication);
      }
    }
  }, [isVa, form]);

  const termsContent = !isSignIn ? (
    <p className={styles.terms}>
      By selecting &apos;Continue&apos;, I confirm that I have read and accept Zeel&apos;s{' '}
      <Link href={routes.TERMS_OF_USE({ host: true })} target='_blank' className={styles.glBoldLink}>
        Terms of Use
      </Link>{' '}
      and{' '}
      <Link href={routes.PRIVACY_POLICY({ host: true })} target='_blank' className={styles.glBoldLink}>
        Privacy Policy
      </Link>
    </p>
  ) : null;

  return (
    <Modal
      {...rest}
      loading={isLoading}
      onClose={onClose}
      className={styles.modal}
      mobileType='drawer'
      title={isSignIn ? 'Welcome Back!' : 'See Pricing In Your Area'}
      description={
        isSignIn ? (
          <>
            Sign in to customize and book your massage.
            <br />
            Don’t have an account?{' '}
            <span
              className={styles.link}
              onClick={() => {
                setIsSignIn(false);
                form.setValue('password', null);
              }}
            >
              Sign Up
            </span>
          </>
        ) : (
          <>
            Provide your email to customize your massage.
            <br />
            Already have an account?{' '}
            <span className={styles.link} onClick={() => setIsSignIn(true)}>
              Sign In
            </span>
          </>
        )
      }
      actions={[
        isSignIn
          ? { label: 'Sign In', onClick: handleSignIn }
          : { label: 'Continue', onClick: _continue, disabled: !form.isValid() },
      ]}
      customContentAfterActions={!isMobile && termsContent}
    >
      <FieldRow>
        <Input {...form.getProps('email')} type='email' autoFocus={!isSignIn} label='Email Address' />
      </FieldRow>

      {isMobile && termsContent}

      {isSignIn && (
        <>
          <Input
            {...form.getProps('password')}
            type='password'
            autoFocus={!!form.getValue('email')}
            label='Password'
            autoComplete='current-password'
          />
          <div
            className={styles.linkButton}
            onClick={() =>
              openModal({
                element: <ModalForgotPassword {...{ emailFunnel: true, email: form.getValue('email') }} />,
              })
            }
          >
            Forgot Password?
          </div>
        </>
      )}
    </Modal>
  );
};

export default ContinueCustomizing;
