import { FC } from 'react';

import clsx from 'clsx';

import { Html } from 'components/common';

import CoreBlock from '../CoreBlock';
import { PodcastBlockProps } from './PodcastBlock.types';
import styles from './style.module.scss';

const PodcastBlock: FC<PodcastBlockProps> = ({
  className,
  topper,
  title,
  embedPodcastHtml,
  testId = 'podcast-block',
}) => {
  return (
    <CoreBlock className={clsx(styles.podcastBlock, className)} contentClassName={styles.content} testId={testId}>
      <div className={styles.infoContent}>
        <p data-testid={`${testId}--topper`}>{topper}</p>
        <h3 data-testid={`${testId}--title`}>{title}</h3>
      </div>
      <div className={styles.podcastWrapper}>
        <Html
          purify={false}
          className={styles.iframeWrapper}
          content={embedPodcastHtml}
          testId={`${testId}--html-content`}
        />
      </div>
    </CoreBlock>
  );
};

export default PodcastBlock;
