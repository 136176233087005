import { CSSProperties, FC, ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { EditPencilBoldIcon, GarbageTrashDeleteBoldIcon, SkeletonCover } from '@zeel-dev/zeel-ui';
import { Button, Icon } from 'components/common';
import { ButtonProps } from 'components/common/Button';

import styles from './style.module.scss';

export type CartLineItem = {
  label: string | ReactNode;
  value: string | number | ReactNode;
  modifier?: {
    credit?: boolean;
    heavy?: boolean;
    line?: string; // before or after
  };
};

export type CartProps = {
  toggled: boolean;
  items: CartItem[];
  groups?: CartGroup[];
  onClose?: () => void;
  onItemEdit?: (item: CartItem) => void;
  onItemDelete?: (item: CartItem) => void;
  actions?: CartAction[];
  lineItems?: CartLineItem[];
  containerType?: 'fixed' | 'block';
  noHeader?: boolean;
  style?: CSSProperties;
  contentUnderTotal?: ReactNode | string;
};

export type CartItem = {
  id: string;
  groupId?: string;
  label: string;
  content?: ReactNode;
};

export type CartGroup = {
  id: string;
  name?: string;
  description?: string;
};

export type CartAction = {
  label: string;
  onClick: () => void;
  buttonProps: ButtonProps;
};

/**
 * Cart component to display items in a cart. It is built to be generic, but is kept within the
 * flow for now, as it is only used there. Will be moved to zeel-ui if it is used in multiple places.
 * Allows for editing and deleting items, and has a total generator function to calculate the total.
 */
const Cart: FC<CartProps> = ({
  toggled,
  items = [],
  groups = [],
  onItemEdit,
  onItemDelete,
  actions,
  onClose,
  containerType,
  noHeader,
  contentUnderTotal,
  style,
  lineItems = [],
}) => {
  // Memoized grouped items
  const groupedItems = useMemo<{ group: CartGroup | null; items: CartItem[] }[]>(() => {
    const _groups = {};
    items.forEach((item) => {
      const { groupId = 'ungrouped' } = item;
      if (!_groups[groupId]) {
        _groups[groupId] = [];
      }
      _groups[groupId].push(item);
    });
    return Object.keys(_groups).map((groupId) => {
      const group = groups.find((g) => g.id === groupId);
      return {
        group: group || null,
        items: _groups[groupId],
      };
    });
  }, [items, groups]);

  return (
    <div
      className={clsx(styles.container, {
        [styles['container--toggled']]: toggled,
        [styles[`container-type-${containerType}`]]: !!containerType,
      })}
      onClick={() => onClose?.()}
    >
      <div className={styles.cart} onClick={(e) => e.stopPropagation()} style={style}>
        {!noHeader && (
          <div className={styles.header}>
            <h1>Cart</h1>
            <Icon type='times' className={styles.close} onClick={() => onClose?.()} title='close' />
          </div>
        )}
        <div className={styles.body}>
          {!items?.length && (
            <SkeletonCover>
              <div className={styles.empty}>You don&apos;t have any requests in your cart.</div>
            </SkeletonCover>
          )}
          {(items || []).length > 0 && (
            <>
              <div className={styles.items}>
                {groupedItems.map((g) => {
                  const { group, items: _items } = g;
                  return (
                    <>
                      {group && (
                        <div className={styles.group}>
                          <p className={styles.groupName}>{group.name}</p>
                          {group.description && <p className={styles.groupDescription}>{group.description}</p>}
                        </div>
                      )}
                      {_items.map((item) => {
                        return (
                          <div key={item.id} className={styles.item}>
                            <SkeletonCover>
                              <div className={styles.info}>
                                <b>{item.label}</b>
                                {item.content}
                              </div>
                              <div className={styles.actions}>
                                {onItemEdit && <EditPencilBoldIcon size={20} onClick={() => onItemEdit?.(item)} />}
                                {onItemDelete && (
                                  <GarbageTrashDeleteBoldIcon
                                    className={styles.dangerIcon}
                                    size={20}
                                    onClick={() => onItemDelete?.(item)}
                                  />
                                )}
                              </div>
                            </SkeletonCover>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {(actions?.length > 0 || lineItems?.length > 0 || contentUnderTotal) && (
          <div className={styles.footer}>
            {lineItems.map((lineItem) => {
              const { label, value, modifier } = lineItem;
              return (
                <>
                  <div
                    className={clsx(styles.line, {
                      [styles.hasTopLine]: modifier?.line === 'before',
                      [styles.hasBottomLine]: modifier?.line === 'after',
                    })}
                  >
                    <b>{label}</b>
                    <SkeletonCover>
                      <span
                        className={clsx(styles.value, {
                          [styles['value--credit']]: modifier?.credit,
                          [styles['value--heavy']]: modifier?.heavy,
                        })}
                      >
                        {value}
                      </span>
                    </SkeletonCover>
                  </div>
                </>
              );
            })}
            {contentUnderTotal}
            {actions?.length > 0 && (
              <div className={styles.actions}>
                {actions.map(({ label, onClick, buttonProps }, i) => (
                  <Button key={i} onClick={onClick} {...buttonProps}>
                    {label}
                  </Button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
