import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, useCallback, useMemo } from 'react';

import { loadStripe } from '@stripe/stripe-js';

import { useForm } from 'utils/hooks';

import { Button, Loader } from 'components/common';

import Body from '../../../../components/Body';
import Footer from '../../../../components/Footer';
import { usePMCContext } from '../../../../context';
import { CreditCardProcessorProps } from '../../index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const DEVMODE_PAYMENT_INFO = {
  cardNumber: '5555555555554444',
  expiryDate: '12/2028',
  cvv: '123',
  label: 'Master card',
  zipCode: '10005',
};

enum ErrorCodes {
  FieldsEmpty = 'HOSTED_FIELDS_FIELDS_EMPTY',
  // ???
}

const StripeProcessor: FC<CreditCardProcessorProps> = (props) => {
  const { clientToken } = usePMCContext();

  return (
    <>
      <Elements stripe={stripePromise} options={{ clientSecret: clientToken }}>
        <StripePaymentForm {...props} />
      </Elements>
    </>
  );
};

const StripePaymentForm: FC<CreditCardProcessorProps> = ({ mutate, buttonText }) => {
  const { collectionType, devMode } = usePMCContext();

  const stripe = useStripe();
  const elements = useElements();

  const ready = useMemo(() => !!stripe && !!elements, [stripe, elements]);

  // Form state for custom (non-sdk) fields
  const form = useForm({
    initialValues: {
      label: devMode ? DEVMODE_PAYMENT_INFO.label : null,
      // sdk-specific
      number: '',
      expirationDate: '',
      cvv: '',
      zip: '',
    },
  });

  const validateStripeErrors = useCallback(
    (error: any) => {
      switch (error.SDK_ERROR_CODE) {
        case [ErrorCodes.FieldsEmpty]: {
          form.setError('number', 'Field can not be empty');
          break;
        }
        default:
      }
    },
    [form]
  );

  const handleSave = useCallback(async () => {
    const label = form.getValue('label');
    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://zeel.com/webview/payment-methods/stripe-confirmation',
      },
    });
    if (result.error) {
      console.log(result.error.message);
      return;
    }
    const mutationPayload = {
      collectionType,
      data: { label, nonce: result.setupIntent?.client_secret },
    };
    const { errors } = await mutate(mutationPayload);
    if (errors) {
      if (errors.SDK_ERROR_CODE) validateStripeErrors(errors);
      if (errors.label) form.setError('label', errors.label);
      if (errors.nonce) form.setError('number', errors.nonce);
      if (errors.cardNumber) form.setError('number', errors.cardNumber);
      if (errors.stripe) form.setError('number', errors.stripe);
    }
  }, [form, mutate, stripe, elements]);

  return (
    <>
      <Loader loading={!ready} />
      <Body>
        <PaymentElement />
      </Body>
      <Footer>
        <Button onClick={handleSave} disabled={!ready || !form.isValid()}>
          {buttonText}
        </Button>
      </Footer>
    </>
  );
};

export default StripeProcessor;
