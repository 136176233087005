import { FC, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { Button, Image, Link, LinkButton } from 'components/common';

import styles from './style.module.scss';

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  action?: string | ((e?: MouseEvent<HTMLButtonElement>) => void);
  actionButton?: boolean;
  actionText?: string;
  actionClassName?: string;
  actionProps?: any;
  right?: boolean;
  image?: string;
  imageAlt?: string;
  image2?: string;
  className?: string;
  contentClassName?: string;
  parallax?: boolean;
  parallaxConfig?: [string, string];
  imageUrl?: string;
  imageClass?: string;
  image2Class?: string;
  testId?: string;
};

const Info: FC<Props> = ({
  title,
  description,
  action,
  className = '',
  contentClassName = '',
  actionButton = false,
  actionText,
  actionClassName,
  actionProps = {},
  right,
  image,
  image2,
  parallax,
  parallaxConfig,
  imageUrl,
  imageAlt,
  imageClass,
  image2Class,
  testId = 'info',
}) => {
  const parallaxRange = parallaxConfig || ['-10px', '10px'];
  return (
    <div
      className={clsx(styles.infoContainer, styles.glContainerSmall, { [styles.right]: right }, className)}
      data-testid={testId}
    >
      <div className={clsx(styles.infoBox, contentClassName)}>
        {title && <h3>{title}</h3>}
        {description && <div className={styles.description}>{description}</div>}
        {action && actionText && !actionButton && (
          <LinkButton
            className={clsx(styles.action, actionClassName)}
            testId={`${testId}--action-link`}
            {...(typeof action === 'string' ? { href: action } : { onClick: () => action() })}
            {...actionProps}
          >
            {actionText}
          </LinkButton>
        )}
        {action && actionText && actionButton && (
          <Button
            className={clsx(styles.actionButton, actionClassName)}
            onClick={action as (e: MouseEvent<HTMLButtonElement>) => void}
            testId={`${testId}--action-button`}
            {...actionProps}
          >
            {actionText}
          </Button>
        )}
      </div>
      <div className={styles.images}>
        {image && !imageUrl && (
          <Image
            className={clsx(styles.image, imageClass)}
            src={image}
            parallax={parallax ? parallaxRange : null}
            alt={imageAlt}
          />
        )}
        {image && imageUrl && (
          <Link href={imageUrl}>
            <Image
              className={clsx(styles.image, imageClass)}
              src={image}
              parallax={parallax ? parallaxRange : null}
              alt={imageAlt}
            />
          </Link>
        )}
        {image2 && <Image className={clsx(styles.image2, image2Class)} src={image2} />}
      </div>
    </div>
  );
};

export default Info;
