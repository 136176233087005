import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from 'utils/hooks';

import {
  useLazyGetClientProcessorTokenQuery,
  useLazyGetPaymentOptionsQuery,
  useLazyGetProcessorPaymentMethodQuery,
} from 'redux/apis/OG/payment/endpoints';
import {
  PaymentCollectionType,
  PaymentMethodType,
  PaymentOption,
  PaymentOptionsResponse,
  PaymentProcessor,
  PaymentUsedFor,
} from 'redux/apis/OG/payment/types';

import ChoiceList from './components/ChoiceList';
import Root, { SCROLL_ANCHOR_ID } from './components/Root';
import StatusView from './components/StatusView';
import View from './components/View';
import CollectRenderer from './components/_renderer/Collect';
import ListRenderer from './components/_renderer/List';
import { PMCContext, PMCContextType } from './context';
import { useLogger } from './logger';
import {
  PMCCollectionData,
  PMCConfig,
  PMCErrorCode,
  PMCErrorMessage,
  PMCInitStep,
  PMCInitialState,
  PMCPaymentContextConfig,
  PMCPaymentOptionCollectionTypeMap,
  PMCRenderMode,
  PMCResponse,
  PMCStatus,
  PMCStatusCode,
  PMCSupportedPaymentOptions,
  PMCSupportedProcessors,
  PMCUpdatableProcessors,
} from './types';

// Props type

// Props type
export type PMCProps = PMCPaymentContextConfig & {
  initialMode: PMCRenderMode;
  config?: PMCConfig;
  initialState?: PMCInitialState;
  onSuccess?: (args: PMCResponse) => void | Promise<void>;
  onFailure?: (ags: PMCResponse) => void | Promise<void>;
  devMode?: boolean;
  logs?: boolean;
};

/**
 * Payment Method Collector component.
 * This component is responsible for collecting payment methods from the user.
 * It will automatically select the first available and supported processor.
 * If a processor is forced, it will use that processor if available and supported.
 * If no processors are available or supported, it will fail.
 *
 * LOGIC:
 * 1. Initial State Override:
 *    - If the initial state is provided, it will skip the initialization process and go directly to the success or failure state
 *
 * 2. Validation:
 *    - Verifies that user is authenticated
 *    - If the mode is Create, usedFor and bookingData must be provided
 *    - If the mode is Update, paymentMethodId must be provided and updatable
 *
 * 3. Set the payment option:
 *    - If the paymentType is specified and available, it will use it
 *    - If the payment type is specified but unavailable, or not specified and more than 1 option is available, PMC status is set to "waiting for payment type choice"
 *
 * 4. Set the collection type:
 *   - If the collection type is specified and available, it will use it
 *   - If the collection type is specified but unavailable, or not specified and more than 1 option is available, PMC status is set to "waiting for collection type choice"
 *   - If no collection types are available, PMC status set to "failure"
 *
 * 5. Set the processor:
 *    - If the processor is enforced via parameters [dev-mode]:
 *       - It will use it if available and supported
 *       - If unsupported or unavailable, PMC status set to "failure"
 *    - Otherwise:
 *       - If allowed to choose processor, PMC status set to "waiting for processor choice"
 *       - If not allowed to choose processor, use the first available and supported processor
 *       - If no processors are available or supported, PMC status set to "failure"
 *
 *
 * Each logical step will run in sequence until the PMC is ready to collect the payment method.
 */
const PaymentMethodCollector: FC<PMCProps> = ({
  initialMode,
  usedFor,
  bookingData,
  amount,
  config,
  initialState,
  onSuccess,
  onFailure,
  devMode,
  logs = true,
}) => {
  const {
    paymentOption: enforcedPaymentOption,
    collectionType: enforcedCollectionType,
    setCreatedAsDefault,
    // DEV
    ignorePaymentOptionAvailability: ignorePaymentOptionAvailability__dev,
    processor: enforcedProcessor__dev,
    ignoreProcessorAvailability: ignoreProcessorAvailability__dev,
    allowProcessorSelection: allowProcessorSelection__dev,
    // Renderer
    ...createRendererConfig
  } = config?.create || {};

  const {
    entityId: entityId_update,
    entityPaymentOption: entityPaymentOption_update,
    // Renderer
    ...updateRendererConfig
  } = config?.update || {};
  const {
    selectedId: preselectedEntityId_list,
    setSelectedAsDefault,
    applePay: listApplePay,
    // Renderer
    ...listRendererConfig
  } = config?.list || {};
  /**************/
  const logger = useLogger({ disabled: !logs });
  const { isAuthenticated } = useAuth();
  /*** States ***/
  const [mode, setMode] = useState<PMCRenderMode>(initialMode);
  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = useState<PaymentProcessor | null>(null);
  const [selectedPaymentOptionType, setSelectedPaymentOptionType] = useState<PaymentOption | null>(null);
  const [selectedCollectionType, setSelectedCollectionType] = useState<PaymentCollectionType | null>(null);
  const [paymentProcessorClientToken, setPaymentProcessorClientToken] = useState<string | null>(null);
  const [preselectedEntityId, setPreselectedEntityId] = useState<string | null>(preselectedEntityId_list || null);
  const [entityToUpdateId, setEntityToUpdateId] = useState<string | null>(entityId_update || null);
  const [entityToUpdatePaymentOption, setEntityToUpdatePaymentOption] = useState<PaymentOption | null>(
    entityPaymentOption_update || null
  );
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsResponse | null>(null);
  const [entity, setEntity] = useState<PaymentMethodType | null>(null);
  const [collectedEntity, setCollectedEntity] = useState<{
    paymentOption: PaymentOption;
    collectionType: PaymentCollectionType;
    processor: PaymentProcessor;
    data: PMCCollectionData;
  } | null>(null);
  const [successNotified, setSuccessNotified] = useState<boolean>(false);
  const [failureNotified, setFailureNotified] = useState<boolean>(false);
  const [status, setStatus] = useState<PMCStatus>({
    code: PMCStatusCode.Waiting,
  });
  const [preventBack, setPreventBack] = useState<boolean>(false);
  const [queueInit, setQueueInit] = useState<boolean>(true);
  const [stepRunning, setStepRunning] = useState<boolean>(null);
  const [activeStep, setActiveStep] = useState<PMCInitStep | null>(null);

  /*** Queries ***/
  const [paymentOptionsQuery] = useLazyGetPaymentOptionsQuery();
  const [entityToUpdateQuery] = useLazyGetProcessorPaymentMethodQuery();
  const [processorClientTokenQuery] = useLazyGetClientProcessorTokenQuery();

  const exit = useCallback((e: PMCErrorCode, m?: any) => {
    setStatus({
      code: PMCStatusCode.Failure,
      error: {
        code: e,
        ...(m ? { message: JSON.stringify(m) } : {}),
      },
    });
  }, []);

  /** INITIAL STATE OVERRIDE **/
  const init__override = useCallback(async () => {
    if (initialState) setStatus({ code: initialState.statusCode, error: initialState.error || undefined });
  }, [initialState]);

  /** PARAMETERS VALIDATION **/
  const init__validation = useCallback(async () => {
    // Verify user is authenticated
    if (!isAuthenticated) return exit(PMCErrorCode.UserUnauthenticated);
    // Verify global parameter requirements
    if (!usedFor || !Object.values(PaymentUsedFor).find((u) => u === usedFor))
      return exit(PMCErrorCode.InvalidParameterUsedFor);
    if (usedFor === PaymentUsedFor.InHomeBooking && !bookingData) return exit(PMCErrorCode.InvalidParameterBooking);
    if (!mode || !Object.values(PMCRenderMode).find((m) => m === mode)) return exit(PMCErrorCode.InvalidParameterMode);
    // Verify update parameter requirements
    if (mode === PMCRenderMode.Update) {
      if (!entityToUpdateId) return exit(PMCErrorCode.InvalidParameterEntityId);
      if (!entityToUpdatePaymentOption) return exit(PMCErrorCode.InvalidParameterEntityPaymentOption);
      // Fetching payment method to update
      let _paymentProfileToUpdateResponse;
      try {
        _paymentProfileToUpdateResponse = await entityToUpdateQuery(entityToUpdateId).unwrap();
      } catch (_e) {
        return exit(PMCErrorCode.EntityRetrievalFailure, _e);
      }
      if (!_paymentProfileToUpdateResponse) return exit(PMCErrorCode.EntityNotFound);
      if (!_paymentProfileToUpdateResponse?.updatable) return exit(PMCErrorCode.EntityNotUpdatable);
      setEntity(_paymentProfileToUpdateResponse);
    }
  }, [exit, isAuthenticated, mode, usedFor, bookingData]);

  /** PAYMENT OPTION SELECTION **/
  const init__paymentOption = useCallback(async () => {
    let _selectedPaymentOptionType: PaymentOption | null = null;
    // Pre-selecting payment option
    if (mode === PMCRenderMode.Create && enforcedPaymentOption) _selectedPaymentOptionType = enforcedPaymentOption;
    if (mode === PMCRenderMode.Update && entityToUpdatePaymentOption)
      _selectedPaymentOptionType = entityToUpdatePaymentOption;
    if (selectedPaymentOptionType) _selectedPaymentOptionType = selectedPaymentOptionType;
    // Retrieving payment options from api
    let _paymentOptions: PaymentOptionsResponse;
    try {
      _paymentOptions = await paymentOptionsQuery({ usedFor, bookingData, amount }).unwrap();
    } catch (_paymentOptionsError) {
      return exit(PMCErrorCode.PaymentOptionsRetrievalFailure, _paymentOptionsError);
    }
    if (!_paymentOptions) return exit(PMCErrorCode.PaymentOptionsRetrievalFailure);
    // Setting payment options in state
    setPaymentOptions(_paymentOptions);
    // Checking update requirements
    if (mode === PMCRenderMode.Update) {
      if (!_selectedPaymentOptionType) return exit(PMCErrorCode.EntityMissingExternalType);
    }
    // Handling selection of the payment option (if not pre-selected)
    if (!_selectedPaymentOptionType) {
      const availablePaymentOptions: PaymentOption[] = [];
      for (const [option, data] of Object.entries(_paymentOptions)) {
        if (data.available && data.webview_available && PMCSupportedPaymentOptions[option]) {
          availablePaymentOptions.push(option as PaymentOption);
        }
      }
      if (availablePaymentOptions.length > 1 && !ignorePaymentOptionAvailability__dev) {
        setStatus({ code: PMCStatusCode.WaitingForPaymentOptionChoice });
        return;
      }
      if (availablePaymentOptions.length === 0) return exit(PMCErrorCode.NoAvailablePaymentOptions);
      _selectedPaymentOptionType = availablePaymentOptions[0] as PaymentOption;
    }
    // Checking if selected payment option is valid
    if (
      !_paymentOptions[_selectedPaymentOptionType] ||
      (!_paymentOptions[_selectedPaymentOptionType].available && !ignorePaymentOptionAvailability__dev)
    )
      return exit(PMCErrorCode.ForcePaymentOptionNotAvailable);
    if (!PMCSupportedPaymentOptions[_selectedPaymentOptionType])
      return exit(PMCErrorCode.ForcePaymentOptionNotSupported);
    // Setting the selected payment option in state
    setSelectedPaymentOptionType(_selectedPaymentOptionType);
  }, [
    selectedPaymentOptionType,
    entityToUpdatePaymentOption,
    enforcedPaymentOption,
    ignorePaymentOptionAvailability__dev,
  ]);

  /** COLLECTION TYPE SELECTION **/
  const init__paymentCollectionType = useCallback(async () => {
    const _paymentOption = paymentOptions?.[selectedPaymentOptionType];
    let _selectedCollectionType: PaymentCollectionType | null = null;
    // Pre-selecting collection type
    if (
      _paymentOption.recommended_collection_type &&
      PMCPaymentOptionCollectionTypeMap[selectedPaymentOptionType]?.includes(_paymentOption.recommended_collection_type)
    ) {
      _selectedCollectionType = _paymentOption.recommended_collection_type;
    }
    if (enforcedCollectionType) _selectedCollectionType = enforcedCollectionType;
    if (selectedCollectionType) _selectedCollectionType = selectedCollectionType;
    // Handling selection of the collection type (if not pre-selected)
    if (!_selectedCollectionType) {
      if (PMCPaymentOptionCollectionTypeMap[selectedPaymentOptionType]?.length > 1)
        return exit(PMCErrorCode.MultipleCollectionTypesAvailable);
      _selectedCollectionType = PMCPaymentOptionCollectionTypeMap[selectedPaymentOptionType][0];
      if (!_selectedCollectionType) return exit(PMCErrorCode.NoAvailableCollectionTypes);
    }
    // Checking if selected collection type is valid
    if (!Object.values(PaymentCollectionType).find((t) => t === _selectedCollectionType))
      return exit(PMCErrorCode.InvalidParameterCollectionType);
    if (!PMCPaymentOptionCollectionTypeMap[selectedPaymentOptionType]?.includes(_selectedCollectionType))
      return exit(PMCErrorCode.InvalidParameterCollectionTypeForPaymentOption);
    // Setting the selected collection type in state
    setSelectedCollectionType(_selectedCollectionType);
  }, [paymentOptions, selectedPaymentOptionType, enforcedCollectionType]);

  /** PROCESSOR SELECTION **/
  const init__processor = useCallback(async () => {
    const _paymentOption = paymentOptions?.[selectedPaymentOptionType];
    let _selectedProcessor: PaymentProcessor | null = null;
    // Pre-selecting processor
    if (mode === PMCRenderMode.Create && enforcedProcessor__dev) _selectedProcessor = enforcedProcessor__dev;
    if (mode === PMCRenderMode.Update && entity?.processor) _selectedProcessor = entity.processor as PaymentProcessor;
    if (selectedPaymentProcessor) _selectedProcessor = selectedPaymentProcessor;
    // Checking for api errors or empty response
    if (!_paymentOption || (_paymentOption.processors || []).length === 0)
      return exit(PMCErrorCode.NoAvailableProcessors);
    // Checking update requirements
    if (mode === PMCRenderMode.Update) {
      if (!_selectedProcessor) return exit(PMCErrorCode.EntityMissingExternalType);
      if (!entity?.updatable) return exit(PMCErrorCode.EntityNotUpdatable);
      if (!PMCUpdatableProcessors[_selectedProcessor]) return exit(PMCErrorCode.ForcedProcessorUpdateNotSupported);
    }
    // Handling selection of the processor (if not pre-selected)
    if (!_selectedProcessor) {
      if (allowProcessorSelection__dev || process.env.REACT_APP_PMC__ALLOW_PROCESSOR_SELECTION) {
        setStatus({ code: PMCStatusCode.WaitingForProcessorChoice });
        return;
      }
      // Picking the first supported processor
      for (const processor of _paymentOption.processors) {
        if (PMCSupportedProcessors[processor]) {
          _selectedProcessor = processor;
          break;
        }
      }
      if (!_selectedProcessor) return exit(PMCErrorCode.NoAvailableProcessors);
    }
    // Checking if pre-selected processor is valid
    if (
      !(_paymentOption.processors || []).find((o) => o.includes(_selectedProcessor)) &&
      !ignoreProcessorAvailability__dev
    ) {
      return exit(PMCErrorCode.ForcedProcessorNotAvailable);
    }
    if (!PMCSupportedProcessors[_selectedProcessor]) return exit(PMCErrorCode.ForcedProcessorNotSupported);
    // Setting the selected processor in state
    setSelectedPaymentProcessor(_selectedProcessor);
  }, [
    paymentOptions,
    selectedPaymentOptionType,
    selectedPaymentProcessor,
    entity,
    enforcedProcessor__dev,
    ignoreProcessorAvailability__dev,
  ]);

  /** CLIENT TOKEN RETRIEVAL **/
  const init__clientToken = useCallback(async () => {
    if (!selectedPaymentProcessor) return exit(PMCErrorCode.ProcessorMissingBeforeClientTokenRetrieval);
    let _clientToken: string | null = null;
    try {
      _clientToken = await processorClientTokenQuery({
        processor: selectedPaymentProcessor,
        usedFor,
      }).unwrap();
    } catch (clientTokenError: any) {
      return exit(PMCErrorCode.ClientTokenRetrievalFailure, clientTokenError);
    }
    if (!_clientToken) return exit(PMCErrorCode.ClientTokenMissingAfterRetrieval);
    // Setting the client token for the selected processor in state
    setPaymentProcessorClientToken(_clientToken);
  }, [selectedPaymentProcessor, usedFor]);

  /*** Handlers ***/

  // Payment Option was picked from UI
  const handlePaymentOptionChosen = useCallback((p: PaymentOption) => {
    setSelectedPaymentOptionType(p);
    setQueueInit(true);
  }, []);

  // Processor was picked from UI
  const handleProcessorChosen = useCallback((p: PaymentProcessor) => {
    setSelectedPaymentProcessor(p);
    setQueueInit(true);
  }, []);

  // Handles when the submodule wants to change the active mode
  const changeModeHandler = useCallback<PMCContextType['changeMode']>((_mode, _params) => {
    if (_params?.updateEntity) {
      setEntityToUpdateId(_params.updateEntity?.id);
      setEntityToUpdatePaymentOption(_params.updateEntity?.paymentOption);
    }

    if (_params?.dueToNoMethods) setPreventBack(_params?.dueToNoMethods || false);
    setMode(_mode);
    setQueueInit(true);
  }, []);

  // Completes the payment flow
  const completeHandler = useCallback(
    ({ paymentOption, collectionType, processor, data }: PMCResponse) => {
      setCollectedEntity({
        paymentOption,
        collectionType,
        processor,
        data,
      });
      if (
        initialMode === PMCRenderMode.List &&
        mode !== PMCRenderMode.List &&
        collectionType === PaymentCollectionType.Vault
      ) {
        setPreselectedEntityId(data?.id);
        setMode(PMCRenderMode.List);
      } else {
        setStatus({ code: PMCStatusCode.Success });
      }
    },
    [initialMode, mode]
  );

  // Handles when the sdk ran into an error and PMC has to fail
  const failHandler = useCallback((e: string) => {
    console.error(e);
    setStatus({
      code: PMCStatusCode.Failure,
      error: {
        code: PMCErrorCode.SdkFailure,
        message: e,
      },
    });
  }, []);

  /*** Effects ***/
  /**
   * Queues the initialization process when the payment options are loaded
   * and the queue is not already initialized.
   */
  useEffect(() => {
    if (queueInit) {
      setQueueInit(false);
      setStatus({ code: PMCStatusCode.Initializing });
    }
  }, [queueInit]);

  /**
   * While PMC is initializing and there are no actively running step,
   * it will run the next step in the initialization process until it
   * reaches the end or an error occurs.
   */
  useEffect(() => {
    const runner = async () => {
      if (status.code === PMCStatusCode.Initializing && !stepRunning) {
        setStepRunning(true);
        const stepOrder: [PMCInitStep, Function][] = [
          [PMCInitStep.Override, init__override],
          [PMCInitStep.Validation, init__validation],
          [PMCInitStep.PaymentOption, init__paymentOption],
          [PMCInitStep.PaymentCollectionType, init__paymentCollectionType],
          [PMCInitStep.Processor, init__processor],
          [PMCInitStep.ClientToken, init__clientToken],
        ];
        const nextStepIndex = activeStep ? stepOrder.findIndex(([s]) => s === activeStep) + 1 : 0;
        if (nextStepIndex >= stepOrder.length) {
          setStatus({ code: PMCStatusCode.Ready });
          setStepRunning(false);
          setActiveStep(null);
          return;
        }
        const [nextStep, nextStepHandler] = stepOrder[nextStepIndex];
        setActiveStep(nextStep);
        await nextStepHandler();
        setStepRunning(false);
      }
    };
    try {
      runner();
    } catch (stepError) {
      setStatus({
        code: PMCStatusCode.Failure,
        error: {
          code: PMCErrorCode.UnknownError,
          message: JSON.stringify(stepError),
        },
      });
    }
  }, [status, stepRunning, activeStep]);

  // Scroll to the anchor when mode changes
  useEffect(() => {
    if (mode) {
      const node = document.getElementById(SCROLL_ANCHOR_ID);
      if (node) node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [mode]);

  // Notifying SUCCESS or FAILURE
  useEffect(() => {
    const redirectEnabled = !initialState;
    if (status.code === PMCStatusCode.Success && !successNotified) {
      setSuccessNotified(true);
      if (redirectEnabled) onSuccess?.(collectedEntity);
    }
    if (status.code === PMCStatusCode.Failure && !failureNotified) {
      setFailureNotified(true);
      if (redirectEnabled) {
        onFailure?.({
          paymentOption: selectedPaymentOptionType,
          collectionType: selectedCollectionType,
          processor: selectedPaymentProcessor,
          error: status.error,
        });
      }
    }
  }, [status]);

  // Log PMC boot
  useEffect(() => {
    logger.header(
      'The Payment Method Collector (PMC) is starting. If you run into any issues, please refer to the documentation: https://zeel.atlassian.net/wiki/x/AYCqsw '
    );
  }, []);
  // Logs mode changes
  useEffect(() => {
    if (mode) logger.infoContrast(mode, { namespace: 'MODE' });
  }, [logger, mode]);
  // Logs status changes
  useEffect(() => {
    (
      ({
        [PMCStatusCode.Failure]: logger.error,
        [PMCStatusCode.Success]: logger.success,
      })[status.code] || logger.infoContrast
    )(status.code, {
      namespace: initialState?.statusCode === status?.code ? 'STATUS OVERRIDE' : 'STATUS',
    });
    if (status.error) {
      logger.error(`<${status.error?.code}>\n${PMCErrorMessage[status.error?.code]}`, { namespace: 'ERROR' });
    }
  }, [logger, status, initialState]);
  // Logs init step changes
  useEffect(() => {
    if (stepRunning) logger.info(activeStep, { namespace: 'STEP' });
  }, [logger, stepRunning, activeStep]);
  // Logs collected entity data
  useEffect(() => {
    if (collectedEntity) logger.success(collectedEntity.data, { namespace: 'COLLECTED PAYMENT METHOD' });
  }, [logger, collectedEntity]);
  // Side-effects logs...
  // Logs fetched entity
  useEffect(() => {
    if (entity) logger.info(entity, { namespace: 'Entity', indent: 1 });
  }, [logger, entity]);
  // Logs payment option selection
  useEffect(() => {
    if (selectedPaymentOptionType) logger.info(selectedPaymentOptionType, { namespace: 'Payment option', indent: 1 });
  }, [logger, selectedPaymentOptionType]);
  // Logs collection type selection
  useEffect(() => {
    if (selectedCollectionType) logger.info(selectedCollectionType, { namespace: 'Collection type', indent: 1 });
  }, [logger, selectedCollectionType]);
  // Logs processor selection
  useEffect(() => {
    if (selectedPaymentProcessor) logger.info(selectedPaymentProcessor, { namespace: 'Processor', indent: 1 });
  }, [logger, selectedPaymentProcessor]);

  /*** Contexts ***/
  const PMCContextValue = useMemo<PMCContextType>(
    () => ({
      mode,
      paymentOption: selectedPaymentOptionType,
      collectionType: selectedCollectionType,
      processor: selectedPaymentProcessor,
      usedFor,
      amount,
      bookingData,
      initialSelectedEntityId: preselectedEntityId,
      setCreatedAsDefault,
      setSelectedAsDefault,
      listApplePay,
      clientToken: paymentProcessorClientToken,
      entity,
      devMode,
      complete: completeHandler,
      fail: failHandler,
      changeMode: changeModeHandler,
      canBack: initialMode === PMCRenderMode.List && !preventBack,
    }),
    [
      mode,
      selectedPaymentOptionType,
      selectedCollectionType,
      selectedPaymentProcessor,
      usedFor,
      amount,
      bookingData,
      preselectedEntityId,
      setCreatedAsDefault,
      setSelectedAsDefault,
      listApplePay,
      paymentProcessorClientToken,
      entity,
      devMode,
      completeHandler,
      failHandler,
      initialMode,
      preventBack,
    ]
  );

  return (
    <Root loading={status.code === PMCStatusCode.Initializing}>
      <PMCContext.Provider value={PMCContextValue}>
        {/* PMC is initializing */}
        {status.code === PMCStatusCode.Initializing && <View loading skeleton />}

        {/* PMC waiting for user to choose payment option from list */}
        {status.code === PMCStatusCode.WaitingForPaymentOptionChoice && (
          <View title='Choose a Payment Option'>
            <ChoiceList
              items={Object.entries(PMCSupportedPaymentOptions).map(([p, isSupported]) => ({
                id: p,
                label: p,
                iconType: {
                  [PaymentOption.CreditCard]: 'credit-card',
                  [PaymentOption.ApplePay]: 'apple',
                  [PaymentOption.GooglePay]: 'google',
                  [PaymentOption.Insurance]: 'clipboard',
                }[p],
                disabledMessage: !isSupported ? 'Unsupported in UI' : 'Unavailable in OG backend',
                disabled: !isSupported || (!ignorePaymentOptionAvailability__dev && !paymentOptions?.[p]?.available),
              }))}
              onClick={(item) => handlePaymentOptionChosen(item.id as PaymentOption)}
              hideDisabledItems={!devMode}
            />
          </View>
        )}

        {/* PMC waiting for user to choose processor from list */}
        {status.code === PMCStatusCode.WaitingForProcessorChoice && (
          <View title='Choose which processor to use'>
            <ChoiceList
              items={Object.entries(PMCSupportedProcessors).map(([p, isSupported]) => ({
                id: p,
                label: `${p} (priority #${
                  ((paymentOptions?.[selectedPaymentOptionType]?.processors || []).findIndex((i) => i === p) || 0) + 1
                })`,
                iconType: 'credit-card',
                disabledMessage: !isSupported ? 'Unsupported in UI' : 'Unavailable in OG backend',
                disabled:
                  !isSupported ||
                  (!ignoreProcessorAvailability__dev &&
                    !(paymentOptions?.[selectedPaymentOptionType]?.processors || []).includes(p as PaymentProcessor)),
              }))}
              onClick={(item) => handleProcessorChosen(item.id as PaymentProcessor)}
            />
          </View>
        )}

        {/* PMC is ready */}
        {status.code === PMCStatusCode.Ready && (
          <>
            {PMCRenderMode.Create === mode && <CollectRenderer {...(createRendererConfig || {})} />}
            {PMCRenderMode.Update === mode && <CollectRenderer {...(updateRendererConfig || {})} />}
            {PMCRenderMode.List === mode && <ListRenderer {...(listRendererConfig || {})} />}
          </>
        )}

        {/* PMC has failed */}
        {status.code === PMCStatusCode.Failure && <StatusView failure={status.error} showDetails={devMode} />}

        {/* PMC has completed */}
        {status.code === PMCStatusCode.Success && <StatusView success />}
      </PMCContext.Provider>
    </Root>
  );
};

export default PaymentMethodCollector;
