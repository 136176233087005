import { ReactNode } from 'react';

import Detail from './components/Detail';
import styles from './style.module.scss';

type Props = {
  data: Array<{ title: string; image?: string; description: ReactNode; imageUrl?: string }>;
  title?: string;
  subtitle?: string;
  testId?: string;
  bottomContent?: any;
};

const DetailsBlock = (props: Props) => {
  const { data, title, subtitle, testId = 'details-block', bottomContent } = props;

  return (
    <div className={styles.block} data-testid={testId}>
      {title && <h3 data-testId={`${testId}--title`}>{title}</h3>}
      {subtitle && (
        <p className={styles.subtitle} data-testid={`${testId}--description`}>
          {subtitle}
        </p>
      )}
      <div className={`${styles.glContainerSmall} ${[1, 2].includes(data.length) ? styles.center : ''}`}>
        {data.map(({ title: detailTitle, image, description, imageUrl }, i) => (
          <Detail key={i} title={detailTitle} image={image} description={description} imageUrl={imageUrl} />
        ))}
      </div>
      {bottomContent}
    </div>
  );
};

export default DetailsBlock;
