import { FC, useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';

import { log } from 'utils';

import { useSubmitProspectMutation } from 'redux/apis/Prospect';

import { useModals } from '@zeel-dev/zeel-ui';
import { Button, FieldRow, Link } from 'components/common';
import { IconSvg } from 'components/common';
import { FormikSelect, GroupOptionType } from 'components/common/Select';
import { FormikInput } from 'components/common/inputs/Input';
import { ModalPTCurrentPatients } from 'components/modals/items';

import CoreBlock from '../CoreBlock';
import { PhysicalTherapyLeadFormBlockProps, PhysicalTherapyLeadFormValues } from './PhysicalTherapyLeadFormBlock.types';
import styles from './style.module.scss';

const primaryAreaItems: Array<GroupOptionType> = [
  {
    label: 'Front',
    options: [
      { label: 'Jaw (TMJ) (left/right)', value: 'jaw' },
      { label: 'Torso / Chest', value: 'torso-chest' },
      { label: 'Abdomen', value: 'abdomen' },
      { label: 'Right Upper Arm (Shoulder to Elbow)', value: 'arm-upper-right' },
      { label: 'Left Upper Arm', value: 'arm-upper-left' },
      { label: 'Right Lower Arm (Elbow to Wrist)', value: 'arm-lower-right' },
      { label: 'Left Lower Arm', value: 'arm-lower-left' },
      { label: 'Right Hand', value: 'hand-right' },
      { label: 'Left Hand', value: 'hand-left' },
      { label: 'Pelvic Floor', value: 'pelvic-floor' },
      { label: 'Right Hip', value: 'hip-right' },
      { label: 'Left Hip', value: 'hip-left' },
      { label: 'Right Upper Leg (Hip to Knee)', value: 'leg-upper-right' },
      { label: 'Left Upper Leg', value: 'leg-upper-left' },
      { label: 'Right Lower Leg (Knee to Ankle)', value: 'leg-lower-right' },
      { label: 'Left Lower Leg', value: 'leg-lower-left' },
      { label: 'Right Foot', value: 'foot-right' },
      { label: 'Left Foot', value: 'foot-left' },
    ],
  },
  {
    label: 'Back',
    options: [
      { label: 'Head and Neck', value: 'head-and-neck' },
      { label: 'Mid-Back', value: 'mid-back' },
      { label: 'Lower Back', value: 'back-lower' },
      { label: 'Right Upper Leg (Butt to Knee)', value: 'leg-upper-right' },
      { label: 'Left Upper Leg', value: 'leg-upper-left' },
      { label: 'Right Lower Leg (Knee to Ankle)', value: 'leg-lower-right' },
      { label: 'Left Lower Leg', value: 'leg-lower-left' },
    ],
  },
];

const PhysicalTherapyLeadFormBlock: FC<PhysicalTherapyLeadFormBlockProps> = ({
  id,
  page,
  leadType,
  leadSource,
  data,
  testId = 'physical-therapy-lead-form-block',
}) => {
  const [leadSubmitted, setLeadSubmitted] = useState(false);
  const { openModal } = useModals();
  const [submitProspect] = useSubmitProspectMutation();

  const submitLead = async (values: PhysicalTherapyLeadFormValues, { setSubmitting }) => {
    const { firstName, lastName, zip, email, phone, primaryArea } = values;

    setSubmitting(true);

    try {
      await submitProspect({
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        zip,
        primary_treatment_area: primaryArea,
        form_url: `https://www.zeel.com/${page}`,
        lead_type: leadType || 'General',
        lead_source: leadSource || 'Physical Therapy Page Lead Form',
      }).unwrap();
    } catch (error) {
      log.error(error);
    }

    setLeadSubmitted(true);
    setSubmitting(false);
  };

  return (
    <CoreBlock
      id={id}
      className={styles.physicalTherapyLeadFormBlock}
      contentClassName={styles['physicalTherapyLeadFormBlock-content']}
      testId={testId}
    >
      {leadSubmitted && (
        <div className={styles.successContainer}>
          <IconSvg name='checkmark-filled-circle' size={48} />
          <h2>{data.successMessage}</h2>
          <p>{data.successDescription}</p>
        </div>
      )}
      {!leadSubmitted && (
        <>
          <div className={styles.formTitle}>
            <h2>{data?.title}</h2>
            <p>{data?.description}</p>
            <div className={styles.question}>
              <span>{data?.question}</span>&nbsp;
              <Link
                arrow={false}
                onClick={() => {
                  openModal({
                    element: <ModalPTCurrentPatients />,
                  });
                }}
              >
                Contact US
              </Link>
            </div>
          </div>
          <div className={styles.formContainer}>
            <Formik
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                zip: '',
                phone: '',
                primaryArea: '',
              }}
              validateOnMount={true}
              onSubmit={submitLead}
            >
              {({ isValid, handleSubmit, isSubmitting }: FormikProps<PhysicalTherapyLeadFormValues>) => (
                <Form>
                  <FieldRow>
                    <FormikInput
                      name='firstName'
                      type='text'
                      required
                      label='First Name'
                      autoComplete='given-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--first-name-input`}
                    />
                    <FormikInput
                      name='lastName'
                      type='text'
                      label='Last Name'
                      required
                      autoComplete='family-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--last-name-input`}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormikInput
                      name='email'
                      type='email'
                      required
                      label='Email'
                      autoComplete='email username'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--email-input`}
                    />
                    <FormikInput
                      name='phone'
                      type='mobile'
                      label='Phone'
                      required
                      autoComplete='phone'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--phone-input`}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormikInput
                      name='zip'
                      type='number'
                      label='Zip Code'
                      required
                      max={5}
                      autoComplete='zip'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--zip-code-input`}
                    />
                    <FormikSelect
                      name='primaryArea'
                      required={false}
                      label='Primary Area for Treatment (Optional)'
                      placeholder='Select a body part...'
                      inputClassName={styles.select}
                      className={styles.input}
                      mountInBody
                      options={primaryAreaItems}
                      testId={`${testId}--primary-area-select`}
                    />
                  </FieldRow>
                  <Button
                    className={styles.button}
                    flex='mobile'
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                    testId={`${testId}--main-button`}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </CoreBlock>
  );
};

export default PhysicalTherapyLeadFormBlock;
