import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers, routes } from 'utils';
import { useForm, usePage } from 'utils/hooks';
import { themes } from 'utils/theme-context';

import { useSignUpFromPartial__newMutation } from 'redux/apis/OG/auth';
import { useSendMobilePhoneVerificationCodeMutation, useVerifyMobilePhoneMutation } from 'redux/apis/OG/user';

import { FieldRow, Input, Link } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type ModalFinishAccountSetupProps = ModalProps & {
  initialEmail?: string;
  initialMobilePhone?: string;
  onClose?: (result: any) => void;
};

/**
 * Modal for finishing the account setup. Allows the user to set their name, email, and password.
 * The email field is pre-filled if an initial email is provided.
 */
const ModalFinishAccountSetup: FC<ModalFinishAccountSetupProps> = ({
  initialEmail,
  initialMobilePhone,
  onClose,
  ...rest
}) => {
  const form = useForm();
  const [signUpFromTemporarySession] = useSignUpFromPartial__newMutation();
  const [sendMobilePhoneVerificationCode] = useSendMobilePhoneVerificationCodeMutation();
  const [verifyMobilePhone] = useVerifyMobilePhoneMutation();

  /** States **/
  const [mode, setMode] = useState<'registration' | 'mobile-confirm' | null>(null);
  const [newMemberId, setNewMemberId] = useState<string | null>(null);
  const [sending, setSending] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<string | null>(null);

  /** Effects **/
  const { isLoading, user } = usePage(async ({ _user, _isTempMember, _isAuthenticated }) => {
    if (initialEmail) form.setValue('email', initialEmail);
    if (initialMobilePhone) form.setValue('mobile', initialMobilePhone);

    if (_isTempMember) {
      setMode('registration');
    } else if (_isAuthenticated && !_user?.mobileConfirmed) {
      setMode('mobile-confirm');
    } else {
      onClose?.(true);
    }
  });

  const sendVerifCode = useCallback(
    async (force?: boolean) => {
      setSending(true);
      if (mode === 'mobile-confirm' && (force || !codeSent)) {
        try {
          setCodeSent(true);
          await sendMobilePhoneVerificationCode().unwrap();
        } catch (sendError) {
          console.error(sendError);
        }
      }
      setSending(false);
    },
    [mode, codeSent]
  );

  useEffect(() => {
    sendVerifCode();
  }, [mode]);

  /** Handlers **/
  const register = useCallback(async () => {
    const { firstName, lastName, mobile, email, password } = form.getValues();
    try {
      const { id: _newMemberId } = await signUpFromTemporarySession({
        member: {
          firstName,
          lastName,
          mobile,
          email,
          password,
        },
        options: {
          updateTerms: true,
          forChairEventFlow: true,
          forConcierge: true,
        },
      }).unwrap();

      setNewMemberId(_newMemberId);

      // Confirm phone number with code
      setMode('mobile-confirm');
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setError('email', errors.authentication);
      }
      if (errors?.password) {
        form.setError('password', errors.password);
      }
      if (errors?.mobile) {
        form.setError('mobile', errors.mobile);
      }
      if (errors?.email) {
        form.setError('email', 'Please enter a valid email address.');
      }
    }
  }, [form]);

  const confirmPhone = useCallback(
    async (code: string) => {
      const { firstName, lastName, mobile, email } = form.getValues();
      try {
        await verifyMobilePhone(code).unwrap();
        onClose?.({ firstName, lastName, mobile, email, id: newMemberId });
      } catch (errors: any) {
        if (errors?.mobile_confirm_code) {
          setCodeError(errors.mobile_confirm_code);
        }
        throw new Error('Invalid code');
      }
    },
    [newMemberId, form]
  );

  const displayPhone = useMemo(() => {
    const mobileNumber = user?.mobile || '';
    const mobileWithoutSpaces = mobileNumber.replace(/\s/g, '');
    return /^[0-9 ]+$/.test(mobileNumber)
      ? `${mobileWithoutSpaces.substring(0, 3)}-${mobileWithoutSpaces.substring(3, 6)}-${mobileWithoutSpaces.substring(
          6,
          10
        )}`
      : mobileNumber;
  }, [user]);

  return (
    <Modal
      {...rest}
      onClose={() => onClose(false)}
      backdropClose={false}
      mobileType='drawer'
      loading={isLoading || sending}
      alert={codeError}
      alertSeverity={'error'}
      theme={themes.atWork}
      title={mode === 'mobile-confirm' ? 'Mobile Verification' : 'Finish Setting Up Your Account'}
      description={mode === 'mobile-confirm' ? `A verification code was just sent to ${displayPhone}.` : ''}
      actions={[
        ...(mode === 'registration'
          ? [
              {
                label: 'Save and Continue',
                onClick: register,
                disabled: !form.isValid(['firstName', 'lastName', 'mobile', 'email', 'password']),
              },
            ]
          : []),
      ]}
      customContentAfterActions={
        mode === 'registration' && (
          <p className={styles.terms}>
            I agree to Zeel&apos;s{' '}
            <Link href={routes.TERMS_OF_USE({ host: true })} target='_blank' className={styles.glBoldLink}>
              Terms of Use
            </Link>{' '}
            and the{' '}
            <Link href={routes.SPA_TERMS({ host: true })} target='_blank' className={styles.glBoldLink}>
              Zeel Spa Terms
            </Link>
          </p>
        )
      }
    >
      {mode === 'registration' && (
        <>
          <FieldRow>
            <Input {...form.getProps('firstName')} label='First Name' />
            <Input {...form.getProps('lastName')} label='Last Name' />
          </FieldRow>
          <FieldRow>
            <Input {...form.getProps('mobile')} type='mobile' label='Mobile Phone Number' />
          </FieldRow>
          <FieldRow>
            <Input {...form.getProps('email')} type='email' label='Email Address' />
          </FieldRow>
          <FieldRow>
            <Input
              {...form.getProps('password')}
              type='password'
              label='Password'
              autoComplete='current-password'
              passwordGuidelines
            />
          </FieldRow>
        </>
      )}
      {mode === 'mobile-confirm' && (
        <>
          <FieldRow>
            <CodeInput onSubmit={(c) => confirmPhone(c)} disabled={isLoading} />
          </FieldRow>
          <u
            onClick={() => sendVerifCode(true)}
            className={styles.glBoldLink}
            data-testId='mobile-verification-modal--resend-code-link'
          >
            Resend Code
          </u>
        </>
      )}
    </Modal>
  );
};

export default ModalFinishAccountSetup;

const CodeInput: FC<{ onSubmit: (code: string) => Promise<void>; disabled?: boolean }> = ({ onSubmit, disabled }) => {
  const n1Ref = useRef<HTMLInputElement>(null);
  const n2Ref = useRef<HTMLInputElement>(null);
  const n3Ref = useRef<HTMLInputElement>(null);
  const n4Ref = useRef<HTMLInputElement>(null);
  const form = useForm();

  useEffect(() => {
    n1Ref.current?.focus();
  }, []);

  const confirm = useCallback(async () => {
    const numbers = [1, 2, 3, 4];
    const code = numbers.map((nr) => form.getValue('n' + nr)).join('');
    try {
      await onSubmit(code);
    } catch {
      form.setValues({
        n1: null,
        n2: null,
        n3: null,
        n4: null,
      });
    }
  }, [form, onSubmit]);

  const focusNext = useCallback(
    (id: number, event: ChangeEvent<HTMLInputElement>) => {
      form.setValue('n' + id, event.target.value);
      switch (id) {
        case 1:
          return n2Ref.current?.focus();
        case 2:
          return n3Ref.current?.focus();
        case 3:
          return n4Ref.current?.focus();
        case 4:
          return n4Ref.current?.blur();
      }
    },
    [form]
  );

  const handlePaste = useCallback(
    (event) => {
      event.clipboardData.items[0].getAsString((text) => {
        const code = text
          .trim()
          .split('')
          .map((char) => {
            if (Number.isInteger(Number(char))) {
              return Number(char);
            }
            return '';
          });

        if (code.length === 4) {
          code.forEach((val, index) => {
            if (val !== '') {
              form.setValue(`n${index + 1}`, val);
            }
          });
        }
      });
    },
    [form]
  );
  const fullCode = useMemo(() => {
    const { n1, n2, n3, n4 } = form.getValues();
    return `${n1 || ''}${n2 || ''}${n3 || ''}${n4 || ''}`;
  }, [form]);

  useEffect(() => {
    if (fullCode.length === 4) {
      confirm();
    }
  }, [fullCode]);

  return (
    <div
      className={clsx(styles.inputsWrapper, { [styles['inputsWrapperDisabled--disabled']]: disabled })}
      onPaste={handlePaste}
    >
      <input
        ref={(input) => (n1Ref.current = input)}
        inputMode='numeric'
        pattern='[0-9]*'
        type='number'
        placeholder={helpers.onLowerEnvironment() ? '1' : ''}
        className={styles.input}
        name='n1'
        value={form.getValue('n1')}
        onChange={(event) => {
          focusNext(1, event);
        }}
        maxLength={1}
        autoComplete='one-time-code'
        data-testid='mobile-verification-modal--code-input-1'
      />
      <input
        ref={(input) => (n2Ref.current = input)}
        inputMode='numeric'
        pattern='[0-9]*'
        type='number'
        placeholder={helpers.onLowerEnvironment() ? '2' : ''}
        className={styles.input}
        name='n2'
        value={form.getValue('n2')}
        onChange={(event) => {
          focusNext(2, event);
        }}
        maxLength={1}
        data-testid='mobile-verification-modal--code-input-2'
      />
      <input
        ref={(input) => (n3Ref.current = input)}
        inputMode='numeric'
        pattern='[0-9]*'
        type='number'
        placeholder={helpers.onLowerEnvironment() ? '3' : ''}
        className={styles.input}
        name='n3'
        value={form.getValue('n3')}
        onChange={(event) => {
          focusNext(3, event);
        }}
        maxLength={1}
        data-testid='mobile-verification-modal--code-input-3'
      />
      <input
        ref={(input) => (n4Ref.current = input)}
        inputMode='numeric'
        pattern='[0-9]*'
        type='number'
        placeholder={helpers.onLowerEnvironment() ? '4' : ''}
        className={styles.input}
        name='n4'
        value={form.getValue('n4')}
        onChange={(event) => {
          focusNext(4, event);
        }}
        maxLength={1}
        data-testid='mobile-verification-modal--code-input-4'
      />
    </div>
  );
};
