import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate as ReduxPersistGate } from 'redux-persist/integration/react';

import * as amplitude from '@amplitude/analytics-browser';
import 'styles/main.css';

import { helpers } from 'utils';
import { getEnvironment, isInjected, onQaEnvironment } from 'utils/helpers';
import log from 'utils/logger';
import 'utils/polyfills';

import { persistor, store } from 'redux/store';

import { RollbarProvider, initRollbar } from '@zeel-dev/zeel-ui';

import App from './app';

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, { minIdLength: 2 });

window['react_env'] = getEnvironment();
window['react_injected'] = isInjected();

initRollbar({
  enabled: !helpers.onLocalEnvironment(),
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

if (onQaEnvironment()) {
  const version = process.env.REACT_APP_QARC_VERSION;
  (window as any)['qarc'] = version;
  if (version) console.log(`QARC version: ${version}`);
  else console.log('QARC version not found');
}

export const router = createBrowserRouter([{ path: '*', element: <App /> }]);

try {
  const rootElement = document && document.getElementById('root');
  if (!rootElement) throw new Error('No root element');

  const root = createRoot(rootElement);
  root.render(
    <RollbarProvider>
      <ReduxProvider store={store}>
        <ReduxPersistGate loading={<p>LOADING</p>} persistor={persistor}>
          <RouterProvider router={router} />
        </ReduxPersistGate>
      </ReduxProvider>
    </RollbarProvider>
  );
} catch (error) {
  log.error(`Error from inital app entry point ${error})`);
}
