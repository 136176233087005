import { FC } from 'react';

import Modal from 'components/modals/templates/ModalNew';
import { ModalProps } from 'components/modals/templates/ModalNew';

type Props = ModalProps & {
  gender: string;
};

const AlternateTimesInfo: FC<Props> = ({ gender, ...rest }) => {
  const labelMapCapitalized = {
    f: 'Female',
    m: 'Male',
  };
  const labelMap = {
    f: 'female',
    m: 'male',
  };

  return (
    <Modal
      {...rest}
      icon='information'
      iconColor='midnight'
      title={`${labelMapCapitalized[gender] || ''} Therapist`}
      centerContent
      mobileType={'drawer'}
      actions={[{ label: 'OK', action: 'close' }]}
      testId='alternate-times-info-modal'>
      These are instantly confirmable times with an available {labelMap[gender] || ''} therapist you haven&apos;t seen
      before. We&apos;ll match you with a vetted, top-rated therapist in your area at your chosen time.
    </Modal>
  );
};

export default AlternateTimesInfo;
