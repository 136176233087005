import { FC, useMemo, useState } from 'react';

import { AddressType, useGetAddressesQuery, useGetShippingAddressesQuery } from 'redux/apis/OG/user';

import { useModals } from '@zeel-dev/zeel-ui';
import { AddressItem, LinkButton } from 'components/common';
import ModalAddressAddOrEdit from 'components/modals/items/AddressAddOrEdit';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  disableDelete?: boolean;
  disableEdit?: boolean;
  shipping?: boolean;
  title?: string;
  subTitle?: string;
};

const AddressList: FC<Props> = ({ disableEdit, disableDelete, shipping, onClose, title, subTitle, ...rest }) => {
  const { openModal } = useModals();
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const {
    data: addresses,
    isLoading: isAddressesLoading,
    isFetching: isAddressesFetching,
  } = useGetAddressesQuery({ includeBlastingClients: true });

  const {
    data: shippingAddresses,
    isLoading: isShippingAddressesLoading,
    isFetching: isShippingAddressesFetching,
  } = useGetShippingAddressesQuery({
    includeBlastingClients: true,
  });

  const isLoading = useMemo(
    () => isAddressesLoading || isShippingAddressesLoading,
    [isAddressesLoading, isShippingAddressesLoading]
  );
  const isFetching = useMemo(
    () => isAddressesFetching || isShippingAddressesFetching,
    [isAddressesFetching, isShippingAddressesFetching]
  );

  const handleAddressCallback = (address?: AddressType) => {
    if (address) {
      handleOnClose(address);
    }
  };

  const handleOnClose = (result?: AddressType) => {
    onClose(result);
  };

  const selectAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <Modal
      {...rest}
      loading={isLoading || isFetching}
      title={title ?? (!shipping ? 'Home Address' : 'Mailing Address')}
      description={
        subTitle ||
        (shipping
          ? 'Provide a mailing address to receive future membership perks. Please ensure packages can be accepted at this location.'
          : '')
      }
      actions={[{ label: 'Continue', onClick: () => onClose(selectedAddress), disabled: !selectedAddress }]}
      mobileType={'drawer'}
      onClose={() => handleOnClose()}
      testId='address-list'
      centerContent
    >
      {((shipping ? shippingAddresses : addresses) || []).map((address, i) => {
        return (
          <AddressItem
            key={address.address1}
            disableDelete={disableDelete}
            disableEdit={disableEdit}
            selected={selectedAddress && selectedAddress.id === address.id}
            address={address}
            onClick={() => selectAddress(address)}
            onEdit={() => {
              openModal(
                {
                  id: 'address-add-edit',
                  element: <ModalAddressAddOrEdit {...{ address, subTitle }} />,
                  options: {
                    exclusive: true,
                  },
                },
                handleAddressCallback
              );
            }}
            testId={`address-list-modal--address-item-${i}`}
          />
        );
      })}
      <LinkButton
        className={styles.link}
        arrow={false}
        icon='add-circle-bold'
        iconProps={{ size: 22 }}
        testId={`address-list-modal--add-new-address-link`}
        onClick={() => {
          openModal(
            {
              id: 'address-add-edit',
              element: <ModalAddressAddOrEdit {...{ subTitle, shipping, showAsShipping: true }} />,
              options: {
                exclusive: true,
              },
            },
            handleAddressCallback
          );
        }}
      >
        Add New Address
      </LinkButton>
    </Modal>
  );
};

export default AddressList;
