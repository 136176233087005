import { FC } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';
import { useForm } from 'utils/hooks';

import { ServiceIds } from 'redux/apis/OG/booking/constants';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Carousel, ModalityCardList } from 'components/common';
import ImageDetail from 'components/common/Carousel/templates/ImageDetail';

import styles from './style.module.scss';

type Props = {
  className?: string;
  title?: string;
  description?: string;
  detailed?: boolean;
  testId?: string;
};

const ModalitiesBlock: FC<Props> = ({ title, description, className, detailed, testId = 'modalities-block' }) => {
  const form = useForm();
  const navigate = useNavigate();

  const onBook = () => {
    const modality = form.getValue('modalities');
    navigate(routes.PERSONAL_WELLNESS.SERVICE(), {
      state: {
        presetModality: modality,
      },
    });
  };

  const treatmentIds = [
    ServiceIds.Swedish,
    ServiceIds.DeepTissue,
    ServiceIds.Prenatal,
    ServiceIds.Sports,
    ServiceIds.Sleep,
  ];
  const massages = [
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/service-swedish.svg',
          description: 'Swedish',
          details:
            'A restorative technique that relieves stress, boosts circulation, and eases muscle aches and pains.',
          link: routes.MASSAGE_SWEDISH({ host: true }),
        },
      },
    },
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/service-deep-tissue.svg',
          description: 'Deep Tissue',
          details: '',
          link: routes.MASSAGE_DEEP_TISSUE({ host: true }),
        },
      },
    },
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/service-prenatal.svg',
          description: 'Prenatal',
          link: routes.MASSAGE_PRENATAL({ host: true }),
        },
      },
    },
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/service-sports.svg',
          description: 'Sports',
          link: routes.MASSAGE_SPORTS({ host: true }),
        },
      },
    },
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/service-sleep.svg',
          description: 'Sleep',
          link: routes.MASSAGE_SLEEP({ host: true }),
        },
      },
    },
    {
      template: {
        name: 'imageDetail',
        props: {
          image: 'massages/couples.svg',
          description: 'Couples',
          link: routes.MASSAGE_COUPLES({ host: true }),
        },
      },
    },
  ];

  return (
    <div className={clsx(styles.block, className)} data-testid={testId}>
      <h3 data-testid={`${testId}--title`}>
        {title ?? (
          <>
            Book Massage <br className={styles.glMobileLineBreak} />
            On Demand® Now
          </>
        )}
      </h3>
      <h5 data-testid={`${testId}--description`}>{description ?? 'Start by choosing your massage technique.'}</h5>
      {!detailed && (
        <div className={styles.desktopMassageContainer}>
          {massages.map((massage, i) => {
            return <ImageDetail key={i} {...massage.template.props} />;
          })}
        </div>
      )}
      {!detailed && (
        <Carousel
          className={styles.carousel}
          overflow='visible'
          slides={massages}
          settings={{
            autoplay: false,
            infinite: false,
            slidesToShow: 4,
            variableWidth: true,
            dots: false,
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: 755,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 585,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 300,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
          testId={`${testId}--carousel`}
        />
      )}
      {detailed && (
        <div className={styles.detailedCarousel}>
          <ModalityCardList
            {...form.getProps('modalities')}
            cardClassName={styles.card}
            itemIds={treatmentIds}
            carousel
            shrink
            hasIcons={false}
            deselectable={false}
            customDescriptions={{
              [ServiceIds.DeepTissue]:
                'A stronger style that focuses on deeper muscle tension and knots – perfect for athletic recovery.',
              [ServiceIds.Swedish]:
                'A restorative technique that relieves stress, boosts circulation, and eases muscle aches and pains.',
              [ServiceIds.Prenatal]:
                'A gentle modality designed to boost wellness and relieve the physical strains of pregnancy.',
              [ServiceIds.Sleep]:
                'Our specially designed technique created to help you get a better, more restful night’s sleep.',
              [ServiceIds.Sports]:
                'A fusion of deep tissue massage and stretching designed for recovery and injury prevention.',
            }}
            testId={`${testId}--modality-card-list`}
          />
        </div>
      )}
      <Button className={styles.button} onClick={onBook} testId={`${testId}--main-button`}>
        Book a Massage
      </Button>
    </div>
  );
};

export default ModalitiesBlock;
