import { ReactNode } from 'react';

import clsx from 'clsx';

import Detail from './components/Detail';
import styles from './style.module.scss';

type Props = {
  data: Array<{ title?; image?; icon?; description; imageClassName? }>;
  title?: ReactNode;
  className?: string;
  detailsClass?: string;
  testId?: string;
};

const DetailsStackedBlock = (props: Props) => {
  const { data, title, className, detailsClass, testId = 'details-stacked-block' } = props;

  return (
    <div className={clsx(styles.block, className)} data-testid={testId}>
      {title && <h2 data-testid={`${testId}--title`}>{title}</h2>}
      <div className={`${styles.glContainerSmall} ${data.length === 1 ? styles.center : ''}`}>
        {data.map((d, i) => (
          <Detail
            key={i}
            title={d.title}
            image={d.image}
            className={detailsClass}
            imageClassName={d.imageClassName}
            icon={d.icon}
            description={d.description}
          />
        ))}
      </div>
    </div>
  );
};

export default DetailsStackedBlock;
