import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Button, IconSvg } from 'components/common';

import CoreBlock from '../CoreBlock';
import { ValuePropListBlockProps } from './ValuePropListBlock.types';
import styles from './styles.module.scss';

const ValuePropListBlock: FC<ValuePropListBlockProps> = ({
  className = '',
  title,
  action,
  actionText,
  actionClass = '',
  items,
  testId = 'value-prop-list-block',
}) => {
  return (
    <CoreBlock title={title} className={clsx(styles.valuePropListSection, className)} testId={testId}>
      <ul>
        {(items || []).map((item, index) => (
          <SkeletonCover key={index}>
            <li>
              <IconSvg className={styles.checkIcon} name='checkmark-circle' size={24} color='theme' />
              <p>{item}</p>
            </li>
          </SkeletonCover>
        ))}
      </ul>
      {action && actionText && (
        <Button
          type='secondary'
          className={clsx(styles.button, actionClass)}
          onClick={action}
          testId={`${testId}--action-button`}
        >
          {actionText}
        </Button>
      )}
    </CoreBlock>
  );
};

export default ValuePropListBlock;
