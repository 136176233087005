import { FC, useCallback, useState } from 'react';

import { log } from 'utils';
import { useForm, usePage } from 'utils/hooks';

import { AddressType, useCreateAddressMutation, useUpdateAddressMutation } from 'redux/apis/OG/user';

import FieldRow from 'components/common/FieldRow';
import Icon from 'components/common/Icon';
import Input from 'components/common/inputs/Input';
import InputLocation from 'components/common/inputs/InputLocation';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  address?: AddressType;
  manual?: boolean;
  shipping?: boolean;
  showAsShipping?: boolean;
  subTitle?: string;
};

const AddressAddOrEdit: FC<Props> = ({
  address,
  manual: propManual,
  shipping,
  showAsShipping,
  subTitle,
  onClose,
  ...rest
}) => {
  const form = useForm();
  const [manual, setManual] = useState<boolean>(propManual);
  const [createAddressMutation] = useCreateAddressMutation();
  const [updateAddressMutation] = useUpdateAddressMutation();

  const { isLoading } = usePage(() => {
    const editing = !!address;
    if (editing && address) {
      form.setValue('address1', address.address1);
      form.setValue('address2', address.address2);
      form.setValue('city', address.city);
      form.setValue('state', address.state);
      form.setValue('zip', address.zipCode);
    }
  });

  const onBack = useCallback(() => {
    setManual(false);
  }, [address]);

  const save = useCallback(async () => {
    const editing = !!address;
    const filledAddressData: Partial<AddressType> = {
      address1: manual ? form.getValue('address1') : form.getValue('address1').address1,
      address2: form.getValue('address2'),
      name: manual ? form.getValue('address1') : form.getValue('address1').address1,
      city: manual ? form.getValue('city') : form.getValue('address1').city,
      state: manual ? form.getValue('state') : form.getValue('address1').state,
      zipCode: manual ? form.getValue('zip') : form.getValue('address1').zip,
      ...(!manual
        ? {
            country: form.getValue('address1').country,
          }
        : {}),
    };

    try {
      if (editing) {
        await updateAddressMutation({
          ...address,
          ...filledAddressData,
        }).unwrap();
        onClose(address);
      } else {
        if (shipping) filledAddressData.shipping = true;

        const createdAddress = await createAddressMutation(filledAddressData).unwrap();
        onClose(createdAddress);
      }
    } catch (errors: any) {
      log.error(errors);
      for (const errorName in errors) {
        if (errors.hasOwnProperty(errorName)) {
          form.setError(manual ? errorName : 'address1', errors[errorName]);
        }
      }
    }
  }, [address, manual, form, shipping]);

  return (
    <Modal
      {...rest}
      loading={isLoading}
      onBack={manual && onBack}
      title={`${!address ? 'Add' : 'Edit'} ${
        !address?.shipping && !showAsShipping ? 'Home Address' : 'Mailing Address'
      }`}
      description={subTitle}
      mobileType={'drawer'}
      actions={[
        {
          label: 'Save',
          onClick: save,
          main: true,
          disabled: manual ? !form.isValid() : !form.isValid(['address1']),
        },
        {
          label: 'Cancel',
          action: 'close',
        },
      ]}
      onClose={() => onClose()}
      centerContent
      testId='address-add-or-edit-modal'
    >
      {!isLoading && (
        <>
          {!manual && (
            <>
              <FieldRow>
                <InputLocation
                  {...form.getProps('address1')}
                  rawValue={address?.address1}
                  searchOptions={{ componentRestrictions: { country: 'us' } }}
                  zipRequired
                  label='Address'
                  testId='address-add-or-edit-modal--address1-input'
                />
              </FieldRow>
              <FieldRow>
                <Input
                  {...form.getProps('address2')}
                  required={false}
                  label='Unit Number (Optional)'
                  testId='address-add-or-edit-modal--address2-input'
                />
              </FieldRow>

              <div
                className={styles.centerLink}
                onClick={() => {
                  form.setValue('address1', form.getValue('address1')?.address1 || address?.address1);
                  setManual(true);
                }}
                data-testid='address-add-or-edit-modal--cant-find-address-link'
              >
                I can’t find my address <Icon type='arrow-right' />
              </div>
            </>
          )}

          {manual && (
            <>
              <FieldRow>
                <Input
                  {...form.getProps('address1')}
                  label='Address'
                  testId='address-add-or-edit-modal--address1-manual-input'
                />
              </FieldRow>
              <FieldRow>
                <Input
                  {...form.getProps('address2')}
                  label='Unit Number (Optional)'
                  required={false}
                  testId='address-add-or-edit-modal--address2-input'
                />
              </FieldRow>
              <FieldRow>
                <Input
                  {...form.getProps('city')}
                  label='City'
                  type='letters'
                  testId='address-add-or-edit-modal--city-input'
                />
              </FieldRow>
              <FieldRow>
                <Input
                  {...form.getProps('state')}
                  placeholder='e.g. NY'
                  label='State'
                  type='letters'
                  max={2}
                  testId='address-add-or-edit-modal--state-input'
                />
              </FieldRow>
              <FieldRow>
                <Input
                  {...form.getProps('zip')}
                  label='Zip Code'
                  type='number'
                  testId='address-add-or-edit-modal--zip-code-input'
                />
              </FieldRow>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default AddressAddOrEdit;
