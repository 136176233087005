import { createContext } from 'react';

export const themes: { [key: string]: string } = {
  primary: 'primary',
  medical: 'medical',
  corporate: 'corporate',
  health: 'health',
  atWork: 'atWork',
};

export const ThemeContext = createContext<string>(themes.primary);

export const ThemeProvider = ThemeContext.Provider;
