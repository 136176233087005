import { FC, useCallback, useState } from 'react';

import { routes } from 'utils';
import { useUser } from 'utils/hooks';

import { Steps } from 'redux/features/spa/selfServe';

import { useModals, useNavigate } from '@zeel-dev/zeel-ui';
import { Link } from 'components/common';

import ConfigureRequestForm from '../../components/ConfigureRequestForm';
import Layout from '../../components/Layout';
import ModalBookingTips from '../../modals/BookingTips';

/**
 * Requests step for Spa Self-Serve flow. Allows the user to create requests and
 * add them to their cart. The form is driven by the client & location ID.
 * Requires partial of full authentication.
 */
const Requests: FC = () => {
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { isTempMember } = useUser();

  // The client object for the selected spa
  const [selectedSpaClientObject, setSelectedSpaClientObject] = useState<any>(null);

  const openBookingTipsModal = useCallback(() => {
    openModal({
      element: <ModalBookingTips />,
    });
  }, []);

  return (
    <Layout
      tags={{
        title: 'Spa Self-Serve - Requests',
      }}
      step={Steps.Requests}
      testId='spa-self-serve--requests-step'
      showTimeline
      showHowItWorks
      title='Self-Serve Spa Staffing'
      description={
        <>
          Questions? Read our <Link onClick={openBookingTipsModal}>Booking Tips</Link>
        </>
      }
      navProps={
        selectedSpaClientObject
          ? { dualLogo: { logoUrl: selectedSpaClientObject.logo, fallbackName: selectedSpaClientObject.name } }
          : {}
      }
      backAction={() => navigate(isTempMember ? routes.SPA({ host: true }) : routes.SETTINGS.SPA_BOOKINGS.UPCOMING())}
    >
      <ConfigureRequestForm onSelectedSpaChange={(s) => setSelectedSpaClientObject(s)} />
    </Layout>
  );
};

export default Requests;
