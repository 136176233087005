import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

const Body: FC<{ error?: string | null; children: ReactNode }> = ({ error, children }) => {
  return (
    <div className={clsx(styles.base)}>
      {error && <p className={styles.error}>{error}</p>}
      {children}
    </div>
  );
};

export default Body;
