import { FC, useCallback } from 'react';

import {
  PaymentCollectionType,
  PaymentMethodType,
  PaymentOption,
  PaymentProcessor,
  useCreateProcessorPaymentMethodMutation,
  useMakePaymentMethodDefaultMutation,
  useUpdateProcessorPaymentMethodMutation,
} from 'redux/apis/OG/payment';

import { CollectRendererOptionProps } from '../../components/_renderer/Collect';
import { usePMCContext } from '../../context';
import { PMCCollectionData, PMCErrorCode, PMCErrorMessage, PMCRenderMode } from '../../types';
import BraintreeProcessor from './processors/Braintree';
import FortisProcessor from './processors/Fortis';
import StripeProcessor from './processors/Stripe';

type CreditCardHandler = (payload: {
  collectionType: PaymentCollectionType;
  data: { label: string } & (
    | { nonce: string; type?: string; transactionId?: never }
    | {
        transactionId: string;
        nonce?: never;
        type?: never;
      }
  );
}) => Promise<{ success: boolean; errors?: Record<string, string> }>;

export type CreditCardProcessorProps = {
  mutate: CreditCardHandler;
  creditCardToEdit?: PaymentMethodType;
  buttonText?: string;
};

const CreditCard: FC<CollectRendererOptionProps> = ({ buttonText = 'Save and Continue' }) => {
  const { mode, processor, entity, complete, usedFor, setCreatedAsDefault } = usePMCContext();

  const [createProcessorPaymentMethodMutation] = useCreateProcessorPaymentMethodMutation();
  const [updateProcessorPaymentMethodMutation] = useUpdateProcessorPaymentMethodMutation();
  const [makeCreditCardDefault] = useMakePaymentMethodDefaultMutation();

  const done = useCallback(
    (collectionType: PaymentCollectionType, data: PMCCollectionData) => {
      complete({
        success: true,
        paymentOption: PaymentOption.CreditCard,
        collectionType,
        processor,
        data,
      });
    },
    [complete, processor]
  );

  const mutate = useCallback<CreditCardHandler>(
    async (payload) => {
      let collectedData: PMCCollectionData = {};
      switch (payload.collectionType) {
        case PaymentCollectionType.Vault: {
          try {
            if (mode === PMCRenderMode.Update && !!entity?.id) {
              const response = await updateProcessorPaymentMethodMutation({
                id: entity.id,
                label: payload.data.label,
                nonce: payload.data.nonce,
                usedFor,
              }).unwrap();
              collectedData = {
                id: response.id,
                extra: {
                  profile_name: response.label,
                  expiration_month: response.expirationMonth,
                  expiration_year: response.expirationYear,
                  last_four: response.lastFourDigits,
                  card_type: response.cardType,
                },
              };
            } else {
              const nonce = payload.data.nonce;
              const transactionId = payload.data.transactionId;
              const paymentData = transactionId ? { transactionId } : { nonce };
              const response = await createProcessorPaymentMethodMutation({
                processor,
                label: payload.data.label,
                usedFor,
                ...paymentData,
              }).unwrap();
              if (setCreatedAsDefault) await makeCreditCardDefault(response.id).unwrap();
              collectedData = {
                id: response.id,
                extra: {
                  profile_name: response.label,
                  expiration_month: response.expirationMonth,
                  expiration_year: response.expirationYear,
                  last_four: response.lastFourDigits,
                  card_type: response.cardType,
                },
              };
            }
          } catch (_errors: any) {
            const { profile_name, payment_method_nonce, ...rest } = _errors;
            return {
              success: false,
              errors: {
                ...rest,
                label: profile_name,
                nonce: payment_method_nonce,
              },
            };
          }
          break;
        }
        case PaymentCollectionType.OneTime: {
          if (!payload.data.nonce) {
            return {
              success: false,
              errors: { [PMCErrorCode.UnknownError]: PMCErrorMessage[PMCErrorCode.UnknownError] },
            };
          }
          collectedData = { nonce: payload.data.nonce };
          break;
        }
        default:
          break;
      }
      done(payload.collectionType, collectedData);
      return { success: true };
    },
    [processor, entity, done, usedFor, setCreatedAsDefault]
  );

  const processorProps = {
    buttonText,
    mutate,
    creditCardToEdit: mode === PMCRenderMode.Update && !!entity ? entity : undefined,
  };
  return (
    <>
      {{
        [PaymentProcessor.Braintree]: <BraintreeProcessor {...processorProps} />,
        [PaymentProcessor.Fortis]: <FortisProcessor {...processorProps} />,
        [PaymentProcessor.Stripe]: <StripeProcessor {...processorProps} />,
      }[processor] || null}
    </>
  );
};

export default CreditCard;
