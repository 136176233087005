import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import { ArrowBoldLeftIcon, SkeletonCover, SkeletonProvider } from '@zeel-dev/zeel-ui';
import { Button, IconSvg } from 'components/common';

import { PMCViewError } from '../../types';
import styles from './style.module.scss';

export type ViewProps = {
  title?: string;
  description?: string;
  onBack?: () => void;
  error?: PMCViewError;
  className?: string;
  loading?: boolean;
  skeleton?: boolean;
  children?: ReactNode;
  button?: {
    label: ReactNode;
    onClick: () => void;
    disabled?: boolean;
  };
};
const View: FC<ViewProps> = ({ title, description, onBack, error, className, loading, skeleton, children, button }) => {
  return (
    <div className={clsx(styles.base, className)}>
      <SkeletonProvider loading={loading}>
        <SkeletonCover>
          {title && (
            <h2 className={styles.title}>
              {onBack && <ArrowBoldLeftIcon className={styles.backIcon} size={24} onClick={() => onBack()} />}
              {title}
            </h2>
          )}
          {description && <h3 className={styles.description}>{description}</h3>}
          {error && (
            <div className={clsx(styles.errorBox)}>
              <IconSvg name='exclamation' color='error' size={23} />
              <div className={styles.content}>
                <h2>{error.title}</h2>
                <p>{error.description}</p>
              </div>
            </div>
          )}
        </SkeletonCover>

        {loading && skeleton && (
          <SkeletonCover>
            <h2 className={styles.title}>Loading the payment form</h2>
            <div className={styles.skeletonField} />
            <div className={styles.skeletonField} />
            <div className={styles.skeletonField} />
          </SkeletonCover>
        )}
        {!loading && children}

        {button && (
          <SkeletonCover>
            <Button
              className={clsx(styles.button, { [styles['button--disabled']]: button.disabled })}
              onClick={() => button.onClick()}
              disabled={button.disabled}
              testId={'pmc-submit-button'}
            >
              {button.label}
            </Button>
          </SkeletonCover>
        )}
      </SkeletonProvider>
    </div>
  );
};

export default View;
