import { FC, useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';

import { routes } from 'utils';

import { useSubmitProspectMutation } from 'redux/apis/Prospect';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Button, FieldRow, Link } from 'components/common';
import { FormikSelect } from 'components/common/Select';
import { FormikInput } from 'components/common/inputs/Input';

import CoreBlock from '../CoreBlock';
import { ContactBlockProps, ContactFormValues } from './ContactBlock.types';
import styles from './style.module.scss';

const interestedInOptions: Array<{
  label: string;
  value: string;
  type: 'prospect' | 'mail';
  meta?: { mail: string; subject: string; body: (firstName: string, lastName: string) => string };
}> = [
  { label: 'Services for Businesses', value: 'service_business_or_employees', type: 'prospect' },
  { label: 'Services for Healthcare Partners', value: 'service_health_plan_and_systems', type: 'prospect' },
  { label: 'Information for Care Providers', value: 'information_care_providers', type: 'prospect' },
  {
    label: 'Services for Individuals',
    value: 'service_individuals',
    type: 'mail',
    meta: {
      mail: 'help@zeel.com',
      subject: 'Inquiry about Services for Individuals',
      body: (firstName, lastName) =>
        `Hi Zeel, my name is ${firstName} ${lastName} and I’d like to inquire about Services for Individuals.`,
    },
  },
  {
    label: 'Press/Media Inquiry',
    value: 'press_media_inquiry',
    type: 'mail',
    meta: {
      mail: 'marketing@zeel.com',
      subject: 'Press/Media Inquiry',
      body: (firstName, lastName) => `Hi Zeel, my name is ${firstName} ${lastName} and I have a Press/Media Inquiry.`,
    },
  },
  {
    label: 'Other',
    value: 'other',
    type: 'mail',
    meta: {
      mail: 'help@zeel.com',
      subject: 'General Inquiry',
      body: (firstName, lastName) => `Hi Zeel, my name is ${firstName} ${lastName} and I’d like to inquire about…`,
    },
  },
];

const ContactBlock: FC<ContactBlockProps> = ({ page, leadType, leadSource, testId = 'contact-block' }) => {
  const [leadSubmitted, setLeadSubmitted] = useState(false);
  const [submitProspect] = useSubmitProspectMutation();

  const submitLead = async (values, { setSubmitting }) => {
    const { firstName, lastName, email, interestedIn } = values;
    const selectedOption = interestedInOptions.find((o) => o.value === interestedIn);
    if (!selectedOption) return;

    setSubmitting(true);

    // Check if lead needs to be sent via a "mailto"
    if (selectedOption.type === 'mail') {
      const { mail, subject, body } = selectedOption.meta || {};
      const link = document.createElement('a');
      link.href = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body?.(firstName, lastName)
      )}`;
      link.click();
    } else if (selectedOption.type === 'prospect') {
      await submitProspect({
        first_name: firstName,
        last_name: lastName,
        email,
        // RecordTypeId: '',
        how_can_we_help: interestedIn,
        form_url: `https://www.zeel.com/${page}`,
        lead_type: leadType || 'General',
        lead_source: leadSource || 'Landing Page Lead Form',
      }).unwrap();
    }
    setLeadSubmitted(true);
    setSubmitting(false);
  };

  return (
    <CoreBlock
      className={styles.contactFormBlock}
      contentClassName={styles['contactFormBlock-content']}
      testId={testId}
    >
      {leadSubmitted && (
        <h2 className={styles.successTitle}>Thank you! A member of our team will follow up with you promptly.</h2>
      )}
      {!leadSubmitted && (
        <>
          <SkeletonCover>
            <h2>Find out how Zeel is revolutionizing healthcare for patients, providers, and administrators alike.</h2>
          </SkeletonCover>
          <div className={styles.formContainer}>
            <Formik
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                interestedIn: '',
              }}
              validateOnMount={true}
              onSubmit={submitLead}
            >
              {({ isValid, handleSubmit, isSubmitting }: FormikProps<ContactFormValues>) => (
                <Form>
                  <FieldRow>
                    <FormikInput
                      name='firstName'
                      type='text'
                      required
                      label='First Name'
                      autoComplete='given-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--first-name-input`}
                    />
                    <FormikInput
                      name='lastName'
                      type='text'
                      label='Last Name'
                      required
                      autoComplete='family-name'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--last-name-input`}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormikInput
                      name='email'
                      type='email'
                      required
                      label='Email address'
                      autoComplete='email username'
                      containerClassName={styles.input}
                      wrapperClassName={styles.inputWrapper}
                      hideValidation
                      testId={`${testId}--email-input`}
                    />
                    <FormikSelect
                      name='interestedIn'
                      required
                      label='I’m Interested In...'
                      inputClassName={styles.select}
                      className={styles.input}
                      mountInBody
                      options={interestedInOptions}
                      testId={`${testId}--interested-in-select`}
                    />
                  </FieldRow>
                  <Button
                    flex='mobile'
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                    testId={`${testId}--main-button`}
                  >
                    Contact Zeel
                  </Button>
                  <div className={styles.termsOfUse}>
                    By selecting &apos;Contact Zeel&apos;, I confirm that I have read and agreed to our{' '}
                    <Link styled href={routes.TERMS_OF_USE({ host: true })} target='_blank'>
                      Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link styled href={routes.PRIVACY_POLICY({ host: true })} target='_blank'>
                      Privacy Policy
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </CoreBlock>
  );
};

export default ContactBlock;
