import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../Icon';
import Link from '../Link';
import styles from './style.module.scss';

export type LinkButtonFlowProps = {
  id?: string;
  onClick: string | ((e?: MouseEvent<HTMLDivElement>) => void);
  children?: ReactNode;
  className?: string;
  icon?: string;
  arrow?: boolean;
  theme?: string;
  testId?: string;
};

export default class LinkButtonFlow extends Component<LinkButtonFlowProps> {
  render() {
    const { id, className = '', children, arrow = false, icon = '', onClick, testId } = this.props;
    const theme = this.props.theme || this.context;

    return (
      <SkeletonCover>
        <div className={clsx(styles.glContainerFull, styles[`theme-${theme}`])}>
          {typeof onClick === 'string' && (
            <Link
              id={id}
              href={onClick}
              className={clsx(className, styles.linkButton)}
              data-testid={testId ?? `link-button-flow`}
            >
              {icon && <Icon type={icon} className={styles.iconBeforeText} />}
              {children}
              {arrow && <Icon className={styles.iconAfterText} type='arrow-right' />}
            </Link>
          )}
          {typeof onClick !== 'string' && (
            <div
              id={id}
              onClick={onClick}
              className={clsx(className, styles.linkButton)}
              data-testid={testId ?? `link-button-flow-div`}
            >
              {icon && <Icon className={styles.iconBeforeText} type={icon} />}
              {children}
              {arrow && <Icon className={styles.iconAfterText} type='arrow-right' />}
            </div>
          )}
        </div>
      </SkeletonCover>
    );
  }
}
LinkButtonFlow.contextType = ThemeContext;
