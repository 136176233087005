import moment from 'moment';

import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

registerMapping<T.SourceQuantityType, T.QuantityType>('ReferralQuantity', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceQuantityDecoder,
    target: T.QuantityDecoder,
  },
});

registerMapping<T.SourceReasonReferenceType, T.ReasonReferenceType>('ReferralReasonReference', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceReasonReferenceDecoder,
    target: T.ReasonReferenceDecoder,
  },
});

registerMapping<T.SourceSubjectType, T.SubjectType>('Subject', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceSubjectDecoder,
    target: T.SubjectDecoder,
  },
});

registerMapping<T.SourceOccurrencePeriodType, T.OccurrencePeriodType>('ReferralOccurrencePeriod', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceOccurrencePeriodDecoder,
    target: T.OccurrencePeriodDecoder,
  },
});

registerMapping<T.SourceSupportingInfoType, T.SupportingInfoType>('ReferralSupportingInfo', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceSupportingInfoDecoder,
    target: T.SupportingInfoDecoder,
  },
});

registerMapping<T.SourceTelecomType, T.TelecomType>('Telecom', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceTelecomDecoder,
    target: T.TelecomDecoder,
  },
});

registerMapping<T.SourceNameType, T.NameType>('SubjectName', {
  operations: [
    ['join', { sourceProperty: 'given', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'family', targetProperty: 'lastName' }],
  ],
  decoders: {
    source: T.SourceNameDecoder,
    target: T.NameDecoder,
  },
});

registerMapping<T.SourceCodingType, T.CodingType>('Coding', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceCodingDecoder,
    target: T.CodingDecoder,
  },
});

registerMapping<T.SourceCodeType, T.CodeType>('Code', {
  operations: [['compose', { sourceProperty: 'coding', mapper: 'Coding' }]],
  decoders: {
    source: T.SourceCodeDecoder,
    target: T.CodeDecoder,
  },
});

registerMapping<T.SourceMetaType, T.MetaType>('Meta', {
  operations: [
    ['copy', { sourceProperties: ['source', 'tag'] }],
    ['copy', { sourceProperty: 'last_updated', targetProperty: 'lastUpdated' }],
    ['copy', { sourceProperty: 'version_id', targetProperty: 'versionId' }],
  ],
  decoders: {
    source: T.SourceMetaDecoder,
    target: T.MetaDecoder,
  },
});

registerMapping<T.SourceIdentifierType, T.IdentifierType>('Identifier', {
  operations: [['copy', { sourceProperties: '*' }]],
  decoders: {
    source: T.SourceIdentifierDecoder,
    target: T.IdentifierDecoder,
  },
});

registerMapping<T.SourceConsentType, T.ConsentType>('Consent', {
  operations: [
    ['copy', { sourceProperties: ['type', 'url'] }],
    ['copy', { sourceProperty: 'signed_on', targetProperty: 'signedOn' }],
  ],
  decoders: {
    source: T.SourceConsentDecoder,
    target: T.ConsentDecoder,
  },
});

registerMapping<T.SourceProcedureType, T.ProcedureType>('ReferralProcedure', {
  operations: [
    ['copy', { sourceProperties: ['performed', 'status'] }],
    ['compose', { sourceProperty: 'identifier', mapper: 'Identifier' }],
  ],
  derivedProperties: [
    ['appointmentId'],
    ({ input }) => ({
      appointmentId: (input?.identifier?.find((e) => e.system.includes('appointment-id')) || {})?.value as string,
    }),
  ],
  decoders: {
    source: T.SourceProcedureDecoder,
    target: T.ProcedureDecoder,
  },
});

registerMapping<T.SourceConditionType, T.ConditionType>('ReferralCondition', {
  operations: [
    ['copy', { sourceProperties: ['id', 'comorbidities', 'extensions'] }],
    ['copy', { sourceProperty: 'chief_complaint', targetProperty: 'chiefComplaint' }],
    ['copy', { sourceProperty: 'provisional_diagnosis', targetProperty: 'provisionalDiagnosis' }],
    ['copy', { sourceProperty: 'recorded_date', targetProperty: 'recordedDate' }],
    ['copy', { sourceProperty: 'resource_type', targetProperty: 'resourceType' }],
    ['compose', { sourceProperty: 'body_site', targetProperty: 'bodySite', mapper: 'Code' }],
    ['compose', { sourceProperty: 'code', mapper: 'Code' }],
    ['compose', { sourceProperty: 'identifier', mapper: 'Identifier' }],
    ['compose', { sourceProperty: 'meta', mapper: 'Meta' }],
    ['compose', { sourceProperty: 'subject', mapper: 'Subject' }],
  ],
  decoders: {
    source: T.SourceConditionDecoder,
    target: T.ConditionDecoder,
  },
});

registerMapping<T.SourceCaregiverType, T.CaregiverType>('PatientCaregiver', {
  operations: [
    ['copy', { sourceProperties: ['id', 'active', 'relationship'] }],
    ['copy', { sourceProperty: 'resource_type', targetProperty: 'resourceType' }],
    ['compose', { sourceProperty: 'meta', mapper: 'Meta' }],
    ['compose', { sourceProperty: 'name', targetProperty: 'names', mapper: 'SubjectName' }],
    ['compose', { sourceProperty: 'patient', mapper: 'Subject' }],
    ['compose', { sourceProperty: 'telecom', mapper: 'Telecom' }],
  ],
  decoders: {
    source: T.SourceCaregiverDecoder,
    target: T.CaregiverDecoder,
  },
});

registerMapping<T.SourcePatientAddressType, T.PatientAddressType>('PatientAddress', {
  operations: [
    ['copy', { sourceProperties: ['id', 'city', 'line', 'state', 'country'] }],
    ['toString', { sourceProperty: 'postal_code', targetProperty: 'zipCode' }],
  ],
  decoders: {
    source: T.SourcePatientAddressDecoder,
    target: T.PatientAddressDecoder,
  },
});

export const ReferralMapping = registerMapping<T.SourceReferralType, T.ReferralType>('Referral', {
  operations: [
    ['copy', { sourceProperties: ['id', 'insurance', 'intent', 'note', 'status'] }],
    ['copy', { sourceProperty: 'authored_on', targetProperty: 'authoredOn' }],
    ['copy', { sourceProperty: 'office_based_referral', targetProperty: 'isOfficeBased' }],
    ['copy', { sourceProperty: 'revocation_reason', targetProperty: 'revocationReason' }],
    ['copy', { sourceProperty: 'revocation_date', targetProperty: 'revocationDate' }],
    ['copy', { sourceProperty: 'service_duration', targetProperty: 'duration' }],
    ['copy', { sourceProperty: 'resource_type', targetProperty: 'resourceType' }],
    ['compose', { sourceProperty: 'code', mapper: 'Code' }],
    ['compose', { sourceProperty: 'consent', mapper: 'Consent' }],
    ['compose', { sourceProperty: 'identifier', mapper: 'Identifier' }],
    ['compose', { sourceProperty: 'meta', mapper: 'Meta' }],
    [
      'compose',
      { sourceProperty: 'occurrence_period', targetProperty: 'occurrencePeriod', mapper: 'ReferralOccurrencePeriod' },
    ],
    ['compose', { sourceProperty: 'quantity', mapper: 'ReferralQuantity' }],
    ['compose', { sourceProperty: 'procedures', mapper: 'ReferralProcedure' }],
    ['compose', { sourceProperty: 'subject', mapper: 'Meta' }],
    ['compose', { sourceProperty: 'reason_reference', targetProperty: 'reasons', mapper: 'ReferralReasonReference' }],
    [
      'compose',
      { sourceProperty: 'supporting_info', targetProperty: 'supportingInfo', mapper: 'ReferralSupportingInfo' },
    ],
    ['compose', { sourceProperty: 'conditions', mapper: 'ReferralCondition' }],
  ],
  derivedProperties: [
    ['referralNumber', 'expired', 'active', 'revoked', 'patientId'],
    ({ input }) => {
      const isExpired =
        moment().diff(moment(input?.occurrence_period?.end).endOf('day')) > 0 ||
        input?.status === T.ReferralStatusEnum.Expired;

      return {
        referralNumber: ((input?.identifier || []).find((e) => e.system.includes('referral-number'))?.value || '') + '',
        expired: isExpired,
        active: !isExpired && input?.status === T.ReferralStatusEnum.Active,
        revoked: input?.status === T.ReferralStatusEnum.Revoked,
        patientId: (input?.subject?.reference || '').split('/').pop(),
      };
    },
  ],
  decoders: {
    source: T.SourceReferralDecoder,
    target: T.ReferralDecoder,
  },
});

export const PatientMapping = registerMapping<T.SourcePatientType, T.PatientType>('Patient', {
  operations: [
    ['copy', { sourceProperties: ['id', 'active', 'gender', 'communication'] }],
    ['copy', { sourceProperty: 'birth_date', targetProperty: 'birthdate' }],
    ['copy', { sourceProperty: 'removed_from_network', targetProperty: 'removedFromNetwork' }],
    ['compose', { sourceProperty: 'address', targetProperty: 'addresses', mapper: 'PatientAddress' }],
    ['compose', { sourceProperty: 'service_requests', targetProperty: 'referrals', mapper: 'Referral' }],
    ['compose', { sourceProperty: 'caregivers', mapper: 'PatientCaregiver' }],
    ['compose', { sourceProperty: 'meta', mapper: 'Meta' }],
    ['compose', { sourceProperty: 'identifier', mapper: 'Identifier' }],
    ['compose', { sourceProperty: 'name', targetProperty: 'names', mapper: 'SubjectName' }],
    ['compose', { sourceProperty: 'telecom', mapper: 'Telecom' }],
  ],
  derivedProperties: [
    ['icn', 'edipi', 'memberId', 'email', 'phone', 'firstName', 'lastName', 'fullName'],
    ({ input, output }) => {
      const activeReferrals = (output?.referrals || []).filter((r) => r.active && r.quantity?.available > 0);
      const pastReferrals = (output?.referrals || []).filter(
        (r) => !r.active && (r.expired || r.revoked || r.quantity?.available === 0)
      );
      const hasActiveReferrals = activeReferrals && activeReferrals.length > 0;
      const hasPastReferrals = pastReferrals && pastReferrals.length > 0;
      const defaultName = output?.names?.[0];

      return {
        firstName: defaultName?.firstName,
        lastName: defaultName?.lastName,
        fullName:
          defaultName?.firstName && defaultName?.lastName ? `${defaultName.firstName} ${defaultName.lastName}` : null,
        icn: (input?.identifier?.find((e) => e.system.includes('icn')) || {})?.value as string,
        edipi: (input?.identifier?.find((e) => e.system.includes('edipi')) || {})?.value as string,
        memberId: (input?.identifier?.find((e) => e.system.includes('member_id')) || {})?.value as string,
        email: (input?.telecom?.find((e) => e.system === 'email') || {})?.value,
        phone: (input?.telecom?.find((e) => e.system === 'phone') || {})?.value,
        activeReferrals,
        pastReferrals,
        hasActiveReferrals: hasActiveReferrals,
        hasPastReferrals: hasPastReferrals,
        hasAnyReferrals: hasActiveReferrals || hasPastReferrals,
      };
    },
  ],
  decoders: {
    source: T.SourcePatientDecoder,
    target: T.PatientDecoder,
  },
});

export const PatientDocumentMapping = registerMapping<T.SourcePatientDocumentType, T.PatientDocumentType>(
  'PatientDocument',
  {
    operations: [['copy', { sourceProperties: ['name', 'url', 'date'] }]],
    decoders: {
      source: T.SourcePatientDocumentDecoder,
      target: T.PatientDocumentDecoder,
    },
  }
);
