import { PaymentProcessor } from 'redux/apis/OG/payment';

import { ApplePayErrorCode, ApplePayPaymentRequest, ApplePayPaymentRequestConfig } from './index';

export type ApplePayApi = any;
export type ApplePayPaymentRequestSessionType = any;
export type ApplePaySdkInstanceType = any;

type ApplePayConnectorConstructor = {
  clientToken: string;
};

abstract class ApplePayConnector {
  protected readonly MERCHANT_DISPLAY_NAME = 'Zeel Massage On Demand';
  protected readonly MERCHANT_ID = process.env.REACT_APP_APPLE_PAY_MERCHANT_ID as string;
  protected readonly APPLE_PAY_VERSION = 3;

  protected clientToken: string;
  protected applePayPaymentRequestSession: ApplePayPaymentRequestSessionType;
  protected applePaySdkInstance: ApplePaySdkInstanceType;
  protected initialized = false;
  protected available = false;
  protected credentialsAvailability: 'unknown' | 'available' | 'unavailable' = 'unknown';
  protected paymentSuccessCallback?: (payload: { nonce: string }) => void | Promise<void> = () => undefined;
  protected paymentErrorCallback?: (error: any) => void | Promise<void> = () => undefined;
  abstract processor: PaymentProcessor;

  constructor({ clientToken }: ApplePayConnectorConstructor) {
    this.clientToken = clientToken;
  }

  get applePayApi(): ApplePayApi | null {
    return (window as any).ApplePaySession || null;
  }

  /**
   * Create a new Apple Pay instance for the current client token and store it in the connector instance.
   * @returns {Promise<{ success: true } | { success: false; error: string }>}
   */
  initialize = async (): Promise<
    | { success: true; errorCode?: never; error?: never }
    | {
        success: false;
        errorCode?: ApplePayErrorCode;
        error?: string;
      }
  > => {
    try {
      // Checking if Apple Pay is supported
      if (!this.applePayApi) return { success: false, errorCode: ApplePayErrorCode.ApplePayNotSupported };

      // Checking supported version
      if (!this.applePayApi?.supportsVersion(this.APPLE_PAY_VERSION))
        return {
          success: false,
          errorCode: ApplePayErrorCode.ApplePayVersionNotSupported,
        };

      // Checking if merchant ID is available
      if (!this.MERCHANT_ID) return { success: false, errorCode: ApplePayErrorCode.ApplePayMissingMerchantId };

      // Checking Apple Pay capabilities for the device
      if (this.applePayApi?.applePayCapabilities) {
        const capabilities = await this.applePayApi?.applePayCapabilities?.(this.MERCHANT_ID);
        if (!capabilities || capabilities?.paymentCredentialStatus === 'applePayUnsupported')
          return {
            success: false,
            errorCode: ApplePayErrorCode.ApplePayNotSupported,
          };
        this.credentialsAvailability =
          {
            paymentCredentialsAvailable: 'available',
            paymentCredentialStatusUnknown: 'unknown',
            paymentCredentialsUnavailable: 'unavailable',
          }[capabilities?.paymentCredentialStatus || ''] || 'unavailable';
      } else if (this.applePayApi?.canMakePayments) {
        if (!this.applePayApi.canMakePayments())
          return {
            success: false,
            errorCode: ApplePayErrorCode.ApplePayNotSupported,
          };
        if (
          this.applePayApi?.canMakePaymentsWithActiveCard &&
          !this.applePayApi.canMakePaymentsWithActiveCard(this.MERCHANT_ID)
        )
          this.credentialsAvailability = 'unavailable';
      } else {
        return {
          success: false,
          errorCode: ApplePayErrorCode.ApplePayNotSupported,
        };
      }

      // Creating SDK instance with token
      const instance = await this.createInstance(this.clientToken);
      if (!instance) return { success: false, errorCode: ApplePayErrorCode.SdkFailure };

      // Storing the instance and states in the connector
      this.applePaySdkInstance = instance;
      this.initialized = true;
      this.available = true;
    } catch (instanceError: any) {
      return { success: false, errorCode: ApplePayErrorCode.SdkFailure, error: JSON.stringify(instanceError) };
    }

    // Returning success
    return { success: true };
  };

  /**
   * Create a new Apple Pay session for the specified payment request.
   */
  requestPaymentSync = (
    paymentRequestConfig: ApplePayPaymentRequestConfig,
    successCallback?: (payload: { nonce: string }) => void | Promise<void>,
    errorCallback?: (error: any) => void | Promise<void>
  ): ApplePayPaymentRequestSessionType => {
    if (!this.initialized) {
      throw new Error('Please call the initialize method before creating a payment request session');
    }
    if (!this.applePaySdkInstance) {
      throw new Error('Apple Pay SDK instance not found');
    }

    // Getting the payment request from the SDK connector
    const applePayRequest = this.createPaymentRequest(paymentRequestConfig);

    // Creating the Apple Pay session
    const session = new this.applePayApi(this.APPLE_PAY_VERSION, applePayRequest);
    if (!session) {
      throw new Error('Failed to create Apple Pay session');
    }
    this.applePayPaymentRequestSession = session;

    // Storing success callback in connector
    if (successCallback) this.paymentSuccessCallback = successCallback;
    if (errorCallback) this.paymentErrorCallback = errorCallback;

    // Attaching event handlers
    this.applePayPaymentRequestSession.onvalidatemerchant = this.handleSessionOnValidateMerchant;
    this.applePayPaymentRequestSession.onpaymentauthorized = this.handleSessionOnPaymentAuthorized;
    this.applePayPaymentRequestSession.oncancel = this.handleOnCancel;

    setTimeout(() => {
      this.applePayPaymentRequestSession.begin();
    });
  };

  /**
   * Request payment asynchronously and return a promise.
   * @param paymentRequestConfig
   */
  requestPayment = (paymentRequestConfig: ApplePayPaymentRequestConfig): Promise<{ nonce: string }> => {
    return new Promise((resolve, reject) => {
      this.requestPaymentSync(
        paymentRequestConfig,
        (payload) => {
          resolve(payload);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  /**
   * Handles the onvalidatemerchant event for the Apple Pay session.
   */
  protected handleSessionOnValidateMerchant = async (event: { validationURL: string }): Promise<void> => {
    try {
      await this.sessionOnValidateMerchant(event);
    } catch (err) {
      this.handlePaymentSessionError(err);
    }
  };

  /**
   * Handles the onpaymentauthorized event for the Apple Pay session.
   */
  protected handleSessionOnPaymentAuthorized = async (event: {
    payment: { token: any; billingContact: any; shippingContact: any };
  }): Promise<void> => {
    try {
      const { nonce } = await this.sessionOnPaymentAuthorized(event);
      this.paymentSuccessCallback({ nonce });
      this.applePayPaymentRequestSession.completePayment(this.getStatus().SUCCESS);
    } catch (err) {
      this.handlePaymentSessionError(err);
    }
  };

  /**
   * Handles the oncancel event for the Apple Pay session.
   */
  protected handleOnCancel = (): void => {
    this.handlePaymentSessionError('Apple Pay session cancelled');
  };

  /**
   * Handles if any error occurs during payment request session
   * @param error
   */
  protected handlePaymentSessionError = (error: any): void => {
    if (this.applePayPaymentRequestSession) {
      try {
        this.applePayPaymentRequestSession.abort();
      } catch {
        console.error('Could not abort apple pay session');
      }
    }
    this.paymentErrorCallback(error);
  };

  // SDK-specific method to create Apple Pay instance from session
  abstract createInstance(clientToken: string): Promise<ApplePaySdkInstanceType>;

  // SDK-specific method to create Apple Pay payment request
  protected abstract createPaymentRequest(request: ApplePayPaymentRequestConfig): ApplePayPaymentRequest;

  // SDK-specific method to handle merchant validation
  protected abstract sessionOnValidateMerchant(event: { validationURL: string }): Promise<void>;

  // SDK-specific method to handle payment authorization
  protected abstract sessionOnPaymentAuthorized(event: {
    payment: { token: any; billingContact: any; shippingContact: any };
  }): Promise<{ nonce: string }>;

  /**
   * Get the Apple Pay status codes.
   */
  getStatus = (): { SUCCESS: string; FAILURE: string } => {
    return {
      SUCCESS: this.applePayApi?.STATUS_SUCCESS,
      FAILURE: this.applePayApi?.STATUS_FAILURE,
    };
  };
}

export default ApplePayConnector;
