import { FC, useCallback, useState } from 'react';

import { routes } from 'utils';
import { useForm, usePage } from 'utils/hooks';

import { useCreatePartialSessionMutation } from 'redux/apis/OG/auth';
import { useLazyGetServicesQuery, useSendEmailFunnelMutation } from 'redux/apis/OG/booking';
import { SpaAddress, Steps, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { useModals, useNavigate } from '@zeel-dev/zeel-ui';
import { FieldTitle, Input, InputLocation, Link } from 'components/common';

import FieldHelperText from '../../components/FieldHelperText';
import Layout, { Body, Footer } from '../../components/Layout';
import { useSpaSelfServeEvent } from '../../index';
import ModalBookingTips from '../../modals/BookingTips';
import ModalExistingAccountSignIn from '../../modals/ExistingAccountSignIn';
import ModalServiceUnavailable from '../../modals/ServiceUnavailable';

/**
 * Intro step for Spa Self-Serve flow. Collects user's spa location and email.
 * If user's email is already in the system, opens a modal to sign in,
 * otherwise, creates a temporary user and continues to the next step.
 */
const Intro: FC = () => {
  const navigate = useNavigate();
  const form = useForm();
  const { openModal } = useModals();
  const {
    actions: { collectedInformationChanged, collectedInformationCleared, emailChanged },
  } = useSpaSelfServeFlowSlice();
  const { logEvent } = useSpaSelfServeEvent();

  /** States **/
  const [manualMode, setManualMode] = useState(false);

  /** Queries and Mutations **/
  const [checkAvailabilityQuery] = useLazyGetServicesQuery();
  const [checkUserEmail] = useSendEmailFunnelMutation();
  const [createPartialSession] = useCreatePartialSessionMutation();

  /** Effects **/
  const { isLoading } = usePage(async ({ _isAuthenticated }) => {
    if (_isAuthenticated) {
      return navigate(routes.SPA_SELF_SERVE.REQUESTS({ replace: true }));
    }
  });

  /** Handlers **/
  const navigateToNextStep = useCallback(() => {
    navigate(routes.SPA_SELF_SERVE.REQUESTS());
  }, []);

  const submit = useCallback(async () => {
    const { email, locationAddress1, locationAddress2, locationCity, locationState, locationZip } = form.getValues();
    const address = form.getValue('location') || {};
    const addressData: SpaAddress = {
      address1: manualMode ? locationAddress1 : address.address1,
      address2: manualMode ? locationAddress2 : address.address2,
      city: manualMode ? locationCity : address.city,
      state: manualMode ? locationState : address.state,
      zip: manualMode ? locationZip : address.zip,
      type: manualMode ? 'manual' : 'match',
      matchString: manualMode ? '' : address.addressLong,
    };

    // 1. check pricing, and open modal if pricing not available
    let pricingAvailable = true;
    try {
      const availableServices = await checkAvailabilityQuery({
        zipCode: addressData.zip,
        // clientId: ???
      }).unwrap();
      if (!availableServices?.length) pricingAvailable = false;
    } catch (availabilityError) {
      console.error(availabilityError);
      pricingAvailable = false;
    }
    if (!pricingAvailable) {
      return openModal({
        element: <ModalServiceUnavailable zip={addressData.zip} />,
      });
    }

    // 2. Set address data in store
    collectedInformationChanged({ address: addressData });

    // 3. check email funnel, and open login modal if account exists
    try {
      await checkUserEmail({ zipCode: addressData.zip, email }).unwrap();
    } catch (errors: any) {
      if (errors?.user_exists) {
        return openModal(
          {
            element: <ModalExistingAccountSignIn initialEmail={email} />,
          },
          (signInCompleted) => {
            if (signInCompleted) {
              collectedInformationCleared();
              navigateToNextStep();
            }
          }
        );
      }
      if (errors?.email) {
        return form.setError('email', 'Please enter a valid email address.');
      }
    }

    // 4. Create temporary user and continue
    emailChanged(email);
    try {
      await createPartialSession().unwrap();
      await logEvent({
        lead_source: 'Self-Serve Booking Flow',
        industry: 'Hospitality',
        email,
        address_1: addressData.address1,
        address_2: addressData.address2,
        city: addressData.city,
        state: addressData.state,
        first_name: 'Self-Serve Spa',
        last_name: 'LNU',
      });
      navigateToNextStep();
    } catch (partialSessionError) {
      console.error(partialSessionError);
    }
  }, [form, manualMode, navigateToNextStep, logEvent]);

  const openBookingTipsModal = useCallback(() => {
    openModal({
      element: <ModalBookingTips />,
    });
  }, []);

  return (
    <Layout
      loading={isLoading}
      skeleton={isLoading}
      step={Steps.Intro}
      tags={{ title: 'Spa Self-Serve' }}
      title='Where do you want spa staffing?'
      description={
        <>
          Questions? Read our <Link onClick={openBookingTipsModal}>Booking Tips</Link>
        </>
      }
      showHowItWorks
      testId='spa-self-serve--intro-step'
      backAction={() => navigate(routes.SPA({ host: true }))}
    >
      <Body size='narrow'>
        <FieldTitle>Location</FieldTitle>
        {!manualMode && (
          <>
            <InputLocation
              {...form.getProps('location')}
              clearable
              searchOptions={{ componentRestrictions: { country: 'us' } }}
              placeholder='Address'
              hideValidationSpaceWhenEmpty
            />
            <FieldHelperText accent onClick={() => setManualMode(true)}>
              I can't find the address
            </FieldHelperText>
          </>
        )}
        {manualMode && (
          <>
            <Input
              {...form.getProps('locationAddress1')}
              placeholder='Address 1'
              autoFocus
              required
              hideValidationSpaceWhenEmpty
            />
            <Input
              {...form.getProps('locationAddress2')}
              required={false}
              placeholder='Room/Suite/Floor # (Optional)'
              hideValidationSpaceWhenEmpty
            />
            <Input
              {...form.getProps('locationCity')}
              placeholder='City'
              type='letters'
              required
              hideValidationSpaceWhenEmpty
            />
            <Input {...form.getProps('locationState')} placeholder='State' required hideValidationSpaceWhenEmpty />
            <Input {...form.getProps('locationZip')} placeholder='Zip Code' type='number' required />
          </>
        )}

        <FieldTitle>Your Email</FieldTitle>
        <Input {...form.getProps('email')} placeholder='Email Address' />
      </Body>
      <Footer
        actions={[
          {
            type: 'button',
            label: 'Continue',
            onClick: submit,
            componentProps: { disabled: !form.isValid(manualMode ? ['email', ''] : ['email', 'location']) },
          },
        ]}
      />
    </Layout>
  );
};

export default Intro;
