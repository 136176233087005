import { FC } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';

import { SkeletonCover, useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Html, LinkButton } from 'components/common';

import CoreBlock from '../CoreBlock';
import { PressReleasesBlockProps } from './PressReleasesBlock.types';
import styles from './style.module.scss';

const PressReleasesBlock: FC<PressReleasesBlockProps> = ({
  title: blockTitle,
  className,
  articles = [],
  condensed,
  hasMore,
  onLoadMore,
  testId = 'press-releases-block',
}) => {
  const navigate = useNavigate();
  const openPost = (slug: string) => {
    if (slug) {
      navigate(routes.PRESS_RELEASES.POST({ params: { slug } }));
    }
  };
  return (
    <CoreBlock
      size='normal'
      title={blockTitle}
      className={clsx(styles.section, className)}
      titleClassName={styles.blockTitle}
      testId={testId}
    >
      {articles.map((article, i) => {
        const { topper, title, content, slug } = article;
        return (
          <SkeletonCover key={title}>
            <div
              className={clsx(styles.article, { [styles.articleCondensed]: condensed })}
              data-testid={`${testId}--article-${i}`}
            >
              <p className={styles.topper}>{topper}</p>
              {!condensed && (
                <h3 className={styles.title}>
                  <Html content={title} />
                </h3>
              )}
              {condensed && (
                <LinkButton className={styles.title} arrow={false} onClick={() => openPost(slug)} target='_blank'>
                  <Html content={title} />
                </LinkButton>
              )}
              <div className={styles.summary}>
                <Html content={content} />
              </div>
              <LinkButton className={styles.link} onClick={() => openPost(slug)} target='_blank'>
                Keep Reading
              </LinkButton>
            </div>
          </SkeletonCover>
        );
      })}
      {!articles?.length && (
        <SkeletonCover>
          <div className={clsx(styles.article, { [styles.articleCondensed]: condensed })}>
            <p className={styles.topper}>aaa</p>
            <h3 className={styles.title}>
              <Html content={'bbb'} />
            </h3>
          </div>
          <div className={clsx(styles.article, { [styles.articleCondensed]: condensed })}>
            <p className={styles.topper}>aaa</p>
            <h3 className={styles.title}>
              <Html content={'bbb'} />
            </h3>
          </div>
          <div className={clsx(styles.article, { [styles.articleCondensed]: condensed })}>
            <p className={styles.topper}>aaa</p>
            <h3 className={styles.title}>
              <Html content={'bbb'} />
            </h3>
          </div>
        </SkeletonCover>
      )}
      {hasMore && onLoadMore && (
        <div className={styles.buttonContainer}>
          <Button type='secondary' onClick={onLoadMore} testId={`${testId}--load-more-button`}>
            Load More
          </Button>
        </div>
      )}
    </CoreBlock>
  );
};

export default PressReleasesBlock;
