import { cloneDeep } from 'lodash';

import { OGApi, TAGS } from '..';
import { DiscountType, originalPrices } from './constants';
import { OrderPayloadType, ProductType } from './types';

export const estoreApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get estore products
     */
    getProducts: builder.query<ProductType[], void>({
      query: () => {
        return {
          url: '/api/v1/physicalproduct/list',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { products: ProductType[] }) => {
        const groups = {};
        const updatedProducts = (responseData.products || []).map((product) => {
          const augmentedProduct = cloneDeep(product);

          // Handling colors

          const splittedName = (product.label || '')?.split(' ');
          const splittedColorId = splittedName.pop();
          if (splittedColorId === 'Green') {
            augmentedProduct._color_id = 'green';
            augmentedProduct._secondary = true;
            augmentedProduct._label_without_color = splittedName.join(' ');
            if (!groups[splittedName.join(' ')]) groups[splittedName.join('_')] = [];
            groups[splittedName.join('_')].push({
              _color_id: 'green',
              _product_id: product.id,
              _hex: '#9ee344',
            });
          } else if (splittedColorId === 'Black') {
            augmentedProduct._color_id = 'black';
            augmentedProduct._secondary = false;
            augmentedProduct._label_without_color = splittedName.join(' ');
            if (!groups[splittedName.join(' ')]) groups[splittedName.join('_')] = [];
            groups[splittedName.join('_')].push({
              _color_id: 'black',
              _product_id: product.id,
              _hex: '#000000',
            });
          } else {
            augmentedProduct._label_without_color = product.label;
          }

          // Handling sales
          augmentedProduct.price.original = originalPrices[augmentedProduct.name] ?? augmentedProduct.price.current;
          if (augmentedProduct.price.current < augmentedProduct.price.original) {
            augmentedProduct.price.discount_type = DiscountType.Sale;
          }

          return augmentedProduct;
        });

        const finalProducts = updatedProducts.map((product) => {
          const augmentedProduct = cloneDeep(product);
          const splittedName = (product.label || '')?.split(' ');
          const splittedColorId = splittedName.pop();
          if (['Black', 'Green'].includes(splittedColorId) && groups[splittedName.join(' ')]) {
            augmentedProduct._colors = groups[splittedName.join(' ')];
          }
          return augmentedProduct;
        });

        return finalProducts;
      },
      providesTags: () => {
        return [TAGS.STORE_PRODUCT];
      },
    }),

    /**
     * Get cart checkout pricing
     */
    getOrderPricing: builder.query<any, OrderPayloadType>({
      query: (orderPayload) => {
        return {
          url: '/api/v1/order/checkout_summary',
          method: 'POST',
          body: {
            _data: '',
            ...orderPayload,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { pricing: any }) => {
        const pricing = responseData?.pricing;

        return {
          subtotal: pricing?.find((p) => p.type === 'subtotal'),
          tax: pricing?.find((p) => p.type === 'tax'),
          total: pricing?.find((p) => p.type === 'total'),
        };
      },
    }),

    /**
     * Create order from cart
     */
    createOrder: builder.mutation<string, OrderPayloadType>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const checkoutPricingResult = await fetchWithBQ({
          url: '/api/v1/order/checkout_summary',
          method: 'POST',
          body: {
            _data: '',
            ..._arg,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (checkoutPricingResult.error) return { error: checkoutPricingResult.error };

        const { transaction_eid } = checkoutPricingResult?.data || {};
        const orderResult = await fetchWithBQ({
          url: '/api/v1/order/purchase',
          method: 'POST',
          body: {
            _data: '',
            ..._arg,
            transaction_eid,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (orderResult.error) return { error: orderResult.error };

        return { data: orderResult?.data?.eid };
      },
      invalidatesTags: [TAGS.STORE_ORDER],
    }),

    /**
     * Get all orders
     */
    getOrders: builder.query<any[], void>({
      query: () => {
        return {
          url: `/api/v1/order/get`,
          method: 'GET',
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { orders: any[] }) => {
        return responseData?.orders;
      },
    }),

    /**
     * Get an order by id
     */
    getOrder: builder.query<any, string>({
      query: (orderId) => {
        return {
          url: `/api/v1/order/get/${orderId}`,
          method: 'GET',
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { order: any }) => {
        return responseData?.order;
      },
    }),
  }),
});

export const {
  // Queries
  useGetProductsQuery,
  useGetOrderPricingQuery,
  useGetOrderQuery,
  useGetOrdersQuery,
  // Mutations
  useCreateOrderMutation,
} = estoreApi;
