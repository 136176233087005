import { FC } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Html } from 'components/common';

import { InfoWithHtmlBlockProps } from './InfoWithHtmlBlock.types';
import styles from './style.module.scss';

const InfoWithHtmlBlock: FC<InfoWithHtmlBlockProps> = ({
  title,
  content,
  image,
  contentClass,
  testId = 'info-with-html-block',
}) => {
  return (
    <div className={styles.infoBlock} data-testid={testId}>
      <SkeletonCover>
        <div className={styles.image} style={{ backgroundImage: `url(${image?.url})` }} />
      </SkeletonCover>
      <div className={clsx(styles['infoBlock-content'], contentClass)}>
        <div className={styles.info}>
          <SkeletonCover>
            <h2 data-testid={`${testId}--title`}>{title}</h2>
            <Html content={content} testId={`${testId}--html-content`} />
          </SkeletonCover>
        </div>
      </div>
    </div>
  );
};

export default InfoWithHtmlBlock;
