import { FC } from 'react';

import clsx from 'clsx';

import { Carousel, Image } from 'components/common';

import CoreBlock from '../CoreBlock';
import { VAProvider, VAProviderBlockProps } from './VAProviderBlock.types';
import styles from './style.module.scss';

const VAProviderBlock: FC<VAProviderBlockProps> = ({ className = '', providers, testId = 'va-provider-block' }) => {
  const slides = providers.map((provider: VAProvider, i) => {
    return (
      <div key={i} className={styles.insideSlide} data-testid={`${testId}--carousel-slide-${i}`}>
        <Image className={styles.image} alt='mask' src={provider.image} />
        <div className={styles.info}>
          <p className={styles.quote}>{provider.quote}</p>
          <h5>{provider.name}</h5>
          <p className={styles.location}>{provider.location}</p>
        </div>
      </div>
    );
  });

  return (
    <CoreBlock size='normal' className={clsx(styles.section, className)} testId={testId}>
      <h4 data-testid={`${testId}--title`}>Meet Your Providers</h4>
      <Carousel
        className={styles.carousel}
        slideClassName={styles.slide}
        slides={slides}
        settings={{
          autoplay: false,
          slidesToShow: 1,
          dots: false,
          arrows: true,
          swipeToSlide: true,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                dots: false,
              },
            },
          ],
        }}
        testId={`${testId}--carousel`}
      />
    </CoreBlock>
  );
};

export default VAProviderBlock;
