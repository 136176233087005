import { FC, useCallback, useEffect } from 'react';

import { useForm } from 'utils/hooks';

import { useSignInMutation } from 'redux/apis/OG/auth';

import { useModals } from '@zeel-dev/zeel-ui';
import { FieldRow, Input } from 'components/common';
import ModalForgotPassword from 'components/modals/items/ForgotPassword';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type ModalExistingAccountSignInProps = ModalProps & {
  initialEmail?: string;
};

/**
 * Modal for signing in with an existing account. Allows the user to sign in with their email and password.
 * The email field is pre-filled if an initial email is provided.
 */
const ModalExistingAccountSignIn: FC<ModalExistingAccountSignInProps> = ({ initialEmail, onClose, ...rest }) => {
  const form = useForm();
  const { openModal } = useModals();

  /** Mutations **/
  const [signInMutation] = useSignInMutation();

  /** Effects **/
  useEffect(() => {
    if (initialEmail) form.setValue('email', initialEmail);
  }, []);

  /** Handlers **/
  const handleSignIn = useCallback(async () => {
    const email = form.getValue('email');
    const password = form.getValue('password');

    try {
      await signInMutation({ email, password }).unwrap();
      onClose(true);
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setError('email', errors.authentication);
      }
    }
  }, [form]);

  return (
    <Modal
      {...rest}
      onClose={() => onClose(false)}
      mobileType='drawer'
      title='Looks like you already have an account. Let’s sign you in.'
      actions={[{ label: 'Continue', onClick: handleSignIn, disabled: !form.isValid() }]}
    >
      <FieldRow>
        <Input {...form.getProps('email')} type='email' label='Email Address' />
      </FieldRow>

      <Input
        {...form.getProps('password')}
        type='password'
        autoFocus={!!form.getValue('email')}
        label='Password'
        autoComplete='current-password'
      />
      <div
        className={styles.linkButton}
        onClick={() =>
          openModal({
            element: <ModalForgotPassword {...{ emailFunnel: true, email: form.getValue('email') }} />,
          })
        }
      >
        Forgot Password?
      </div>
    </Modal>
  );
};

export default ModalExistingAccountSignIn;
