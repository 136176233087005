import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';

import { routes } from 'utils';
import { useForm, usePage, useUser } from 'utils/hooks';

import { GenderRequestedEnum, GenderRequestedType } from 'redux/apis/OG/booking';
import {
  useGetBookingPreferencesQuery,
  useGetBookingTimesQuery,
  useGetClientListQuery,
  useLazyGetGigMultiplePricingQuery,
} from 'redux/apis/OG/spa';
import { RequestType, Spa, formatRequest, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import {
  EditPencilBoldIcon,
  InformationIcon,
  SkeletonCover,
  SkeletonProvider,
  useModals,
  useNavigate,
} from '@zeel-dev/zeel-ui';
import { FieldRow, FieldTitle, Input, InputDate, Select } from 'components/common';
import { ModalConfirmation, ModalDialog } from 'components/modals/items';
import ModalSpaClientAndAddress from 'components/modals/items/SpaClientAndAddress';

import { Body, Footer } from '../../components/Layout';
import ModalModifyCollectedLocation from '../../modals/ModifyCollectedLocation';
import styles from './style.module.scss';

type ConfigureRequestFormProps = {
  editId?: string;
  onSuccess?: (request: RequestType) => void;
  onSelectedSpaChange?: (spa: any) => void;
  fullSize?: boolean;
};
/**
 * Requests step for Spa Self-Serve flow. Allows the user to create requests and
 * add them to their cart. The form is driven by the client & location ID.
 * Requires partial of full authentication.
 */
const ConfigureRequestForm: FC<ConfigureRequestFormProps> = ({ editId, onSuccess, onSelectedSpaChange, fullSize }) => {
  const navigate = useNavigate();
  const form = useForm();
  const { openModal } = useModals();
  const { isAuthenticated, isTempMember, isLoading: isUserLoading } = useUser();
  const {
    actions: { requestAdded, requestUpdated, cartToggledStateChanged },
    selectors: { useDefaultSpaSelector, useCollectedInformationSelector, useRequestsSelector },
  } = useSpaSelfServeFlowSlice();

  /** Selectors **/
  const defaultSpa = useDefaultSpaSelector();
  const collectedInformation = useCollectedInformationSelector();
  const requests = useRequestsSelector();

  /** States **/
  const [selectedSpa, setSelectedSpa] = useState<Spa>();
  const [addingAnimation, setAddingAnimation] = useState<boolean>(false);
  const [edit, setEdit] = useState<false | RequestType>(false);
  const [fetchingPrice, setFetchingPrice] = useState<boolean>(false);
  const [estimatedRequestPricing, setEstimatedRequestPricing] = useState<number | null>(null);

  /** Queries **/
  const [getMultipleRequestPricingQuery] = useLazyGetGigMultiplePricingQuery();
  const { data: clientsList, isLoading: isClientsListLoading } = useGetClientListQuery(
    { bookingType: 'spa_self_serve' },
    {
      skip: !isAuthenticated || isUserLoading || isTempMember,
    }
  );
  const { data: bookingPreferences } = useGetBookingPreferencesQuery({
    clientId: selectedSpa?.clientId || '22601',
    isGig: true,
  });
  const { data: bookingDatesAndTimes } = useGetBookingTimesQuery(
    {
      category: 'gig',
      gig: true,
      ...(selectedSpa?.clientId ? { client: { id: selectedSpa.clientId } } : {}),
      location: selectedSpa?.locationId
        ? { id: selectedSpa.locationId }
        : collectedInformation?.address?.zip
        ? { zip: collectedInformation.address.zip }
        : undefined,
    },
    { skip: !selectedSpa?.locationId && !collectedInformation?.address?.zip }
  );

  /** Effects **/
  const { isLoading } = usePage(
    async () => {
      if (editId) {
        const requestToEdit = requests.find((r) => r.id === editId);
        if (requestToEdit) {
          setEdit(requestToEdit);
          if (requestToEdit.spa) setSelectedSpa(requestToEdit.spa);
          form.setValues({
            date: moment(requestToEdit.requestedDateTime.label),
            time: requestToEdit.requestedDateTime.start,
            duration: requestToEdit.duration,
            quantity: requestToEdit.quantity,
            gender: requestToEdit.gender,
            notes: requestToEdit.notes,
          });
        }
      } else {
        if (clientsList?.length > 0) {
          let clientObject = clientsList.find((c) => c.id === defaultSpa?.clientId);
          let locationObject = clientObject?.addresses?.find((a) => a.id === defaultSpa?.locationId);
          if (!clientObject) {
            if (clientsList?.length === 1) {
              clientObject = clientsList[0];
              if (clientObject?.addresses?.length === 1) {
                locationObject = clientObject.addresses[0];
              }
            }
          } else if (clientObject && !locationObject) {
            if (clientObject?.addresses?.length === 1) {
              locationObject = clientObject.addresses[0];
            }
          }
          if (!clientObject || !locationObject) {
            openModal(
              {
                element: <ModalSpaClientAndAddress selectedClientId={clientObject?.id} clients={clientsList} />,
              },
              (response) => {
                if (response?.client && response?.address) {
                  setSelectedSpa({
                    clientId: response.client.id,
                    locationId: response.address.id,
                  });
                }
              }
            );
          } else {
            setSelectedSpa({
              clientId: clientObject.id,
              locationId: locationObject.id,
            });
          }
        } else if (!collectedInformation?.address) {
          openModal({
            element: <ModalModifyCollectedLocation />,
          });
        }
      }
    },
    { hold: isClientsListLoading }
  );

  /** Memoized values **/
  // Available times for the selected date
  const timesForDate = useMemo(() => {
    return (bookingDatesAndTimes || []).find((dt) => {
      return (
        dt.date ===
        moment(form.getValue('date'))
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format('YYYY-MM-DD HH:mm:ss')
      );
    });
  }, [form.getValue('date'), bookingDatesAndTimes]);

  const selectedSpaClientObject = useMemo(() => {
    let singleClientId = selectedSpa?.clientId || null;
    (requests || []).forEach((r) => {
      if (singleClientId && singleClientId !== r.spa?.clientId) singleClientId = null;
      else singleClientId = r.spa?.clientId || null;
    });
    if (!singleClientId) return undefined;
    return clientsList?.find((c) => c.id === singleClientId);
  }, [selectedSpa, requests, clientsList]);

  useEffect(() => {
    onSelectedSpaChange?.(selectedSpaClientObject);
  }, [selectedSpaClientObject?.id]);

  // If the user has any saved spa locations
  const hasSpasOnFile = useMemo(() => (clientsList || []).length > 0, [clientsList]);
  const isDraftRequestValid = useMemo(() => {
    const { date, time, duration, quantity, gender } = form.getValues();
    return (
      !!date &&
      !!time &&
      !!duration &&
      !!quantity &&
      !!gender &&
      (!hasSpasOnFile || (selectedSpa?.clientId && selectedSpa?.locationId))
    );
  }, [form, selectedSpa?.clientId, selectedSpa?.locationId, hasSpasOnFile]);

  /** Handlers **/
  const navigateToManageLocations = useCallback(() => {
    navigate(routes.SPA_SELF_SERVE.LOCATIONS(), { state: { initialSpa: selectedSpa } });
  }, [selectedSpa]);

  const openSpaMaterialsModal = useCallback(() => {
    openModal({
      element: (
        <ModalDialog
          title='Spa Equipment'
          description={`Because you are requesting a therapist to a spa location, the therapist will not be bringing a massage table. We expect the spa to supply all materials necessary for treatment, such as lotion and massage table.`}
          slim
        />
      ),
    });
  }, []);

  const handleAddRequest = useCallback(() => {
    const { time, duration, quantity, gender, notes } = form.getValues();
    let timeLabel = '';
    (timesForDate?.hours || []).forEach((_h) => {
      const matchingMinute = (_h?.minutes || []).find((_m) => {
        return _m?.id === time;
      });
      if (matchingMinute) {
        timeLabel = matchingMinute.date;
      }
    });
    const request: RequestType = {
      id: nanoid(),
      spa: selectedSpa,
      requestedDateTime: {
        label: timeLabel,
        start: time,
        end: time,
      },
      duration: duration,
      gender: gender as GenderRequestedType,
      quantity: quantity,
      notes: notes,
      estimatedPrice: estimatedRequestPricing,
    };
    if (edit) {
      requestUpdated({ ...edit, ...request, id: edit.id });
    } else {
      cartToggledStateChanged(true);
      requestAdded(request);
      if ((requests || []).length === 0) openSpaMaterialsModal();
    }
    onSuccess?.(request);

    form.setValues({
      date: null,
      time: null,
      duration: null,
      quantity: null,
      notes: null,
    });
    setEstimatedRequestPricing(null);
    setAddingAnimation(true);
    setTimeout(() => setAddingAnimation(false), 1000);
  }, [form, selectedSpa, timesForDate, requests, estimatedRequestPricing, onSuccess, edit]);

  const handleOnCollectedLocationChange = useCallback(() => {
    openModal({
      element: <ModalModifyCollectedLocation />,
    });
  }, []);

  const handleOnSavedLocationChange = useCallback(
    (value: string) => {
      if (value === '__add__') {
        return navigate(routes.SPA_SELF_SERVE.LOCATION_INFO(), {
          state: {
            next: routes.SPA_SELF_SERVE.REQUESTS(),
            preventRedirect: true,
            setAsFlowDefault: true,
          },
        });
      }
      const [clientId, addressId] = (value || '').split('.');
      const clientIdChanged = clientId !== selectedSpa?.clientId;
      const locationIdChanged = addressId !== selectedSpa?.locationId;
      if (!clientIdChanged && !locationIdChanged) return;
      const updateState = () => {
        if (clientIdChanged) form.setValue('duration', null);
        if (locationIdChanged) {
          form.setValue('date', null);
          form.setValue('time', null);
        }
        setSelectedSpa({
          clientId: clientId,
          locationId: addressId,
        });
      };
      const currentForm = form.getValues();
      const dangerToOverride = currentForm.date || currentForm.time || currentForm.duration;
      if (dangerToOverride) {
        openModal(
          {
            element: (
              <ModalConfirmation
                title='Change Location'
                description={`Any unsaved progress will be lost. Do you wish to continue?`}
              />
            ),
          },
          (response) => {
            if (!response) return;
            updateState();
          }
        );
      } else {
        updateState();
      }
    },
    [form, selectedSpa]
  );

  useEffect(() => {
    const check = async () => {
      if (!isDraftRequestValid || fetchingPrice) return;
      setFetchingPrice(true);
      setEstimatedRequestPricing(null);
      try {
        const { time, duration, quantity, gender, notes } = form.getValues();
        const formattedRequest = formatRequest({
          spa: selectedSpa,
          requestedDateTime: {
            start: time,
            end: time,
          },
          duration: duration,
          gender: gender as GenderRequestedType,
          quantity: quantity,
          notes: notes,
          ...(!selectedSpa && collectedInformation?.address?.zip ? { _zip: collectedInformation.address.zip } : {}),
        });
        const allRequests: any[] = [];
        for (let i = 0; i < quantity; i++) {
          allRequests.push(formattedRequest);
        }
        const allRequestsPricingResult = await getMultipleRequestPricingQuery({
          bookings: allRequests.map((rt) => {
            return rt;
          }),
        }).unwrap();
        const totalPricing =
          Number(
            allRequestsPricingResult?.pricings?.[0]?.pricing?.pre_credit_sub_total_with_tip?.prc ||
              Number(allRequestsPricingResult?.pricings?.[0]?.pricing?.total_no_credit?.prc || 0) -
                Number(allRequestsPricingResult?.pricings?.[0]?.pricing?.tax?.prc || 0) ||
              0
          ) * quantity;
        setEstimatedRequestPricing(totalPricing);
      } catch (pricingError) {
        console.error(pricingError);
      }
      setFetchingPrice(false);
    };
    check();
  }, [
    form.getValue('date'),
    form.getValue('time'),
    form.getValue('duration'),
    form.getValue('quantity'),
    selectedSpa?.clientId,
    selectedSpa?.locationId,
    collectedInformation?.address?.zip,
    isDraftRequestValid,
  ]);

  /** Memoized field options **/
  const durationOptions = useMemo<{ value: number; label: string }[]>(() => {
    return (bookingPreferences?.gig?.lengths?.gig || []).map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }, [bookingPreferences]);

  const quantityOptions = useMemo<{ value: number; label: string }[]>(() => {
    const quantities = [];
    for (let i = 1; i < 10; i++) {
      quantities.push({
        value: i,
        label: i + '',
      });
    }
    return quantities;
  }, []);

  const genderPreferenceOptions = useMemo<{ value: GenderRequestedType; label: string }[]>(() => {
    return [
      { value: GenderRequestedEnum.MaleOnly, label: 'Male Only' },
      { value: GenderRequestedEnum.MalePreferred, label: 'Male Preferred' },
      { value: GenderRequestedEnum.Any, label: 'Either' },
      { value: GenderRequestedEnum.FemalePreferred, label: 'Female Preferred' },
      { value: GenderRequestedEnum.FemaleOnly, label: 'Female Only' },
    ];
  }, []);

  const savedSpaOptions = useMemo(() => {
    const options = [];
    (clientsList || []).forEach((client) => {
      (client.addresses || []).forEach((address) => {
        options.push({
          value: `${client.id}.${address.id}`,
          label: `${client.name} (${address.address1})`,
        });
      });
    });
    if (!edit) {
      options.push({
        value: '__add__',
        label: '+ Add New Spa Location',
      });
    }
    return options;
  }, [clientsList, edit]);

  const timeOptions = useMemo<{ value: string; label: string }[]>(() => {
    const allTimes = [];
    (timesForDate?.hours || []).forEach((h) => {
      (h?.minutes || []).forEach((m) => {
        allTimes.push({
          label: `${h.hour + ''}${m.label}${h.full_hour < 12 ? 'am' : 'pm'}`,
          value: m.id,
        });
      });
    });

    return allTimes;
  }, [timesForDate]);

  const requestSummaryText = useMemo(() => {
    const _quantity = form.getValue('quantity');
    const _duration = (form.getValue('duration') || 0) / 60;
    const _totalHours = _quantity * _duration;
    return `${_quantity} Therapist${_quantity > 1 ? 's' : ''} x ${_duration} Hour${
      _duration > 1 ? 's' : ''
    } = ${_totalHours} Total Hour${_totalHours > 1 ? 's' : ''}`;
  }, [form]);

  const requestSummaryVisible = useMemo(() => {
    const { duration, quantity } = form.getValues();
    return !!duration && !!quantity;
  }, [form]);

  return (
    <SkeletonProvider loading={isLoading}>
      <Body size={fullSize ? 'full' : 'narrow'}>
        <FieldTitle
          {...(hasSpasOnFile && !edit
            ? {
                contentRight: (
                  <EditPencilBoldIcon
                    className={styles.editLocationIcon}
                    size={24}
                    onClick={navigateToManageLocations}
                  />
                ),
              }
            : {})}
        >
          Location
        </FieldTitle>
        {hasSpasOnFile && (
          <Select
            value={
              selectedSpa?.clientId && selectedSpa?.locationId
                ? `${selectedSpa.clientId}.${selectedSpa.locationId}`
                : null
            }
            items={savedSpaOptions}
            onChange={handleOnSavedLocationChange}
            hideValidationSpaceWhenEmpty
          />
        )}
        {!hasSpasOnFile && (
          <SkeletonCover>
            <div
              className={clsx(styles.newLocationCard, {
                [styles['newLocationCard--none']]: !collectedInformation.address,
              })}
              onClick={handleOnCollectedLocationChange}
            >
              {!collectedInformation.address && 'No location specified'}
              {collectedInformation.address &&
                [
                  collectedInformation.address.address1,
                  collectedInformation.address.address2,
                  collectedInformation.address.city,
                  collectedInformation.address.state,
                  collectedInformation.address.zip,
                ]
                  .filter((a) => a)
                  .join(', ')}
              <EditPencilBoldIcon className={styles.editIcon} size={20} />
            </div>
          </SkeletonCover>
        )}

        <FieldTitle marginTop>Date & Time</FieldTitle>
        <FieldRow>
          <InputDate
            {...form.getProps('date')}
            showCalendar
            minDate={moment().format('MM/DD/YYYY')}
            calendarClassName={styles.calendar}
            placeholder='MM / DD / YYYY'
            hideValidation
          />
        </FieldRow>
        <FieldRow>
          <Select {...form.getProps('time')} placeholder={`Time`} items={timeOptions} />
          <Select {...form.getProps('duration')} placeholder={`Duration`} items={durationOptions} />
        </FieldRow>

        <FieldTitle>Therapist Preferences</FieldTitle>
        <FieldRow>
          <Select
            {...form.getProps('quantity')}
            hideValidationSpaceWhenEmpty
            placeholder={`# of Therapists`}
            items={quantityOptions}
          />
          <Select
            {...form.getProps('gender')}
            hideValidationSpaceWhenEmpty
            placeholder={`Therapist Gender`}
            items={genderPreferenceOptions}
          />
        </FieldRow>
        <FieldRow>
          <Input
            {...form.getProps('notes')}
            required={false}
            multiline
            rows={2}
            placeholder={`Notes (Optional)`}
            hideValidationSpaceWhenEmpty
            subText={
              <>
                <span style={{ opacity: 0.7 }}>Spa must provide all necessary materials</span>
                <InformationIcon style={{ top: 5, marginLeft: 4 }} size={18} onClick={() => openSpaMaterialsModal()} />
              </>
            }
          />
        </FieldRow>
        <div className={clsx(styles.requestSummary, { [styles['requestSummary--hidden']]: !requestSummaryVisible })}>
          <FieldTitle marginTop>Request</FieldTitle>
          <div className={styles.lineItem}>{requestSummaryText}</div>
        </div>
      </Body>
      <Footer
        actions={[
          addingAnimation
            ? {
                type: 'button',
                label: edit ? 'Saved' : 'Added',
                onClick: () => true,
                componentProps: { className: styles.addedButton },
              }
            : {
                type: 'button',
                label: edit ? 'Save' : 'Add to Request',
                onClick: handleAddRequest,
                componentProps: {
                  loading: fetchingPrice,
                  disabled: !isDraftRequestValid || addingAnimation || fetchingPrice,
                },
              },
        ]}
      />
    </SkeletonProvider>
  );
};

export default ConfigureRequestForm;
