import type { Reducer } from 'redux';

import { authReducer, authSlice } from './auth';
import { estoreFlowReducer, estoreFlowSlice } from './estoreFlow';
import { giftFlowReducer, giftFlowSlice } from './giftFlow';
import { membershipFlowReducer, membershipFlowSlice } from './membershipFlow';
import { personalWellnessFlowReducer, personalWellnessFlowSlice } from './personalWellnessFlow';
import { spaIndividualReducer, spaIndividualSlice } from './spa/individual';
import { spaSelfServeFlowReducer, spaSelfServeFlowSlice } from './spa/selfServe';
import { spaBlockProjectReducer, spaBlockProjectSlice } from './spa/spaBlock';
import { vaBookingFlowReducer, vaBookingFlowSlice } from './va/bookingFlow';
import { vaConsentFlowReducer, vaConsentFlowSlice } from './va/consentFlow';
import { vaEvaluationFlowReducer, vaEvaluationFlowSlice } from './va/evaluationFlow';

/**
 * Contains the slice of all features of the app.
 * NOTE - When you are creating a new feature, and create a new
 *        slice, make sure to add it here, otherwise it will not
 *        be loaded in the root state.
 */

export const allFeatures = {
  [authSlice.name]: authReducer,
  [personalWellnessFlowSlice.name]: personalWellnessFlowReducer,
  [membershipFlowSlice.name]: membershipFlowReducer,
  [giftFlowSlice.name]: giftFlowReducer,
  [estoreFlowSlice.name]: estoreFlowReducer,
  [vaBookingFlowSlice.name]: vaBookingFlowReducer,
  [vaConsentFlowSlice.name]: vaConsentFlowReducer,
  [vaEvaluationFlowSlice.name]: vaEvaluationFlowReducer,
  [spaBlockProjectSlice.name]: spaBlockProjectReducer,
  [spaIndividualSlice.name]: spaIndividualReducer,
  [spaSelfServeFlowSlice.name]: spaSelfServeFlowReducer,
};
export const featureReducers = Object.entries(allFeatures).reduce<{ [path: string]: Reducer }>(
  (acc, [curKey, curVal]) => {
    acc[curKey] = curVal;
    return acc;
  },
  {}
);
