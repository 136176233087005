import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonProvider } from '@zeel-dev/zeel-ui';
import { ProgressBar } from 'components/common';

import BaseLayout, { BaseLayoutProps } from '../BaseLayout';
import { NavigationProps } from '../Navigation';
import styles from './style.module.scss';

export type FlowLayoutProps = BaseLayoutProps & {
  className?: string;
  flowClassName?: string;
  stepContainerClassName?: string;
  overlayLoading?: boolean;
  error?: any;
  full?: boolean;
  ultraWide?: boolean;
  wide?: boolean;
  small?: boolean;
  narrow?: boolean;
  step?: string;
  navProps?: Partial<NavigationProps>;
  steps?: {
    id: string;
    title: string;
    action: string;
    activeFor?: string[];
    disabled?: boolean;
    hidden?: boolean;
  }[];
  children?: ReactNode;
  backAction?: () => void;
  skeleton?: boolean;
  testId?: string;
};

const FlowLayout: FC<FlowLayoutProps> = ({
  overlayLoading,
  error,
  full,
  ultraWide,
  wide,
  small,
  narrow,
  step,
  steps = [],
  children,
  stepContainerClassName,
  flowClassName,
  className,
  navProps,
  skeleton,
  backAction,
  testId,
  ...rest
}) => {
  const rebuiltSteps = (steps || []).map((s) => ({
    ...s,
    title: s.title,
    disabled: s.disabled,
    hidden: s.hidden,
    current: (s.activeFor && s.activeFor.includes(step)) || step === s.id,
  }));
  const progressBar =
    steps && steps.length > 0 ? <ProgressBar data={rebuiltSteps} className={stepContainerClassName} /> : null;

  return (
    <BaseLayout
      {...rest}
      className={clsx(styles.layout, className)}
      navProps={{ progressBar, backAction, gated: true, ...(navProps || {}) }}
      footer={false}
      data-testid={testId ?? `flow-layout`}
    >
      {error && (
        <div className={styles.errorBox} data-testid={`${testId ?? `flow-layout`}--error-message-div`}>
          {error}
        </div>
      )}
      <SkeletonProvider loading={skeleton}>
        <div
          className={clsx(styles.flowContainer, flowClassName, {
            [styles['flowContainer--full']]: full,
            [styles['flowContainer--ultraWide']]: ultraWide,
            [styles['flowContainer--wide']]: wide,
            [styles['flowContainer--narrow']]: narrow,
            [styles['flowContainer--small']]: small,
          })}
        >
          {!overlayLoading && children}
        </div>
      </SkeletonProvider>
    </BaseLayout>
  );
};

export default FlowLayout;
