import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './style.module.scss';

const Footer: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => {
  return <div className={clsx(styles.base, className)}>{children}</div>;
};

export default Footer;
