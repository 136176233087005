import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  icon?: string;
  description?: ReactNode;
  buttonText?: string;
  title?: string;
  slimPadding?: boolean;
};

const Info: FC<Props> = ({ buttonText = 'OK', testId, slimPadding, ...rest }) => {
  return (
    <Modal
      {...rest}
      mobileType={'drawer'}
      className={clsx(styles.modal, { [styles.slimBase]: slimPadding }, rest.className)}
      actions={[
        {
          action: 'confirm',
          main: true,
          label: buttonText,
        },
      ]}
      testId={testId ?? 'info-modal'}
      {...(slimPadding
        ? {
            iconsBarClassName: styles['slim-icons-bar'],
            headerClassName: styles['slim-header'],
            bodyClassName: styles['slim-body'],
            footerClassName: styles['slim-footer'],
          }
        : {})}
    />
  );
};

export default Info;
