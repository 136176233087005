import { FC } from 'react';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

type Props = ModalProps & {
  tables: number;
  isStretch?: boolean;
};

const MassageTable: FC<Props> = ({ tables, isStretch, ...rest }) => {
  return (
    <Modal
      {...rest}
      title={`You have ${tables} massage table${tables > 1 ? 's' : ''}`}
      mobileType={'drawer'}
      actions={[
        {
          action: 'confirm',
          label: 'Confirm',
        },
      ]}
      testId='massage-table-modal'>
      Great! Since you have {tables} massage table{tables > 1 ? 's' : ''} at your location,{' '}
      {isStretch
        ? 'the stretch pro will stretch you on a table instead of a yoga mat.'
        : 'the therapist will not need to bring a table to your appointment.'}
    </Modal>
  );
};

export default MassageTable;
