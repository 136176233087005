import { FC, useCallback } from 'react';

import clsx from 'clsx';

import { useForm, usePage } from 'utils/hooks';

import { useLazyGetPromoIssuerQuery } from 'redux/apis/OG/user';

import { useModals } from '@zeel-dev/zeel-ui';
import { Button, FieldRow, Image, Input } from 'components/common';
import ModalPromosSuccess from 'components/modals/items/PromosSuccess';
import Modal, { ModalProps } from 'components/modals/templates/Modal';

import styles from './style.module.scss';

type Props = ModalProps & {
  onPromoCallback?: Function;
  code?: string;
};

const PromosAdd: FC<Props> = ({ onPromoCallback, code, ...rest }) => {
  const form = useForm();
  const { openModal, closeAllModals } = useModals();
  const { isLoading } = usePage(() => {
    if (code) {
      form.setValue('code', code);
    }
  });
  const [getPromoIssuer] = useLazyGetPromoIssuerQuery();

  const proceed = useCallback(async () => {
    const _code = form.getValue('code');

    // get promo type
    let promoType = null;
    try {
      const issuer = await getPromoIssuer(_code).unwrap(); // returns promo16, promo19 or gift
      promoType = issuer;
    } catch (errors: any) {
      if (errors?.promotion_code) {
        form.setError('code', errors.promotion_code);
      }
      return;
    }

    switch (promoType) {
      case 'gift':
        try {
          await onPromoCallback();
          closeAllModals();
          openModal({ element: <ModalPromosSuccess /> });
        } catch (errors: any) {
          if (errors?.promotion_code) {
            form.setError('code', errors.promotion_code);
          }
        }
        break;
      case 'promo16':
        form.setError('code', 'Blackhawk cards are no longer supported');
        break;

      case 'promo19':
        form.setError('code', 'Spafinder cards are no longer supported');
        break;

      default:
        return true;
    }
  }, [form]);

  return (
    <Modal {...rest} loading={isLoading} mobileStyle='fullscreen' testId='promos-add-modal'>
      {!isLoading && (
        <div className={styles.insideModal}>
          <div className={clsx(styles.modalHeader, styles.customHeader)}>
            <>
              <Image src='icons/promo.svg' className={styles.icon} />
              <div className={styles.title} data-testid='promos-add-modal--title'>
                Add Gift Card / Promo
              </div>
            </>
          </div>

          <div className={clsx(styles.modalBodyWrapper, styles.customBody)}>
            <div className={`${styles.modalBody} ${styles.expandTop}`}>
              <FieldRow>
                <Input
                  {...form.getProps('code')}
                  label='Enter Card Number or Code'
                  testId='promos-add-modal--code-input'
                />
              </FieldRow>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <Button
              className={styles.button}
              type='primary'
              disabled={!form.isValid()}
              onClick={() => proceed()}
              testId='promos-add-modal--main-button'
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PromosAdd;
