import { FC, KeyboardEvent, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { Button, LinkButton, Pill } from 'components/common';
import { ButtonProps } from 'components/common/Button';
import { LinkButtonProps } from 'components/common/LinkButton';

import styles from './style.module.scss';

type Props = {
  id?: string;
  topper?: string;
  title: string;
  titleLegend?: string;
  description?: string;
  content?: ReactNode;
  imageContent?: ReactNode;
  action?: ActionData;
  subAction?: ActionData;
  contentUnderActions?: ReactNode;
  alignContent?: string;
  image?: string;
  images?: Array<string>;
  parallax?: boolean;
  parallaxRange?: Array<number>;
  parallaxOffset?: number;
  greenery?: boolean;
  pills?: Array<string>;
  className?: string;
  classes?: Classes;
  useAsBackgroundImage?: boolean;
  testId?: string;
};

type Classes = {
  content?: string;
  boxContent?: string;
  image?: string;
  imageContainer?: string;
  info?: string;
  actionContainer?: string;
};

type ActionData = {
  actionType: string;
  linkPrefix?: string;
  linkPrefixClassName?: string;
  text: string;
  buttonType?: string;
  onClick?: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
  href?: string;
  actionProps?: ButtonProps & LinkButtonProps;
  subContent?: ReactNode;
  className?: string;
};

const InfoBlock: FC<Props> = ({
  id,
  topper,
  title,
  titleLegend,
  description,
  content,
  imageContent,
  action,
  subAction,
  contentUnderActions,
  alignContent = 'left',
  image,
  images,
  className,
  parallax,
  greenery,
  pills,
  classes = {},
  useAsBackgroundImage = false,
  parallaxRange = [-20, 20],
  parallaxOffset = 100,
  testId = 'info-block',
}) => {
  const [imageHeight, setImageHeight] = useState<any>(null);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const ref = useRef<any>(null);
  const imageRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkIfMobile();

    if (parallax) {
      window.addEventListener('scroll', handleScroll, { capture: true, passive: true });
      window.addEventListener('resize', checkIfMobile);
    }
    window.addEventListener('resize', fitImageHeight);
    setTimeout(fitImageHeight, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkIfMobile);
      window.removeEventListener('resize', fitImageHeight);
    };
  }, []);

  const checkIfMobile = useCallback(() => {
    // if inline styles were set, remove as mobile does not animate
    if (helpers.isMobileWidth() && ref.current && parallax) {
      ref.current.style.removeProperty('top');
    }
  }, []);

  const fitImageHeight = () => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.height);
    }
  };

  const handleScroll = useCallback(() => {
    if (ref.current && !helpers.isMobileWidth() && parallax) {
      const greeneryHeight = ref.current?.height || 0;
      const mainImageHeight = imageRef?.current?.height || 0;
      const yRange = Math.abs(parallaxRange[0]) + Math.abs(parallaxRange[1]) + (mainImageHeight - greeneryHeight);

      const rect = ref.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const visible = rect.top - windowHeight - yRange / 2 < 0 && rect.bottom - yRange / 2 > 0;
      const interpolation = Math.min(
        1,
        Math.max(
          0,
          ((rect.top - windowHeight + parallaxOffset) * -1 * 1) /
            (windowHeight + rect.height - parallaxOffset * 2 - 108)
        )
      ); // 108 is the navbar height
      const interpolatedVal = parallaxRange[0] + yRange * interpolation;

      if (visible && !helpers.isMobileWidth()) {
        ref.current.style.top = `${interpolatedVal}px`;
      }
    }
  }, []);

  const getActionElement = (
    {
      actionType = 'button',
      actionProps = {},
      linkPrefix,
      linkPrefixClassName,
      text,
      buttonType = 'primary',
      onClick = null,
      href = null,
      subContent = null,
      className: _className = null,
    }: ActionData,
    isSub?: boolean
  ) => {
    let element: ReactNode = null;
    if (actionType === 'button') {
      element = (
        <Button
          type={buttonType}
          className={clsx(styles.actionButton, _className)}
          onClick={href ? () => navigate(href) : onClick}
          role='link'
          {...actionProps}
          testId={`${testId}--${isSub ? 'sub-' : ''}action-button`}
        >
          {text}
        </Button>
      );
    } else if (actionType === 'link') {
      element = linkPrefix ? (
        <div className={styles.linkWithInlinePrefix}>
          <p className={linkPrefixClassName}>{linkPrefix}</p>
          <LinkButton
            className={clsx(styles.actionLink, styles['actionLink--inline'], _className)}
            arrow
            href={href}
            onClick={onClick as any}
            {...actionProps}
            testId={`${testId}--${isSub ? 'sub-' : ''}action-link`}
          >
            {text}
          </LinkButton>
        </div>
      ) : (
        <LinkButton
          className={clsx(styles.actionLink, _className)}
          arrow
          href={href}
          onClick={onClick as any}
          {...actionProps}
          testId={`${testId}--${isSub ? 'sub-' : ''}action-link`}
        >
          {text}
        </LinkButton>
      );
    }
    return (
      <>
        {element}
        {subContent && <div className={styles.actionSubContent}>{subContent}</div>}
      </>
    );
  };

  const getImagePicker = useCallback(
    (forMobile = false) => {
      return (
        (images || []).length > 0 && (
          <div
            className={clsx(styles.imageTileContainer, {
              [styles['imageTileContainer--desktop']]: !forMobile,
              [styles['imageTileContainer--mobile']]: forMobile,
            })}
          >
            {(images || []).map((photo, i) => {
              const tileImageUrl = helpers.getImagePath(photo);
              return (
                <div
                  key={i}
                  className={clsx(styles.imageTile, { [styles['imageTile--active']]: activeImageIndex === i })}
                  style={{ backgroundImage: `url(${tileImageUrl})` }}
                  onClick={() => setActiveImageIndex(i)}
                />
              );
            })}
          </div>
        )
      );
    },
    [activeImageIndex, images]
  );

  const {
    content: contentClass,
    boxContent: boxContentClass,
    image: imageClass,
    imageContainer: imageContainerClass,
    info: infoClass,
    actionContainer: actionContainerClass,
  } = classes;
  let imageSrc = null;
  if (images && images[activeImageIndex]) {
    imageSrc = helpers.getImagePath(images[activeImageIndex]);
  } else if (image) {
    imageSrc = helpers.getImagePath(image);
  }

  return (
    <div id={id} className={clsx(styles.block, className)} data-testid={testId}>
      {/* Content */}
      <div className={clsx(styles.content, { [styles['content-alignRight']]: alignContent === 'right' }, contentClass)}>
        <div className={clsx(styles.photosContainer, imageContainerClass)}>
          {!useAsBackgroundImage && (
            <>
              <img className={clsx(styles.hiddenSpacing, imageClass)} src={imageSrc} alt='' />
              {(parallax || greenery) && (
                <img
                  className={styles.backgroundPhoto}
                  src={helpers.getImagePath('greenery/greenery-square.png')}
                  ref={(r) => (ref.current = r)}
                  alt='greenery'
                />
              )}
              <img
                ref={(r) => (imageRef.current = r)}
                className={clsx(styles.mainPhoto, imageClass)}
                src={imageSrc}
                alt=''
              />
              {imageContent && (
                <div className={styles.photoContent} style={{ height: `${imageHeight}px` }}>
                  {imageContent}
                </div>
              )}
            </>
          )}
        </div>
        <div className={clsx(styles.infoBox, infoClass)}>
          {getImagePicker(true)}
          {topper && (
            <p className={styles.topper} data-testid={`${testId}--topper`}>
              {topper}
            </p>
          )}
          <h2 className={styles.title} data-testid={`${testId}--title`}>
            {title}
          </h2>
          {!!pills && (
            <div className={styles.pillsContainer}>
              {(pills || []).map((pill) => (
                <Pill key={pill} label={pill} margin />
              ))}
            </div>
          )}
          {titleLegend && (
            <p className={styles.titleLegend} data-testid={`${testId}--title-legend`}>
              {titleLegend}
            </p>
          )}
          {description && (
            <div className={styles.description} data-testid={`${testId}--description`}>
              {description}
            </div>
          )}
          {content && <div className={clsx(styles.boxContent, boxContentClass)}>{content}</div>}
          <div className={clsx(styles.actionContainer, actionContainerClass)}>
            {action && getActionElement(action)}
            {subAction && getActionElement(subAction, true)}
            {contentUnderActions}
          </div>
          {getImagePicker()}
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
