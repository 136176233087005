import { FC, useCallback, useState } from 'react';

import clsx from 'clsx';

import { IconSvg } from 'components/common';

import CoreBlock from '../CoreBlock';
import { PTFAQBlockProps } from './PTFAQBlock.types';
import PTFAQItem from './components/PTFAQItem';
import styles from './style.module.scss';

const PTFAQBlock: FC<PTFAQBlockProps> = ({ className, title, data, testId = 'phyical-therapy-faq-block' }) => {
  const [active, setActive] = useState(-1);
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback((index: number) => {
    setActive((prevActive) => (prevActive === index ? -1 : index));
  }, []);

  return (
    <CoreBlock
      className={clsx(styles.faqSection, className, { [styles.isVisible]: visible })}
      contentClassName={styles['faqSection-content']}
      testId={testId}>
      <h2 data-testid={`${testId}--title`}>{title ?? 'Frequently Asked Questions'}</h2>
      <ul>
        {data.map((item, index) => (
          <li key={`faq-item-${index}`}>
            <PTFAQItem item={item} collapsed={active === index} onClick={() => handleClick(index)} />
          </li>
        ))}
      </ul>
      <div className={styles.collapsibleContainer}>
        <IconSvg
          name='caret-down'
          className={styles.arrowIcon}
          onClick={() => setVisible(!visible)}
          testId={`${testId}--collapse-icon`}
        />
      </div>
    </CoreBlock>
  );
};

export default PTFAQBlock;
