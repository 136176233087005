import { ReactNode } from 'react';

import { Image, Link } from 'components/common';

import styles from './style.module.scss';

type Props = {
  title: string;
  description: ReactNode;
  image?: string;
  imageUrl?: string;
};

const Detail = (props: Props) => {
  const { title, description, image, imageUrl } = props;
  return (
    <div className={styles.detail}>
      {image &&
        (imageUrl ? (
          <Link href={imageUrl}>
            <Image className={styles.image} src={image} />
          </Link>
        ) : (
          <Image className={styles.image} src={image} />
        ))}
      <h5>{title}</h5>
      <p className={styles.content}>{description}</p>
    </div>
  );
};

export default Detail;
