import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { routes } from 'utils';
import { withErrorBoundary } from 'utils/hoc';
import { usePage, useUser } from 'utils/hooks';
import { ThemeProvider, themes } from 'utils/theme-context';

import { FlowParams, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { useNavigate } from '@zeel-dev/zeel-ui';
import RequireAuth from 'components/routing/RequireAuth';

import { useGetClientListQuery, useSubmitSelfServeFlowEventMutation } from '../../redux/apis/OG/spa';
import Checkout from './sections/Checkout';
import Intro from './sections/Intro';
import LocationInfo from './sections/LocationInfo';
import Locations from './sections/Locations';
import Requests from './sections/Requests';

/**
 * Main component for the SPA Self Serve flow.
 * Handles the routing for the different sections of the flow.
 *
 * Flow pages:
 * - Intro: allows the user to sign in or creates a temp session. Only shown if the user is not authenticated, and
 * - Requests: allows the user to create requests and add them to the cart
 * - Locations: allows the user to manage their saved locations
 * - Location Info: allows the user to add or modify a location. For new user, this is where their 1st spa will be created and assigned to the items in their cart
 * - Checkout: allows the user to review their cart and complete the booking
 *
 * How to access the flow:
 * Navigate to the SPA_SELF_SERVE.INTRO route.
 * If the user is not authenticated, they will be redirected to the intro page.
 *
 * Flow parameters:
 * - preselectedSpa: { clientId, locationId } - the spa to use as default when accessing the requests page (Optional)
 *
 * Flow sequence order:
 * For new users: Intro -> Requests -> Location Info -> Checkout
 * For existing users: Requests -> Checkout (can also access Locations page to manage their saved locations)
 *
 * Data store:
 * Uses the spa/selfServe slice to manage the flow state.
 */
const SpaSelfServe: FC = () => {
  const navigate = useNavigate();
  const {
    actions: { defaultSpaChanged },
    selectors: { useRequestsSelector },
  } = useSpaSelfServeFlowSlice();
  const requests = useRequestsSelector();
  const location = useLocation();

  const [sanitized, setSanitized] = useState<boolean>(false);

  usePage(async () => {
    const { preselectedSpa } = (location?.state || {}) as FlowParams;
    if (preselectedSpa) defaultSpaChanged(preselectedSpa);
  });

  const { isAuthenticated, isLoading: isUserLoading, isTempMember } = useUser();
  const {
    actions: { requestsSanitized },
  } = useSpaSelfServeFlowSlice();

  /** Queries **/
  const { data: clientsList, isLoading: isClientsListLoading } = useGetClientListQuery(
    {
      bookingType: 'spa_self_serve',
    },
    {
      skip: !isAuthenticated || isUserLoading || isTempMember,
    }
  );

  usePage(async ({ _isSpa, _isSpaFullService }) => {
    if (_isSpa && _isSpaFullService) {
      return navigate(routes.index({ host: true }));
    }
  });

  /** Effects **/
  // Sanitize requests to only include those with valid spa data
  useEffect(() => {
    if (isClientsListLoading || isUserLoading || sanitized) return;
    if ((requests || []).length > 0) {
      const sanitizedRequests = requests.filter((r) => {
        if (r.spa) {
          const client = (clientsList || []).find((c) => `${c.id}` == `${r.spa?.clientId || ''}`);
          const _location = client?.addresses?.find((a) => `${a.id}` == `${r.spa?.locationId || ''}`);
          return !!client && !!_location;
        }
        return true;
      });
      requestsSanitized(sanitizedRequests);
      setSanitized(true);
    }
  }, [clientsList]);

  // Memoized auth route props
  const authRouteProps = useMemo(
    () => ({
      redirectTo: routes.SPA_SELF_SERVE.INTRO(),
      allowTemp: true,
      omitReferrer: true,
    }),
    []
  );

  return (
    <ThemeProvider value={themes.atWork}>
      <Routes>
        <Route path={routes.SPA_SELF_SERVE.INTRO({ leaf: true })} element={<Intro />} />
        <Route
          path={routes.SPA_SELF_SERVE.REQUESTS({ leaf: true })}
          element={
            <RequireAuth {...authRouteProps}>
              <Requests />
            </RequireAuth>
          }
        />
        <Route
          path={routes.SPA_SELF_SERVE.LOCATIONS({ leaf: true })}
          element={
            <RequireAuth {...authRouteProps}>
              <Locations />
            </RequireAuth>
          }
        />
        <Route
          path={routes.SPA_SELF_SERVE.LOCATION_INFO({ leaf: true })}
          element={
            <RequireAuth {...authRouteProps}>
              <LocationInfo />
            </RequireAuth>
          }
        />
        <Route
          path={routes.SPA_SELF_SERVE.CHECKOUT({ leaf: true })}
          element={
            <RequireAuth {...authRouteProps} allowTemp={false}>
              <Checkout />
            </RequireAuth>
          }
        />
        <Route path={'/*'} element={<Navigate to={routes.SPA_SELF_SERVE.INTRO()} replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default withErrorBoundary(SpaSelfServe);

export const useSpaSelfServeEvent = () => {
  const {
    selectors: { useEmailSelector, useRequestsSelector, useCollectedInformationSelector, useBookingPayloadSelector },
  } = useSpaSelfServeFlowSlice();
  const [logEvent] = useSubmitSelfServeFlowEventMutation();

  const email = useEmailSelector();
  const requests = useRequestsSelector();
  const collectedInformation = useCollectedInformationSelector();
  const bookingPayload = useBookingPayloadSelector();

  const logEventHandler = useCallback(
    async (payload?: Record<any, any>) => {
      try {
        await logEvent({
          lead_source: 'Self-Serve Booking Flow',
          industry: 'Hospitality',
          first_name: 'Self-Serve Spa',
          last_name: 'LNU',
          ...payload,
        }).unwrap();
      } catch (error) {
        console.error('Error logging event', error);
      }
    },
    [logEvent, email, requests, collectedInformation, bookingPayload]
  );

  return {
    logEvent: logEventHandler,
  };
};
