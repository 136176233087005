import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useRollbar } from '@zeel-dev/zeel-ui';
import { Button, Loader } from 'components/common';

import Body from '../../../../components/Body';
import Footer from '../../../../components/Footer';
import { usePMCContext } from '../../../../context';
import { CreditCardProcessorProps } from '../../index';
import styles from './style.module.scss';

const DEVMODE_PAYMENT_INFO = {
  cardNumber: '5454545454545454',
  expiryDate: '12/2025',
  cvv: '999',
  label: 'Master card',
  zipCode: '10005',
};

const FORTIS_CONTAINER_NODE_ID = 'fortis-sdk-payment-form';

const FortisProcessor: FC<CreditCardProcessorProps> = ({ mutate, buttonText }) => {
  const { clientToken, collectionType, devMode, fail } = usePMCContext();
  const rollbar = useRollbar();

  // If the fortis sdk is ready to be rendered
  const [authorizedAndReady, setAuthorizedAndReady] = useState<boolean>();

  // States
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [mutationEventData, setMutationEventData] = useState<any>();
  const [mutating, setMutating] = useState<boolean>(false);
  const [, /*hasValidationErrors*/ setHasValidationErrors] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | null>(null);

  // Reference to the fortis elements instance
  const elements = useRef<any>(new (window as any).Commerce.elements(clientToken));

  useEffect(() => {
    // Create fortis elements container
    if (devMode) console.log('DEVMODE_PAYMENT_INFO', DEVMODE_PAYMENT_INFO);
    try {
      elements.current?.create({
        container: `#${FORTIS_CONTAINER_NODE_ID}`,
        theme: 'default',
        environment: devMode ? 'sandbox' : 'production',
        floatingLabels: false,
        showSubmitButton: false,
        showValidationAnimation: true,
        showReceipt: false,
        fields: {
          custom: [
            {
              type: 'text',
              name: 'card_label',
              label: 'Card Label',
              required: false,
            },
          ],
          billing: [{ name: 'postal_code', required: true }],
        },
        digitalWallets: [],
        appearance: {
          colorButtonSelectedBackground: '#363636',
          colorButtonSelectedText: '#ffffff',
          colorButtonActionBackground: '#00d1b2',
          colorButtonActionText: '#ffffff',
          colorButtonBackground: '#ffffff',
          colorButtonText: '#363636',
          colorFieldBackground: '#ffffff',
          colorFieldBorder: '#dbdbdb',
          colorText: '#4a4a4a',
          colorLink: '#485fc7',
          fontSize: '16px',
          marginSpacing: '-20px',
          borderRadius: '12px',
          trimWhitespace: 'false',
          rowMarginSpacing: '-20px',
        },
      });
    } catch (e: any) {
      console.error(e);
      rollbar.error(`PMC - Fortis Element Create Exception`, e);
    }
    if (!elements.current) {
      rollbar.error(`PMC - Fortis Element missing after creation`);
      fail('Fortis elements could not be created');
      return;
    }

    // Register fortis event callbacks
    registerCallbacks();
  }, []);

  const onReady = useCallback(() => {
    setAuthorizedAndReady(true);
  }, []);

  const onSubmitted = useCallback(() => {
    setHasValidationErrors(false);
    setSubmitting(true);
  }, []);

  const onValidationError = useCallback(() => {
    setSubmitting(false);
    setHasValidationErrors(true);
  }, []);

  const onDone = useCallback(
    async (event?: any) => {
      if (mutating) return;
      setSubmitting(false);
      setMutationEventData(event);
    },
    [mutate, mutating]
  );

  const handleMutation = useCallback(async () => {
    if (mutating || !mutationEventData) return;
    setHasValidationErrors(false);
    setLocalError(null);
    const mutationPayload = {
      collectionType,
      data: {
        label: mutationEventData?.data?.custom_data?.['card_label'] || '',
        transactionId: mutationEventData?.data?.id as string,
      },
    };
    const { errors } = await mutate(mutationPayload);
    if (errors) {
      rollbar.error(`PMC - Fortis Payment Method Mutation Api Error`, errors);
      console.error('Fortis Error:', errors);
      let errorMessage = 'An error occurred, please try again';
      if (errors.SDK_ERROR_CODE) {
        // TODO...
      } else {
        errorMessage = Object.values(errors || {}).join('\n');
      }
      setLocalError(errorMessage);
    }
    setMutating(false);
  }, [mutate, mutationEventData]);

  useEffect(() => {
    if (submitting || mutating || !mutationEventData || localError) return;
    setMutating(true);
    handleMutation();
  }, [mutationEventData, submitting, mutating, handleMutation, localError]);

  const onTokenExpired = useCallback(
    (errors: any) => {
      console.error(errors);
      rollbar.error(`PMC - Fortis Token Expired`, errors);
      fail('An error occurred, please try again');
    },
    [fail]
  );

  const onError = useCallback(
    (errors: any) => {
      console.error(errors);
      rollbar.error(`PMC - Fortis Error`, errors);
      fail('An error occurred, please try again');
    },
    [fail]
  );

  const registerCallbacks = useCallback(() => {
    if (!elements.current) return;
    elements.current.on('ready', onReady);
    elements.current.on('submitted', onSubmitted);
    elements.current.on('validationError', onValidationError);
    elements.current.on('done', onDone);
    elements.current.on('tokenExpired', onTokenExpired);
    elements.current.on('error', onError);
  }, [onReady, onSubmitted, onValidationError, onDone, onTokenExpired, onError]);
  useEffect(() => registerCallbacks(), [registerCallbacks]);

  return (
    <>
      <Loader loading={!authorizedAndReady} />
      <Body error={localError}>
        <div id={FORTIS_CONTAINER_NODE_ID} className={styles.fortis} />
      </Body>

      <Footer className={styles.fortisFooter}>
        <Button
          onClick={() => elements.current?.submit()}
          loading={submitting || mutating}
          disabled={!authorizedAndReady || !!localError}
          testId={'pmc-payment-button'}
        >
          {buttonText}
        </Button>
      </Footer>
    </>
  );
};

export default FortisProcessor;
