import * as D from 'io-ts/Decoder';

// Referral Status
export enum ReferralStatusEnum {
  Active = 'active',
  Expired = 'expired',
  Revoked = 'revoked',
  Completed = 'completed',
}
export const ReferralStatusDecoder = D.literal(
  ReferralStatusEnum.Active,
  ReferralStatusEnum.Expired,
  ReferralStatusEnum.Revoked,
  ReferralStatusEnum.Completed
);
export type ReferralStatusType = D.TypeOf<typeof ReferralStatusDecoder>;

// Gender
export enum GenderEnum {
  Male = 'm',
  Female = 'f',
  NonBinary = 'nb',
}
export const GenderDecoder = D.literal(GenderEnum.Male, GenderEnum.Female, GenderEnum.NonBinary);
export type GenderType = D.TypeOf<typeof GenderDecoder>;

// Quantity
export const SourceQuantityDecoder = D.partial({
  value: D.number,
  available: D.number,
  completed: D.nullable(D.number),
  planned: D.nullable(D.number),
});
export type SourceQuantityType = D.TypeOf<typeof SourceQuantityDecoder>;
export const QuantityDecoder = D.partial({
  value: D.number,
  available: D.number,
  completed: D.nullable(D.number),
  planned: D.nullable(D.number),
});
export type QuantityType = D.TypeOf<typeof QuantityDecoder>;

// Reason Reference
export const SourceReasonReferenceDecoder = D.partial({
  id: D.nullable(D.string),
  description: D.nullable(D.string),
  url: D.nullable(D.string),
  type: D.nullable(D.string),
});
export type SourceReasonReferenceType = D.TypeOf<typeof SourceReasonReferenceDecoder>;
export const ReasonReferenceDecoder = D.partial({
  id: D.nullable(D.string),
  description: D.nullable(D.string),
  url: D.nullable(D.string),
  type: D.nullable(D.string),
});
export type ReasonReferenceType = D.TypeOf<typeof ReasonReferenceDecoder>;

// Subject
export const SourceSubjectDecoder = D.partial({ reference: D.string, type: D.string });
export type SourceSubjectType = D.TypeOf<typeof SourceSubjectDecoder>;
export const SubjectDecoder = D.partial({ reference: D.string, type: D.string });
export type SubjectType = D.TypeOf<typeof SubjectDecoder>;

// Occurrence Period
export const SourceOccurrencePeriodDecoder = D.partial({ start: D.nullable(D.string), end: D.nullable(D.string) });
export type SourceOccurrencePeriodType = D.TypeOf<typeof SourceOccurrencePeriodDecoder>;
export const OccurrencePeriodDecoder = D.partial({ start: D.nullable(D.string), end: D.nullable(D.string) });
export type OccurrencePeriodType = D.TypeOf<typeof OccurrencePeriodDecoder>;

// Supporting Info
export const SourceSupportingInfoDecoder = D.partial({
  description: D.nullable(D.string),
  url: D.nullable(D.string),
  type: D.nullable(D.string),
});
export type SourceSupportingInfoType = D.TypeOf<typeof SourceSupportingInfoDecoder>;
export const SupportingInfoDecoder = D.partial({
  description: D.nullable(D.string),
  url: D.nullable(D.string),
  type: D.nullable(D.string),
});
export type SupportingInfoType = D.TypeOf<typeof SupportingInfoDecoder>;

// Telecom
export const SourceTelecomDecoder = D.partial({
  system: D.string,
  value: D.string,
  use: D.nullable(D.string),
  rank: D.nullable(D.number),
});
export type SourceTelecomType = D.TypeOf<typeof SourceTelecomDecoder>;
export const TelecomDecoder = D.partial({
  system: D.string,
  value: D.string,
  use: D.nullable(D.string),
  rank: D.nullable(D.number),
});
export type TelecomType = D.TypeOf<typeof TelecomDecoder>;

// Name
export const SourceNameDecoder = D.struct({ family: D.string, given: D.array(D.string) });
export type SourceNameType = D.TypeOf<typeof SourceNameDecoder>;
export const NameDecoder = D.struct({ firstName: D.string, lastName: D.string });
export type NameType = D.TypeOf<typeof NameDecoder>;

// Code
export const SourceCodingDecoder = D.partial({
  code: D.nullable(D.string),
  display: D.nullable(D.string),
  system: D.nullable(D.string),
});
export type SourceCodingType = D.TypeOf<typeof SourceCodingDecoder>;
export const CodingDecoder = D.partial({
  code: D.nullable(D.string),
  display: D.nullable(D.string),
  system: D.nullable(D.string),
});
export type CodingType = D.TypeOf<typeof CodingDecoder>;
export const SourceCodeDecoder = D.partial({ coding: D.nullable(D.array(SourceCodingDecoder)) });
export type SourceCodeType = D.TypeOf<typeof SourceCodeDecoder>;
export const CodeDecoder = D.partial({ coding: D.nullable(D.array(CodingDecoder)) });
export type CodeType = D.TypeOf<typeof CodeDecoder>;

// Meta
export const SourceMetaDecoder = D.partial({
  last_updated: D.string,
  source: D.string,
  version_id: D.string,
  tag: D.nullable(D.array(SourceCodingDecoder)),
});
export type SourceMetaType = D.TypeOf<typeof SourceMetaDecoder>;
export const MetaDecoder = D.partial({
  lastUpdated: D.string,
  source: D.string,
  versionId: D.string,
  tag: D.nullable(D.array(CodingDecoder)),
});
export type MetaType = D.TypeOf<typeof MetaDecoder>;

// Identifier
export const SourceIdentifierDecoder = D.partial({
  system: D.string,
  value: D.union(D.string, D.number, D.boolean, D.UnknownRecord, D.UnknownArray),
});
export type SourceIdentifierType = D.TypeOf<typeof SourceIdentifierDecoder>;
export const IdentifierDecoder = D.partial({
  system: D.string,
  value: D.union(D.string, D.number, D.boolean, D.UnknownRecord, D.UnknownArray),
});
export type IdentifierType = D.TypeOf<typeof IdentifierDecoder>;

// Consent
export const SourceConsentDecoder = D.partial({
  type: D.string,
  url: D.nullable(D.string),
  signed_on: D.nullable(D.string),
});
export type SourceConsentType = D.TypeOf<typeof SourceConsentDecoder>;
export const ConsentDecoder = D.partial({
  type: D.string,
  url: D.nullable(D.string),
  signedOn: D.nullable(D.string),
});
export type ConsentType = D.TypeOf<typeof ConsentDecoder>;

// Procedure
export const ProcedureStatusDecoder = D.string; // ????? (also missing enum)
export type ProcedureStatusType = D.TypeOf<typeof ProcedureStatusDecoder>;
export const SourceProcedureDecoder = D.partial({
  identifier: D.nullable(D.array(SourceIdentifierDecoder)),
  status: D.nullable(ProcedureStatusDecoder),
  performed: D.nullable(D.string),
});
export type SourceProcedureType = D.TypeOf<typeof SourceProcedureDecoder>;
export const ProcedureDecoder = D.partial({
  identifier: D.nullable(D.array(IdentifierDecoder)),
  status: D.nullable(ProcedureStatusDecoder),
  performed: D.nullable(D.string),
  appointmentId: D.nullable(D.string),
});
export type ProcedureType = D.TypeOf<typeof ProcedureDecoder>;

// Condition
export const SourceConditionDecoder = D.partial({
  id: D.nullable(D.string),
  body_site: D.nullable(D.array(SourceCodeDecoder)),
  code: D.nullable(SourceCodeDecoder),
  chief_complaint: D.nullable(D.string),
  comorbidities: D.nullable(D.string),
  provisional_diagnosis: D.nullable(D.string),
  extensions: D.nullable(D.array(D.string)), // ?????
  identifier: D.nullable(D.array(SourceIdentifierDecoder)),
  meta: D.nullable(SourceMetaDecoder),
  recorded_date: D.nullable(D.string),
  resource_type: D.string,
  subject: D.nullable(SourceSubjectDecoder),
});
export type SourceConditionType = D.TypeOf<typeof SourceConditionDecoder>;
export const ConditionDecoder = D.partial({
  id: D.nullable(D.string),
  bodySite: D.nullable(D.array(CodeDecoder)),
  code: D.nullable(CodeDecoder),
  chiefComplaint: D.nullable(D.string),
  comorbidities: D.nullable(D.string),
  provisionalDiagnosis: D.nullable(D.string),
  extensions: D.nullable(D.array(D.string)), // ?????
  identifier: D.nullable(D.array(IdentifierDecoder)),
  meta: D.nullable(MetaDecoder),
  recordedDate: D.nullable(D.string),
  resourceType: D.string,
  subject: D.nullable(SubjectDecoder),
});
export type ConditionType = D.TypeOf<typeof ConditionDecoder>;

// Caregiver
export const SourceCaregiverDecoder = D.partial({
  id: D.string,
  active: D.nullable(D.string),
  meta: SourceMetaDecoder,
  name: D.array(SourceNameDecoder),
  patient: D.nullable(SourceSubjectDecoder),
  relationship: D.nullable(D.array(D.string)),
  resource_type: D.string,
  telecom: D.array(SourceTelecomDecoder),
});
export type SourceCaregiverType = D.TypeOf<typeof SourceCaregiverDecoder>;
export const CaregiverDecoder = D.partial({
  id: D.string,
  active: D.nullable(D.string),
  meta: MetaDecoder,
  names: D.array(NameDecoder),
  patient: D.nullable(SubjectDecoder),
  relationship: D.nullable(D.array(D.string)),
  resourceType: D.string,
  telecom: D.array(TelecomDecoder),
});
export type CaregiverType = D.TypeOf<typeof CaregiverDecoder>;

// Patient Address
export const SourcePatientAddressDecoder = D.partial({
  id: D.nullable(D.string),
  city: D.nullable(D.string),
  line: D.nullable(D.array(D.string)),
  postal_code: D.nullable(D.string),
  state: D.nullable(D.string),
  country: D.nullable(D.string),
});
export type SourcePatientAddressType = D.TypeOf<typeof SourcePatientAddressDecoder>;
export const PatientAddressDecoder = D.partial({
  id: D.nullable(D.string),
  city: D.nullable(D.string),
  line: D.nullable(D.array(D.string)),
  zipCode: D.nullable(D.string),
  state: D.nullable(D.string),
  country: D.nullable(D.string),
});
export type PatientAddressType = D.TypeOf<typeof PatientAddressDecoder>;

// Referral
export const SourceReferralDecoder = D.partial({
  id: D.string,
  insurance: D.array(D.partial({ insuror: D.string })),
  intent: D.string,
  note: D.array(D.partial({ text: D.string, time: D.string })),
  status: ReferralStatusDecoder,
  authored_on: D.string,
  office_based_referral: D.boolean,
  revocation_reason: D.nullable(D.string),
  revocation_date: D.nullable(D.string),
  service_duration: D.number, // ?????
  resource_type: D.string,
  code: D.nullable(SourceCodeDecoder),
  consent: D.nullable(D.array(SourceConsentDecoder)),
  identifier: D.nullable(D.array(SourceIdentifierDecoder)),
  meta: D.nullable(SourceMetaDecoder),
  occurrence_period: SourceOccurrencePeriodDecoder,
  quantity: SourceQuantityDecoder,
  procedures: D.array(SourceProcedureDecoder),
  subject: SourceSubjectDecoder,
  reason_reference: D.nullable(D.array(SourceReasonReferenceDecoder)),
  supporting_info: D.nullable(D.array(SourceSupportingInfoDecoder)),
  conditions: D.nullable(D.array(SourceConditionDecoder)),
});
export type SourceReferralType = D.TypeOf<typeof SourceReferralDecoder>;
export const ReferralDecoder = D.partial({
  id: D.string,
  insurance: D.array(D.partial({ insuror: D.string })),
  intent: D.string,
  note: D.array(D.partial({ text: D.string, time: D.string })),
  status: ReferralStatusDecoder,
  authoredOn: D.string,
  isOfficeBased: D.boolean,
  revocationReason: D.nullable(D.string),
  revocationDate: D.nullable(D.string),
  duration: D.number,
  resourceType: D.string,
  code: D.nullable(CodeDecoder),
  consent: D.nullable(D.array(ConsentDecoder)),
  identifier: D.nullable(D.array(IdentifierDecoder)),
  meta: D.nullable(MetaDecoder),
  occurrencePeriod: OccurrencePeriodDecoder,
  quantity: QuantityDecoder,
  procedures: D.array(ProcedureDecoder),
  subject: SubjectDecoder,
  reasons: D.nullable(D.array(ReasonReferenceDecoder)),
  supportingInfo: D.nullable(D.array(SupportingInfoDecoder)),
  conditions: D.nullable(D.array(ConditionDecoder)),
  // from identifiers
  referralNumber: D.string,
  // from subject
  patientId: D.string,
  // custom
  active: D.boolean,
  expired: D.boolean,
  revoked: D.boolean,
});
export type ReferralType = D.TypeOf<typeof ReferralDecoder>;

// Patient
export const SourcePatientDecoder = D.partial({
  id: D.string,
  active: D.boolean,
  gender: D.nullable(D.string), // ?????
  communication: D.nullable(D.string), // ?????
  birth_date: D.nullable(D.string),
  address: D.array(SourcePatientAddressDecoder),
  service_requests: D.nullable(D.array(SourceReferralDecoder)),
  caregivers: D.nullable(D.array(SourceCaregiverDecoder)),
  meta: D.nullable(SourceMetaDecoder),
  removed_from_network: D.nullable(D.boolean),
  identifier: D.nullable(D.array(SourceIdentifierDecoder)),
  name: D.array(SourceNameDecoder),
  telecom: D.nullable(D.array(SourceTelecomDecoder)),
});
export type SourcePatientType = D.TypeOf<typeof SourcePatientDecoder>;
export const PatientDecoder = D.partial({
  id: D.string,
  active: D.boolean,
  gender: D.nullable(D.string), // ?????
  communication: D.nullable(D.string), // ?????
  birthdate: D.nullable(D.string),
  addresses: D.array(PatientAddressDecoder),
  referrals: D.array(ReferralDecoder),
  caregivers: D.nullable(D.array(CaregiverDecoder)),
  meta: D.nullable(MetaDecoder),
  removedFromNetwork: D.boolean,
  identifier: D.nullable(D.array(IdentifierDecoder)),
  names: D.array(NameDecoder),
  telecom: D.nullable(D.array(TelecomDecoder)),
  // from identifiers
  memberId: D.nullable(D.string),
  icn: D.nullable(D.string),
  edipi: D.nullable(D.string),
  // from telecoms
  email: D.nullable(D.string),
  phone: D.nullable(D.string),
  // custom
  activeReferrals: D.array(ReferralDecoder),
  pastReferrals: D.array(ReferralDecoder),
  hasActiveReferrals: D.boolean,
  hasPastReferrals: D.boolean,
  hasAnyReferrals: D.boolean,
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  fullName: D.nullable(D.string),
});

export type PatientType = D.TypeOf<typeof PatientDecoder>;

export const SourcePatientDocumentDecoder = D.partial({
  name: D.string,
  url: D.string,
  date: D.string,
});
export type SourcePatientDocumentType = D.TypeOf<typeof SourcePatientDocumentDecoder>;

export const PatientDocumentDecoder = D.partial({
  name: D.string,
  url: D.string,
  date: D.string,
});
export type PatientDocumentType = D.TypeOf<typeof PatientDocumentDecoder>;
