import { FC, MouseEvent, useRef } from 'react';

import styles from './style.module.scss';

type Props = {
  id: string;
  title: string;
  description: string;
  price: number;
  discountedPrice?: number;
  showDiscount?: boolean;
  strikeRegularPrice?: boolean;
  image?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  showPrices?: boolean;
};

const Detail: FC<Props> = ({ title, description, image, onClick }) => {
  const downClickX = useRef<any>(null);
  const upClickX = useRef<any>(null);

  const onMouseDown = (e) => {
    const { clientX } = e;
    downClickX.current = clientX;
  };

  const onMouseUp = (e) => {
    const { clientX } = e;
    upClickX.current = clientX;
  };

  const handleOnClick = (e) => {
    if (downClickX.current && upClickX.current && Math.abs(downClickX.current - upClickX.current) < 20) {
      // checking if click range is within 20px (and is not result of carousel swipe)
      onClick(e);
    }
  };
  return (
    <div className={styles.product} onClick={handleOnClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <div className={styles.imgContainer}>
        <img src={image} alt='product' />
      </div>
      <h5>{title}</h5>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default Detail;
