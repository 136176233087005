import { FC, useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';
import { useForm, usePage } from 'utils/hooks';

import { useLazyGetStandardPricingForZipQuery } from 'redux/apis/OG/booking';

import { LoadingIcon, useNavigate } from '@zeel-dev/zeel-ui';
import { Icon, Input } from 'components/common';

import { getUserZipCode } from '../../../utils/helpers';
import { HsaFsaEligible, HsaFsaEligibleProps } from '../../common/HsaFsaEligible';
import ButtonBlock from '../ButtonBlock';
import DetailedPricing from './components/DetailedPricing';
import Pricing from './components/Pricing';
import styles from './style.module.scss';

type Props = {
  className?: string;
  containerClassName?: string;
  autoLocate?: boolean;
  description?: string;
  overrideLocation?: { city: string };
  overridePricings?: Array<any>;
  onlyStretch?: boolean;
  detailedPricing?: boolean;
  expanded?: boolean;
  details?: Record<string, any>;
  title?: string;
  pricingOrder?: Array<string>;
  payAsYouGoTitle?: string;
  testId?: string;
  hidePayAsYouGo?: boolean;
  initialZip?: string;
  includeHsaFsaCallout?: boolean;
  hsaFsaProps?: HsaFsaEligibleProps;
};

const PricingBlock: FC<Props> = ({
  pricingOrder,
  autoLocate,
  description,
  overridePricings,
  overrideLocation,
  className,
  containerClassName,
  detailedPricing = false,
  details = {},
  expanded = false,
  title = 'Pricing in',
  onlyStretch,
  payAsYouGoTitle,
  hidePayAsYouGo,
  initialZip,
  testId = 'pricing-block',
  includeHsaFsaCallout = true,
  hsaFsaProps,
}) => {
  const [placeholder, setPlaceholder] = useState<any>('10036');
  const [fetchedLocation /*, setFetchedLocation*/] = useState<any>(null);
  const [pricings, setPricings] = useState<any>([]);
  const [unavailable, setUnavailable] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm();
  const [getPricingForZip] = useLazyGetStandardPricingForZipQuery();
  const navigate = useNavigate();

  const { error } = usePage(async () => {
    if (initialZip) {
      form.setValue('zip', initialZip);
    }
    fetchData(initialZip);
  });

  useEffect(() => {
    if (initialZip && !isLoading) {
      form.setValue('zip', initialZip);
      fetchData(initialZip);
    }
  }, [initialZip]);

  const fetchData = useCallback(
    async (forcedZip = null) => {
      setUnavailable(false);
      setIsLoading(true);
      try {
        if (!overrideLocation) {
          const zip = forcedZip || (await getUserZipCode()) || 10005;
          if (!forcedZip) form.setValue('zip', zip);
          let fetchedPricings = await getPricingForZip(zip).unwrap();

          if (onlyStretch) {
            fetchedPricings = fetchedPricings.filter((item) => item?.type === 'stretch');
          } else {
            fetchedPricings = fetchedPricings.filter((item) => item?.type !== 'stretch');
          }
          if (pricingOrder) {
            fetchedPricings = orderPricing(fetchedPricings);
          }
          setPricings(fetchedPricings);
        }

        setIsLoading(false);
      } catch (errors: any) {
        // error is from fetchPricings call and means it is unavailable
        setIsLoading(false);
        if (errors?.zip) setUnavailable(true);
        else {
          setUnavailable(true);
          return errors;
        }
      }
    },
    [overrideLocation, onlyStretch, pricingOrder, form]
  );

  const orderPricing = useCallback(
    (_pricings) => {
      return pricingOrder
        .map((type) => {
          return _pricings.find((pricing) => pricing?.type === type);
        })
        .filter(Boolean);
    },
    [pricingOrder]
  );

  const edit = () => {
    setEditing(true);
    setPlaceholder(form.getValue('zip'));
    // inputRef.current?.clearInput();
  };

  const submit = useCallback(async () => {
    // if (inputRef.current?.ref?.blur) {
    //   inputRef.current.ref.blur();
    // }
    setEditing(false);
    const newZip = form.getValue('zip');

    await fetchData(newZip);
  }, [form]);

  if ((error as any)?.errors?.unavailable) {
    return (
      <ButtonBlock
        action={() => navigate(routes.MASSAGES_NEAR_ME({ host: true }))}
        actionText='See Pricing in Your Area'
      />
    );
  }

  const PricingComponent = detailedPricing ? DetailedPricing : Pricing;
  return (
    <div className={clsx(styles.block, className)} data-testid={testId}>
      <div className={styles.top}>
        {!autoLocate && !overrideLocation && (
          <>
            <h2 data-testid={`${testId}--label`}>{title}</h2>
            <form
              className={clsx(styles.editableBox, { [styles.disableOnStretch]: onlyStretch })}
              onClick={!editing ? edit : undefined}
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <Input
                {...form.getProps('zip')}
                // ref={(r) => (inputRef.current = r)}
                containerClassName={styles.zipInput}
                wrapperClassName={styles.zipInputWrapper}
                placeholder={placeholder}
                hideValidation
                max={5}
                type='number'
                disabled={isLoading}
                testId={`${testId}--zip-code-input`}
              />
              {isLoading && <LoadingIcon spin color={'white'} />}
              {!isLoading && (
                <>
                  {!editing && <Icon type='edit' testId={`${testId}--edit-icon`} />}
                  {editing && <Icon type='arrow-right' onClick={submit} testId={`${testId}--submit-icon`} />}
                </>
              )}
            </form>
          </>
        )}
        {autoLocate && !error && (
          <h2 data-testid={`${testId}--label`}>
            {title} {fetchedLocation && fetchedLocation.city}
          </h2>
        )}
        {autoLocate && error && <h2 data-testid={`${testId}--label`}>{title} your area</h2>}
        {!autoLocate && overrideLocation && (
          <h2 data-testid={`${testId}--label`}>
            {title} {overrideLocation && overrideLocation.city}
          </h2>
        )}
      </div>

      <p className={styles.description} data-testid={`${testId}--description`}>
        {!unavailable && !error && (
          <span>{description || 'Prices shown are for a 60-minute massage in your area.'}</span>
        )}
        {(unavailable || error) && <span>This service is not yet available in your area.</span>}
      </p>
      {includeHsaFsaCallout && <HsaFsaEligible className={styles.hsafsa} {...(hsaFsaProps || {})} />}
      {!unavailable && !error && (
        <div className={clsx(styles.pricingsContainer, containerClassName)}>
          {!overridePricings &&
            pricings?.length > 0 &&
            pricings.map(({ type, title: pricingTitle, subtotal, total }, i) => {
              if (pricingTitle?.toLowerCase() === 'pay as you go' && hidePayAsYouGo) {
                return null;
              }
              if (!type) return null;
              const buttonText =
                type === 'pay_as_you_go'
                  ? 'Book a Massage'
                  : type === 'stretch'
                  ? 'Book a Stretch'
                  : `Subscribe and ${detailedPricing ? 'Book' : 'Save'}`;
              const extraProps = {};
              if (detailedPricing) {
                const typeInfo = details[type];
                extraProps['info'] = typeInfo['items'];
                extraProps['expanded'] = expanded;
                extraProps['details'] = typeInfo['title'];
                extraProps['subtitle'] = typeInfo['subtitle'];
                extraProps['learnMoreHref'] = typeInfo['learnMoreHref'];
                extraProps['highlightCost'] = false;
                extraProps['expandFunction'] = typeInfo['expandFunction'];
              }

              return (
                <PricingComponent
                  key={i}
                  loading={isLoading}
                  invalid={!!error}
                  name={
                    pricingTitle?.toLowerCase() === 'pay as you go'
                      ? payAsYouGoTitle || 'Single Appointment'
                      : pricingTitle
                  }
                  cost={subtotal.price}
                  details={`+ tax and 18% tip = $${total.price}`}
                  buttonText={buttonText}
                  highlightCost={type === 'has_table_zeelot'}
                  onClick={async () => {
                    navigate(routes.PERSONAL_WELLNESS.index(), {
                      state: {
                        service: onlyStretch ? 'Stretch' : 'Massage',
                        zipCode: form.getValue('zip'),
                      },
                    });
                  }}
                  {...extraProps}
                />
              );
            })}
          {overridePricings &&
            overridePricings.map(
              (
                {
                  title: pricingTitle,
                  subtotal,
                  total,
                  buttonText = 'Book now',
                  hideButton = false,
                  highlightCost,
                  onClick,
                },
                i
              ) => {
                return (
                  <Pricing
                    key={i}
                    name={pricingTitle}
                    cost={subtotal}
                    details={`+ tax and 18% tip = $${total}`}
                    buttonText={buttonText}
                    hideButton={hideButton}
                    highlightCost={highlightCost}
                    onClick={onClick}
                  />
                );
              }
            )}
        </div>
      )}
    </div>
  );
};

export default PricingBlock;
