import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';

import { createTransform } from 'redux-persist';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import { GenderRequestedType } from '../../../apis/OG/booking';
import { SourceSpaBookingRequestType, SpaBookingPayloadType } from '../../../apis/OG/spa';
import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Intro = 'intro',
  Requests = 'requests',
  Locations = 'locations',
  LocationInfo = 'location-info',
  Checkout = 'checkout',
}

export type FlowParams = {
  preselectedSpa?: Spa;
};

export type Spa = {
  clientId: string | null;
  locationId: string | null;
};

export type RequestType = {
  id?: string;
  spa?: Spa; // if omitted, means it will need to be attached to the new spa
  requestedDateTime?: {
    label?: string;
    start: string;
    end: string;
  };
  duration: number;
  notes?: string;
  gender?: GenderRequestedType;
  quantity?: number;
  estimatedPrice?: number;
  _zip?: string;
};

export type SpaAddress = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  // ---
  type: 'match' | 'manual';
  matchString?: string;
};

export type SpaCollectedInformation = {
  name: string;
  address: SpaAddress;
  roomSuiteFloor?: string;
  mobilePhoneNumber: string;
  einNumber?: string;
  dressCode: string;
  checkInOutInstructions: string;
  parkingInstructions: string;
};

export type State = {
  email?: string;
  defaultSpa: Spa;
  collectedInformation: Partial<SpaCollectedInformation>;
  requests: RequestType[];
  paymentMethodId?: string;
  cartToggled: boolean;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  defaultSpa: {
    clientId: null,
    locationId: null,
  },
  collectedInformation: {},
  requests: [],
  paymentMethodId: null,
  cartToggled: false,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const spaSelfServeFlowSlice = createSlice({
  name: 'spaSelfServeFlowSlice',
  initialState,
  reducers: {
    cartToggledStateChanged(state, action: PayloadAction<boolean>) {
      state.cartToggled = action.payload;
    },
    emailChanged(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    emailCleared(state) {
      state.email = undefined;
    },
    defaultSpaChanged(state, action: PayloadAction<Spa>) {
      const { clientId, locationId } = action.payload || {};
      if (clientId) state.defaultSpa.clientId = clientId;
      if (locationId) state.defaultSpa.locationId = locationId;
    },
    defaultSpaCleared(state) {
      state.defaultSpa.clientId = null;
      state.defaultSpa.locationId = null;
    },
    collectedInformationChanged(state, action: PayloadAction<Partial<SpaCollectedInformation>>) {
      const info = action.payload;
      if (info.name) state.collectedInformation.name = info.name;
      if (info.address) state.collectedInformation.address = info.address;
      if (info.roomSuiteFloor) state.collectedInformation.roomSuiteFloor = info.roomSuiteFloor;
      if (info.mobilePhoneNumber) state.collectedInformation.mobilePhoneNumber = info.mobilePhoneNumber;
      if (info.einNumber) state.collectedInformation.einNumber = info.einNumber;
      if (info.dressCode) state.collectedInformation.dressCode = info.dressCode;
      if (info.checkInOutInstructions) state.collectedInformation.checkInOutInstructions = info.checkInOutInstructions;
      if (info.parkingInstructions) state.collectedInformation.parkingInstructions = info.parkingInstructions;
    },
    collectedInformationCleared(state) {
      state.collectedInformation = {};
    },
    requestAdded(state, action: PayloadAction<RequestType>) {
      const updatedRequests = [...state.requests];
      updatedRequests.push({ id: nanoid(), ...action.payload });
      state.requests = updatedRequests;
    },
    requestUpdated(state, action: PayloadAction<RequestType>) {
      state.requests = [...state.requests].map((r) => {
        return r.id === action.payload?.id
          ? {
              ...r,
              ...(action.payload || {}),
            }
          : r;
      });
    },
    requestDeleted(state, action: PayloadAction<string>) {
      state.requests = [...state.requests].filter((r) => r.id !== action.payload);
    },
    requestsSanitized(state, action: PayloadAction<RequestType[]>) {
      state.requests = cloneDeep(action.payload);
    },
    paymentMethodIdSelected(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    paymentMethodIdCleared(state) {
      state.paymentMethodId = null;
    },
    completed() {
      return { ...cloneDeep(initialState) };
    },
  },
});

export const spaSelfServeFlowReducer = persistReducer<State>(spaSelfServeFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => os
    ),
  ],
  blacklist: ['cartToggled'],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[spaSelfServeFlowSlice.name] as State;

export const formatRequest = (request: RequestType, collectedZip?: string): SourceSpaBookingRequestType => {
  const { spa, requestedDateTime, duration, notes, gender, _zip } = request;
  return {
    appointments: [
      {
        length: duration,
        notes,
        therapist_gender_requested: gender as any,
        service: {
          id: '370',
        },
      },
    ],
    category: 'gig',
    gig: true,
    for_concierge_client: true,
    ...(spa?.clientId ? { client: { id: `${spa.clientId}` } } : undefined),
    location: spa?.locationId
      ? { id: `${spa.locationId}` }
      : collectedZip
      ? { zip: collectedZip }
      : _zip
      ? { zip: _zip }
      : undefined,
    request_time: {
      start: Number(requestedDateTime?.start),
      end: Number(requestedDateTime?.end),
    },
  };
};

const generateBookingPayload = (state: RootState): SpaBookingPayloadType => {
  const { requests, paymentMethodId, collectedInformation } = getSliceState(state);
  const formattedRequests: SourceSpaBookingRequestType[] = [];
  (requests || []).forEach((request) => {
    const nb = request.quantity || 1;
    for (let i = 0; i < nb; i++) {
      formattedRequests.push({
        ...formatRequest(request, collectedInformation?.address?.zip),
        card_id: paymentMethodId,
      });
    }
  });

  return {
    bookings: formattedRequests,
    only_first_to_blasting: true,
  };
};

export const spaSelfServeFlowSelectors = {
  selectCartToggled: (state) => getSliceState(state).cartToggled,
  selectEmail: (state) => getSliceState(state).email,
  selectRequests: (state) => getSliceState(state).requests,
  selectDefaultSpa: (state) => getSliceState(state).defaultSpa,
  selectCollectedInformation: (state) => getSliceState(state).collectedInformation,
  selectPaymentMethodId: (state) => getSliceState(state).paymentMethodId,
  selectBookingPayload: (state) => generateBookingPayload(state),
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useSpaSelfServeFlowSlice = () => {
  return useSliceWrapper(spaSelfServeFlowSlice, spaSelfServeFlowSelectors);
};
