import { BookingConfigurationType } from '../../redux/apis/OG/booking';
import {
  PaymentProcessor,
  PaymentUsedFor,
  PaymentCollectionType as _PaymentCollectionType,
  PaymentOption as _PaymentOption,
} from '../../redux/apis/OG/payment';
import { ApplePayPaymentRequestClientConfig } from './native/ApplePay';

export enum PMCInitStep {
  Override = 'override',
  Validation = 'validation',
  PaymentOption = 'paymentOption',
  PaymentCollectionType = 'paymentCollectionType',
  Processor = 'processor',
  ClientToken = 'clientToken',
}

export enum PMCStatusCode {
  Waiting = 'waiting',
  Initializing = 'initializing',
  WaitingForPaymentOptionChoice = 'waiting_for_payment_option_choice',
  WaitingForProcessorChoice = 'waiting_for_processor_choice',
  Ready = 'ready',
  Success = 'success',
  Failure = 'failure',
}

export enum PMCErrorCode {
  UserUnauthenticated = 'user__unauthenticated',
  InvalidParameterMode = 'invalid_parameter__mode',
  InvalidParameterCollectionType = 'invalid_parameter__collection_type',
  InvalidParameterCollectionTypeForPaymentOption = 'invalid_parameter__collection_type_for_payment_option',
  InvalidParameterUsedFor = 'invalid_parameter__used_for',
  InvalidParameterBooking = 'invalid_parameter__booking',
  InvalidParameterEntityId = 'invalid_parameter__entity_id',
  InvalidParameterEntityPaymentOption = 'invalid_parameter__entity_payment_option',
  EntityNotFound = 'entity__not_found',
  EntityMissingExternalType = 'entity__missing_external_type',
  EntityNotUpdatable = 'entity__not_updatable',
  EntityRetrievalFailure = 'entity__retrieval_failure',
  PaymentOptionsRetrievalFailure = 'payment_option__retrieval_failure',
  ForcePaymentOptionNotAvailable = 'payment_option__not_available',
  ForcePaymentOptionNotSupported = 'payment_option__not_supported',
  NoAvailablePaymentOptions = 'payment_option__none_available',
  NoAvailableCollectionTypes = 'collection_type__none_available',
  MultipleCollectionTypesAvailable = 'collection_type__multiple_available',
  AvailableProcessorsRetrievalFailure = 'processor__retrieval_failure',
  ProcessorMissingBeforeClientTokenRetrieval = 'processor__missing_before_client_token_retrieval',
  ForcedProcessorNotAvailable = 'processor__not_available',
  ForcedProcessorNotSupported = 'processor__not_supported',
  ForcedProcessorUpdateNotSupported = 'processor__update_not_supported',
  NoAvailableProcessors = 'processor__none_available',
  ClientTokenRetrievalFailure = 'client_token__retrieval_failure',
  ClientTokenMissingAfterRetrieval = 'client_token__missing_after_retrieval',
  PaymentMethodMissingOnCompletion = 'payment_method__missing_on_completion',
  SdkFailure = 'sdk__failure',
  UnknownError = 'unknown_error',
}

export const PMCErrorMessage: Record<PMCErrorCode, string> = {
  [PMCErrorCode.UserUnauthenticated]: 'Please log in to manage your payment methods',
  [PMCErrorCode.InvalidParameterMode]: 'The url you are trying to access is invalid (mode)',
  [PMCErrorCode.InvalidParameterCollectionType]: 'The url you are trying to access is invalid (collection_type)',
  [PMCErrorCode.InvalidParameterCollectionTypeForPaymentOption]:
    'The url you are trying to access is invalid (collection_type for payment_option)',
  [PMCErrorCode.InvalidParameterUsedFor]: 'The url you are trying to access is invalid (used_for)',
  [PMCErrorCode.InvalidParameterBooking]: 'The url you are trying to access is invalid (booking)',
  [PMCErrorCode.InvalidParameterEntityId]: 'The url you are trying to access is invalid (entity_id)',
  [PMCErrorCode.InvalidParameterEntityPaymentOption]:
    'The url you are trying to access is invalid (entity_payment_option)',
  [PMCErrorCode.EntityNotFound]: 'The payment method you are trying to update does not exist',
  [PMCErrorCode.EntityMissingExternalType]:
    'The payment method you are trying to update is missing information required to update',
  [PMCErrorCode.EntityNotUpdatable]:
    'The payment method you are trying to update is not updatable at the moment. Please try again later',
  [PMCErrorCode.EntityRetrievalFailure]:
    'Could not retrieve the payment method you are trying to update. Please try again later',
  [PMCErrorCode.PaymentOptionsRetrievalFailure]:
    'Could not retrieve the available payment options. Please try again later',
  [PMCErrorCode.ForcePaymentOptionNotAvailable]:
    'The specified payment option is not available at the moment. Please try again later',
  [PMCErrorCode.ForcePaymentOptionNotSupported]:
    'The specified payment option is unsupported. Please specify another payment option',
  [PMCErrorCode.NoAvailablePaymentOptions]: 'No payment options are available at the moment. Please try again later',
  [PMCErrorCode.NoAvailableCollectionTypes]: 'No collection types are available at the moment. Please try again later',
  [PMCErrorCode.MultipleCollectionTypesAvailable]:
    'Multiple collection types are available. Please specify a collection type',
  [PMCErrorCode.AvailableProcessorsRetrievalFailure]:
    'Could not retrieve the available payment processors. Please try again later',
  [PMCErrorCode.ProcessorMissingBeforeClientTokenRetrieval]:
    'The specified processor is missing. Please specify another processor',
  [PMCErrorCode.ForcedProcessorNotAvailable]:
    'The specified processor is not available at the moment. Please try again later',
  [PMCErrorCode.ForcedProcessorNotSupported]:
    'The specified processor is unsupported. Please specify another processor',
  [PMCErrorCode.ForcedProcessorUpdateNotSupported]:
    'The specified processor is unsupported for updating. Please specify another processor',
  [PMCErrorCode.NoAvailableProcessors]:
    'Payment method creation is not available at the moment. Please try again later',
  [PMCErrorCode.ClientTokenRetrievalFailure]:
    'Could not retrieve the necessary information to connect to the selected payment processor. Please try again later',
  [PMCErrorCode.ClientTokenMissingAfterRetrieval]:
    'Could not retrieve the necessary information to connect to the selected payment processor. Please try again later',
  [PMCErrorCode.PaymentMethodMissingOnCompletion]:
    'An issue occurred when collecting your payment information. Please try again later',
  [PMCErrorCode.SdkFailure]: 'An issue occurred when processing your payment information. Please try again later',
  [PMCErrorCode.UnknownError]: 'An unknown error occurred. Please try again later',
};

export type PMCStatus = { code: PMCStatusCode; error?: PMCError };
export type PMCError = { code: PMCErrorCode | string; message?: string };

export enum PMCRenderMode {
  Create = 'create',
  Update = 'update',
  List = 'list',
}

export type PMCPaymentContextConfig = {
  usedFor: PaymentUsedFor;
  bookingData?: BookingConfigurationType;
  amount?: number;
};

export type PMCViewError = {
  title: string;
  description: string;
  affectedEntityId?: string;
  cardMessage?: string;
};

export type PMCRendererConfig = {
  title?: string | null;
  description?: string | null;
  buttonText?: string;
  secondaryLabelText?: string;
  initialError?: PMCViewError;
  className?: string;
};

export const PMCSupportedPaymentOptions: Record<_PaymentOption, boolean> = {
  [_PaymentOption.CreditCard]: true,
  [_PaymentOption.Insurance]: false,
  [_PaymentOption.ApplePay]: false, // NATIVE
  [_PaymentOption.GooglePay]: false, // NATIVE
};

export const PMCPaymentOptionCollectionTypeMap: Record<_PaymentOption, _PaymentCollectionType[]> = {
  [_PaymentOption.CreditCard]: [_PaymentCollectionType.Vault], // *** SOON *** PaymentCollectionType.OneTime
  [_PaymentOption.Insurance]: [_PaymentCollectionType.Vault],
  // native
  [_PaymentOption.ApplePay]: [],
  [_PaymentOption.GooglePay]: [],
};

export const PMCSupportedProcessors: Record<PaymentProcessor, boolean> = {
  [PaymentProcessor.Braintree]: true,
  [PaymentProcessor.Fortis]: true,
  [PaymentProcessor.Stripe]: true,
  [PaymentProcessor.Authorize]: false,
};

export const PMCUpdatableProcessors: Record<PaymentProcessor, boolean> = {
  [PaymentProcessor.Braintree]: true,
  [PaymentProcessor.Fortis]: false,
  [PaymentProcessor.Stripe]: false,
  [PaymentProcessor.Authorize]: false,
};

export enum PMCWebviewUrlAction {
  Success = 'success', // successful collection, with payment id in url
  Failure = 'failure', // failed with error 'code' as search param
  Create = 'create', // create a new payment method
  Update = 'update', // update an existing payment method
  List = 'list', // list all payment methods
}

export type PMCConfig = Partial<{
  [PMCRenderMode.Create]: PMCRendererConfig & {
    paymentOption?: _PaymentOption;
    collectionType?: _PaymentCollectionType;
    setCreatedAsDefault?: boolean;
    // DEV MODE
    ignorePaymentOptionAvailability?: boolean;
    processor?: PaymentProcessor;
    ignoreProcessorAvailability?: boolean;
    allowProcessorSelection?: boolean;
  };
  [PMCRenderMode.Update]: PMCRendererConfig & {
    entityId?: string;
    entityPaymentOption?: _PaymentOption;
  };
  [PMCRenderMode.List]: PMCRendererConfig & {
    selectedId?: string;
    setSelectedAsDefault?: boolean;
    applePay?: ApplePayPaymentRequestClientConfig;
  };
}>;

export type PMCInitialState = { statusCode: PMCStatusCode.Success | PMCStatusCode.Failure; error?: PMCError };

export type PMCCollectionData = {
  id?: string;
  nonce?: string;
  type?: string;
  extra?: Record<string, string | number | boolean>;
};

export type PMCResponse = {
  success?: boolean;
  paymentOption: _PaymentOption;
  collectionType: _PaymentCollectionType;
  processor: PaymentProcessor;
  data?: PMCCollectionData;
  error?: PMCError;
};

export type TransactionType = { id: string }; // TODO...
export type InsuranceProfileType = { id: string }; // TODO...
