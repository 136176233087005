import { FC, KeyboardEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Button, Link, Video } from 'components/common';
import { ButtonProps } from 'components/common/Button';

import styles from './style.module.scss';

type Props = {
  id?: string;
  containerId?: string;
  title?: ReactNode;
  topper?: ReactNode;
  subtitle?: ReactNode;
  additional?: ReactNode;
  className?: string;
  src: string;
  srcAlt?: string;
  srcMobile?: string;
  srcPoster?: string;
  action?: (e: MouseEvent<HTMLButtonElement>) => void;
  actionProps?: ButtonProps;
  actionText?: ReactNode;
  actionContent?: ReactNode;
  subAction?: (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
  subActionText?: ReactNode;
  subActionHref?: string;
  subActionContent?: ReactNode;
  underContent?: ReactNode;
  sideAction?: (e: MouseEvent<HTMLButtonElement>) => void;
  sideActionText?: ReactNode;
  size?: string; //full, normal, short
  vertical?: string; //top, center, bottom
  horizontal?: string; //left, center, right
  backgroundCoverId?: string;
  classes?: {
    videoContainer?: string;
    backgroundCover?: string;
    container?: string;
    content?: string;
    title?: string;
    topper?: string;
    subtitle?: string;
    subtitleShort?: string;
    additional?: string;
    action?: string;
    subAction?: string;
    subActionContent?: string;
    underContent?: string;
  };
  overlay?: boolean;
  testId?: string;
};

const HeroBlock: FC<Props> = ({
  id,
  containerId,
  backgroundCoverId,
  src,
  srcAlt,
  topper = '',
  title = '',
  additional = '',
  subtitle = '',
  action,
  actionContent,
  actionProps = {},
  subAction,
  sideAction,
  sideActionText = '',
  size = 'normal',
  actionText = '',
  subActionText = '',
  subActionHref,
  subActionContent,
  underContent,
  className = '',
  vertical = 'center',
  horizontal = 'center',
  overlay = false,
  classes,
  srcMobile,
  srcPoster = '',
  testId = 'hero-block',
}) => {
  const [isMobile, setIsMobile] = useState<boolean>();

  const handleIsMobile = () => {
    setIsMobile(helpers.isMobileWidth());
  };

  useEffect(() => {
    window.addEventListener('resize', handleIsMobile);
    return () => {
      window.removeEventListener('resize', handleIsMobile);
    };
  }, []);

  const fileExtensionDesktop = helpers.getFileExtension(src);
  const typeDesktop = fileExtensionDesktop === 'mp4' ? 'video' : 'image';

  let _type = null;
  let _src = null;
  if (srcMobile || src) {
    if (isMobile) {
      _type = helpers.getFileExtension(srcMobile || src) === 'mp4' ? 'video' : 'image';
      _src = _type === 'video' ? helpers.getVideoPath(srcMobile || src) : helpers.getImagePath(srcMobile || src);
    } else {
      _type = helpers.getFileExtension(src) === 'mp4' ? 'video' : 'image';
      _src = typeDesktop === 'video' ? helpers.getVideoPath(src) : helpers.getImagePath(src);
    }
  }

  const {
    videoContainer: videoContainerClass,
    backgroundCover: backgroundCoverClass,
    container: containerClass,
    content: contentClass,
    title: titleClass,
    topper: topperClass,
    subtitle: subtitleClass,
    subtitleShort: subtitleShortClass,
    additional: additionalClass,
    action: actionClass,
    subAction: subActionClass,
    subActionContent: subActionContentClass,
    underContent: underContentClass,
  } = classes || {};

  return (
    <div id={id} className={className} data-testid={testId}>
      {_type === 'video' && (
        <div className={clsx(styles.videoContainer, styles['videoContainer-size-' + size], videoContainerClass)}>
          <Video src={_src} poster={helpers.getImagePath(srcPoster)} testId={`${testId}--video`} title={srcAlt} />
          <div data-type='overlay' className={styles.overlay} />
        </div>
      )}

      {(backgroundCoverId || backgroundCoverClass) && (
        <div
          id={backgroundCoverId}
          className={clsx(styles.backgroundCover, styles['backgroundCover-size-' + size], backgroundCoverClass)}
        />
      )}

      <SkeletonCover>
        <div
          id={containerId}
          data-type='block'
          style={{
            backgroundImage:
              _type === 'image' && _src
                ? `${overlay ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ' : ''}url(${_src})`
                : 'none',
          }}
          title={srcAlt}
          className={clsx(
            styles['size-' + size],
            styles['type-' + _type],
            styles['vertical-' + vertical],
            styles['horizontal-' + horizontal],
            styles.block,
            containerClass
          )}
        >
          <div className={clsx(styles.glContainerSmall, contentClass)}>
            {topper && (
              <div className={topperClass} data-testid={`${testId}--topper`}>
                {topper}
              </div>
            )}
            {title && size !== 'short' && (
              <h1 className={titleClass} data-testid={`${testId}--title`}>
                {title}
              </h1>
            )}
            {title && size === 'short' && (
              <h2 className={titleClass} data-testid={`${testId}--title`}>
                {title}
              </h2>
            )}
            {title && <br />}

            {subtitle && (
              <>
                {size !== 'short' && (
                  <p className={clsx(styles.subtitle, subtitleClass)} data-testid={`${testId}--description`}>
                    {subtitle}
                  </p>
                )}
                {size === 'short' && (
                  <p className={clsx(styles.subtitleShort, subtitleShortClass)} data-testid={`${testId}--description`}>
                    {subtitle}
                  </p>
                )}
                <br />
              </>
            )}
            {additional && (
              <div data-type='additional' className={clsx(styles.additional, additionalClass)}>
                {additional}
              </div>
            )}
            {sideAction && sideActionText && (
              <Button
                type='transparent'
                className={styles.sideButton}
                onClick={sideAction}
                testId={`${testId}--side-button`}
              >
                {sideActionText}
              </Button>
            )}
            {action && actionText && (
              <Button
                type='inverse'
                className={clsx(styles.button, actionClass)}
                {...(actionProps || {})}
                onClick={action}
                role='link'
                testId={`${testId}--main-button`}
              >
                {actionText}
              </Button>
            )}
            {actionContent}
            {subActionText && (
              <div className={styles.subActionContainer}>
                {subAction && (
                  <Link
                    className={clsx(styles.subActionLink, subActionClass)}
                    onClick={subAction}
                    href={subActionHref}
                    testId={`${testId}--sub-link`}
                  >
                    {subActionText}
                  </Link>
                )}
                {!subAction && <a className={clsx(styles.subActionNoLink, subActionClass)}>{subActionText}</a>}
              </div>
            )}
            {subActionContent && (
              <div className={clsx(styles.subActionContent, subActionContentClass)}>{subActionContent}</div>
            )}
          </div>
          {underContent && <div className={clsx(styles.underContent, underContentClass)}>{underContent}</div>}
        </div>
      </SkeletonCover>
    </div>
  );
};

export default HeroBlock;
