import { MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';
import { Button, Carousel, IconSvg, Image } from 'components/common';
import { IconProps } from 'components/common/IconSvg';

import { ImageProps } from '../../common/Image';
import CoreBlock from '../CoreBlock';
import styles from './style.module.scss';

type Props = {
  id?: string;
  data: Array<{
    title?: ReactNode;
    description?: ReactNode;
    image?: string;
    iconName?: string;
    iconProps?: IconProps;
    imageProps?: Partial<ImageProps>;
  }>;
  title?: string;
  description?: string;
  containerSize?: string;
  buttonText?: string;
  actionType?: string;
  onButtonClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  subContent?: ReactNode;
  carousel?: boolean;
  className?: string;
  carouselSettings?: { [key: string]: any };
  carouselClassName?: string;
  testId?: string;
  forceWrap?: boolean;
};

const DetailsHorizontalBlock = (props: Props) => {
  const {
    id,
    data,
    title,
    description: mainDescription,
    containerSize = 'normal',
    carousel = true,
    buttonText,
    actionType = 'primary',
    onButtonClick,
    subContent,
    className,
    carouselClassName,
    carouselSettings,
    testId = 'details-horizontal-block',
    forceWrap,
  } = props;
  const slides = (data || []).map(
    ({ title: slideTitle, description, image, iconName, iconProps = {}, imageProps = {} }, i) => (
      <div key={`${slideTitle}-${i}`} className={styles.detail} data-testid={`${testId}--slide-${i}`}>
        <SkeletonCover>
          <div className={styles.imgContainer}>
            {image && <Image src={image} {...imageProps} className={clsx(styles.image, imageProps?.className)} />}
            {iconName && <IconSvg size={50} name={iconName} {...iconProps} />}
          </div>
          {slideTitle && <h5 className={styles.title}>{slideTitle}</h5>}
          {description && <p className={styles.description}>{description}</p>}
        </SkeletonCover>
      </div>
    )
  );

  return (
    <CoreBlock
      id={id}
      title={title}
      description={mainDescription}
      className={className}
      size={containerSize}
      testId={testId}
    >
      <div
        className={clsx(styles.detailsListContainer, {
          [styles['detailsListContainer--stacked']]: !carousel,
          [styles.forceWrap]: forceWrap,
        })}
      >
        {slides}
      </div>
      {carousel && (
        <Carousel
          className={clsx(styles.carousel, carouselClassName, { [styles.forceWrap]: forceWrap })}
          slides={slides}
          settings={{
            autoplay: false,
            infinite: false,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '0px',
            dots: true,
            swipeToSlide: true,
            ...carouselSettings,
          }}
          testId={`${testId}--carousel`}
        />
      )}
      {buttonText && (
        <Button className={styles.button} type={actionType} onClick={onButtonClick} testId={`${testId}--main-button`}>
          {buttonText}
        </Button>
      )}
      {subContent && <div className={styles.subContent}>{subContent}</div>}
    </CoreBlock>
  );
};

export default DetailsHorizontalBlock;
