import { FC } from 'react';

import clsx from 'clsx';

import { Carousel, Image } from 'components/common';

import CoreBlock from '../CoreBlock';
import styles from './style.module.scss';

type Props = {
  className?: string;
  providers?: Array<Provider>;
  testId?: string;
};

type Provider = {
  name: string;
  location: string;
  description: string;
  license: string;
  image: string;
  quote: string;
};

const defaultProviders = [
  // {
  //   image: 'meetYourProviders/lona.png',
  //   name: 'Lona Lyons',
  //   license: 'Licensed Massage Therapist',
  //   location: 'Indiana',
  //   description:
  //     'In her 20+ years as an MT, Lona has worked in spas, health clubs, hospitals, and as the proprietor of her own massage studio. Lona began her career in the Army, serving 4 years as a medical specialist, before shifting her focus to massage therapy. Lona is also an experienced social worker but enjoys helping others through massage most of all.',
  //   quote:
  //     '“Connecting with people through massage is so wonderful, I’m just passionate about it. You get to help people in the medical profession and in social work, but there’s something so powerful about the power of touch. With massage, I can treat 5 or 6 people and not get tired! I’m keyed in because I love it so much.”',
  // },
  // {
  //   image: 'meetYourProviders/marie.png',
  //   name: 'Marie Lamarre',
  //   license: 'Registered Nurse',
  //   location: 'New Jersey',
  //   description:
  //     'Marie Lamarre is a registered nurse with over a decade of experience in surgical ICUs, critical care facilities, and on-site care. She holds a BSN from the Rutgers School of Nursing and an MS in Nursing Informatics from NYU. Marie has supported state testing and vaccine efforts in New York & New Jersey and brings her wealth of experience to Zeel patients for home visits.',
  //   quote:
  //     '“Zeel has been a really positive experience. The patients are appreciative and extremely thankful that they can be tested safely in their homes. It’s really quick, convenient, and safe because you don’t have people standing in line for hours, increasing their risk of exposure.”',
  // },
  // {
  //   image: 'meetYourProviders/matthew.png',
  //   name: 'Matthew Laurie',
  //   license: 'Personal Trainer',
  //   location: 'New York',
  //   description:
  //     'Certified with the American College of Sports Medicine, Matthew Laurie holds both a BS and MS in Exercise Science. His passion for fitness began with his own weight loss journey, leading to career stops in multiple high-end sports clubs, and now exclusively with private clients, focusing on weight loss, strength training, and overall fitness.',
  //   quote:
  //     "“Nothing brings me greater joy than to help people feel better and move better. I couldn't be more appreciative of Zeel for providing the opportunity to do exactly that. I've watched the platform grow immensely before my eyes.”",
  // },
  // {
  //   image: 'meetYourProviders/mahri.png',
  //   name: 'Mahri Relin',
  //   license: 'Virtual Wellness Instructor',
  //   location: 'Colorado',
  //   description:
  //     'Mahri Relin uses her extensive research and experience as a dancer, personal trainer, and nutrition expert to provide clients with personalized, intelligent, and joyful fitness experiences. Mahri is a NASM Certified Personal Trainer, Corrective Exercise Specialist, Certified Pre/Postnatal Exercise Specialist, and Certified Wellness and Nutrition Consultant.',
  //   quote:
  //     '“I love leading mindfulness sessions as much as I love practicing it myself! Even if we’re not in the same room together, virtual wellness programs let me work directly with clients—addressing their unique wellness concerns with exercises they can use to continue building healthy practices that fit their lives.”',
  // },
  {
    image: 'meetYourProviders/jean-pierre.png',
    name: 'Jean-Pierre DeBarros',
    license: 'Licensed Massage Therapist',
    location: 'Miami, FL',
    description:
      'Jean-Pierre started his healthcare career as an exercise physiologist, later entering the Air Force, where he managed its largest healthcare clinic at Andrews Air Force Base. Jean-Pierre holds a BS in Exercise Physiology and an MS in Healthcare Administration.',
    quote:
      '“I got into massage so I could work directly with patients on their injuries, pain, and other conditions reducing their quality of life. Massage is part of a hands-on healthcare continuum—and can make a huge difference in people’s long-term wellbeing.”',
  },
];

const ProviderBlock: FC<Props> = ({ className = '', providers = defaultProviders, testId = 'providers-block' }) => {
  const slides = providers.map((provider, i) => {
    return (
      <div key={i} className={styles.insideSlide}>
        <Image className={styles.image} alt='mask' src={provider.image} />
        <div className={styles.info}>
          <h2>{provider.name}</h2>
          <p className={styles.location}>
            <span className={styles.license}>{provider.license},</span> {provider.location}
          </p>
          <p className={styles.description}>{provider.description}</p>
          <p className={styles.quote}>{provider.quote}</p>
        </div>
      </div>
    );
  });
  return (
    <CoreBlock natural size='normal' className={clsx(styles.section, className)} testId={testId}>
      <h1 data-testid={`${testId}--title`}>Meet Your Providers</h1>
      <Carousel
        className={styles.carousel}
        slideClassName={styles.slide}
        slides={slides}
        settings={{
          autoplay: false,
          slidesToShow: 1,
          dots: true,
          arrows: true,
          swipeToSlide: true,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: true,
                dots: true,
              },
            },
          ],
        }}
        testId={`${testId}--carousel`}
      />
    </CoreBlock>
  );
};

export default ProviderBlock;
