import { isAnyOf } from '@reduxjs/toolkit';

import { authApi } from './auth';

/**
 * Action matcher that will match any fulfilled api calls that may inform us that the user
 * has changed - via the "id" property from the transformed response and available
 * in the action payload. This action is useful for flows where the some or all of
 * the persisted data depends on who initiated the booking.
 *
 */
export const USER_HAS_LOGGED_IN_MATCHER = isAnyOf(
  /**
   * All auth endpoints which affects the active user
   */
  authApi.endpoints.signIn.matchFulfilled,
  authApi.endpoints.signUp.matchFulfilled,
  authApi.endpoints.signUpFromPartial__new.matchFulfilled,
  authApi.endpoints.signUpFromPartial__deprecated.matchFulfilled,
  authApi.endpoints.createPartialSession.matchFulfilled,
  authApi.endpoints.verifyOneTimeAccessToken.matchFulfilled
);

/**
 * Action matcher that will match when the user has logged out from react.
 * Note that it will not match for cases where the user is already logged out
 * when the app loads. Handle it in the transformIn of all persisted api slices
 */
export const USER_HAS_LOGGED_OUT_MATCHER = isAnyOf(
  authApi.endpoints.logout.matchFulfilled,
  authApi.endpoints.logoutAndRefresh.matchFulfilled
);
