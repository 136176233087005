import clsx from 'clsx';

import { IconSvg, Image } from 'components/common';

import styles from './style.module.scss';

type Props = {
  title?: string;
  description: string;
  image?: string;
  icon?: { name: string; size: number; color: string };
  imageClassName?: string;
  className?: string;
};

const Detail = (props: Props) => {
  const { title, description, image, icon, className, imageClassName } = props;
  return (
    <div className={clsx(styles.detail, className)}>
      {image && <Image className={clsx(styles.image, imageClassName)} src={image} />}
      {icon && (
        <IconSvg className={clsx(styles.image, imageClassName)} name={icon.name} color={icon.color} size={icon.size} />
      )}
      <div className={styles.info}>
        {title && <h3>{title}</h3>}
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Detail;
