import { FC, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import Info from './components/Info';
import styles from './style.module.scss';

type DataInfo = {
  title: ReactNode;
  description: ReactNode;
  right?: boolean;
  image?: string;
  image2?: string;
  className?: string;
  contentClassName?: string;
  action?: string | ((e: MouseEvent<HTMLButtonElement>) => void);
  actionText?: string;
  actionButton?: boolean;
  actionProps?: any;
  actionClassName?: string;
  parallax?: boolean;
  parallaxConfig?: [string, string];
  imageUrl?: string;
  imageClass?: string;
  image2Class?: string;
  imageAlt?: string;
};

type Props = {
  data: Array<DataInfo>;
  className?: string;
  testId?: string;
};

const InfosBlock: FC<Props> = ({ data, className = '', testId = 'infos-block' }) => {
  return (
    <div className={clsx(styles.block, className)} data-testid={testId}>
      {data.map((info, i) => (
        <Info
          key={i}
          title={info.title}
          description={info.description}
          right={info.right}
          image={info.image}
          imageClass={info.imageClass}
          imageAlt={info.imageAlt}
          image2={info.image2}
          image2Class={info.image2Class}
          className={info.className}
          contentClassName={info.contentClassName}
          action={info.action}
          actionText={info.actionText}
          actionButton={info.actionButton}
          actionProps={info.actionProps}
          actionClassName={info.actionClassName}
          parallax={info.parallax}
          parallaxConfig={info.parallaxConfig}
          imageUrl={info.imageUrl}
          testId={`${testId}--info-${i}`}
        />
      ))}
    </div>
  );
};

export default InfosBlock;
