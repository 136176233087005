import { FC, MouseEvent } from 'react';

import clsx from 'clsx';

import { Button } from 'components/common';

import styles from './style.module.scss';

type Props = {
  name: string;
  cost: number;
  details: string;
  hideButton?: boolean;
  buttonText: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  highlightCost: boolean;
  loading?: boolean;
  invalid?: boolean;
};

const Pricing: FC<Props> = ({
  name,
  loading = false,
  invalid = false,
  cost,
  details,
  hideButton = false,
  buttonText,
  onClick,
  highlightCost,
}) => {
  return (
    <div className={clsx(styles.pricing, { [styles.loading]: loading }, { [styles.invalid]: invalid })}>
      <h4>{name}</h4>
      <p className={clsx(styles.cost, { [styles.highlightCost]: highlightCost })}>${cost.toFixed(2)}</p>
      <p className={styles.details}>{details}</p>
      {!hideButton && (
        <Button className={styles.button} onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Pricing;
