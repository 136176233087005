import { FC } from 'react';

import { Carousel } from 'components/common';

import CoreBlock from '../CoreBlock';
import styles from './style.module.scss';

type Props = {
  id?: string;
  title?: string;
  description?: string;
  images?: Array<any>;
  testId?: string;
};

const GalleryBlock: FC<Props> = ({ id, title, description, images = [], testId = 'gallery-block' }) => {
  const imagesList = images.map((imageSrc) => {
    return (
      <div key={imageSrc} className={styles.imageWrapper}>
        <div className={styles.image} style={{ backgroundImage: `url(${imageSrc})` }} />
      </div>
    );
  });

  return (
    <CoreBlock id={id} title={title} description={description} className={styles.block} testId={testId}>
      <div className={styles.imagesGridContainer}>{imagesList}</div>
      <div className={styles.imagesCarouselContainer}>
        <Carousel
          className={styles.carousel}
          slides={imagesList}
          settings={{
            autoplay: true,
            autoplaySpeed: 4000,
            infinite: true,
            centerMode: true,
            centerPadding: '8px',
            slidesToShow: 1,
            swipeToSlide: true,
          }}
          testId={`${testId}--carousel`}
        />
      </div>
    </CoreBlock>
  );
};

export default GalleryBlock;
