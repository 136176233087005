import { FC, ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { useAuth } from 'utils/hooks';

import { useGetProductsQuery } from 'redux/apis/OG/estore';

import { Carousel } from 'components/common';

import Product from './components/Product';
import styles from './style.module.scss';

type Props = {
  id?: string;
  title?: ReactNode;
  className?: string;
  onProductClick?: (product: any) => void;
  testId?: string;
};

const ProductsBlock: FC<Props> = ({ id, title, className, onProductClick, testId = 'products-block' }) => {
  const { data: products } = useGetProductsQuery();

  // const filteredProducts = useMemo(() => {
  //   const _filtered = {};
  //   if (!products) return {};
  //   Object.keys(products).forEach((pId) => {
  //     const product = products[pId];
  //     if (!product.isSecondary) {
  //       _filtered[pId] = product;
  //     }
  //   });
  //   return _filtered;
  // }, [products]);

  const { isAuthenticated } = useAuth();
  const handleProductClick = (product) => {
    if (onProductClick) onProductClick(product);
  };

  const productsSlides = useMemo(() => {
    return (products || [])
      .filter((p) => !p._secondary)
      .map((product) => {
        const { name: slideName, _label_without_color, price, image, extra } = product;
        const props = {
          id: slideName,
          title: _label_without_color,
          image: image?.icon,
          description: extra?.slogan,
          price: price?.original,
          discountedPrice: price?.current,
          // showDiscount: isAuthenticated,
          strikeRegularPrice: !!price?.discount_type,
        };
        return <Product key={slideName} onClick={() => handleProductClick(product)} {...props} />;
      });
  }, [products, isAuthenticated]);

  return (
    <div id={id} className={clsx(styles.block, className)} data-testid={`${testId}`}>
      <h3 data-testid={`${testId}--title`}>{title || 'Products'}</h3>
      <Carousel
        className={styles.carousel}
        slides={productsSlides}
        settings={{
          autoplay: false,
          infinite: false,
          slidesToShow: 6,
          arrows: true,
          dots: true,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1.6,
              },
            },
          ],
        }}
        testId={`${testId}--carousel`}
      />
    </div>
  );
};

export default ProductsBlock;
