import { PaymentProcessor } from 'redux/apis/OG/payment';

import ApplePayConnector from '../connector';
import BraintreeConnector from './braintree';
import FortisConnector from './fortis';

export const Connectors: Partial<Record<PaymentProcessor, typeof ApplePayConnector>> = {
  [PaymentProcessor.Braintree]: BraintreeConnector,
  [PaymentProcessor.Fortis]: FortisConnector,
  [PaymentProcessor.Stripe]: BraintreeConnector,
};
