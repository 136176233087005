import { FC, useCallback } from 'react';

import { usePage } from 'utils/hooks';

import { useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import { themes } from '../../../../utils/theme-context';
import ConfigureRequestForm from '../../components/ConfigureRequestForm';
import styles from './style.module.scss';

type ModalEditCartRequestProps = ModalProps & { requestId: string };

const ModalEditCartRequest: FC<ModalEditCartRequestProps> = ({ requestId, onClose, ...rest }) => {
  const {
    selectors: { useRequestsSelector },
  } = useSpaSelfServeFlowSlice();

  const requests = useRequestsSelector();

  /** Effects **/
  const { isLoading } = usePage(async () => {
    const request = requests.find((r) => r.id === requestId);
    if (!request) onClose?.();
  });

  /** Handlers **/
  const save = useCallback(async () => {
    onClose?.();
  }, [onClose]);

  return (
    <Modal
      {...rest}
      onClose={() => onClose(false)}
      loading={isLoading}
      mobileType='drawer'
      className={styles.base}
      footerClassName={styles.footer}
      title='Edit Request'
      theme={themes.atWork}
    >
      {!isLoading && <ConfigureRequestForm fullSize editId={requestId} onSuccess={() => save()} />}
    </Modal>
  );
};

export default ModalEditCartRequest;
