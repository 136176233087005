import { FC } from 'react';

import clsx from 'clsx';

import { Image } from 'components/common';

import DetailsStackedBlock from '../DetailsStackedBlock';
import styles from './style.module.scss';

type Props = {
  className?: string;
  phoneClassName?: string;
  stackedItemsClassName?: string;
  phonePlace?: string;
  title?: string | JSX.Element;
  extraContent?: JSX.Element;
  data?: Array<{ icon: string; description: string }>;
  testId?: string;
};

const PhoneBlock: FC<Props> = ({
  className = '',
  phoneClassName = '',
  stackedItemsClassName = '',
  extraContent,
  title = '',
  data = [],
  phonePlace = 'left',
}) => {
  const phoneOnLeft = phonePlace === 'left';
  const phoneElement = <Image src='healthCarePlans/phone.png' className={clsx(styles.phone, phoneClassName)} />;
  const listElement = (
    <>
      <DetailsStackedBlock
        title={title}
        className={clsx(styles.stackedItems, stackedItemsClassName)}
        detailsClass={styles.details}
        data={data.map((item) => {
          return {
            icon: {
              name: item.icon,
              size: 40,
            },
            description: item.description,
            imageClassName: styles.icon,
          };
        })}
      />
      {extraContent}
    </>
  );

  return (
    <div className={clsx(styles.section, className, { [styles.phoneOnLeft]: phoneOnLeft })} data-testid='phone-block'>
      <div className={styles.leftSide}>
        {phoneOnLeft && phoneElement}
        {!phoneOnLeft && listElement}
      </div>
      <div className={styles.rightSide}>
        {phoneOnLeft && listElement}
        {!phoneOnLeft && phoneElement}
      </div>
    </div>
  );
};

export default PhoneBlock;
