import * as D from 'io-ts/Decoder';

import { DiscountType } from './constants';

/** Product */
export const ProductDecoder = D.partial({
  _color_id: D.nullable(D.string),
  _colors: D.nullable(
    D.array(
      D.partial({
        _color_id: D.string,
        _product_id: D.string,
        _hex: D.string,
      })
    )
  ),
  _secondary: D.nullable(D.boolean),
  _label_without_color: D.nullable(D.string),
  id: D.number,
  name: D.string,
  label: D.string,
  description: D.string,
  client_id: D.number,
  alvara_tax_code: D.nullable(D.string),
  service_id: D.number,
  intacct_item_id: D.string,
  price: D.struct({
    current: D.number,
    original: D.nullable(D.number),
    discount_type: D.nullable(D.literal(DiscountType.Zeelot, DiscountType.Provider, DiscountType.Sale)),
    available: D.struct({
      regular: D.number,
      zeelot: D.nullable(D.number),
      provider: D.nullable(D.number),
    }),
  }),
  image: D.struct({
    icon: D.nullable(D.string),
    thumbnail: D.nullable(D.string),
    large: D.nullable(D.string),
  }),
  extra: D.struct({
    slogan: D.nullable(D.string),
    photos: D.array(D.string),
    photos_wide: D.array(D.string),
    benefits: D.array(
      D.struct({
        title: D.string,
        icon: D.struct({
          svg: D.nullable(D.string),
          png: D.nullable(D.string),
        }),
      })
    ),
    zeel_take: D.struct({
      description: D.string,
      best_for: D.array(
        D.struct({
          label: D.nullable(D.string),
          icon: D.struct({
            svg: D.nullable(D.string),
            png: D.nullable(D.string),
          }),
        })
      ),
    }),
    includes: D.array(D.string),
    specifications: D.array(
      D.struct({
        label: D.string,
        description: D.string,
      })
    ),
    features: D.array(
      D.struct({
        title: D.string,
        description: D.string,
      })
    ),
  }),
});
export type ProductType = D.TypeOf<typeof ProductDecoder>;

export const OrderPayloadDecoder = D.partial({
  cart: D.array(
    D.struct({
      product_id: D.string,
      quantity: D.number,
    })
  ),
  shipping_address_id: D.string,
  payment_method_nonce: D.string,
  payment_method_type: D.string,
  payment_profile_id: D.string,
  transaction_eid: D.nullable(D.string),
  cart_hash: D.nullable(D.string),
});
export type OrderPayloadType = D.TypeOf<typeof OrderPayloadDecoder>;
