import { FC, useState } from 'react';

import { log } from 'utils';
import { usePage } from 'utils/hooks';

import { useLazyGetMembershipAgreementQuery } from 'redux/apis/OG/user';

import { Html } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  planId: string;
};

const MembershipAgreement: FC<Props> = ({ planId, ...rest }) => {
  const [terms, setTerms] = useState<any>(null);
  const [useGetMembershipAgreementQuery] = useLazyGetMembershipAgreementQuery();

  const { isLoading } = usePage(async ({ _user }) => {
    try {
      const _terms = await useGetMembershipAgreementQuery({
        planId,
        memberId: _user?.id,
      }).unwrap();
      setTerms(_terms);
    } catch (error) {
      log.error(error);
    }
  });

  return (
    <Modal
      {...rest}
      loading={isLoading}
      title='Membership Agreement'
      actions={[{ label: 'I Accept', action: 'confirm' }]}
      testId='membership-agreement-modal'>
      <Html
        className={`${styles.modalBody} ${styles.justify} ${styles.agreementText}`}
        content={terms || ''}
        testId='membership-agreement-modal--html'
      />
    </Modal>
  );
};

export default MembershipAgreement;
