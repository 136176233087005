import { FC, useMemo } from 'react';

import clsx from 'clsx';

import { PMCError, PMCErrorMessage } from '../../types';
import styles from './style.module.scss';

export type FailureProps = {
  failure?: PMCError;
  success?: string | boolean;
  showDetails?: boolean;
};

const StatusView: FC<FailureProps> = ({ success, failure, showDetails }) => {
  const message = useMemo(() => {
    if (success) return typeof success === 'string' ? success : 'Thank you! Your payment profile has been saved.';
    if (failure) return PMCErrorMessage[failure.code];
  }, [success, failure]);

  return (
    <div className={clsx(styles.base, { [styles.failure]: !!failure, [styles.success]: !failure && !!success })}>
      <p className={styles.message}>{message}</p>
      {showDetails && failure && (failure as PMCError)?.message && (
        <p className={styles.details}>
          ERROR:
          <br />
          {(failure as PMCError).message}
        </p>
      )}
    </div>
  );
};

export default StatusView;
