import { FC, ReactNode, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';

import { useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Carousel, LinkButton, SelectionCard } from 'components/common';
import { IconProps } from 'components/common/IconSvg';

import styles from './style.module.scss';

type Tile = {
  id?: string;
  title: string;
  description?: string;
  className?: string;
  image?: string;
  icon?: string;
  iconProps?: IconProps;
  article?: Article;
  _hash?: string | number;
};

type Props = {
  id?: string;
  title?: ReactNode;
  description?: ReactNode;
  defaultTileId?: string;
  tileClassName?: string;
  onTileChange?: (tile: Tile) => void;
  tiles: Array<Tile>;
  ctaUnderneathImage?: boolean;
  testId?: string;
  extraAction?: ActionData;
};

type Article = {
  image: string;
  imageMobile?: string;
  content: ReactNode;
  action?: ActionData;
  subAction?: ActionData;
};

type ActionData = {
  type: string;
  text: string;
  buttonType?: string;
  onClick?: () => void;
  href?: string;
};

const ArticlesWithTilesBlock: FC<Props> = ({
  id,
  title,
  description,
  tileClassName,
  onTileChange,
  tiles: _tiles = [],
  ctaUnderneathImage,
  extraAction,
  testId = 'articles-with-tiles-block',
}) => {
  const [tileIndex, setTileIndex] = useState<number>(0);
  const navigate = useNavigate();

  const getActionElement = (data: ActionData) => {
    const { type = 'button', text, buttonType, onClick, href } = data;

    if (type === 'button') {
      return (
        <Button
          type={buttonType || 'primary'}
          className={styles.actionButton}
          onClick={href ? () => navigate(href) : onClick}
          testId={`${testId}--button`}
        >
          {text}
        </Button>
      );
    } else if (type === 'link') {
      return (
        <LinkButton className={styles.actionLink} onClick={onClick} href={href} testId={`${testId}--link`}>
          {text}
        </LinkButton>
      );
    }
  };

  const article = (_tiles[Math.round(tileIndex)] || {}).article;
  const {
    image: articleImage,
    imageMobile: articleImageMobile,
    content: articleContent,
    action: articleAction,
    subAction: articleSubAction,
  } = article || {};
  let articleImageUrl = null;
  const isMobile = helpers.isMobileWidth();
  if (articleImage) {
    articleImageUrl = helpers.getImagePath(`${isMobile && articleImageMobile ? articleImageMobile : articleImage}`);
  }

  const tiles: ReactNode[] = _tiles.map(
    ({ title: tileTitle, description: tileDescription, className: tClassName, image, icon, iconProps }, i) => {
      return (
        <SelectionCard
          key={tileTitle}
          title={tileTitle}
          description={tileDescription}
          image={image}
          icon={icon}
          iconProps={iconProps}
          selected={tileIndex === i}
          onClick={() => {
            setTileIndex(i);
            if (onTileChange) onTileChange(_tiles[i]);
          }}
          className={clsx(styles.tile, tileClassName, tClassName)}
          imageClassName={styles['tile-icon']}
          testId={`${testId}--selection-card-${i}`}
        />
      );
    }
  );
  return (
    <div id={id} className={clsx(styles.block)}>
      <div className={styles.glContainer}>
        {title && <h3 data-testid={`${testId}--title`}>{title}</h3>}
        {description && (
          <p className={styles.description} data-testid={`${testId}--description`}>
            {description}
          </p>
        )}
        <div className={styles.tilesContainer}>{tiles}</div>
      </div>
      <Carousel
        className={styles.carousel}
        slides={tiles}
        settings={{
          autoplay: false,
          infinite: false,
          slidesToShow: 5,
          dots: false,
          swipeToSlide: true,
          afterChange: (index) => setTileIndex(Math.round(index)),
          responsive: [
            {
              breakpoint: 1477,
              settings: {
                slidesToShow: 4.2,
              },
            },
            {
              breakpoint: 1214,
              settings: {
                slidesToShow: 3.2,
              },
            },
            {
              breakpoint: 935,
              settings: {
                slidesToShow: 2.2,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1.2,
              },
            },
          ],
        }}
        testId={`${testId}--carousel`}
      />
      {article && (
        <div className={clsx(styles.glContainerSmall, styles.articleContainer)}>
          <div className={styles.imageArticleContainer}>
            <div className={styles.imageArticle} style={{ backgroundImage: `url(${articleImageUrl})` }} />
            {ctaUnderneathImage && !isMobile && (
              <div className={styles.ctaUnderneathImage}>
                {articleAction && getActionElement(articleAction)}
                {articleSubAction && getActionElement(articleSubAction)}
              </div>
            )}
          </div>
          <div className={styles.content}>{articleContent}</div>
        </div>
      )}
      {(!ctaUnderneathImage || isMobile) && (
        <div className={clsx(styles.glContainerSmall, styles.actionsContainer)}>
          {articleAction && getActionElement(articleAction)}
          {articleSubAction && getActionElement(articleSubAction)}
        </div>
      )}
      {extraAction && (
        <div className={clsx(styles.glContainerSmall, styles.actionsContainer)}>
          {extraAction && getActionElement(extraAction)}
        </div>
      )}
    </div>
  );
};

export default ArticlesWithTilesBlock;
