import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { helpers, routes } from 'utils';

import { rollbar } from '@zeel-dev/zeel-ui';

import { getCookie } from '../../../utils/helpers';
import { WEBVIEW_SOURCE_COOKIE_NAME } from '../../../utils/hooks/useUi';
import { getTokenCookie, refreshLastActivityCookie, setTokenCookie } from './auth/utils';
import { API_KEY, BASE_URL, TAGS } from './constants';
import './mappings';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
});

export const OGApi = createApi({
  reducerPath: 'OGApi',
  baseQuery: async (args, api, extraOptions) => {
    const extraParams: {
      api_key: string;
      token?: string;
      session?: string;
    } = {
      api_key: API_KEY,
    };

    if (!args.excludeTokenCookie) {
      const token = getTokenCookie();
      if (token) extraParams.token = token;
    }

    args.params = { ...(args.params || {}), ...extraParams };

    if (args.method === 'GET' && args.params && !Object.hasOwn(args.params, '_data')) args.params._data = '';
    if (args.method === 'POST' && args.body && !Object.hasOwn(args.body, '_data')) args.body._data = '';

    const result: any = await baseQuery(args, api, extraOptions);

    if (result?.meta?.response?.status === 503 && window.location.pathname !== routes.MAINTENANCE({ host: false })) {
      window.location.href = routes.MAINTENANCE({ host: true });
    }

    const isSessionExpired =
      (!!result?.error?.data?.errors?.authentication &&
        !(result?.meta?.request?.url || '').includes('api/v1/member/login') &&
        !(result?.meta?.request?.url || '').includes('api/v1/limited/reimbursement_for_zip')) ||
      result?.error?.data?.errors?.access_token_expired;

    const newToken = result?.data?.response?.token || result?.data?.response?.temp?.token;
    if (!helpers.isInjected() && newToken && !getCookie(WEBVIEW_SOURCE_COOKIE_NAME)) {
      setTokenCookie(newToken);
    } else if (isSessionExpired) {
      if (!getCookie(WEBVIEW_SOURCE_COOKIE_NAME)) {
        console.log('Session expired. Logging out and refreshing');
        // Dynamically importing auth module to avoid a circular dependency since OGApi is null
        import('./auth/endpoints').then((module) => {
          api.dispatch(module.authApi.endpoints.logoutAndRefresh.initiate());
        });
      } else {
        console.log(
          `Session expired. Skipping logout and refresh due to ${WEBVIEW_SOURCE_COOKIE_NAME} cookie being set to ${getCookie(
            WEBVIEW_SOURCE_COOKIE_NAME
          )}`
        );
      }
    }

    if (result?.data instanceof Blob) {
      return result;
    } else if (result?.data) {
      if (helpers.onVa()) refreshLastActivityCookie();
      return { ...result, data: result.data.response };
    } else if (result?.error) {
      if (result?.error && result.error !== 'authentication') {
        if (result?.error?.data?.errors) console.error('OG Api error', result.error.data.errors);
        rollbar.error(`OG Api error - ${Object.keys(result?.error?.data?.errors || {}).join(', ')}`, {
          request: args,
          errors: result?.error,
          response: result?.data?.response,
        });
      }

      return { ...result, error: result.error?.data?.errors || {} };
    } else return result;
  },
  endpoints: () => ({}),
  tagTypes: Object.values(TAGS),
});
