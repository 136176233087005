import { FC } from 'react';

import { DetailsHorizontalBlock } from 'components/blocks';

import styles from './style.module.scss';

/**
 * How it works section for the Spa Self Serve flow.
 * Includes a list of steps for the user to follow.
 * It is currently displayed on the intro and requests pages.
 */
const HowItWorks: FC = () => {
  return (
    <div className={styles.base}>
      <h3>How it Works</h3>
      <DetailsHorizontalBlock
        containerSize={'small'}
        className={styles.detailsBlock}
        data={[
          {
            title: '1. Request a Booking',
            description: 'Use our booking tool to set your staffing preferences and location.',
            iconName: 'clipboard-star',
          },
          {
            title: '2. Get Confirmed',
            description: 'We find therapists that fit your staffing needs and schedule.',
            iconName: 'bullet-list-progress',
          },
          {
            title: '3. Therapist Arrives',
            description: 'Therapist arrives at the specified time and place to deliver treatments.',
            iconName: 'user',
          },
        ]}
      />
    </div>
  );
};

export default HowItWorks;
