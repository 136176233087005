import Modal, { ModalProps } from '../../templates/ModalNew';
import styles from './style.module.scss';

export type PTCurrentPatientsType = ModalProps;

export default function PTCurrentPatients({ ...rest }: PTCurrentPatientsType) {
  return (
    <Modal
      {...rest}
      title='Current Patients'
      className={styles.base}
      mobileType={'drawer'}
      testId='physical-therapy-current-patients-modal'>
      If you have questions about your account, your upcoming appointments, or billing matters, please email{' '}
      <b className={styles.email}>help@zeel.com</b> or call <b>877-GET-ZEEL</b>.
      <br />
      <br />
      For questions or concerns about your unique PT program, you may contact your physical therapist as you have been
      until your course of care is complete.
    </Modal>
  );
}
