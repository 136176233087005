import { FC } from 'react';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps;

const Alert: FC<Props> = ({ ...rest }) => {
  return (
    <Modal
      {...rest}
      mobileType={'drawer'}
      className={styles.modal}
      iconsBarClassName={styles.iconsBar}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      testId={'alert-modal'}
    />
  );
};

export default Alert;
