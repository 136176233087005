import { FC, useCallback, useMemo, useState } from 'react';

import { routes } from 'utils';
import { useLocation, usePage, useUser } from 'utils/hooks';

import { useGetClientListQuery } from 'redux/apis/OG/spa';
import { Steps, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { Card, EditPencilBoldIcon, Group, useNavigate } from '@zeel-dev/zeel-ui';
import { FieldTitle, LinkButtonFlow } from 'components/common';

import Layout, { Body, Footer } from '../../components/Layout';
import styles from './style.module.scss';

/**
 * Locations step for Spa Self-Serve flow.
 * Allows user to manage their spa locations.
 */
const Locations: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //const { openModal } = useModals();
  const { isTempMember, isLoading: isUserLoading } = useUser();
  const {
    actions: { defaultSpaChanged },
  } = useSpaSelfServeFlowSlice();

  /** States **/
  const [selectedSpaHash, setSelectedSpaHash] = useState<string | null>(null);

  /** Queries **/
  const { data: clientsList, isLoading: isClientsListLoading } = useGetClientListQuery(
    { bookingType: 'spa_self_serve' },
    {
      skip: isUserLoading || isTempMember,
    }
  );

  /** Memoized values **/
  const selectedSpa = useMemo(() => {
    if (!selectedSpaHash) return null;
    const [clientId, locationId] = selectedSpaHash.split('.');
    return { clientId, locationId };
  }, [selectedSpaHash]);

  /** Effects **/
  usePage(() => {
    const { initialSpa } = location?.state || {};
    if (initialSpa && initialSpa.clientId && initialSpa.locationId) {
      setSelectedSpaHash(`${initialSpa.clientId}.${initialSpa.locationId}`);
    }
  });

  const savedSpaOptions = useMemo(() => {
    const options = [];
    (clientsList || []).forEach((client) => {
      (client.addresses || []).forEach((address) => {
        options.push({
          value: `${client.id}.${address.id}`,
          label: `${client.name}`,
          address: [address.address1, address.address2, address.city, address.state].filter(Boolean).join(', '),
        });
      });
    });
    return options;
  }, [clientsList]);

  /** Handlers **/
  const handleAddNewLocation = useCallback(() => {
    navigate(routes.SPA_SELF_SERVE.LOCATION_INFO(), { state: { preventRedirect: true } });
  }, []);

  const handleEditLocation = useCallback((id: string) => {
    const [clientId, locationId] = id.split('.');
    navigate(routes.SPA_SELF_SERVE.LOCATION_INFO(), {
      state: { spa: { clientId, locationId }, preventRedirect: true, next: routes.SPA_SELF_SERVE.REQUESTS() },
    });
  }, []);

  /*
  const handleDeleteLocation = useCallback(
    (id: string) => {
      const option = savedSpaOptions.find((spa) => spa.value === id);
      if (!option) return;
      openModal(
        {
          element: (
            <ModalConfirmation
              title={`Delete ${option.label} (${option.address})?`}
              description='This cannot be undone.'
              hideCancel
              confirmLabel='Delete Spa'
              slim
              confirmButtonVariant='destructive-alt'
            />
          ),
        },
        (response) => {
          if (response) {
            // TODO: Delete Spa mutation
          }
        }
      );
    },
    [savedSpaOptions]
  );
  */

  const submit = useCallback(() => {
    if (!selectedSpa) return;
    defaultSpaChanged(selectedSpa);
    const { next } = location?.state || {};
    navigate(next || routes.SPA_SELF_SERVE.REQUESTS());
  }, [selectedSpa, defaultSpaChanged]);

  return (
    <Layout
      loading={isClientsListLoading}
      skeleton={isClientsListLoading}
      tags={{
        title: 'Spa Self-Serve - Locations',
      }}
      step={Steps.Locations}
      testId='spa-self-serve--locations-step'
      showTimeline
      title='Manage your Spa Location'
      backAction={() => navigate(routes.SPA_SELF_SERVE.REQUESTS())}
    >
      <Body size='narrow'>
        <FieldTitle>Spa Location</FieldTitle>
        <Group columns={1} fullWidth gutter>
          {savedSpaOptions.map((spa) => (
            <Card
              key={spa.value}
              className={styles.card}
              onClick={() => setSelectedSpaHash(spa.value)}
              full
              selected={selectedSpaHash === spa.value}
              actions={[
                {
                  icon: <EditPencilBoldIcon size={20} className={styles.editIcon} />,
                  onClick: () => handleEditLocation(spa.value),
                } /*,
                {
                  icon: <GarbageTrashDeleteBoldIcon size={20} className={styles.deleteIcon} />,
                  onClick: () => handleDeleteLocation(spa.value),
                }*/,
              ]}
            >
              <b>{spa.label}</b>
              <p>{spa.address}</p>
            </Card>
          ))}
        </Group>
        <LinkButtonFlow className={styles.addNewLink} onClick={handleAddNewLocation} icon={'add-circle'}>
          Add New Spa Location
        </LinkButtonFlow>
      </Body>
      <Footer
        actions={[
          {
            type: 'button',
            label: 'Continue',
            onClick: submit,
            componentProps: {
              disabled: !selectedSpa,
            },
          },
        ]}
      />
    </Layout>
  );
};

export default Locations;
