import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

export const SpaClientAddressMapping = registerMapping<T.SourceSpaClientAddressType, T.SpaClientAddressType>(
  'SpaClientAddress',
  {
    operations: [
      ['toString', { sourceProperty: 'id' }],
      ['copy', { sourceProperties: ['name', 'address1', 'address2', 'city', 'state'] }],
      ['toString', { sourceProperty: 'zip', targetProperty: 'zipCode' }],
      ['copy', { sourceProperty: 'check_in', targetProperty: 'checkInInstructions' }],
      ['copy', { sourceProperty: 'check_out', targetProperty: 'checkOutInstructions' }],
      ['copy', { sourceProperty: 'dress_code', targetProperty: 'dressCode' }],
      ['copy', { sourceProperty: 'parking', targetProperty: 'parkingInstructions' }],
    ],
    decoders: {
      source: T.SourceSpaClientAddressDecoder,
      target: T.SpaClientAddressDecoder,
    },
  }
);

export const SpaClientMapping = registerMapping<T.SourceSpaClientType, T.SpaClientType>('SpaClient', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['copy', { sourceProperties: ['name', 'logo'] }],
    ['copy', { sourceProperty: 'logo_sm', targetProperty: 'logoSmall' }],
    ['copy', { sourceProperty: 'logo_small_path', targetProperty: 'logoSmallPath' }],
    ['copy', { sourceProperty: 'logo_regular_path', targetProperty: 'logoPath' }],
    ['compose', { sourceProperty: 'addresses', mapper: 'SpaClientAddress' }],
    ['copy', { sourceProperty: 'booking_flow', targetProperty: 'bookingType' }],
  ],
  decoders: {
    source: T.SourceSpaClientDecoder,
    target: T.SpaClientDecoder,
  },
});

export const SpaGigLengthOptionMapping = registerMapping<T.SourceSpaGigLengthOptionType, T.SpaGigLengthOptionType>(
  'SpaGigLengthOption',
  {
    operations: [
      ['copy', { sourceProperties: ['label'] }],
      ['copy', { sourceProperty: 'merged_label', targetProperty: 'mergedLabel' }],
      ['toString', { sourceProperty: 'minimum_value', targetProperty: 'minimumValue' }],
      ['copy', { sourceProperty: 'minimum_label', targetProperty: 'minimumLabel' }],
      ['toString', { sourceProperty: 'value' }],
    ],
    decoders: {
      source: T.SourceSpaGigLengthOptionDecoder,
      target: T.SpaGigLengthOptionDecoder,
    },
  }
);

export const SpaBookingRequestAppointmentMapping = registerMapping<
  T.SourceSpaBookingRequestAppointmentType,
  T.SpaBookingRequestAppointmentType
>('SpaBookingRequestAppointment', {
  operations: [
    ['copy', { sourceProperties: ['length', 'service', 'notes', 'therapist'] }],
    ['copy', { sourceProperty: 'target__member_fname', targetProperty: 'targetMemberFirstName' }],
    ['copy', { sourceProperty: 'target__member_lname', targetProperty: 'targetMemberLastName' }],
    ['copy', { sourceProperty: 'therapist_gender_requested', targetProperty: 'therapistGenderRequested' }],
    ['copy', { sourceProperty: 'dev_force_failure', targetProperty: 'devForceFailure' }],
  ],
  decoders: {
    source: T.SourceSpaBookingRequestAppointmentDecoder,
    target: T.SpaBookingRequestAppointmentDecoder,
  },
});

export const SpaBookingRequestMapping = registerMapping<T.SourceSpaBookingRequestType, T.SpaBookingRequestType>(
  'SpaBookingRequest',
  {
    operations: [
      [
        'copy',
        { sourceProperties: ['category', 'single', 'couples', 'orientation', 'gig', 'chair', 'location', 'client'] },
      ],
      ['copy', { sourceProperty: 'back_to_back', targetProperty: 'backToBack' }],
      ['copy', { sourceProperty: 'rebooking_flow', targetProperty: 'rebookingFlow' }],
      ['copy', { sourceProperty: 'for_concierge_client', targetProperty: 'forConciergeClient' }],
      ['copy', { sourceProperty: 'card_id', targetProperty: 'paymentMethodId' }],
      ['copy', { sourceProperty: 'fail_on_duplicate_appointment', targetProperty: 'failOnDuplicateAppointment' }],
      ['copy', { sourceProperty: 'request_time', targetProperty: 'requestTime' }],
      ['compose', { sourceProperty: 'appointments', mapper: 'SpaBookingRequestAppointment' }],
    ],
    decoders: {
      source: T.SourceSpaBookingRequestDecoder,
      target: T.SpaBookingRequestDecoder,
    },
  }
);

export const SpaBookingAppointmentMapping = registerMapping<
  T.SourceSpaBookingAppointmentType,
  T.SpaBookingAppointmentType
>('SpaBookingAppointment', {
  operations: [['toString', { sourceProperty: 'id' }]],
  decoders: {
    source: T.SourceSpaBookingAppointmentDecoder,
    target: T.SpaBookingAppointmentDecoder,
  },
});

export const SpaBookingMapping = registerMapping<T.SourceSpaBookingType, T.SpaBookingType>('SpaBooking', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    ['toString', { sourceProperty: 'parent_id', targetProperty: 'parentId' }],
    [
      'copy',
      {
        sourceProperties: [
          'stage',
          'notes',
          'start',
          'time',
          'member',
          'duration',
          'end',
          'tip',
          'subtotal',
          'pricing',
        ],
      },
    ],
    ['copy', { sourceProperty: 'category_2', targetProperty: 'category' }],
    [
      'boolLiteralToBool',
      {
        sourceProperty: 'specific_therapist_requested',
        targetProperty: 'specificTherapistRequested',
      },
    ],
    ['copy', { sourceProperty: 'therapist_gender_requested', targetProperty: 'therapistGenderRequested' }],
    ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
    ['copy', { sourceProperty: 'for_guest', targetProperty: 'forGuest' }],
    ['copy', { sourceProperty: 'address_name', targetProperty: 'addressName' }],
    ['boolLiteralToBool', { sourceProperty: 'is_block_project', targetProperty: 'isBlockProject' }],
    ['boolLiteralToBool', { sourceProperty: 'is_orientation', targetProperty: 'isOrientation' }],
    ['copy', { sourceProperty: 'start_nyc', targetProperty: 'startNyc' }],
    ['copy', { sourceProperty: 'now_nyc', targetProperty: 'nowNyc' }],
    ['copy', { sourceProperty: 'provider_id', targetProperty: 'providerId' }],
    ['copy', { sourceProperty: 'member_id', targetProperty: 'memberId' }],
    ['boolLiteralToBool', { sourceProperty: 'past_scheduled', targetProperty: 'pastScheduled' }],
    ['boolLiteralToBool', { sourceProperty: 'happening_now_scheduled', targetProperty: 'happeningNowScheduled' }],
    ['boolLiteralToBool', { sourceProperty: 'future_scheduled', targetProperty: 'futureScheduled' }],
    ['copy', { sourceProperty: 'num_of_appointments', targetProperty: 'numOfAppointments' }],
    ['boolLiteralToBool', { sourceProperty: 'is_cancelable', targetProperty: 'isCancelable' }],
    ['boolLiteralToBool', { sourceProperty: 'is_canceled', targetProperty: 'isCanceled' }],
    ['copy', { sourceProperty: 'gig_appoitment_duration', targetProperty: 'gigAppointmentDuration' }],
    ['boolLiteralToBool', { sourceProperty: 'tip_cash', targetProperty: 'tipCash' }],
    ['boolLiteralToBool', { sourceProperty: 'tip_refused', targetProperty: 'tipRefused' }],
    ['copy', { sourceProperty: 'batch_booked_child_link', targetProperty: 'batchBookedChildLink' }],
    ['copy', { sourceProperty: 'duration_hours', targetProperty: 'durationHours' }],
    ['copy', { sourceProperty: 'end_nyc', targetProperty: 'endNyc' }],
    ['compose', { sourceProperty: 'provider', mapper: 'Provider' }],
    [
      'compose',
      { sourceProperty: 'child_appts', targetProperty: 'childAppointments', mapper: 'SpaBookingAppointment' },
    ],
  ],
  decoders: {
    source: T.SourceSpaBookingDecoder,
    target: T.SpaBookingDecoder,
  },
});
