import { FC, ReactNode, useContext } from 'react';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import CoreBlock from '../CoreBlock';
import styles from './style.module.scss';

type Props = {
  className?: string;
  containerClassName?: string;
  statClassName?: string;
  title?: ReactNode;
  description?: ReactNode;
  infoClassName?: string;
  content?: ReactNode;
  data?: {
    iconComponent: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    subdescription?: ReactNode;
  }[];
  small?: boolean;
  theme?: string;
  testId?: string;
};

const StatsBlock: FC<Props> = ({
  data,
  className,
  containerClassName,
  statClassName,
  infoClassName,
  title,
  description,
  content,
  small,
  theme: propTheme,
  testId = 'stats-block',
}) => {
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  return (
    <CoreBlock
      className={clsx(styles.block, styles[`theme-${theme}`], { [styles.small]: small }, className)}
      title={title}
      description={description}
      size='small'
      testId={testId}>
      <div className={clsx(styles.statsContainer, containerClassName)}>
        {data.map(
          (stat, index) =>
            stat && (
              <div key={index} className={clsx(styles.stat, statClassName)} data-testid={`${testId}--stat-${index}`}>
                {stat.iconComponent}
                <div className={clsx(styles.info, infoClassName)}>
                  <p className={styles.title}>{stat.title}</p>
                  <p className={styles.description}>{stat.description}</p>
                  {stat.subdescription && <p className={styles.subdescription}>{stat.subdescription}</p>}
                </div>
              </div>
            )
        )}
        {content}
      </div>
    </CoreBlock>
  );
};

export default StatsBlock;
