import * as D from 'io-ts/Decoder';

import { BookingCategoryDecoder, GenderRequestedDecoder, ProviderDecoder, SourceProviderDecoder } from '../booking';
import { BooleanLiteralDecoder, StringOrNumberDecoder } from '../types';

/** Gig Length Option */
export const SourceSpaGigLengthOptionDecoder = D.partial({
  value: D.nullable(StringOrNumberDecoder),
  label: D.nullable(D.string),
  merged_label: D.nullable(D.string),
  minimum_value: D.nullable(StringOrNumberDecoder),
  minimum_label: D.nullable(D.string),
});
export type SourceSpaGigLengthOptionType = D.TypeOf<typeof SourceSpaGigLengthOptionDecoder>;
export const SpaGigLengthOptionDecoder = D.partial({
  value: D.nullable(D.string),
  label: D.nullable(D.string),
  mergedLabel: D.nullable(D.string),
  minimumValue: D.nullable(D.string),
  minimumLabel: D.nullable(D.string),
});
export type SpaGigLengthOptionType = D.TypeOf<typeof SpaGigLengthOptionDecoder>;

/** Length Option */
export const SourceSpaLengthOptionDecoder = D.partial({
  value: D.nullable(D.number),
  label: D.nullable(D.string),
  minimum_value: D.nullable(D.number),
  minimum_label: D.nullable(D.string),
});
export type SourceSpaLengthOptionType = D.TypeOf<typeof SourceSpaLengthOptionDecoder>;
export const SpaLengthOptionDecoder = D.partial({
  value: D.nullable(D.number),
  label: D.nullable(D.string),
  minimumValue: D.nullable(D.number),
  minimumLabel: D.nullable(D.string),
});
export type SpaLengthOptionType = D.TypeOf<typeof SpaLengthOptionDecoder>;

/** Booking Preference */
export const SpaBookingPreferenceDecoder = D.partial({
  rack: D.nullable(
    D.partial({
      lengths: D.nullable(
        D.array(
          D.partial({
            name: D.nullable(D.string),
            value: D.nullable(D.number),
            default: D.boolean,
          })
        )
      ),
      services: D.nullable(
        D.array(
          D.partial({
            name: D.nullable(D.string),
            value: D.nullable(D.number),
            default: D.boolean,
          })
        )
      ),
    })
  ),
  gig: D.nullable(
    D.partial({
      lengths: D.nullable(
        D.partial({
          gig: D.nullable(
            D.array(
              D.partial({
                name: D.nullable(D.string),
                value: D.nullable(D.number),
                default: D.boolean,
                minimum_name: D.nullable(D.string),
                merged_name: D.nullable(D.string),
              })
            )
          ),
          appointment: D.nullable(
            D.array(
              D.partial({
                name: D.nullable(D.string),
                value: D.nullable(D.number),
                default: D.boolean,
              })
            )
          ),
        })
      ),
      services: D.nullable(
        D.array(
          D.partial({
            name: D.nullable(D.string),
            value: D.nullable(D.number),
            default: D.boolean,
          })
        )
      ),
    })
  ),
});
export type SpaBookingPreferenceType = D.TypeOf<typeof SpaBookingPreferenceDecoder>;

/** Client Address */
export const SourceSpaClientAddressDecoder = D.partial({
  id: StringOrNumberDecoder,
  name: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  state: D.nullable(D.string),
  zip: D.nullable(StringOrNumberDecoder),
  check_in: D.nullable(D.string),
  check_out: D.nullable(D.string),
  dress_code: D.nullable(D.string),
  parking: D.nullable(D.string),
});
export type SourceSpaClientAddressType = D.TypeOf<typeof SourceSpaClientAddressDecoder>;
export const SpaClientAddressDecoder = D.partial({
  id: D.string,
  name: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  state: D.nullable(D.string),
  zipCode: D.nullable(D.string),
  checkInInstructions: D.nullable(D.string),
  checkOutInstructions: D.nullable(D.string),
  dressCode: D.nullable(D.string),
  parkingInstructions: D.nullable(D.string),
});
export type SpaClientAddressType = D.TypeOf<typeof SpaClientAddressDecoder>;

/** Client */
export const SourceSpaClientDecoder = D.partial({
  id: StringOrNumberDecoder,
  name: D.string,
  logo: D.nullable(D.string),
  logo_sm: D.nullable(D.string),
  logo_small_path: D.nullable(D.string),
  logo_regular_path: D.nullable(D.string),
  addresses: D.nullable(D.array(SourceSpaClientAddressDecoder)),
  booking_flow: D.literal('spa_premium', 'spa_self_serve', 'concierge'),
});
export type SourceSpaClientType = D.TypeOf<typeof SourceSpaClientDecoder>;
export const SpaClientDecoder = D.partial({
  id: D.string,
  name: D.string,
  logo: D.nullable(D.string),
  logoPath: D.nullable(D.string),
  logoSmall: D.nullable(D.string),
  logoSmallPath: D.nullable(D.string),
  addresses: D.nullable(D.array(SpaClientAddressDecoder)),
  bookingType: D.literal('spa_premium', 'spa_self_serve', 'concierge'),
});
export type SpaClientType = D.TypeOf<typeof SpaClientDecoder>;

/** Request */
export const SourceSpaBookingRequestAppointmentDecoder = D.partial({
  target__member_fname: D.nullable(D.string),
  target__member_lname: D.nullable(D.string),
  length: D.nullable(D.number),
  service: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  therapist_gender_requested: D.nullable(D.literal('bm', 'fp', 'mp', 'm', 'f')),
  therapist: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  notes: D.nullable(D.string),
  dev_force_failure: D.nullable(D.boolean),
});
export type SourceSpaBookingRequestAppointmentType = D.TypeOf<typeof SourceSpaBookingRequestAppointmentDecoder>;
export const SpaBookingRequestAppointmentDecoder = D.partial({
  targetMemberFirstName: D.nullable(D.string),
  targetMemberLastName: D.nullable(D.string),
  length: D.nullable(D.number),
  service: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  therapistGenderRequested: D.nullable(D.literal('bm', 'fp', 'mp', 'm', 'f')),
  therapist: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  notes: D.nullable(D.string),
  devForceFailure: D.nullable(D.boolean),
});
export type SpaBookingRequestAppointmentType = D.TypeOf<typeof SpaBookingRequestAppointmentDecoder>;

export const SourceSpaBookingRequestDecoder = D.partial({
  appointments: D.nullable(D.array(SourceSpaBookingRequestAppointmentDecoder)),
  category: D.nullable(D.literal('single', 'couples', 'back_to_back', 'orientation', 'gig', 'chair')),
  single: D.nullable(D.boolean),
  couples: D.nullable(D.boolean),
  rebooking_flow: D.nullable(D.boolean),
  back_to_back: D.nullable(D.boolean),
  orientation: D.nullable(D.boolean),
  gig: D.nullable(D.boolean),
  for_concierge_client: D.nullable(D.boolean),
  chair: D.nullable(D.boolean),
  card_id: D.nullable(StringOrNumberDecoder),
  location: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
      zip: D.nullable(D.string),
    })
  ),
  client: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  fail_on_duplicate_appointment: D.nullable(D.boolean),
  request_time: D.nullable(
    D.partial({
      start: D.nullable(D.number),
      end: D.nullable(D.number),
    })
  ),
});
export type SourceSpaBookingRequestType = D.TypeOf<typeof SourceSpaBookingRequestDecoder>;
export const SpaBookingRequestDecoder = D.partial({
  appointments: D.nullable(D.array(SpaBookingRequestAppointmentDecoder)),
  category: D.nullable(D.literal('single', 'couples', 'back_to_back', 'orientation', 'gig', 'chair')),
  single: D.nullable(D.boolean),
  couples: D.nullable(D.boolean),
  rebookingFlow: D.nullable(D.boolean),
  backToBack: D.nullable(D.boolean),
  orientation: D.nullable(D.boolean),
  gig: D.nullable(D.boolean),
  forConciergeClient: D.nullable(D.boolean),
  chair: D.nullable(D.boolean),
  paymentMethodId: D.nullable(D.string),
  location: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
      zip: D.nullable(D.string),
    })
  ),
  client: D.nullable(
    D.partial({
      id: D.nullable(StringOrNumberDecoder),
    })
  ),
  failOnDuplicateAppointment: D.nullable(D.boolean),
  requestTime: D.nullable(
    D.partial({
      start: D.nullable(D.number),
      end: D.nullable(D.number),
    })
  ),
  _quantity: D.nullable(D.number),
});
export type SpaBookingRequestType = D.TypeOf<typeof SpaBookingRequestDecoder>;

export type SpaBookingPayloadType = {
  bookings: SourceSpaBookingRequestType[];
  only_first_to_blasting?: boolean;
};

// TODO
export const SourceSpaBookingAppointmentDecoder = D.partial({
  id: StringOrNumberDecoder,
});
export type SourceSpaBookingAppointmentType = D.TypeOf<typeof SourceSpaBookingAppointmentDecoder>;
export const SpaBookingAppointmentDecoder = D.partial({
  id: D.string,
});
export type SpaBookingAppointmentType = D.TypeOf<typeof SpaBookingAppointmentDecoder>;

export const SourceSpaBookingDecoder = D.partial({
  id: StringOrNumberDecoder,
  parent_id: D.nullable(StringOrNumberDecoder),
  category_2: BookingCategoryDecoder,
  stage: D.string, // confirmed, etc - TODO
  notes: D.nullable(D.string),
  specific_therapist_requested: BooleanLiteralDecoder,
  therapist_gender_requested: D.nullable(GenderRequestedDecoder),
  fname: D.string,
  lname: D.string,
  for_guest: D.nullable(D.string),
  address_name: D.string,
  is_block_project: BooleanLiteralDecoder,
  is_orientation: BooleanLiteralDecoder,
  start_nyc: D.string, // 2024-11-12 04:11:55
  now_nyc: D.string, // 2024-11-12 04:11:55
  provider_id: D.nullable(StringOrNumberDecoder),
  member_id: StringOrNumberDecoder,
  start: D.string, // 2024-11-12 04:11:55
  time: D.string, // 2024-11-12 04:11:55
  provider: D.nullable(SourceProviderDecoder),
  member: D.nullable(
    D.partial({
      id: StringOrNumberDecoder,
      fname: D.string,
      lname: D.string,
      email: D.string,
      mobile: D.string,
    })
  ),
  duration: D.number,
  child_appts: D.UnknownArray,
  batch_booked_child_link: D.array(D.partial({ id: D.string })), // TODO ?????
  duration_hours: D.number,
  end_nyc: D.string, // 2024-11-12 04:11:55
  end: D.string, // 2024-11-12 04:11:55
  past_scheduled: BooleanLiteralDecoder,
  happening_now_scheduled: BooleanLiteralDecoder,
  future_scheduled: BooleanLiteralDecoder,
  num_of_appointments: D.number,
  is_cancelable: BooleanLiteralDecoder,
  is_canceled: BooleanLiteralDecoder,
  gig_appoitment_duration: D.number,
  tip: D.number,
  tip_cash: BooleanLiteralDecoder,
  tip_refused: BooleanLiteralDecoder,
  subtotal: D.number,
  pricing: D.array(
    D.partial({
      label: D.string,
      prc: D.number,
    })
  ),
});
export type SourceSpaBookingType = D.TypeOf<typeof SourceSpaBookingDecoder>;

export const SpaBookingDecoder = D.partial({
  id: D.string,
  parentId: D.nullable(D.string),
  category: BookingCategoryDecoder,
  stage: D.string, // confirmed, etc - TODO
  notes: D.nullable(D.string),
  specificTherapistRequested: D.boolean,
  therapistGenderRequested: D.nullable(GenderRequestedDecoder),
  firstName: D.string,
  lastName: D.string,
  forGuest: D.nullable(D.string),
  addressName: D.string,
  isBlockProject: D.boolean,
  isOrientation: D.boolean,
  startNyc: D.string, // 2024-11-12 04:11:55
  nowNyc: D.string, // 2024-11-12 04:11:55
  providerId: D.nullable(D.string),
  memberId: D.string,
  start: D.string, // 2024-11-12 04:11:55
  time: D.string, // 2024-11-12 04:11:55
  provider: D.nullable(ProviderDecoder),
  member: D.nullable(
    D.partial({
      id: D.string,
      fname: D.string,
      lname: D.string,
      email: D.string,
      mobile: D.string,
    })
  ),
  duration: D.number,
  childAppointments: D.UnknownArray,
  batchBookedChildLink: D.array(D.partial({ id: D.string })), // TODO ?????
  durationHours: D.number,
  endNyc: D.string, // 2024-11-12 04:11:55
  end: D.string, // 2024-11-12 04:11:55
  pastScheduled: D.boolean,
  happeningNowScheduled: D.boolean,
  futureScheduled: D.boolean,
  numOfAppointments: D.number,
  isCancelable: D.boolean,
  isCanceled: D.boolean,
  gigAppointmentDuration: D.number,
  tip: D.number,
  tipCash: D.boolean,
  tipRefused: D.boolean,
  subtotal: D.number,
  pricing: D.array(
    D.partial({
      label: D.string,
      price: D.number,
    })
  ),
});
export type SpaBookingType = D.TypeOf<typeof SpaBookingDecoder>;
