import { helpers } from 'utils';

import { Icon } from 'components/common';

import styles from './style.module.scss';

type Props = {
  id?: string;
};

const HypericeFooterBlock = (props: Props) => {
  const { id = '' } = props;
  return (
    <div id={id} className={styles.block} data-testid='hyperice-footer-block'>
      <div className={styles.glContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.imgContainer}>
            <img className={styles.logoZeel} src={helpers.getImagePath('logo.svg')} alt='zeel-logo' />
          </div>
          <Icon type='add' />
          <div className={styles.imgContainer}>
            <img
              className={styles.logoHyperice}
              src={helpers.getImagePath('logos/hyperice-logo.png')}
              alt='hyperice-logo'
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <p>
            Same-day, in-home
            <br /> wellness treatments.
          </p>
          <p>
            Zeel has partnered with Hyperice to deliver a comprehensive wellness experience – enhancing in-home
            treatments and offering technology for independent recovery and pain management.
          </p>
          <p>
            Percussion technology to
            <br /> transform the way you move.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HypericeFooterBlock;
