import { CSSProperties, ReactNode } from 'react';

import clsx from 'clsx';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import styles from './style.module.scss';

type Props = {
  id?: string;
  topper?: string;
  title?: ReactNode;
  description?: ReactNode;
  size?: string;
  half?: string | boolean; // if content takes only half the content container
  noHorizPaddingOnMobile?: boolean;
  slimPadding?: boolean;
  natural?: boolean;
  beige?: boolean;
  lightText?: boolean;
  className?: string;
  contentClassName?: string;
  children?: ReactNode;
  titleClassName?: string;
  topperClassName?: string;
  style?: CSSProperties;
  testId?: string;
};

const CoreBlock = (props: Props) => {
  const {
    id,
    topper,
    title,
    description,
    size: _size = 'normal',
    half,
    noHorizPaddingOnMobile,
    slimPadding,
    natural,
    beige,
    lightText,
    className,
    contentClassName,
    children,
    titleClassName,
    topperClassName,
    style,
    testId = 'core-block',
  } = props;
  const size = ['narrow', 'small', 'normal', 'large', 'full', 'half'].includes(_size) ? _size : 'normal';
  const innerContent = (
    <>
      <SkeletonCover>
        {topper && (
          <p className={clsx(styles.topper, topperClassName)} data-testid={`${testId}--topper`}>
            {topper}
          </p>
        )}
        {title && (
          <h3
            className={clsx(styles.title, titleClassName, { [styles['title--hasDescription']]: description })}
            data-testid={`${testId}--title`}
          >
            {title}
          </h3>
        )}
        {description && (
          <p className={styles.description} data-testid={`${testId}--description`}>
            {description}
          </p>
        )}
      </SkeletonCover>
      {children}
    </>
  );
  return (
    <div
      id={id}
      className={clsx(
        styles.block,
        {
          [styles['block--slimPadding']]: slimPadding,
          [styles['block--natural']]: natural,
          [styles['block--beige']]: beige,
          [styles['block--lightText']]: lightText,
        },
        className
      )}
      style={style}
      data-testid={testId}
    >
      <div
        className={clsx(
          styles.content,
          {
            [styles['content-narrow']]: size === 'narrow',
            [styles['content-small']]: size === 'small',
            [styles['content-normal']]: size === 'normal',
            [styles['content-large']]: size === 'large',
            [styles['content-full']]: size === 'full',
            [styles['content-noHorizPadding']]: noHorizPaddingOnMobile,
          },
          contentClassName
        )}
      >
        {half && (
          <div
            className={clsx(styles.half, {
              [styles['half-left']]: half === true || half === 'left',
              [styles['half-right']]: half === 'right',
            })}
          >
            {innerContent}
          </div>
        )}
        {!half && innerContent}
      </div>
    </div>
  );
};

export default CoreBlock;
