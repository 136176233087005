import { FC, useCallback, useState } from 'react';

import { useForm, usePage } from 'utils/hooks';

import { useLazyGetParkingAvailabilityQuery } from 'redux/apis/OG/booking';

import { Input } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import styles from './style.module.scss';

type Props = ModalProps & {
  required?: boolean;
  zip?: string;
  instructions?: string;
  placeholder?: string;
};

const ParkingLocationsInstructions: FC<Props> = ({
  required: propRequired,
  zip,
  instructions,
  placeholder,
  onClose,
  ...rest
}) => {
  const [required, setRequired] = useState<boolean>(false);
  const [getParkingAvailability] = useLazyGetParkingAvailabilityQuery();

  const form = useForm();

  const { isLoading } = usePage(async () => {
    if (zip && !propRequired) {
      const isParkingAvailable = await getParkingAvailability(zip).unwrap();
      if (isParkingAvailable) {
        setRequired(true);
      }
    } else if (propRequired) {
      setRequired(true);
    }
    form.setValue('instructions', instructions);
  });

  const save = useCallback(async () => {
    const newInstructions = form.getValue('instructions');
    onClose(newInstructions);
  }, [form]);

  return (
    <Modal
      {...rest}
      loading={isLoading}
      title='Add Location Instructions'
      onClose={() => onClose(instructions)}
      mobileType={'drawer'}
      actions={[
        {
          onClick: () => save(),
          label: 'Save',
          main: true,
          disabled: !form.isValid(),
        },
      ]}
      testId='parking-locations-instructions-modal'>
      {!isLoading && (
        <Input
          {...form.getProps('instructions')}
          autoFocus
          multiline
          className={styles.textBox}
          hideValidation
          placeholder={
            placeholder ||
            (required
              ? `Please add notes for your location e.g. "Use gate code 123," and provide parking details. If free parking is unavailable, we will bill you for the parking charge incurred and attach a picture of the parking bill to your Zeel receipt.`
              : `Please add notes for your location e.g. "Use gate code 123," and provide parking details.`)
          }
          testId='parking-locations-instructions-modal--note-input'
        />
      )}
    </Modal>
  );
};

export default ParkingLocationsInstructions;
