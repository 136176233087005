import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  PaymentCollectionType,
  PaymentMethodType,
  PaymentOption,
  getDefaultPaymentMethod,
  useGetProcessorPaymentMethodsQuery,
  useMakePaymentMethodDefaultMutation,
} from 'redux/apis/OG/payment';

import FieldTitle from 'components/common/FieldTitle';
import LinkButton from 'components/common/LinkButton';
import PaymentItem from 'components/common/PaymentItem';

import { usePage } from '../../../../utils/hooks';
import View from '../../components/View';
import { usePMCContext } from '../../context';
import ApplePay from '../../native/ApplePay';
import { PMCRenderMode, PMCRendererConfig, PMCViewError } from '../../types';
import styles from './style.module.scss';

export type PaymentMethodsProps = PMCRendererConfig;

const ListRenderer: FC<PaymentMethodsProps> = ({
  title = 'Select Payment Method',
  description,
  buttonText = 'Continue',
  secondaryLabelText = 'Add New Payment Method',
  initialError,
  className,
}) => {
  const {
    usedFor,
    amount,
    bookingData,
    processor,
    changeMode,
    complete,
    initialSelectedEntityId,
    setSelectedAsDefault,
    listApplePay,
    devMode,
  } = usePMCContext();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<PMCViewError | null>(initialError);
  const [modeChanging, setModeChanging] = useState<boolean>(false);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>();
  // TODO: move this to root, and fetch the available payment methods during init
  const {
    data: paymentMethods,
    isLoading: isPaymentMethodsLoading,
    isFetching: isPaymentMethodsFetching,
  } = useGetProcessorPaymentMethodsQuery({
    usedFor,
    amount,
    bookingData,
  });

  const currentDefaultPaymentMethods = useMemo(() => {
    const defaultMethodIds: Partial<Record<PaymentOption, string>> = {};
    Object.entries(paymentMethods || {}).forEach(([paymentOption, methods]) => {
      const defaultIndex = methods.findIndex((method) => method.default);
      if (defaultIndex > -1) defaultMethodIds[paymentOption as PaymentOption] = methods[defaultIndex].id;
    });
    return defaultMethodIds;
  }, [paymentMethods]);

  const selectedEntityPaymentOption = useMemo<PaymentOption | null>(() => {
    if (!selectedEntityId) return null;
    const options: PaymentOption[] = [PaymentOption.CreditCard];
    let _selected = null;
    options.forEach((option) => {
      (paymentMethods?.[option] || []).forEach((method) => {
        if (method.id === selectedEntityId) {
          _selected = option;
        }
      });
    });
    return _selected;
  }, [selectedEntityId, paymentMethods]);

  const selectedEntity = useMemo(() => {
    if (!selectedEntityId) return null;
    return paymentMethods?.[selectedEntityPaymentOption]?.find((method) => method.id === selectedEntityId);
  }, [paymentMethods, selectedEntityId, selectedEntityPaymentOption]);

  const [makeCreditCardDefault] = useMakePaymentMethodDefaultMutation();

  const saveSelectedMethodAsDefault = useCallback(async () => {
    const currentDefaultPaymentMethodId = currentDefaultPaymentMethods[selectedEntityPaymentOption];
    if (!selectedEntityId || selectedEntityId === currentDefaultPaymentMethodId || saving) return;
    setSaving(true);
    try {
      if (selectedEntityPaymentOption === PaymentOption.CreditCard) {
        await makeCreditCardDefault(selectedEntityId).unwrap();
      }
      // TODO: add support to default other payment options as well (insurance, etc)
    } catch (e) {
      console.error(e);
      throw new Error('There was an error saving your default payment method.');
    }
    setSaving(false);
  }, [makeCreditCardDefault, currentDefaultPaymentMethods, selectedEntityId, selectedEntityPaymentOption]);

  const { isLoading } = usePage(
    () => {
      // TODO: support other payment options too
      if (
        initialSelectedEntityId &&
        paymentMethods?.credit_card?.find((method) => method.id === initialSelectedEntityId)
      ) {
        setSelectedEntityId(initialSelectedEntityId);
      } else if (paymentMethods?.credit_card?.length) {
        setSelectedEntityId(getDefaultPaymentMethod(paymentMethods.credit_card)?.id || null);
      }
    },
    { hold: isPaymentMethodsLoading || isPaymentMethodsFetching }
  );

  useEffect(() => {
    if (isLoading || isPaymentMethodsLoading || isPaymentMethodsFetching || modeChanging) return;
    console.log('in the useEffect responsible for switching to create mode when no payments available in the list');
    console.log('useEffect was allowed to execute. Payment methods on memory: ', paymentMethods);
    if (!paymentMethods || !paymentMethods?.credit_card?.length) {
      setModeChanging(true);
      changeMode(PMCRenderMode.Create, { dueToNoMethods: true });
    }
  }, [isLoading, isPaymentMethodsLoading, isPaymentMethodsFetching, paymentMethods]);

  const handleApplePayCallback = useCallback(
    async ({ nonce, type }) => {
      if (listApplePay)
        complete({
          success: true,
          paymentOption: PaymentOption.ApplePay,
          collectionType: PaymentCollectionType.OneTime,
          processor,
          data: { nonce, type },
        });
    },
    [listApplePay, processor, complete]
  );

  const handleAddClick = useCallback(() => {
    changeMode(PMCRenderMode.Create);
  }, [changeMode]);

  const handleEditClick = useCallback(
    (_id: string, _paymentOption: PaymentOption) => {
      changeMode(PMCRenderMode.Update, { updateEntity: { id: _id, paymentOption: _paymentOption } });
    },
    [changeMode]
  );

  const handleProceed = useCallback(async () => {
    if (saving) return;
    if (setSelectedAsDefault) {
      try {
        await saveSelectedMethodAsDefault();
      } catch (e: any) {
        setError({
          title: 'Error',
          description: e?.message || 'There was an error saving your payment method.',
        });
        return;
      }
    }
    complete({
      success: true,
      paymentOption: selectedEntityPaymentOption,
      collectionType: PaymentCollectionType.Vault,
      processor,
      data: {
        id: selectedEntityId,
        extra: {
          profile_name: selectedEntity?.label,
          expiration_month: selectedEntity?.expirationMonth,
          expiration_year: selectedEntity?.expirationYear,
          last_four: selectedEntity?.lastFourDigits,
          card_type: selectedEntity?.cardType,
        },
      },
    });
  }, [
    selectedEntityId,
    selectedEntityPaymentOption,
    selectedEntity,
    setSelectedAsDefault,
    saving,
    complete,
    processor,
    saveSelectedMethodAsDefault,
  ]);

  useEffect(() => {
    // update url query params to add the id and payment option
    if (selectedEntityId && selectedEntityPaymentOption) {
      const url = new URL(window.location.href);
      url.searchParams.set('list__selected_id', selectedEntityId);
      url.searchParams.set('list__selected_id_payment_option', selectedEntityPaymentOption);
      window.history.replaceState({}, '', url.toString());
    }
  }, [selectedEntityId, selectedEntityPaymentOption]);

  return (
    <View
      title={title}
      description={description}
      error={error}
      loading={isLoading}
      skeleton
      className={className}
      button={{
        label: buttonText,
        onClick: handleProceed,
        disabled: !selectedEntityId || saving,
      }}
    >
      <FieldTitle className={styles.topper}>Credit Cards</FieldTitle>
      {/* TODO: render both insurance and credit cards, and differentiate both in some way */}
      {(isLoading ? dummyPaymentMethods : paymentMethods?.credit_card || []).map((creditCard, index) => {
        const enabled = !saving;
        const updatable = creditCard.updatable && creditCard.processor;
        const affectedByError = error?.affectedEntityId === creditCard.id;
        return (
          <PaymentItem
            key={creditCard.id}
            disabled={!enabled}
            selected={selectedEntityId ? creditCard.id === selectedEntityId : false}
            failedMessage={affectedByError && (error?.cardMessage || error?.title)}
            payment={creditCard}
            onClick={() => setSelectedEntityId(creditCard.id)}
            onEdit={updatable ? () => handleEditClick(creditCard.id, PaymentOption.CreditCard) : undefined}
            testId={`PMC-list--payment-item-${index}`}
          />
        );
      })}
      <LinkButton
        className={styles.link}
        arrow={false}
        icon='add-circle-bold'
        iconProps={{ size: 22 }}
        onClick={() => handleAddClick()}
        testId='PMC-list--add-new-payment-method-link'
      >
        {secondaryLabelText}
      </LinkButton>
      {listApplePay && !isPaymentMethodsFetching && (
        <ApplePay
          usedFor={usedFor}
          request={listApplePay}
          onSuccess={handleApplePayCallback}
          className={styles.applePayButton}
          testId={`PMC-list--apple-pay-button`}
          devMode={devMode}
          logs
        />
      )}
    </View>
  );
};

export default ListRenderer;

const dummyPaymentMethods: PaymentMethodType[] = [
  {
    id: '1',
    token: '9vs6wr2',
    expired: false,
    status: '1',
    braintreePaymentMethodToken: '9vs6wr2',
    braintreeCustomProfileId: '899476042',
    lastFourDigits: '4444',
    expirationYear: '2025',
    expirationMonth: '12',
    zipCode: null,
    cardBin: '555555',
    label: 'Master card',
    firstName: null,
    lastName: null,
    cardType: 'MasterCard',
    cardTypeAbbreviation: 'mc',
    dateCreated: '2021-09-30 10:25:05',
    dateEdited: '2024-05-15 09:37:34',
    statusLabel: 'good',
    labelFull: 'Master card dummy 1',
    expirationLabel: '12/25',
    default: true,
  },
  {
    id: '2',
    token: '9vs6wr2',
    expired: false,
    status: '1',
    braintreePaymentMethodToken: '9vs6wr2',
    braintreeCustomProfileId: '899476042',
    lastFourDigits: '4444',
    expirationYear: '2025',
    expirationMonth: '12',
    zipCode: null,
    cardBin: '555555',
    label: 'Master card',
    firstName: null,
    lastName: null,
    cardType: 'MasterCard',
    cardTypeAbbreviation: 'mc',
    dateCreated: '2021-09-30 10:25:05',
    dateEdited: '2024-05-15 09:37:34',
    statusLabel: 'good',
    labelFull: 'Master card dummy 2',
    expirationLabel: '12/25',
    default: true,
  },
];
