import { CSSProperties, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { Button } from 'components/common';
import { ButtonProps } from 'components/common/Button';

import styles from './style.module.scss';

type Props = {
  title?: string;
  className?: string;
  buttonProps?: ButtonProps;
  action: (e: MouseEvent<HTMLButtonElement>) => void;
  actionText: string;
  subContent?: ReactNode;
  style?: CSSProperties;
  testId?: string;
};

const ButtonBlock = (props: Props) => {
  const { title, action, actionText, className, subContent, buttonProps = {}, style, testId = 'button-block' } = props;

  return (
    <div className={clsx(styles.block, className)} style={style}>
      {title && <h3 data-testid={`${testId}--title`}>{title}</h3>}
      <Button className={styles.button} onClick={action} {...buttonProps} testId={`${testId}--button`}>
        {actionText}
      </Button>
      {subContent && <div className={styles.subContent}>{subContent}</div>}
    </div>
  );
};

export default ButtonBlock;
