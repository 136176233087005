import { FC, MouseEvent } from 'react';

import clsx from 'clsx';

import { Button, IconSvg, LinkButton } from 'components/common';

import styles from './style.module.scss';

type Props = {
  name: string;
  cost: number;
  details: string;
  hideButton?: boolean;
  buttonText: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  highlightCost: boolean;
  loading?: boolean;
  invalid?: boolean;
  info?: Array<{ icon: string; title: string; description: string }>;
  expanded?: boolean;
  subtitle?: string;
  expandFunction?: (e: MouseEvent<HTMLDivElement>) => void;
  learnMoreHref?: string;
};
const DetailedPricing: FC<Props> = ({
  name,
  loading = false,
  invalid = false,
  cost,
  details,
  buttonText,
  onClick,
  highlightCost,
  info = [],
  expanded,
  subtitle,
  learnMoreHref,
  expandFunction,
}) => {
  return (
    <div
      className={clsx(
        styles.pricing,
        { [styles.loading]: loading },
        { [styles.invalid]: invalid },
        { [styles['pricing--open']]: expanded }
      )}
    >
      <h4>{name}</h4>
      <p className={clsx(styles.cost, { [styles.highlightCost]: highlightCost })}>${cost.toFixed(2)}</p>
      <p className={styles.details}>{details}</p>
      <Button className={styles.button} onClick={onClick}>
        {buttonText}
      </Button>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.detailedItems}>
        {info &&
          info.map((item, i) => {
            return (
              <div key={i} className={styles.item}>
                <div className={styles.leftSide}>
                  <IconSvg name={item.icon} />
                </div>
                <div className={styles.rightSide}>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
      </div>
      {learnMoreHref && (
        <LinkButton className={styles.learnLink} arrow href={learnMoreHref}>
          Learn More
        </LinkButton>
      )}
      <div className={styles.toggler} onClick={expandFunction}>
        <IconSvg name={expanded ? 'caret-up' : 'caret-down'} />
      </div>
    </div>
  );
};

export default DetailedPricing;
