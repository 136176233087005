import { FC, useCallback, useEffect } from 'react';

import { useForm } from 'utils/hooks';

import { Input } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

type Props = ModalProps & {
  zip: string;
  zipValidator?: Function; // takes zip, and returns { valid: true|false, error: ?string }
  title?: string;
  description?: string;
  closable?: boolean;
  error?: string;
};

const ZipCode: FC<Props> = ({
  zip,
  zipValidator,
  error,
  title = 'Your Location',
  description,
  closable = true,
  onClose,
  ...rest
}) => {
  const form = useForm();

  useEffect(() => {
    form.setValue('zip', zip);
    if (error) {
      setTimeout(() => {
        form.setError('zip', error);
      });
    }
  }, []);

  const confirm = useCallback(async () => {
    const _zip = form.getValue('zip');

    let _error = null;
    let valid = true;

    if (_zip?.length < 5) {
      form.setError('zip', 'Please enter a valid ZIP code');
      return;
    }

    if (zipValidator) {
      const response = await zipValidator(_zip);
      const { valid: zipValid, error: zipError } = response || {};
      valid = zipValid;
      _error = zipError;
    }

    if (valid) {
      onClose(_zip);
    } else if (_error) {
      form.setError('zip', _error || 'No services in this ZIP code');
    }
  }, [form]);

  return (
    <Modal
      {...rest}
      title={title}
      description={description}
      onClose={onClose}
      mobileType={'drawer'}
      closable={closable}
      onSubmit={confirm}
      actions={[{ label: 'Confirm', action: 'submit', disabled: !form.isValid() }]}
      testId='zip-code-modal'>
      <Input
        id='zip-modal-input'
        {...form.getProps('zip')}
        type='number'
        max={5}
        hideValidState
        autoFocus
        clearable
        label='Zip Code'
        testId='zip-code-modal--zip-code-input'
      />
    </Modal>
  );
};

export default ZipCode;
