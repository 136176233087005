import { FC } from 'react';

import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

type Props = ModalProps & {
  description?: string;
  title?: string;
  confirmLabel?: string;
  hideCancel?: boolean;
  confirmButtonVariant?: string;
};

const Confirmation: FC<Props> = ({
  description = '',
  title = 'Are you sure?',
  confirmLabel = 'Confirm',
  hideCancel,
  onClose,
  testId,
  confirmButtonVariant,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      onClose={onClose}
      title={title}
      description={description}
      mobileType={'drawer'}
      mobileStackButtons
      actions={[
        ...(hideCancel ? [] : ([{ label: 'Cancel', action: 'close' }] as any)),
        {
          label: confirmLabel,
          action: 'confirm',
          main: true,
          ...(confirmButtonVariant ? { variant: confirmButtonVariant } : {}),
        },
      ]}
      testId={testId ?? 'confirmation-modal'}
    />
  );
};

export default Confirmation;
