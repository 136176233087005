import { Component, ReactNode } from 'react';

import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import { SkeletonContext } from '@zeel-dev/zeel-ui';

import Carousel from '../Carousel';
import { IconProps } from '../IconSvg';
import SelectionCard from '../SelectionCard';
import styles from './style.module.scss';

type Props = {
  className?: string;
  items: Array<ItemData>;
  multiple?: boolean;
  label?: ReactNode;
  labelMore?: ReactNode;
  labelMoreOnClick?: () => void;
  initialValue?: any;
  autoSelectFirst?: boolean;
  value?: any;
  name?: string;
  onChange?: (v: any) => void;
  deselectable?: boolean;
  vertical?: boolean;
  cardClasses?: string;
  containerClasses?: string;
  carouselClasses?: string;
  carousel?: boolean;
  animateEntrance?: { from?: number; to?: number };
  carouselSettings?: { [key: string]: any };
  hasIcons?: boolean;
  testId?: string;
};

export interface ItemData {
  id?: string;
  title?: string;
  description?: string;
  subCardContent?: any;
  icons?: Array<{ icon: string; title: string }>;
  image?: string;
  icon?: string;
  iconProps?: IconProps;
  disabled?: boolean;
  selectedBackgroundClass?: string;
  underContent?: ReactNode;
}

export default class SelectionCardList extends Component<Props> {
  static contextType = SkeletonContext;

  componentDidMount() {
    const { onChange, multiple } = this.props;
    const { autoSelectFirst, initialValue, value, items } = this.props;

    let startVal = value || initialValue || (autoSelectFirst && items[0] ? (multiple ? [items[0]] : items[0]) : null);
    if (multiple && (!startVal || !(startVal instanceof Array))) {
      startVal = [];
    }

    if (onChange) {
      onChange(startVal);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value && !isEqual(nextProps.value, this.props.value)) {
      if (nextProps.onChange) {
        nextProps.onChange(nextProps.value);
      }
    }
  }

  onClick = (itemId: string) => {
    const { multiple = false, value, onChange, deselectable = true } = this.props;
    let newValue = value || [];

    if (multiple && !(newValue instanceof Array)) {
      newValue = [];
    }

    if (multiple && newValue instanceof Array) {
      const itemIndex = value.indexOf(itemId);
      if (itemIndex > -1) {
        newValue.splice(itemIndex, 1);
      } else {
        newValue.push(itemId);
      }
    } else {
      if (value === itemId && deselectable) {
        newValue = null;
      } else {
        newValue = itemId;
      }
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  selectOnChange = (index) => {
    const { items, onChange } = this.props;
    if (items && onChange) {
      const itemToSelect: ItemData = items[index] || {};
      onChange(itemToSelect.id);
    }
  };

  getContent = () => {
    const { multiple, items, value, cardClasses, hasIcons = true, testId } = this.props;

    return items.map((item, i) => {
      const {
        title,
        id,
        description,
        subCardContent,
        icons,
        image,
        icon,
        iconProps,
        disabled,
        selectedBackgroundClass,
        underContent,
      } = item;
      const isSelected = (multiple ? (value || []).indexOf(id) > -1 : value === id) && !disabled;

      return (
        <SelectionCard
          key={`${i}-${item.title}`}
          className={clsx(cardClasses, styles.card)}
          title={title}
          description={description}
          subCardContent={subCardContent}
          icons={hasIcons && icons}
          image={image}
          icon={icon}
          iconProps={iconProps}
          disabled={disabled}
          selected={isSelected}
          selectedBackgroundClass={selectedBackgroundClass}
          underContent={underContent}
          onClick={() => this.onClick(id)}
          data-testid={`${testId ?? `selection-card-list`}--item-${i}`}
        />
      );
    });
  };

  render() {
    const {
      label,
      labelMore,
      labelMoreOnClick,
      vertical,
      containerClasses,
      carouselClasses,
      className,
      carousel,
      animateEntrance,
      carouselSettings = {},
      testId,
    } = this.props;
    const content = this.getContent();

    // if simple list
    return (
      <div
        className={clsx(styles.list, { [styles.vertical]: vertical }, className)}
        data-testid={testId ?? `selection-card-list`}
      >
        {label && (
          <label className={styles.label} data-testid={`${testId ?? `selection-card-list`}--label`}>
            {label}
            {labelMore && (
              <span className={styles.labelMore}>
                <a onClick={() => labelMoreOnClick?.()}>{labelMore}</a>
              </span>
            )}
          </label>
        )}
        <div className={clsx(styles.contentContainer, containerClasses)}>
          {!carousel && content.map((item) => item)}
          {carousel && (
            <Carousel
              className={clsx(styles.carousel, carouselClasses)}
              overflow='mobile-visible'
              animateEntrance={animateEntrance}
              slides={content}
              settings={{
                autoplay: false,
                infinite: false,
                speed: animateEntrance ? 1000 : 500,
                slidesToShow: Math.min(content.length, 3),
                dots: false,
                variableWidth: false,
                swipeToSlide: true,
                arrows: true,
                responsive: [
                  {
                    breakpoint: 1040,
                    settings: {
                      arrows: true,
                      variableWidth: false,
                      slidesToShow: Math.min(content.length, 3),
                    },
                  },
                  {
                    breakpoint: 940,
                    settings: {
                      arrows: false,
                      variableWidth: false,
                      slidesToShow: Math.min(content.length, 2),
                    },
                  },
                  {
                    breakpoint: 585,
                    settings: {
                      slidesToShow: 1,
                      arrows: false,
                      variableWidth: false,
                      speed: 300,
                      afterChange: (index) => this.selectOnChange(index),
                    },
                  },
                ],
                ...carouselSettings,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
