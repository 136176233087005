import { MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import { Button } from 'components/common';

import styles from './style.module.scss';

type Props = {
  data: Array<{ title: string; description: ReactNode }>;
  title?: string;
  action?: (e: MouseEvent<HTMLButtonElement>) => void;
  actionText?: string;
  testId?: string;
};

const FAQBlock = (props: Props) => {
  const { data, title, action, actionText, testId = 'faq-block' } = props;

  return (
    <div className={styles.base} data-testid={testId}>
      <h3 data-testid={`${testId}--title`}>{title || 'Frequently Asked Questions'}</h3>
      <div className={clsx(styles.glContainerSmall, styles.faqContainer)}>
        {data.map(({ title: blockTitle, description }, i) => (
          <div key={i} className={styles.faq}>
            <h5>{blockTitle}</h5>
            <p>{description}</p>
          </div>
        ))}
      </div>
      {action && actionText && (
        <div className={styles.actionButtonContainer}>
          <Button className={styles.actionButton} onClick={action} testId={`${testId}--main-button`}>
            {actionText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FAQBlock;
