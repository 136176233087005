import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { routes } from 'utils';
import { useForm, useLocation, usePage, useUser } from 'utils/hooks';

import { useLazyGetParkingAvailabilityQuery, useLazyGetServicesQuery } from 'redux/apis/OG/booking';
import { useCreateSpaClientMutation, useGetClientListQuery, useUpdateSpaClientNameMutation } from 'redux/apis/OG/spa';
import { Spa, Steps, useSpaSelfServeFlowSlice } from 'redux/features/spa/selfServe';

import { InformationIcon, useModals, useNavigate } from '@zeel-dev/zeel-ui';
import { FieldRow, FieldTitle, Input } from 'components/common';

import { useCreateAddressMutation, useUpdateAddressMutation } from '../../../../redux/apis/OG/user';
import FieldHelperText from '../../components/FieldHelperText';
import Layout, { Body, Cell, Footer, Row } from '../../components/Layout';
import { useSpaSelfServeEvent } from '../../index';
import ModalFinishAccountSetup from '../../modals/FinishAccountSetup';
import ModalServiceUnavailable from '../../modals/ServiceUnavailable';

/**
 * Location Info step for the Spa Self-Serve flow. Used to collect location
 * information for the spa, and also to add/edit spas for a returning user.
 */
const LocationInfo: FC = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { logEvent } = useSpaSelfServeEvent();
  const { openModal } = useModals();
  const { next, spa: spaToEdit, preventRedirect, setAsFlowDefault } = locationState || {};
  const { user, isTempMember, isLoading: isUserLoading } = useUser();
  const {
    actions: { defaultSpaChanged, requestsSanitized },
    selectors: { useCollectedInformationSelector, useEmailSelector, useRequestsSelector },
  } = useSpaSelfServeFlowSlice();
  const timeoutRef = useRef<any>();
  /** Queries **/
  const [checkAvailabilityQuery] = useLazyGetServicesQuery();
  const [checkParkingRequirement] = useLazyGetParkingAvailabilityQuery();
  const [createAddressMutation] = useCreateAddressMutation();
  const [updateAddressMutation] = useUpdateAddressMutation();
  const [updateSpaNameMutation] = useUpdateSpaClientNameMutation();
  const [createSpaClientMutation] = useCreateSpaClientMutation();
  const { data: clientsList, isLoading: isClientsListLoading } = useGetClientListQuery(
    { bookingType: 'spa_self_serve' },
    {
      skip: isUserLoading || isTempMember,
    }
  );

  /** States **/
  const [fetchingParkingRequirement, setFetchingParkingRequirement] = useState(false);
  const [parkingRequired, setParkingRequired] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [edit, setEdit] = useState<false | { client: any; location: any }>(false);

  /** Selectors **/
  const storedEmail = useEmailSelector();
  const requests = useRequestsSelector();
  const collectedInformation = useCollectedInformationSelector();

  /** Memoized values **/
  const hasSpasOnFile = useMemo(() => (clientsList || []).length > 0, []);

  /** Effects **/
  const { isLoading } = usePage(
    async ({ _user }) => {
      if (hasSpasOnFile && !spaToEdit && !preventRedirect) {
        return navigate(next || routes.SPA_SELF_SERVE.CHECKOUT(), { replace: true });
      }

      // Checking if edit mode
      const c = clientsList?.find((cc) => cc.id === spaToEdit?.clientId);
      const l = c?.addresses?.find((a) => a.id === spaToEdit?.locationId);

      if (c && l) {
        setEdit({
          client: c,
          location: l,
        });
        form.setValue('spaName', c.name);
        form.setValue('address1', l.address1);
        form.setValue('address2', l.address2);
        form.setValue('city', l.city);
        form.setValue('state', l.state);
        form.setValue('zipCode', l.zipCode);
        if (l.dressCode) form.setValue('dressCode', l.dressCode);
        if (l.checkInInstructions) form.setValue('checkInInstructions', l.checkInInstructions);
        if (l.checkOutInstructions) form.setValue('checkOutInstructions', l.checkOutInstructions);
        if (l.parkingInstructions) form.setValue('parkingInstructions', l.parkingInstructions);
      } else {
        if (_user?.mobile) form.setValue('mobilePhoneNumber', _user.mobile);
        form.setValue('address1', collectedInformation.address.address1);
        form.setValue('address2', collectedInformation.address.address2);
        form.setValue('city', collectedInformation.address.city);
        form.setValue('state', collectedInformation.address.state);
        form.setValue('zipCode', collectedInformation.address.zip);
      }
    },
    { hold: isUserLoading || isClientsListLoading }
  );

  /** Effects **/
  // Fetch parking requirement when zip changes
  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      const _zip = form.getValue('zipCode');
      if (!_zip || fetchingParkingRequirement) return;
      setFetchingParkingRequirement(true);
      try {
        const _parkingRequired = await checkParkingRequirement(_zip).unwrap();
        setParkingRequired(_parkingRequired);
      } catch (e) {
        console.error(e);
      }
      setFetchingParkingRequirement(false);
    }, 200);
  }, [form.getValue('zipCode')]);

  /** Handlers **/
  const saveSpa = useCallback(async () => {
    // 1. check service availability
    const {
      spaName,
      dressCode,
      checkInInstructions,
      checkOutInstructions,
      parkingInstructions,
      address1,
      address2,
      city,
      state,
      zipCode,
    } = form.getValues();
    let pricingAvailable = true;
    try {
      const availableServices = await checkAvailabilityQuery({ zipCode }).unwrap();
      if (!availableServices?.length) pricingAvailable = false;
    } catch (availabilityError) {
      console.error(availabilityError);
      pricingAvailable = false;
    }
    if (!pricingAvailable) {
      setSubmitting(false);
      return openModal({
        element: <ModalServiceUnavailable zip={zipCode} />,
      });
    }
    // 2. create/update spa client
    let spaData: Spa;
    try {
      if (edit) {
        await updateAddressMutation({
          name: edit.location.name,
          address1,
          address2,
          city,
          state,
          zipCode,
          parking: parkingInstructions,
          dressCode,
          checkIn: checkInInstructions,
          checkOut: checkOutInstructions,
          id: edit.location.id,
        }).unwrap();
        await updateSpaNameMutation({ name: spaName, id: edit.client.id }).unwrap();
      } else {
        const spaAddress = await createAddressMutation({
          address1,
          address2,
          city,
          state,
          zipCode,
          parking: parkingInstructions,
          name: spaName,
          dressCode,
          checkIn: checkInInstructions,
          checkOut: checkOutInstructions,
        }).unwrap();
        if (!spaAddress.id) throw new Error('Failed to create spa address');
        const newSpaClientId = await createSpaClientMutation({ spaName, locationId: spaAddress.id }).unwrap();
        spaData = {
          clientId: newSpaClientId,
          locationId: spaAddress.id,
        };
        // 3. update all requests with the new spa data
        requestsSanitized(
          requests.map((request) => {
            if (!request.spa) {
              return {
                ...request,
                spa: spaData,
              };
            }
            return request;
          })
        );
        if (setAsFlowDefault) defaultSpaChanged(spaData);
      }
    } catch (spaCreateError: any) {
      console.error(spaCreateError);
      if (spaCreateError) {
        if (spaCreateError.address1) form.setError('address1', spaCreateError.address1);
        if (spaCreateError.address2) form.setError('address2', spaCreateError.address2);
        if (spaCreateError.city) form.setError('city', spaCreateError.city);
        if (spaCreateError.dress_code) form.setError('dressCode', spaCreateError.dress_code);
        if (spaCreateError.check_in) form.setError('checkInInstructions', spaCreateError.check_in);
        if (spaCreateError.check_out) form.setError('checkOutInstructions', spaCreateError.check_out);
        if (spaCreateError.parking) form.setError('parkingInstructions', spaCreateError.parking);
        if (spaCreateError.zip) form.setError('zipCode', spaCreateError.zip);
        if (spaCreateError.state) form.setError('state', spaCreateError.state);
        if (spaCreateError.spa_name_already_exists) form.setError('spaName', spaCreateError.spa_name_already_exists);
      }
      setSubmitError('An error occurred while creating your Spa Location.');
      setSubmitting(false);
      return;
    }

    // 3. redirect
    navigate(next || routes.SPA_SELF_SERVE.CHECKOUT());
  }, [requests, form, edit]);

  const proceed = useCallback(async () => {
    const { spaName, einNumber, address1, address2, city, state, mobilePhoneNumber } = form.getValues();
    setSubmitting(true);
    if (edit) {
      saveSpa();
    } else {
      await logEvent({
        email: storedEmail,
        address_1: address1,
        address_2: address2,
        city,
        state,
        company: spaName,
        ein: einNumber,
        mobile: mobilePhoneNumber,
      });
      openModal(
        {
          element: (
            <ModalFinishAccountSetup
              initialEmail={storedEmail}
              initialMobilePhone={form.getValue('mobilePhoneNumber')}
            />
          ),
        },
        async (response) => {
          if (response) {
            await logEvent({
              email: storedEmail,
              address_1: address1,
              address_2: address2,
              city,
              state,
              company: spaName,
              ein: einNumber,
              mobile: mobilePhoneNumber,
              first_name: response?.firstName,
              last_name: response?.lastName,
            });
            saveSpa();
          } else {
            setSubmitting(false);
          }
        }
      );
    }
  }, [saveSpa, form, edit]);

  return (
    <Layout
      loading={isLoading}
      skeleton={isLoading}
      tags={{
        title: 'Spa Self-Serve - Location Info',
      }}
      step={Steps.LocationInfo}
      title={`${edit ? 'Edit' : 'Add'} Spa Information`}
      testId='spa-self-serve--location-info-step'
      showTimeline
      navProps={edit ? { dualLogo: { logoUrl: edit.client.logo, fallbackName: edit.client.name } } : {}}
      error={submitError}
      backAction={() => navigate(routes.SPA_SELF_SERVE.REQUESTS())}
    >
      <Body>
        <Row>
          <Cell>
            <FieldTitle>Location</FieldTitle>
            <FieldRow wrap={1}>
              <Input {...form.getProps('spaName')} placeholder={`Spa Name`} hideValidationSpaceWhenEmpty />
              <FieldRow>
                <Input {...form.getProps('address1')} placeholder={`Address 1`} hideValidationSpaceWhenEmpty />
                <Input
                  {...form.getProps('address2')}
                  required={false}
                  placeholder={`Spa/Floor/Room # (Optional)`}
                  hideValidationSpaceWhenEmpty
                />
              </FieldRow>
              <FieldRow>
                <Input {...form.getProps('city')} placeholder={`City`} hideValidationSpaceWhenEmpty />
                <Input {...form.getProps('state')} placeholder={`State`} hideValidationSpaceWhenEmpty />
              </FieldRow>
              <Input {...form.getProps('zipCode')} placeholder={`Zip Code`} hideValidationSpaceWhenEmpty />
              {(!edit || !user?.mobile) && (
                <Input
                  {...form.getProps('mobilePhoneNumber')}
                  type='mobile'
                  placeholder={`Mobile Phone Number`}
                  hideValidationSpaceWhenEmpty
                />
              )}
              <Input
                {...form.getProps('einNumber')}
                required={false}
                placeholder={`EIN # (Optional)`}
                hideValidationSpaceWhenEmpty
              />
            </FieldRow>
          </Cell>
          <Cell>
            <FieldTitle>Therapist Instructions</FieldTitle>

            <Input
              {...form.getProps('dressCode')}
              required={false}
              placeholder={`Dress Code`}
              hideValidationSpaceWhenEmpty
            />
            <FieldHelperText start={<InformationIcon />} expandable gutterTop>
              Please detail exactly what you’d like the therapist to wear. For example, many of our spas request that
              therapists wear all black, professional spa attire, and they do not allow logos or athleisure wear.
              <br />
              Please include detailed information about your tattoo, alternative hair color, and piercing policy as
              well. Are therapists permitted to have visible tattoos? Are there limitations to the size and location of
              the tattoos?
            </FieldHelperText>

            <Input
              {...form.getProps('checkInInstructions')}
              required={false}
              placeholder={`Check-In Instructions`}
              hideValidationSpaceWhenEmpty
            />
            <FieldHelperText start={<InformationIcon />} expandable gutterTop>
              Who should the therapist ask for upon arrival? Where would you like them to go to check in for their
              appointment?
            </FieldHelperText>

            <Input
              {...form.getProps('checkOutInstructions')}
              required={false}
              placeholder={`Check-Out Instructions`}
              hideValidationSpaceWhenEmpty
            />
            <FieldHelperText start={<InformationIcon />} expandable gutterTop>
              What are the checkout expectations for the property? What tasks should the therapist complete upon
              preparing to leave? Who should they inform when they leave? If there is anything they need to turn back in
              (such as a uniform or key card), please note that here.
            </FieldHelperText>

            <Input
              {...form.getProps('parkingInstructions')}
              required={parkingRequired}
              placeholder={`Parking Instructions${parkingRequired ? ' (Required)' : ''}`}
              hideValidationSpaceWhenEmpty
            />
            <FieldHelperText start={<InformationIcon />} expandable gutterTop>
              Where should the therapist park? Where should they enter the facility? Please be as specific as possible
              here. We want to set the therapist up for a successful arrival. If the parking lot is difficult to access,
              please include detailed instructions to park and enter the facility from a major road.
            </FieldHelperText>
          </Cell>
        </Row>
      </Body>
      <Footer
        actions={[
          {
            type: 'button',
            label: edit ? 'Save' : 'Continue',
            onClick: proceed,
            componentProps: {
              loading: submitting,
              disabled:
                (edit
                  ? !form.isValid([
                      'spaName',
                      'address1',
                      'address2',
                      'city',
                      'state',
                      'zipCode',
                      'einNumber',
                      'dressCode',
                      'checkInInstructions',
                      'checkOutInstructions',
                      'parkingInstructions',
                    ])
                  : !form.isValid([
                      'spaName',
                      'address1',
                      'address2',
                      'city',
                      'state',
                      'zipCode',
                      'einNumber',
                      'dressCode',
                      'checkInInstructions',
                      'checkOutInstructions',
                      'parkingInstructions',
                      'mobilePhoneNumber',
                    ])) ||
                (parkingRequired && !form.getValue('parkingInstructions')) ||
                fetchingParkingRequirement,
            },
          },
        ]}
      />
    </Layout>
  );
};

export default LocationInfo;
